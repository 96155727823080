define("ember-cli-active-link-wrapper/components/active-link", ["exports", "ember-cli-active-link-wrapper/mixins/active-link"], function (_exports, _activeLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_activeLink.default, {
    tagName: 'li'
  });

  _exports.default = _default;
});