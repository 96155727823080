define("ember-svg-jar/inlined/16-bits-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5235\" transform=\"translate(-1108 -417)\"><rect data-name=\"Rectangle 2313\" width=\"20\" height=\"20\" rx=\"1\" transform=\"translate(1108 417)\" fill=\"#60C4D0\"/><path data-name=\"Trac&#xE9; 3143\" d=\"M1114.26 431h1.78v-7.3h-2.77v1.59h.99zm8.15-5.33a2.2 2.2 0 00-2.36-2.12c-1.89 0-2.67 1.25-2.67 3.75 0 2.18.54 3.74 2.74 3.74a2.305 2.305 0 002.49-2.45 2.1 2.1 0 00-2.17-2.35 1.784 1.784 0 00-1.55.75c.01-1.41.31-2.06 1.08-2.06a.789.789 0 01.85.74zm-2.38 1.92a.905.905 0 01.97 1.01.9.9 0 01-.95.99.931.931 0 01-1.02-1.02.928.928 0 011-.98z\" fill=\"#fff\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});