define("ember-hifi/mixins/debug-logging", ["exports", "ember-hifi/utils/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Keep this object around to keep track of logs.
  var DebugLogging = Ember.Object.create({
    loggers: {},
    findOrCreateLogger: function findOrCreateLogger(name) {
      var loggerMap = this.get('loggers');
      var logger = loggerMap[name];

      if (!logger) {
        logger = new _debug.default(name);
        loggerMap[name] = logger;
      }

      return logger;
    },
    log: function log(name, message) {
      this.findOrCreateLogger(name).log(message);
    },
    timeStart: function timeStart(name, timerName) {
      this.findOrCreateLogger(name).time(timerName);
    },
    timeEnd: function timeEnd(name, timerName) {
      this.findOrCreateLogger(name).timeEnd(timerName);
    }
  });

  var _default = Ember.Mixin.create({
    debugName: 'ember-hifi',
    debugEnabled: Ember.computed(function () {
      var owner = Ember.getOwner(this); // We need this calculated field in the mixin because configuration gets looked up on the container.

      if (owner) {
        // if there's no owner, we're not quite initialized yet
        var config = owner.resolveRegistration('config:environment') || {};
        return Ember.getWithDefault(config, 'emberHifi.debug', false);
      }
    }),
    debug: function debug() {
      if (!this.get('debugEnabled')) {
        return;
      }

      var debugName, message;

      if (arguments.length === 1) {
        debugName = this.get('debugName');
        message = arguments[0];
      } else if (arguments.length === 2) {
        debugName = arguments[0];
        message = arguments[1];
      }

      DebugLogging.log(debugName, message);
    },
    timeStart: function timeStart() {
      if (!this.get('debugEnabled')) {
        return;
      }

      DebugLogging.timeStart.apply(DebugLogging, arguments);
    },
    timeEnd: function timeEnd() {
      if (!this.get('debugEnabled')) {
        return;
      }

      DebugLogging.timeEnd.apply(DebugLogging, arguments);
    }
  });

  _exports.default = _default;
});