define("ember-svg-jar/inlined/alt-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"27\" height=\"27\" rx=\"5\" fill=\"#ededed\"/><g data-name=\"Groupe 4632\" transform=\"translate(3.333 6.935)\" opacity=\".147\"><circle data-name=\"Ellipse 60\" cx=\"2.795\" cy=\"2.795\" r=\"2.795\" transform=\"translate(2.203 .059)\" fill=\"#6c6d70\"/><path data-name=\"Trac&#xE9; 3021\" d=\"M13.764 11.468H.051a.28.28 0 01-.051 0L6.486.234a.454.454 0 01.79 0l4.353 7.539.209.361z\" fill=\"#3f3d56\"/><path data-name=\"Trac&#xE9; 3022\" d=\"M21.399 11.469H9.539l2.3-3.338.165-.241 2.995-4.351a.606.606 0 01.9-.054.538.538 0 01.043.054z\" fill=\"#3f3d56\"/></g>",
    "attrs": {
      "data-name": "Groupe 5011",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "27",
      "viewBox": "0 0 27 27"
    }
  };
  _exports.default = _default;
});