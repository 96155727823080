define("ember-hifi/utils/mime-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMimeType = getMimeType;
  // we are only concerned with a subset of all available mime types
  // data and function lifted from https://github.com/jshttp/mime-db
  var MIME_TYPES = {
    "audio/3gpp": {
      "source": "iana",
      "extensions": ["3gpp"]
    },
    "audio/adpcm": {
      "source": "apache",
      "extensions": ["adp"]
    },
    "audio/aac": {
      "compressible": false,
      "extensions": ["aac"]
    },
    "audio/basic": {
      "source": "iana",
      "compressible": false,
      "extensions": ["au", "snd"]
    },
    "audio/midi": {
      "source": "apache",
      "extensions": ["mid", "midi", "kar", "rmi"]
    },
    "audio/mp3": {
      "compressible": false,
      "extensions": ["mp3"]
    },
    "audio/mp4": {
      "source": "iana",
      "compressible": false,
      "extensions": ["m4a", "mp4a"]
    },
    "audio/mpeg": {
      "source": "iana",
      "compressible": false,
      "extensions": ["mpga", "mp2", "mp2a", "mp3", "m2a", "m3a"]
    },
    "audio/ogg": {
      "source": "iana",
      "compressible": false,
      "extensions": ["oga", "ogg", "spx"]
    },
    "audio/s3m": {
      "source": "apache",
      "extensions": ["s3m"]
    },
    "audio/silk": {
      "source": "apache",
      "extensions": ["sil"]
    },
    "audio/vnd.dece.audio": {
      "source": "iana",
      "extensions": ["uva", "uvva"]
    },
    "audio/vnd.digital-winds": {
      "source": "iana",
      "extensions": ["eol"]
    },
    "audio/vnd.dra": {
      "source": "iana",
      "extensions": ["dra"]
    },
    "audio/vnd.dts": {
      "source": "iana",
      "extensions": ["dts"]
    },
    "audio/vnd.dts.hd": {
      "source": "iana",
      "extensions": ["dtshd"]
    },
    "audio/vnd.lucent.voice": {
      "source": "iana",
      "extensions": ["lvp"]
    },
    "audio/vnd.ms-playready.media.pya": {
      "source": "iana",
      "extensions": ["pya"]
    },
    "audio/vnd.nuera.ecelp4800": {
      "source": "iana",
      "extensions": ["ecelp4800"]
    },
    "audio/vnd.nuera.ecelp7470": {
      "source": "iana",
      "extensions": ["ecelp7470"]
    },
    "audio/vnd.nuera.ecelp9600": {
      "source": "iana",
      "extensions": ["ecelp9600"]
    },
    "audio/vnd.rip": {
      "source": "iana",
      "extensions": ["rip"]
    },
    "audio/wav": {
      "compressible": false,
      "extensions": ["wav"]
    },
    "audio/wave": {
      "compressible": false,
      "extensions": ["wav"]
    },
    "audio/webm": {
      "source": "apache",
      "compressible": false,
      "extensions": ["weba"]
    },
    "audio/x-aiff": {
      "source": "apache",
      "extensions": ["aif", "aiff", "aifc"]
    },
    "audio/x-caf": {
      "source": "apache",
      "compressible": false,
      "extensions": ["caf"]
    },
    "audio/x-flac": {
      "source": "apache",
      "extensions": ["flac"]
    },
    "audio/x-m4a": {
      "source": "nginx",
      "extensions": ["m4a"]
    },
    "audio/x-matroska": {
      "source": "apache",
      "extensions": ["mka"]
    },
    "audio/x-mpegurl": {
      "source": "apache",
      "extensions": ["m3u"]
    },
    "audio/x-ms-wax": {
      "source": "apache",
      "extensions": ["wax"]
    },
    "audio/x-ms-wma": {
      "source": "apache",
      "extensions": ["wma"]
    },
    "audio/x-pn-realaudio": {
      "source": "apache",
      "extensions": ["ram", "ra"]
    },
    "audio/x-pn-realaudio-plugin": {
      "source": "apache",
      "extensions": ["rmp"]
    },
    "audio/x-realaudio": {
      "source": "nginx",
      "extensions": ["ra"]
    },
    "audio/x-wav": {
      "source": "apache",
      "extensions": ["wav"]
    },
    "audio/xm": {
      "source": "apache",
      "extensions": ["xm"]
    },
    "application/vnd.apple.mpegurl": {
      "source": "iana",
      "extensions": ["m3u8"]
    },
    "audio/x-mpepgurl": {
      "source": "apache",
      "extensions": ["m3u"]
    }
  };
  var TYPES = Object.create(null);
  var EXTENSIONS = Object.create(null);

  function populateMaps(extensions, types) {
    // source preference (least -> most)
    var preference = ['nginx', 'apache', undefined, 'iana'];
    Object.keys(MIME_TYPES).forEach(function (type) {
      var mime = MIME_TYPES[type];
      var exts = mime.extensions;

      if (!exts || !exts.length) {
        return false;
      } // mime -> extensions


      extensions[type] = exts; // extension -> mime

      for (var i = 0; i < exts.length; i++) {
        var extension = exts[i];

        if (types[extension]) {
          var start = preference.indexOf(MIME_TYPES[types[extension]].source);
          var end = preference.indexOf(mime.source);

          if (types[extension] !== 'application/octet-stream' && start > end || start === end && types[extension].substr(0, 12) === 'application/') {
            // skip the remapping
            continue;
          }
        } // set the extension -> mime


        types[extension] = type;
      }
    });
  }

  populateMaps(EXTENSIONS, TYPES);

  function getMimeType(url) {
    if (!url || typeof url !== 'string') {
      return false;
    }

    var extension = url.toLowerCase().split('.').pop().split('?').shift().split('#').shift();

    if (!extension) {
      return false;
    }

    return TYPES[extension] || false;
  }
});