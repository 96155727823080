define("ember-query-params-service/initializers/register-query-params-service", ["exports", "ember-query-params-service/services/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('service:queryParams', _queryParams.default);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});