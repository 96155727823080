define("ember-paper/templates/components/paper-toast-inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibh9REu5",
    "block": "[[[10,0],[14,0,\"md-toast-content\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-toast-inner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});