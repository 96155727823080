define("ember-paper/components/paper-select/no-matches-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GVxcOAFS",
    "block": "[[[10,\"md-content\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[1,\"\\n  \"],[10,\"md-option\"],[14,0,\"ember-power-select-option ember-power-select-option--no-matches-message\"],[14,\"role\",\"option\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@noMatchesMessage\"],false,[]]",
    "moduleName": "ember-paper/components/paper-select/no-matches-message/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});