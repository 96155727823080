define("ember-svg-jar/inlined/announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#33C46C\" fill-rule=\"evenodd\" d=\"M6.039 4.093h12.594v12H6.04c-3.302 0-5.979-2.693-5.979-6 0-3.314 2.661-6 5.979-6zm-4.652 6c0-2.578 2.084-4.667 4.643-4.667h4.643v9.333H6.03c-2.564 0-4.643-2.094-4.643-4.666zM12 5.426h5.307v9.333H12V5.426zm9.812-3.73c1.175-.885 2.128-.412 2.128 1.053v14.688c0 1.467-.949 1.94-2.128 1.052l-3.179-2.396v-12l3.18-2.396zm.801 1.063v14.667l-3.98-2.933v-8.8l3.98-2.934zm-11.94 13.334v4.668a1.99 1.99 0 11-3.98 0v-4.668h3.98zm-2.653 0h1.327v5.333H8.02v-5.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});