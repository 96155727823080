define("ember-svg-jar/inlined/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EC9493\" d=\"M52.732 35.688A26.923 26.923 0 0054 27.5c0-14.894-12.106-27-27-27S0 12.606 0 27.5s12.106 27 27 27c4.86 0 9.422-1.289 13.364-3.543a10.022 10.022 0 01-1.137-1.654A24.854 24.854 0 0127 52.5c-13.789 0-25-11.211-25-25s11.211-25 25-25 25 11.211 25 25a24.93 24.93 0 01-1.123 7.42c.648.194 1.27.453 1.855.768zM27 21.145a6.312 6.312 0 016.355 6.355A6.312 6.312 0 0127 33.855a6.312 6.312 0 01-6.355-6.355A6.312 6.312 0 0127 21.145zm0 2a4.313 4.313 0 00-4.355 4.355A4.313 4.313 0 0027 31.855a4.313 4.313 0 004.355-4.355A4.313 4.313 0 0027 23.145zM48 52.5a8 8 0 100-16 8 8 0 000 16zm0 2c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm-1-14v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "58",
      "height": "55",
      "viewBox": "0 0 58 55"
    }
  };
  _exports.default = _default;
});