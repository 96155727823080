define("ember-add-calendar-button/templates/components/types/ical-cal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JoBjGTwV",
    "block": "[[[1,[34,0]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"text\",\"yield\"]]",
    "moduleName": "ember-add-calendar-button/templates/components/types/ical-cal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});