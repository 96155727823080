define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2725\" d=\"M23 16a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 00-1-1 1 1 0 00-1 1v4a3 3 0 003 3h18a3 3 0 003-3v-4a1 1 0 00-1-1z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2726\" d=\"M5.293 13.538l4.586 4.587a3.007 3.007 0 004.242 0l4.586-4.586a1 1 0 10-1.414-1.414L13 16.416V1a1 1 0 10-2 0v15.417l-4.293-4.293a1 1 0 10-1.414 1.414z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});