define("liquid-wormhole/components/liquid-append", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('replaceNodes')) {
        var nodes = this.get('nodes');
        this.$().children().remove();
        this.$().append(nodes);
      }
    },
    didInsertElement: function didInsertElement() {
      var notify = this.get('notify');
      var nodes = this.get('nodes');

      if (notify && notify.willAppendNodes) {
        notify.willAppendNodes(this.element);
      }

      this.$().append(nodes);

      if (notify && notify.didAppendNodes) {
        notify.didAppendNodes(this.element);
      }
    }
  });

  _exports.default = _default;
});