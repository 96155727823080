define("ember-svg-jar/inlined/unfollow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.297 8.036a15.163 15.163 0 00-3.229-3.732l2.682-2.673a.956.956 0 10-1.355-1.35l-2.917 2.91a11.577 11.577 0 00-5.974-1.614c-5.935 0-9.315 4.047-10.8 6.459a4.673 4.673 0 000 4.929 15.163 15.163 0 003.228 3.732L1.249 19.37a.956.956 0 101.355 1.35l2.924-2.914a11.577 11.577 0 005.976 1.617c5.931 0 9.311-4.046 10.8-6.458a4.673 4.673 0 00-.007-4.929zM2.335 11.965a2.778 2.778 0 010-2.929c1.278-2.069 4.169-5.549 9.169-5.549a9.7 9.7 0 014.561 1.11l-1.928 1.924a4.774 4.774 0 00-6.633 6.608l-2.209 2.205a13.116 13.116 0 01-2.96-3.37zm12.038-1.468a2.869 2.869 0 01-2.869 2.867 2.835 2.835 0 01-1.231-.286l3.818-3.8a2.81 2.81 0 01.282 1.219zm-5.748 0a2.869 2.869 0 012.879-2.861 2.835 2.835 0 011.231.286l-3.818 3.8a2.81 2.81 0 01-.291-1.225zm12.039 1.465c-1.278 2.068-4.166 5.548-9.165 5.548a9.7 9.7 0 01-4.565-1.113l1.928-1.922a4.774 4.774 0 006.629-6.607l2.213-2.202a13.116 13.116 0 012.96 3.37 2.778 2.778 0 010 2.929z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "21",
      "viewBox": "0 0 23 21"
    }
  };
  _exports.default = _default;
});