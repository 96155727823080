define("ember-svg-jar/inlined/cross-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.756.244a.833.833 0 00-1.178 0L10 8.822 1.423.244a.833.833 0 00-1.178 0 .833.833 0 000 1.178L8.822 10 .244 18.578a.833.833 0 000 1.178.833.833 0 001.178 0L10 11.179l8.578 8.578a.833.833 0 001.178 0 .833.833 0 000-1.178L11.179 10l8.578-8.578a.833.833 0 000-1.178z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});