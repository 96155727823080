define("ember-svg-jar/inlined/retry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.087 15.715c-.361-.247-.554-.75-.824-.4-2.468 3.246-6.476 4.862-10.1 3.085A8.868 8.868 0 012.45 8.5a8.044 8.044 0 018.346-6.24c4.018.056 6.613 2.068 7.417 6.1l-1.578-.915a.892.892 0 00-1.21.309 1.671 1.671 0 00.407 1.706l2.735 1.36a2.059 2.059 0 002.63-.671l1.7-2.49a1.314 1.314 0 00-.368-1.5c-.4-.263-.925-.326-1.181.079l-1.186 1.8A10.093 10.093 0 0010.24.005 10.3 10.3 0 00.249 8.188a10.578 10.578 0 005.587 11.789 10.082 10.082 0 0012.454-2.98.9.9 0 00.174-.688.883.883 0 00-.378-.6z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "21",
      "viewBox": "0 0 23 21"
    }
  };
  _exports.default = _default;
});