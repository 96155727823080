define("ember-svg-jar/inlined/empty-illustration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#ED9493\" d=\"M70 140c-38.66 0-70-31.34-70-70S31.34 0 70 0s70 31.34 70 70-31.34 70-70 70zM31.5 65a8.5 8.5 0 018.5-8.5v-1a9.5 9.5 0 00-9.5 9.5h1zm8.5-8.5a8.5 8.5 0 018.5 8.5h1a9.5 9.5 0 00-9.5-9.5v1zm8.5 8.5c0 .555.449 1 1 1v-1h-1zm1 1c.556 0 1-.449 1-1h-1v1zm1-1c0-5.799-4.701-10.5-10.5-10.5v1a9.5 9.5 0 019.5 9.5h1zM40 54.5c-5.799 0-10.5 4.701-10.5 10.5h1a9.5 9.5 0 019.5-9.5v-1zM29.5 65c0 .555.449 1 1 1v-1h-1zm1 1c.555 0 1-.449 1-1h-1v1zm61-1a8.5 8.5 0 018.5-8.5v-1a9.5 9.5 0 00-9.5 9.5h1zm8.5-8.5a8.5 8.5 0 018.5 8.5h1a9.5 9.5 0 00-9.5-9.5v1zm8.5 8.5c0 .555.449 1 1 1v-1h-1zm1 1c.555 0 1-.449 1-1h-1v1zm1-1c0-5.799-4.701-10.5-10.5-10.5v1a9.5 9.5 0 019.5 9.5h1zM100 54.5c-5.799 0-10.5 4.701-10.5 10.5h1a9.5 9.5 0 019.5-9.5v-1zM89.5 65c0 .555.449 1 1 1v-1h-1zm1 1c.555 0 1-.449 1-1h-1v1zM70 110c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "140",
      "height": "140",
      "viewBox": "0 0 140 140"
    }
  };
  _exports.default = _default;
});