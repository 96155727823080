define("ember-multiselect-checkboxes-dropdown/components/multiselect-checkboxes-dropdown", ["exports", "ember-multiselect-checkboxes-dropdown/templates/components/multiselect-checkboxes-dropdown"], function (_exports, _multiselectCheckboxesDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _multiselectCheckboxesDropdown.default,
    selected: [],
    options: null,
    pendingOptions: Ember.computed('selected', function () {
      return (this.get('selected') || []).toArray();
    }),
    isAllSelected: Ember.computed('selected.@each', function () {
      return this.get('options.length') === this.get('selected.length');
    }),
    isAllPendingSelected: Ember.computed('pendingOptions.@each', function () {
      return this.get('options.length') === this.get('pendingOptions.length');
    }),
    actions: {
      apply: function apply() {
        var onApply = this.attrs.onApply;

        if (onApply) {
          onApply(this.get('pendingOptions').toArray());
        }
      },
      toggleAll: function toggleAll(event) {
        var checked = event.target.checked;
        this.set('pendingOptions', checked ? this.get('options').toArray() : []);
      }
    }
  });

  _exports.default = _default;
});