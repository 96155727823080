define("ember-power-select/templates/components/power-select/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5lBVm1pU",
    "block": "[[[11,\"ul\"],[24,\"role\",\"listbox\"],[16,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[30,1,[\"uniqueId\"]]]]],[17,2],[4,[38,0],[[30,0,[\"addHandlers\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"loading\"]],[[[41,[30,3],[[[1,\"      \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--loading-message\"],[14,\"role\",\"option\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null]],[]],null],[44,[[50,[30,4],0,null,null],[50,[30,5],0,null,null]],[[[42,[28,[37,5],[[28,[37,5],[[30,8]],null]],null],null,[[[41,[28,[37,6],[[30,9]],null],[[[1,\"        \"],[8,[30,6],null,[[\"@group\",\"@select\",\"@extra\"],[[30,9],[30,1],[30,11]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,7],[[24,\"role\",\"group\"],[24,0,\"ember-power-select-options\"]],[[\"@options\",\"@select\",\"@groupIndex\",\"@optionsComponent\",\"@groupComponent\",\"@extra\"],[[30,9,[\"options\"]],[30,1],[29,[[30,12],[30,10],\".\"]],[30,5],[30,4],[30,11]]],[[\"default\"],[[[[1,\"\\n            \"],[18,14,[[30,13],[30,1]]],[1,\"\\n          \"]],[13]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"li\"],[14,0,\"ember-power-select-option\"],[15,\"aria-selected\",[29,[[28,[37,8],[[30,9],[30,1,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[52,[30,9,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,9],[[30,9],[30,1,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,12],[30,10]]]],[14,\"role\",\"option\"],[12],[1,\"\\n          \"],[18,14,[[30,9],[30,1]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[9,10]],null]],[6,7]]],[13]],[\"@select\",\"&attrs\",\"@loadingMessage\",\"@groupComponent\",\"@optionsComponent\",\"Group\",\"Options\",\"@options\",\"opt\",\"index\",\"@extra\",\"@groupIndex\",\"option\",\"&default\"],false,[\"did-insert\",\"if\",\"let\",\"component\",\"each\",\"-track-array\",\"ember-power-select-is-group\",\"yield\",\"ember-power-select-is-selected\",\"eq\"]]",
    "moduleName": "ember-power-select/templates/components/power-select/options.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});