define("ember-svg-jar/inlined/eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2727\" d=\"M21.327 5.79A12.235 12.235 0 0011.004 0 12.235 12.235 0 00.672 5.79a3.968 3.968 0 000 4.419A12.235 12.235 0 0011.004 16a12.235 12.235 0 0010.328-5.79 3.968 3.968 0 00-.005-4.42zm-1.562 3.523a10.445 10.445 0 01-8.761 4.975 10.445 10.445 0 01-8.77-4.975 2.359 2.359 0 010-2.626 10.445 10.445 0 018.77-4.975 10.437 10.437 0 018.766 4.975 2.359 2.359 0 01-.005 2.626z\"/><path data-name=\"Trac&#xE9; 2728\" d=\"M11 3.72A4.439 4.439 0 006.418 8 4.439 4.439 0 0011 12.28 4.439 4.439 0 0015.582 8 4.444 4.444 0 0011 3.72zm0 6.849A2.663 2.663 0 018.251 8 2.663 2.663 0 0111 5.432 2.663 2.663 0 0113.749 8 2.663 2.663 0 0111 10.569z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 16"
    }
  };
  _exports.default = _default;
});