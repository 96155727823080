define("ember-svg-jar/inlined/mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" d=\"M0 3a1 1 0 011-1h16a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V3zm17 0l-7.293 7.293a1 1 0 01-1.414 0L1 3v12h16V3zM2.414 3L9 9.586 15.586 3H2.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});