define("ember-svg-jar/inlined/illustration-empty-webhook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5726\"><path data-name=\"Trac&#xE9; 3145\" d=\"M89.125 44.559q0 .5-.012.993c0 .216-.01.432-.018.648 0 .062 0 .122-.007.184l-.015.352q-.055 1.158-.171 2.3c0 .037-.007.074-.01.109-.008.075-.015.151-.025.226-.023.226-.049.452-.077.675-.018.151-.037.3-.057.451-.01.072-.02.144-.028.214s-.022.156-.034.233l-.015.1-.052.343c-.022.149-.045.3-.07.446s-.049.295-.075.442l-.079.442c-.027.147-.055.3-.084.444q-.216 1.1-.482 2.173-.106.42-.216.838-.183.678-.387 1.35l-.116.37-.134.421c-.045.14-.09.28-.137.419l-.142.417c-.048.139-.1.276-.146.414s-.1.276-.149.414-.1.275-.154.41q-.156.412-.32.816c-.054.136-.109.271-.166.405q-.334.809-.7 1.6c-.054.117-.107.235-.163.35l-.109.23-.184.379-.191.385q-.193.382-.394.762c-.132.253-.268.506-.407.756s-.28.5-.422.745l-.216.37q-.435.736-.9 1.456t-.95 1.416c-.163.233-.328.466-.5.7l-.2.273c-.082.114-.168.226-.253.338A44.53 44.53 0 0116 78.768q-.734-.613-1.441-1.256a44.793 44.793 0 01-7.825-9.378c-.27-.434-.534-.873-.789-1.315a44.569 44.569 0 0175.919-46.645c.074.112.147.226.221.34a44.3 44.3 0 016.924 20.877q.117 1.568.116 3.168z\" fill=\"#f2f2f2\"/><path data-name=\"Trac&#xE9; 3220\" d=\"M34.402 48.688q.197.358.432.697a8.488 8.488 0 0011.778 2.24l3.357-2.285-1.206-2.01-3.149 2.056c-3.243 2.206-6.667 2.061-8.874-1.182s-1.47-6.633 1.769-8.843l13.919-9.492c3.243-2.206 6.236-1.92 8.442 1.325s1.74 6.783-1.503 8.99l-4.73 3.397 1.083 1.845 4.992-3.397a8.622 8.622 0 002.06-11.93 8.236 8.236 0 00-11.602-2.085l-14.097 9.593a8.48 8.48 0 00-2.671 11.08z\" fill=\"#3f3d56\"/><path data-name=\"Trac&#xE9; 3232\" d=\"M25.731 64.671q-.253-.321-.473-.67a8.488 8.488 0 012.536-11.72l3.41-2.2 1.38 1.897-3.121 2.097c-3.298 2.124-4.501 5.334-2.376 8.632s5.533 3.948 8.831 1.824l14.174-9.111c3.297-2.125 4.202-4.993 2.076-8.29s-5.566-4.25-8.865-2.126l-1.772 1.171-1.264-1.728 1.864-1.404a8.623 8.623 0 0111.79 2.76 8.237 8.237 0 01-2.61 11.497l-14.338 9.237a8.48 8.48 0 01-11.242-1.866z\" fill=\"#3f3d56\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "89.125",
      "height": "89.141",
      "viewBox": "0 0 89.125 89.141"
    }
  };
  _exports.default = _default;
});