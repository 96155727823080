define("ember-svg-jar/inlined/checked-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 16A8 8 0 108 0a8 8 0 000 16zm-.879-6.464L5.354 7.768a.5.5 0 00-.708.707l2.122 2.121a.499.499 0 00.707 0l4.242-4.242a.5.5 0 10-.707-.708l-3.889 3.89z\" fill=\"#33C46C\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});