define("ember-svg-jar/inlined/down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>down</title><g transform=\"translate(1 1)\" stroke-width=\"2\" stroke=\"#314E55\" fill=\"none\" fill-rule=\"evenodd\" stroke-linejoin=\"round\"><path fill=\"#FFF\" stroke-linecap=\"round\" d=\"M0 22h108v28H0z\"/><path fill=\"#EC9493\" stroke-linecap=\"round\" d=\"M26 22h14L18 50H4z\"/><circle fill=\"#70D598\" stroke-linecap=\"round\" cx=\"30\" cy=\"6\" r=\"6\"/><circle fill=\"#F4C359\" stroke-linecap=\"round\" cx=\"78\" cy=\"6\" r=\"6\"/><path fill=\"#EC9493\" stroke-linecap=\"round\" d=\"M56 22h14L48 50H34zM88 22h14L80 50H66z\"/><path d=\"M18 50v50M90 50v50\" stroke-linecap=\"round\"/><path d=\"M6 100h24M78 100h24\" stroke-linecap=\"square\"/></g>",
    "attrs": {
      "width": "110",
      "height": "102",
      "viewBox": "0 0 110 102",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});