define("ember-hifi/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function jsonStringify(params
  /*, hash*/
  ) {
    if (!params || !params[0] || params[0] == undefined) {
      return "";
    }

    return JSON.stringify(params[0]);
  });

  _exports.default = _default;
});