define("ember-cli-foundation-6-sass/mixins/zf-widget", ["exports", "ember-cli-foundation-6-sass/-private/foundation"], function (_exports, _foundation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var warn = Ember.Logger.warn;
  /**
   * Mixin that was shamelessly ripped off from the Ember jQuery UI folks (hey, why reinvent the
   * wheel). This makes it drop dead simple to convert between Zurb Foundation land and the land
   * of Ember filled with Chocolate rivers and gumdrop rainbows. And bacon. Lot's and lots of
   * bacon.
   */

  var _default = Ember.Mixin.create({
    /**
     * Handle setup of this components' DOM element.
     */
    setup: Ember.on('didInsertElement', function () {
      this._setup();
    }),

    /**
     * Handle destruction of component.
     */
    shutdown: Ember.on('willDestroyElement', function () {
      var _this = this;

      var ui = this.get('zfUi');

      if (Ember.isPresent(ui)) {
        var observers = this._observers; // Nuke any observers that were created

        for (var opKey in observers) {
          if (observers.hasOwnProperty(opKey)) {
            this.removeObserver(opKey, observers[opKey]);
          }
        }
      }

      Ember.run.schedule('render', function () {
        // Finally destroy everything else.
        var zfUiList = _this.get('zfUiList'),
            element = ui && ui.$element;

        var _iterator = _createForOfIteratorHelper(zfUiList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var zfUi = _step.value;
            zfUi.destroy();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        if (element && element.hasClass('reveal')) {
          element.remove();
        }
      });
    }),
    _setup: function _setup() {
      var _this2 = this;

      // Perform any custom handling
      if (Ember.isPresent(this.handlePreRender)) {
        this.handlePreRender();
      }

      Ember.run.scheduleOnce('afterRender', function () {
        // Adapt the options
        var options = _this2._adaptOptions(); // Instantiate widget. Some widgets have multiple controls so we handle this case by
        // creating an array of zfUi elements. The first element gets stuffed into the zfUi
        // member with the whole list getting stuffed into zfUiList. It's up to the control to
        // expose this as friendly properties to the user.


        var zfType = _this2.get('zfType');

        var controlIds = _this2.get('controlIds');

        var zfUiList = [];
        var isZfTypeLoaded = !!_foundation.default[zfType];

        if (isZfTypeLoaded) {
          if (Ember.isPresent(controlIds)) {
            var _iterator2 = _createForOfIteratorHelper(controlIds),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var controlId = _step2.value;
                var ui = new _foundation.default[zfType](_this2.$(controlId), options);
                zfUiList.push(ui);
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }

          if (0 === zfUiList.length) {
            var _ui = new _foundation.default[zfType](_this2.$(), options);

            _this2.set('zfUi', _ui);

            zfUiList.push(_ui);
          } else {
            _this2.set('zfUi', zfUiList[0]);
          }

          _this2.set('zfUiList', zfUiList);
        } else {
          warn("Foundation plugin ".concat(zfType, " has not been loaded, please check your ember-cli-foundation-6-sass configuration"));
        } // Perform any custom handling


        if (Ember.isPresent(_this2.handleInsert)) {
          _this2.handleInsert();
        }
      });
    },

    /**
     * Translate the options from the Ember way to foundation.
     * @return {Object}  An object containing our options.
     */
    _adaptOptions: function _adaptOptions() {
      var fdnOptions = this.get('zfOptions') || [];
      var options = {}; // We are going to be observing changes. Initialze our cached observer list

      this._observers = this._observers || {};

      var observer = function observer(sender, key) {
        // Update options dynamically. Right now this is an all or nothing for widgets with
        // multiple UI elements.
        var value = sender.get(key);
        var zfUiList = this.get('zfUiList');

        var _iterator3 = _createForOfIteratorHelper(zfUiList),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var zfUi = _step3.value;
            zfUi.options[this._getZfOpKey(key)] = value;
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }; // Each component can specify a list of options that will be exposed to an external
      // consumer. Iterate through the options and build up the options object that gets returned


      var _iterator4 = _createForOfIteratorHelper(fdnOptions),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var opKey = _step4.value;

          var zfOpKey = this._getZfOpKey(opKey);

          options[zfOpKey] = this.get(opKey); // We also want to observe any changes so observe each component and push any updates
          // to foundation.

          this.addObserver(opKey, observer); // Cache the obsever so we can be a well behaved compoent and unsubscribe later

          this._observers[opKey] = observer;
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      return options;
    },

    /**
     * Get a "Zurb Foundation" specific options key. In some cases, ZF overloads existing ember
     * component fields. We handle this by prefacing the options with "zf-". So layout (used by
     * Ember) becomes "zf-layout".
     * @param  {string} opKey Options key.
     * @return {string}       Zurb foundation specific options key.
     */
    _getZfOpKey: function _getZfOpKey(opKey) {
      var retVal = opKey;
      var zfPreamble = 'zf-';

      if (true === opKey.startsWith(zfPreamble)) {
        retVal = opKey.substring(zfPreamble.length);
      }

      return retVal;
    }
  });

  _exports.default = _default;
});