define("ember-svg-jar/inlined/play-rounded-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2984\" d=\"M11.5 1.525a9.974 9.974 0 109.974 9.975A9.974 9.974 0 0011.5 1.525M11.5 0A11.5 11.5 0 110 11.5 11.5 11.5 0 0111.5 0z\" fill=\"#9b9999\"/><path data-name=\"Trac&#xE9; 3449\" d=\"M15.398 9.994l-3.5-2.614a1.81 1.81 0 00-1.919-.164 1.874 1.874 0 00-1.007 1.666v5.229a1.867 1.867 0 001.006 1.671 1.809 1.809 0 001.917-.161l3.5-2.614a1.889 1.889 0 000-3.014z\" fill=\"#9b9999\"/>",
    "attrs": {
      "data-name": "play-rounded-big (1)",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23"
    }
  };
  _exports.default = _default;
});