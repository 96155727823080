define("ember-svg-jar/inlined/arrow-left-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.879 4.844L5.468.258a1 1 0 011.411 1.414L2.293 6.258a1 1 0 000 1.414l4.586 4.586a1 1 0 11-1.415 1.414L.879 9.086a3 3 0 010-4.242z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "7.171",
      "height": "13.965",
      "viewBox": "0 0 7.171 13.965"
    }
  };
  _exports.default = _default;
});