define("ember-svg-jar/inlined/zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.5 5.5a.5.5 0 011 0v2h2a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2h-2a.5.5 0 010-1h2v-2z\" fill=\"#9EA1A3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 8c0 1.8-.6 3.4-1.7 4.6l3.35 3.35a.495.495 0 11-.7.7L12.6 13.3C11.4 14.4 9.8 15 8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7zM1.9 8c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.8-6.1 6.1z\" fill=\"#9EA1A3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});