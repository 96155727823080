define("ember-paper/templates/components/paper-card-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZK3rbIqE",
    "block": "[[[18,1,[[28,[37,1],null,[[\"text\",\"media\"],[[50,\"paper-card-title-text\",0,null,null],[50,\"paper-card-title-media\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-card-title.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});