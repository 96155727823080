define("ember-svg-jar/inlined/chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" fill-rule=\"evenodd\" d=\"M10.745 9l-4.599 4.596a.5.5 0 10.708.707l4.952-4.95a.5.5 0 000-.707l-4.952-4.95a.5.5 0 00-.708.708L10.745 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});