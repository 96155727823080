define("ember-paper/templates/components/paper-sidenav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gpzpz8he",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"opaque\",\"class\",\"onClick\"],[true,\"md-sidenav-backdrop\",[28,[37,3],[[30,0],\"onBackdropTap\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,4],null,[[\"class\",\"name\",\"position\",\"lockedOpen\",\"closed\",\"closeOnClick\",\"onToggle\"],[[33,5],[33,6],[33,7],[33,8],[33,1],[33,9],[28,[37,3],[[30,0],\"onToggle\"],null]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"unless\",\"closed\",\"paper-backdrop\",\"action\",\"paper-sidenav-inner\",\"class\",\"name\",\"position\",\"lockedOpen\",\"closeOnClick\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-sidenav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});