define("ember-svg-jar/inlined/add-to-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5644\" transform=\"translate(-114 -117)\" fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 2739\" d=\"M130.065 118.72h-.846v-.845a.846.846 0 10-1.691 0v.846h-6.764v-.846a.846.846 0 10-1.691 0v.846h-.845a4.233 4.233 0 00-4.228 4.227v10.146a4.233 4.233 0 004.228 4.228l11.638-.01-1.2-.885.851-4.772a9.753 9.753 0 014-.966c.5.028.747.952.748.3l.027-8.043a4.233 4.233 0 00-4.227-4.226zm-14.374 4.228a2.537 2.537 0 012.537-2.537h11.837a2.537 2.537 0 012.535 2.537v.846h-16.909zm13.135 12.652l-10.6.029a2.537 2.537 0 01-2.537-2.537v-7.608H132.6v5.127c0 .307-2.853.485-3.079 1.044a7.561 7.561 0 00-.7 2.805c.018.374.45 1.14.005 1.14z\"/><circle data-name=\"Ellipse 45\" cx=\"1.268\" cy=\"1.268\" r=\"1.268\" transform=\"translate(122.878 128.443)\"/><circle data-name=\"Ellipse 46\" cx=\"1.268\" cy=\"1.268\" r=\"1.268\" transform=\"translate(118.65 128.443)\"/><circle data-name=\"Ellipse 47\" cx=\"1.268\" cy=\"1.268\" r=\"1.268\" transform=\"translate(127.105 128.443)\"/><path data-name=\"Trac&#xE9; 3077\" d=\"M133.074 131.478a3.183 3.183 0 00-3.413 3.49 3.215 3.215 0 003.413 3.532 3.387 3.387 0 003.635-3.532 3.359 3.359 0 00-3.635-3.49m0-1.478a4.968 4.968 0 11-4.965 4.968 4.968 4.968 0 014.965-4.968z\"/><g data-name=\"Groupe 5514\"><path data-name=\"Ligne 136\" d=\"M133.074 137.659a.621.621 0 01-.621-.621v-4.14a.621.621 0 111.242 0v4.14a.621.621 0 01-.621.621z\"/><path data-name=\"Ligne 137\" d=\"M130.383 134.968a.621.621 0 01.621-.621h4.14a.621.621 0 010 1.242h-4.14a.621.621 0 01-.621-.621z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24.041",
      "height": "22.935",
      "viewBox": "0 0 24.041 22.935"
    }
  };
  _exports.default = _default;
});