define("ember-svg-jar/inlined/territory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 0a10 10 0 1010 10A10 10 0 0010 0zm8.333 10a8.282 8.282 0 01-1.385 4.59l-.993-.994a.417.417 0 01-.122-.294v-.8a2.5 2.5 0 00-2.5-2.5h-2.5A.833.833 0 0110 9.167V8.75a.417.417 0 01.417-.417A2.083 2.083 0 0012.5 6.25v-.833A.417.417 0 0112.917 5h1.149a2.1 2.1 0 001.472-.61l.314-.314A8.307 8.307 0 0118.333 10zm-16.626.8l2.632 2.632a2.482 2.482 0 001.768.733h2.226a.833.833 0 01.834.835v3.291a8.347 8.347 0 01-7.46-7.491zm9.126 7.488V15a2.5 2.5 0 00-2.5-2.5H6.108a.833.833 0 01-.59-.244L1.8 8.541a8.315 8.315 0 0112.747-5.516l-.187.187a.422.422 0 01-.294.122h-1.149a2.083 2.083 0 00-2.083 2.083v.833a.417.417 0 01-.417.417A2.083 2.083 0 008.333 8.75v.417a2.5 2.5 0 002.5 2.5h2.5a.833.833 0 01.833.833v.8a2.1 2.1 0 00.61 1.473l1.114 1.114a8.309 8.309 0 01-5.057 2.4z\" fill=\"#8e8e8e\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});