define("ember-intl-cp-validations/validators/messages", ["exports", "ember-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
      getOwner = Ember.getOwner,
      isEmpty = Ember.isEmpty;

  var _default = _messages.default.extend({
    intl: Ember.inject.service(),
    warn: Ember.warn,
    prefix: 'errors',
    init: function init() {
      this._super.apply(this, arguments);

      var owner = getOwner(this);

      if (owner) {
        this._config = owner.resolveRegistration('config:environment') || {};
      }
    },
    _warn: function _warn(msg, test, meta) {
      if (this._config && get(this._config, 'intl_cp_validations.suppressWarnings')) {
        return;
      }

      this.warn(msg, test, meta);
    },
    getDescriptionFor: function getDescriptionFor(attribute) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var intl = get(this, 'intl');
      var key = "".concat(get(this, 'prefix'), ".description");
      var foundCustom;

      if (!isEmpty(options.descriptionKey)) {
        key = options.descriptionKey;
        foundCustom = true;
      } else if (!isEmpty(options.description)) {
        return options.description;
      }

      if (intl) {
        if (intl.exists(key)) {
          return intl.t(key, options);
        } else if (foundCustom) {
          this._warn("Custom descriptionKey '".concat(key, "' provided but does not exist in intl translations."), false, {
            id: 'ember-intl-cp-validations-missing-custom-key'
          });
        }
      }

      return this._super.apply(this, arguments);
    },
    getMessageFor: function getMessageFor(type) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var key = get(options, 'messageKey') || "".concat(get(this, 'prefix'), ".").concat(type);
      var intl = get(this, 'intl');

      if (intl && intl.exists(key)) {
        return this.formatMessage(intl.t(key, options));
      }

      this._warn("[ember-intl-cp-validations] Missing translation for validation key: ".concat(key, "\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html"), false, {
        id: 'ember-intl-cp-validations-missing-translation'
      });

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});