define("ember-svg-jar/inlined/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.961 10.742l-1.494-5.376a7.33 7.33 0 00-14.217.373l-1.157 5.2a3.932 3.932 0 003.838 4.785h.876a3.932 3.932 0 007.706 0h.659a3.932 3.932 0 003.789-4.984zm-8.3 6.556a2.359 2.359 0 01-2.214-1.573h4.429a2.359 2.359 0 01-2.215 1.573zm6.39-4.077a2.341 2.341 0 01-1.879.932h-9.24a2.359 2.359 0 01-2.3-2.871l1.156-5.2a5.757 5.757 0 0111.166-.293l1.494 5.376a2.341 2.341 0 01-.395 2.059z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.105",
      "height": "18.875",
      "viewBox": "0 0 17.105 18.875"
    }
  };
  _exports.default = _default;
});