define("ember-query-params-service/utils/query-param/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractArgs = extractArgs;
  _exports.tryDeserialize = tryDeserialize;
  _exports.trySerialize = trySerialize;
  _exports.ensureService = ensureService;
  _exports.getQPService = getQPService;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function extractArgs(args, propertyKey) {
    var _args = _slicedToArray(args, 2),
        maybePathMaybeOptions = _args[0],
        maybeOptions = _args[1];

    var propertyPath;
    var options;

    if (typeof maybePathMaybeOptions === 'string') {
      propertyPath = "current.".concat(maybePathMaybeOptions);
      options = maybeOptions || {};
    } else {
      propertyPath = "current.".concat(propertyKey);
      options = maybePathMaybeOptions || {};
    }

    return [propertyPath, options];
  }

  function tryDeserialize(value, options) {
    if (!options.deserialize) return value;
    return options.deserialize(value);
  }

  function trySerialize(value, options) {
    if (!options.serialize) return value;
    return options.serialize(value);
  } // can't cache the service in module space because we run in to race  conditions
  // where a service on an old app instance may still exist, but be tied to the
  // old application istead of the current one (such as in tests)


  var serviceCache = new WeakMap();

  function ensureService(context) {
    var service = serviceCache.get(context);

    if (!service) {
      service = getQPService(context);
      serviceCache.set(context, service);
    }

    return service;
  }

  function getQPService(context) {
    return Ember.getOwner(context).lookup('service:queryParams');
  }
});