define("ember-collapsible-panel/services/dependency-checker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasLiquidFire: Ember.computed('', function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config['ember-collapsible-panel'].hasLiquidFire;
    })
  });

  _exports.default = _default;
});