define("ember-add-calendar-button/templates/components/calendar-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NTyRjw+E",
    "block": "[[[1,\"\\n\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"google\",\"outlook\",\"ical\"],[[50,\"types/google-cal\",0,null,[[\"event\",\"onClick\"],[[33,5],[28,[37,6],[[30,0],\"onClick\"],null]]]],[50,\"types/outlook-cal\",0,null,[[\"event\",\"onClick\"],[[33,5],[28,[37,6],[[30,0],\"onClick\"],null]]]],[50,\"types/ical-cal\",0,null,[[\"event\",\"onClick\"],[[33,5],[28,[37,6],[[30,0],\"onClick\"],null]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"event\",\"onClick\"],[[33,5],[28,[37,6],[[30,0],\"onClick\"],null]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"event\",\"action\",\"types/ical-cal\"]]",
    "moduleName": "ember-add-calendar-button/templates/components/calendar-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});