define("ember-svg-jar/inlined/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5356\"><path d=\"M14.356 4.812a1.212 1.212 0 00-1.22.1l-1.069.752a2.866 2.866 0 00-2.9-2.488h-.05c-.076-.072-5.135-.055-5.2 0A2.847 2.847 0 001 5.943v4.423a2.847 2.847 0 002.916 2.764h5.249a2.866 2.866 0 002.9-2.488l1.067.754a1.209 1.209 0 001.633-.221 1.068 1.068 0 00.233-.663V5.801a1.1 1.1 0 00-.642-.989zm-3.441 5.554a1.706 1.706 0 01-1.75 1.659H3.916a1.706 1.706 0 01-1.75-1.659V5.943a1.706 1.706 0 011.75-1.659h5.249a1.706 1.706 0 011.75 1.659zm2.916.142l-1.75-1.236V7.037l1.75-1.236z\" fill=\"#fff\"/><path data-name=\"Trac&#xE9; 3152\" d=\"M0 0h16v16H0z\" fill=\"none\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});