define("ember-svg-jar/inlined/drag-and-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5298\" fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 3131\" d=\"M1 8a1 1 0 01-1-1V5a5.006 5.006 0 015-5h2a1 1 0 010 2H5a3 3 0 00-3 3v2a1 1 0 01-1 1z\"/><path data-name=\"Trac&#xE9; 3132\" d=\"M7 24H5a5.006 5.006 0 01-5-5v-2a1 1 0 112 0v2a3 3 0 003 3h2a1 1 0 010 2z\"/><path data-name=\"Trac&#xE9; 3133\" d=\"M23 8a1 1 0 01-1-1V5a3 3 0 00-3-3h-2a1 1 0 110-2h2a5.006 5.006 0 015 5v2a1 1 0 01-1 1z\"/><path data-name=\"Trac&#xE9; 3137\" d=\"M14 9h14a5.006 5.006 0 015 5v14a5.006 5.006 0 01-5 5H14a5.006 5.006 0 01-5-5V14a5.006 5.006 0 015-5zm14 2H14a3 3 0 00-3 3v14a2.951 2.951 0 00.3 1.285l9.163-9.163a5 5 0 017.072 0L31 23.586V14a3 3 0 00-3-3zm0 20a3 3 0 003-3v-1.586l-4.878-4.878a3 3 0 00-4.244 0L12.715 30.7A2.951 2.951 0 0014 31z\"/><path data-name=\"Trac&#xE9; 3138\" d=\"M17 19.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zm0-5a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z\"/><path data-name=\"Polygone 5\" d=\"M25.012 28.338l9.029 2.38-6.025 6.46z\"/><path data-name=\"Trac&#xE9; 3139\" d=\"M35.732 37.297l-1.077-1.077-1.73-1.727 2.789-2.789a1 1 0 00-.365-1.646l-8.969-3.254a2 2 0 00-2.561 2.56l3.263 8.969a1 1 0 001.646.365l2.786-2.794 1.727 1.727 1.077 1.073a1 1 0 101.414-1.414zm-7.307-1.128l-2.724-7.49 7.49 2.724z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36.025",
      "height": "39.001",
      "viewBox": "0 0 36.025 39.001"
    }
  };
  _exports.default = _default;
});