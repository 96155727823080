define("ember-cable/core/connection", ["exports", "ember-cable/core/connection_monitor"], function (_exports, _connection_monitor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    consumer: null,
    connected: false,
    isConnecting: false,
    _reopenTimer: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.open();
      this.monitor = _connection_monitor.default.create(Ember.getOwner(this).ownerInjection(), {
        connection: this
      });
    },
    nextConnectionAt: Ember.computed.readOnly('monitor.nextConnectionAt'),
    send: function send(data) {
      if (this.isOpen()) {
        Ember.get(this, 'webSocket').send(JSON.stringify(data));
      }
    },
    open: function open() {
      var _this = this;

      var ws = new WebSocket(Ember.get(this, 'consumer.url'));
      ['open', 'close', 'error', 'message'].forEach(function (eventName) {
        ws["on".concat(eventName)] = function (event) {
          Ember.run(function () {
            return Ember.tryInvoke(_this, "on".concat(Ember.String.capitalize(eventName)), [event]);
          });
        };
      });
      Ember.run(function () {
        Ember.set(_this, 'isConnecting', true);
        Ember.set(_this, 'webSocket', ws);
      });
    },
    close: function close() {
      var ws = this.get('webSocket');
      ['open', 'close', 'error', 'message'].forEach(function (eventName) {
        ws["on".concat(eventName)] = null;
      });
      Ember.tryInvoke(Ember.get(this, 'webSocket'), 'close');
      this.disconnect();
    },
    reopen: function reopen() {
      if (this.isClose()) {
        this.open();
      } else {
        this.close();
        this._reopenTimer = setTimeout(Ember.run.bind(this, 'reopen'), 500);
      }
    },
    willDestroy: function willDestroy() {
      this._super();

      clearTimeout(this._reopenTimer);
      this.monitor.destroy();
      this.close();
    },
    isClose: function isClose() {
      return !this.isOpen();
    },
    isOpen: function isOpen() {
      return Ember.isEqual(Ember.get(this, 'connected'), true) && Ember.isEqual(Ember.get(this, 'webSocket').readyState, Ember.get(this, 'webSocket').OPEN);
    },
    disconnect: function disconnect() {
      Ember.set(this, 'connected', false);
      Ember.get(this, 'consumer.subscriptions').notifyAll('disconnected');
    },
    onMessage: function onMessage(event) {
      var data = JSON.parse(event.data);

      switch (data.type) {
        case 'welcome':
          Ember.get(this, 'monitor').connected();
          break;

        case 'ping':
          Ember.get(this, 'monitor').ping();
          break;

        case 'confirm_subscription':
          Ember.get(this, 'consumer.subscriptions').notify(data.identifier, 'connected');
          break;

        case 'reject_subscription':
          Ember.get(this, 'consumer.subscriptions').reject(data.identifier);
          break;

        default:
          Ember.get(this, 'consumer.subscriptions').notify(data.identifier, 'received', data.message);
      }
    },
    onOpen: function onOpen() {
      var _this2 = this;

      Ember.run(function () {
        Ember.set(_this2, 'isConnecting', false);
        Ember.set(_this2, 'connected', true);
        Ember.get(_this2, 'consumer.subscriptions').reload();
      });
    },
    onClose: function onClose() {
      var _this3 = this;

      Ember.run(function () {
        Ember.set(_this3, 'isConnecting', false);
      });
      this.disconnect();
    },
    onError: function onError() {
      var _this4 = this;

      Ember.run(function () {
        Ember.set(_this4, 'isConnecting', false);
      });
      this.disconnect();
    }
  });

  _exports.default = _default;
});