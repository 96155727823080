define("ember-svg-jar/inlined/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2664\" d=\"M17.586 16.821H2.294a.765.765 0 01-.765-.765V.765A.765.765 0 100 .765v15.292a2.294 2.294 0 002.294 2.294h15.292a.765.765 0 000-1.529z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2665\" d=\"M11.47 15.292a.765.765 0 00.765-.765V9.176a.765.765 0 10-1.529 0v5.352a.765.765 0 00.764.764z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2666\" d=\"M5.353 15.292a.765.765 0 00.765-.765V9.176a.765.765 0 10-1.529 0v5.352a.765.765 0 00.764.764z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2667\" d=\"M14.528 15.288a.765.765 0 00.765-.765v-9.17a.765.765 0 10-1.529 0v9.175a.765.765 0 00.764.76z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2668\" d=\"M8.411 15.288a.765.765 0 00.765-.765v-9.17a.765.765 0 00-1.529 0v9.175a.765.765 0 00.764.76z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18.351",
      "height": "18.351",
      "viewBox": "0 0 18.351 18.351"
    }
  };
  _exports.default = _default;
});