define("ember-svg-jar/inlined/file-drop-zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#fff\"><path data-name=\"Trac&#xE9; 3093\" d=\"M19.994 18.803l-5.027-5.027a8.436 8.436 0 10-1.191 1.191l5.027 5.027a.842.842 0 101.191-1.191zM8.449 15.187a6.738 6.738 0 116.738-6.738 6.738 6.738 0 01-6.738 6.738z\"/><path data-name=\"Trac&#xE9; 3094\" d=\"M10.98 7.607H9.291V5.922a.843.843 0 10-1.685 0v1.685H5.922a.843.843 0 100 1.685h1.685v1.688a.843.843 0 101.685 0V9.291h1.688a.843.843 0 100-1.685z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20.241",
      "height": "20.241",
      "viewBox": "0 0 20.241 20.241"
    }
  };
  _exports.default = _default;
});