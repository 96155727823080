define("ember-svg-jar/inlined/pause-solid-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 2760\" d=\"M1.556 0A1.509 1.509 0 000 1.458v7.084A1.509 1.509 0 001.556 10a1.509 1.509 0 001.555-1.458V1.458A1.509 1.509 0 001.556 0z\"/><path data-name=\"Trac&#xE9; 2761\" d=\"M6.445 0a1.509 1.509 0 00-1.556 1.458v7.084a1.559 1.559 0 003.111 0V1.458A1.509 1.509 0 006.445 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "10",
      "viewBox": "0 0 8 10"
    }
  };
  _exports.default = _default;
});