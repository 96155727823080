define("ember-svg-jar/inlined/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EC9493\" d=\"M18.252 31.818a1.01 1.01 0 01-.05.122l-2.338 4.773a1 1 0 01-.898.56H9.399C7.065 37.273 5 39.257 5 41.5v4h34v-4.114c0-2.266-1.863-4.113-4.162-4.113h-6.255a1 1 0 01-.898-.56l-2.314-4.724c-1.046.332-2.16.511-3.312.511-1.336 0-2.62-.24-3.807-.682zm-7.028-8.726A3.485 3.485 0 019 19.847v-.694c0-1.43.862-2.674 2.118-3.204v-1.89c0-4.175 3.38-7.559 7.551-7.559H32a1 1 0 011 1v8.504c1.192.56 2 1.774 2 3.15v.693c0 1.42-.85 2.66-2.1 3.196a10.933 10.933 0 01-5.683 8.167l1.99 4.063h5.63c3.4 0 6.163 2.739 6.163 6.113V45.5h13v-43H2v43h1v-4c0-3.366 2.978-6.227 6.399-6.227h4.943l2.064-4.213c-2.722-1.757-4.712-4.612-5.182-7.968zM1 .5h54a1 1 0 011 1v45a1 1 0 01-1 1H1a1 1 0 01-1-1v-45a1 1 0 011-1zm17.67 8a5.555 5.555 0 00-5.552 5.558v2.643a1 1 0 01-.86.99c-.72.102-1.258.721-1.258 1.462v.694c0 .74.553 1.368 1.276 1.464a1 1 0 01.866.918c.342 4.642 4.23 8.271 8.917 8.271 4.698 0 8.585-3.632 8.918-8.287a1 1 0 01.82-.913A1.469 1.469 0 0033 19.847v-.694c0-.707-.507-1.317-1.19-1.45a1 1 0 01-.81-.981V8.5H18.67zm17.688 3v-2H52v2H36.358zm0 4v-2H52v2H36.358zm0 4v-2H48v2H36.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "56",
      "height": "48",
      "viewBox": "0 0 56 48"
    }
  };
  _exports.default = _default;
});