define("ember-svg-jar/inlined/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.625 9.75A3.375 3.375 0 119 6.375 3.375 3.375 0 015.625 9.75zm0-5.25A1.875 1.875 0 107.5 6.375 1.875 1.875 0 005.625 4.5zm5.625 12.75v-.375a5.625 5.625 0 10-11.25 0v.375a.75.75 0 101.5 0v-.375a4.125 4.125 0 118.25 0v.375a.75.75 0 001.5 0zM18 13.5a5.25 5.25 0 00-8.75-3.913.75.75 0 101 1.118 3.75 3.75 0 016.25 2.8.75.75 0 001.5 0zm-4.875-6.75A3.375 3.375 0 1116.5 3.375a3.375 3.375 0 01-3.375 3.375zm0-5.25A1.875 1.875 0 1015 3.375 1.875 1.875 0 0013.125 1.5z\" fill=\"#9ea2a3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});