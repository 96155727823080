define("ember-svg-jar/inlined/switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.55 9.242a.7.7 0 000-1.117l-3.108-2.6 9.83-.006a.877.877 0 00.944-.789.877.877 0 00-.944-.789l-9.832.006 3.11-2.6a.7.7 0 000-1.117 1.08 1.08 0 00-1.335 0L.83 3.062a2.1 2.1 0 000 3.349l3.386 2.831a1.079 1.079 0 001.335 0z\" fill=\"#9b9999\"/><path data-name=\"fi-rr-arrow-small-left\" d=\"M7.666 16.992a.7.7 0 010-1.117l3.108-2.6-9.83-.006a.877.877 0 01-.945-.788.877.877 0 01.944-.789l9.832.006-3.11-2.6a.7.7 0 010-1.117 1.08 1.08 0 011.335 0l3.386 2.831a2.1 2.1 0 010 3.349l-3.387 2.831a1.079 1.079 0 01-1.335 0z\" fill=\"#9b9999\"/>",
    "attrs": {
      "data-name": "Composant 16 – 9",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13.217",
      "height": "17.223",
      "viewBox": "0 0 13.217 17.223"
    }
  };
  _exports.default = _default;
});