define("ember-cable/core/subscriptions", ["exports", "ember-cable/core/subscription"], function (_exports, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Subscriptions = Ember.Object.extend({
    consumer: null,
    subscriptions: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.subscriptions = Ember.A();
    },
    create: function create(channelName, mixin) {
      var params = Ember.isEqual(Ember.typeOf(channelName), 'object') ? channelName : {
        channel: channelName
      };
      return _subscription.default.extend(Ember.Mixin.create(mixin)).create(Ember.getOwner(this).ownerInjection(), {
        subscriptions: this,
        params: params
      });
    },
    add: function add(subscription) {
      this.subscriptions.push(subscription);
      this.sendCommand(subscription, 'subscribe');
    },
    remove: function remove(subscription) {
      this.forget(subscription);

      if (!this.findAll(Ember.get(subscription, 'identifier')).length) {
        return this.sendCommand(subscription, 'unsubscribe');
      }
    },
    reload: function reload() {
      var _this = this;

      this.subscriptions.forEach(function (subscription) {
        _this.sendCommand(subscription, 'subscribe');
      });
    },
    reject: function reject(identifier) {
      var _this2 = this;

      this.findAll(identifier).forEach(function (subscription) {
        _this2.forget(subscription);

        _this2.notify(subscription, "rejected");
      });
    },
    forget: function forget(subscription) {
      this.subscriptions.removeObject(subscription);
    },
    findAll: function findAll(identifier) {
      return this.subscriptions.filter(function (item) {
        return Ember.get(item, 'identifier').toLowerCase() === identifier.toLowerCase();
      });
    },
    notifyAll: function notifyAll(callbackName) {
      var _this3 = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.subscriptions.forEach(function (subscription) {
        _this3.notify.apply(_this3, [subscription, callbackName].concat(args));
      });
    },
    notify: function notify(subscription, callbackName) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      var subscriptions;

      if (Ember.typeOf(subscription) === 'string') {
        subscriptions = this.findAll(subscription);
      } else {
        subscriptions = [subscription];
      }

      subscriptions.forEach(function (subscription) {
        Ember.tryInvoke(subscription, callbackName, args);
      });
    },
    sendCommand: function sendCommand(subscription, command) {
      var identifier = Ember.get(subscription, 'identifier');

      if (Ember.isEqual(identifier, '_ping')) {
        Ember.get(this, 'consumer.connection').isOpen();
      } else {
        Ember.get(this, 'consumer').send({
          command: command,
          identifier: identifier
        });
      }
    },
    willDestroy: function willDestroy() {
      this._super();

      this.subscriptions.forEach(function (subscription) {
        return subscription.destroy();
      });
    }
  });

  Subscriptions.toString = function () {
    return 'Subscriptions';
  };

  var _default = Subscriptions;
  _exports.default = _default;
});