define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.764 18.316a.764.764 0 00.54-.224l5.056-5.056.953.987a3.949 3.949 0 002.754 1.233 3.049 3.049 0 001.023-.173 2.773 2.773 0 001.86-2.385 6.482 6.482 0 00-.17-2.312l-.163-.8a.764.764 0 01.2-.72l1.212-1.213a.26.26 0 01.18-.076.13.13 0 01.128.05 2.351 2.351 0 003.033.33 2.292 2.292 0 00.29-3.487L13.9.711a2.357 2.357 0 00-3.005-.305 2.292 2.292 0 00-.29 3.487l.058.058a.235.235 0 010 .332L9.44 5.505a.764.764 0 01-.729.2l-.624-.16a6.594 6.594 0 00-2.354-.178 2.951 2.951 0 00-2.486 1.975 3.056 3.056 0 00.709 3.208L5.3 11.941.224 17.012a.764.764 0 00.54 1.3zM4.687 7.851a1.418 1.418 0 011.205-.966 5.118 5.118 0 01.529-.028 5.058 5.058 0 011.284.166l.629.16a2.3 2.3 0 002.186-.6l1.222-1.222a1.765 1.765 0 000-2.492l-.058-.058a.764.764 0 01.1-1.167.828.828 0 011.036.145l3.761 3.761a.764.764 0 01-.1 1.167.829.829 0 01-1.045-.153 1.69 1.69 0 00-1.21-.513 1.833 1.833 0 00-1.273.523l-1.211 1.213a2.292 2.292 0 00-.611 2.139l.167.808a5.077 5.077 0 01.138 1.807 1.264 1.264 0 01-.852 1.1 2.139 2.139 0 01-2.177-.688L5.046 9.477a1.548 1.548 0 01-.36-1.631z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18.334",
      "height": "18.316",
      "viewBox": "0 0 18.334 18.316"
    }
  };
  _exports.default = _default;
});