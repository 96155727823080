define("ember-paper/templates/components/paper-toaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fDGTyYIV",
    "block": "[[[18,2,null],[1,\"\\n\"],[41,[33,2,[\"show\"]],[[[6,[39,3],null,[[\"onClose\",\"position\",\"duration\",\"class\",\"parent\"],[[28,[37,4],[[30,0],[33,5],[33,2]],null],[33,2,[\"position\"]],[33,2,[\"duration\"]],[33,2,[\"toastClass\"]],[33,6]]],[[\"default\"],[[[[41,[33,2,[\"componentName\"]],[[[1,\"      \"],[46,[33,2,[\"componentName\"]],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[6,[30,1,[\"text\"]],null,null,[[\"default\"],[[[[1,[33,2,[\"text\"]]]],[]]]]],[1,\"\\n\"],[41,[33,2,[\"action\"]],[[[6,[39,8],null,[[\"primary\",\"accent\",\"warn\",\"onClick\"],[[33,2,[\"action\",\"primary\"]],[33,2,[\"action\",\"accent\"]],[33,2,[\"action\",\"warn\"]],[33,2,[\"action\",\"onClick\"]]]],[[\"default\"],[[[[1,\"          \"],[1,[33,2,[\"action\",\"label\"]]],[1,\"\\n\"]],[]]]]]],[]],null]],[]]]],[1]]]]]],[]],null]],[\"toast\",\"&default\"],false,[\"yield\",\"if\",\"activeToast\",\"paper-toast\",\"action\",\"onClose\",\"parent\",\"component\",\"paper-button\"]]",
    "moduleName": "ember-paper/templates/components/paper-toaster.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});