define("ember-svg-jar/inlined/preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9ea2a3\"><path data-name=\"Trac&#xE9; 3249\" d=\"M.75 3.562H2.8a2.8 2.8 0 005.4 0h9.05a.75.75 0 100-1.5H8.2a2.8 2.8 0 00-5.4 0H.75a.75.75 0 100 1.5zM5.5 1.5a1.312 1.312 0 11-1.313 1.312A1.312 1.312 0 015.5 1.5z\"/><path data-name=\"Trac&#xE9; 3250\" d=\"M17.25 8.25H15.2a2.8 2.8 0 00-5.4 0H.75a.75.75 0 100 1.5H9.8a2.8 2.8 0 005.4 0h2.05a.75.75 0 100-1.5zm-4.75 2.063a1.312 1.312 0 111.313-1.312 1.312 1.312 0 01-1.313 1.312z\"/><path data-name=\"Trac&#xE9; 3251\" d=\"M17.25 14.437H8.2a2.8 2.8 0 00-5.4 0H.75a.75.75 0 100 1.5H2.8a2.8 2.8 0 005.4 0h9.05a.75.75 0 100-1.5zM5.5 16.5a1.312 1.312 0 111.313-1.312A1.312 1.312 0 015.5 16.5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});