define("ember-feature-controls/route-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(router) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      path: 'features'
    };
    router.route('features-list', options);
  }
});