define("ember-svg-jar/inlined/booklet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 .935a4.071 4.071 0 00-3.324-.869l-3.1.558A3.249 3.249 0 009.728 1.66 3.249 3.249 0 007.876.624L4.783.066a4.078 4.078 0 00-3.325.866A4.007 4.007 0 000 4.02v8.68a4.029 4.029 0 003.327 3.955l5.1.919a7.356 7.356 0 002.609 0l5.1-.919a4.029 4.029 0 003.319-3.955V4.02A3.993 3.993 0 0018 .935zM8.917 16.021a5.218 5.218 0 01-.2-.033l-5.099-.918a2.417 2.417 0 01-2-2.373V4.02a2.422 2.422 0 012.435-2.412 2.518 2.518 0 01.438.039l3.1.563a1.612 1.612 0 011.326 1.578zm8.917-3.321a2.418 2.418 0 01-2 2.373l-5.095.918-.2.033V3.788a1.612 1.612 0 011.331-1.582l3.1-.563a2.447 2.447 0 011.995.522 2.4 2.4 0 01.872 1.854z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19.455",
      "height": "17.687",
      "viewBox": "0 0 19.455 17.687"
    }
  };
  _exports.default = _default;
});