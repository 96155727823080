define("ember-svg-jar/inlined/download-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2725\" d=\"M16.721 12.09a.742.742 0 00-.727.756v3.022a.742.742 0 01-.727.756H2.181a.742.742 0 01-.727-.756v-3.022a.742.742 0 00-.727-.756.742.742 0 00-.727.756v3.022a2.225 2.225 0 002.181 2.267h13.086a2.225 2.225 0 002.181-2.267v-3.022a.742.742 0 00-.727-.756z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2726\" d=\"M3.848 10.229L7.182 13.7a2.128 2.128 0 003.084 0l3.334-3.465a.777.777 0 000-1.068.708.708 0 00-1.028 0L9.451 12.4V.756a.728.728 0 10-1.454 0V12.4L4.876 9.161a.708.708 0 00-1.028 0 .777.777 0 000 1.069z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.448",
      "height": "18.134",
      "viewBox": "0 0 17.448 18.134"
    }
  };
  _exports.default = _default;
});