define("ember-svg-jar/inlined/duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2774\" d=\"M16.281 2.727L14.339.762A2.592 2.592 0 0012.507 0H8.5a4.238 4.238 0 00-4.165 3.333H4.25A4.214 4.214 0 000 7.5v8.333A4.214 4.214 0 004.25 20h5.1a4.214 4.214 0 004.25-4.168v-.082a4.2 4.2 0 003.4-4.083v-7.2a2.464 2.464 0 00-.719-1.74zM9.349 18.332H4.25a2.525 2.525 0 01-2.55-2.5V7.5A2.525 2.525 0 014.25 5v6.667a4.214 4.214 0 004.25 4.166h3.4a2.525 2.525 0 01-2.551 2.5zm3.4-4.167H8.5a2.525 2.525 0 01-2.55-2.5v-7.5a2.525 2.525 0 012.55-2.5h3.4v1.668A1.684 1.684 0 0013.6 5h1.7v6.667a2.525 2.525 0 01-2.551 2.5z\" fill=\"#9d9fa3\"/><g data-name=\"Groupe 4078\" fill=\"#9d9fa3\"><path data-name=\"Ligne 136\" d=\"M10.769 11.853a.75.75 0 01-.75-.75v-5a.75.75 0 011.5 0v5a.75.75 0 01-.75.75z\"/><path data-name=\"Ligne 137\" d=\"M13.144 9.353h-4.75a.75.75 0 010-1.5h4.75a.75.75 0 010 1.5z\"/></g>",
    "attrs": {
      "data-name": "Groupe 4079",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "20",
      "viewBox": "0 0 17 20"
    }
  };
  _exports.default = _default;
});