define("ember-svg-jar/inlined/checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Checkbox</title><path d=\"M2.828 4.364L7.071.121l.707.707-4.242 4.243-.708-.707zm0 1.414L0 2.95l.707-.707L3.536 5.07l-.708.707z\" fill=\"#33C46C\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "8",
      "height": "6",
      "viewBox": "0 0 8 6",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});