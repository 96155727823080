define("ember-svg-jar/inlined/extract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#56A1F3\" fill-rule=\"evenodd\" d=\"M6 24H.667V0H12.8l6.532 6.533V24H14v-1.333h4v-15.6l-5.733-5.734H2v21.334h4V24zm.65-5.026c-.351 0-.477.238-.257.531l3.204 4.272c.226.302.576.293.796 0l3.204-4.272c.226-.301.105-.53-.257-.53h-2.679v-4.663a.675.675 0 00-.666-.67c-.358 0-.667.3-.667.67v4.662H6.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "24",
      "viewBox": "0 0 20 24"
    }
  };
  _exports.default = _default;
});