define("ember-query-params-service/utils/query-param/index", ["exports", "@ember-decorators/utils/decorator", "ember-query-params-service/utils/query-param/helpers"], function (_exports, _decorator, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryParam = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // type DecoratorCreator = (...args: Args<string>) => PropertyDecorator;
  // type DecoratorWithParams = PropertyDecorator | DecoratorCreator;
  var queryParam = (0, _decorator.decoratorWithParams)(queryParamWithOptionalParams)
  /* ugh */
  ;
  _exports.queryParam = queryParam;

  function queryParamWithOptionalParams(_target, propertyKey, sourceDescriptor) {
    var oldGet = sourceDescriptor.get,
        initializer = sourceDescriptor.initializer;

    for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
      args[_key - 3] = arguments[_key];
    }

    var _extractArgs = (0, _helpers.extractArgs)(args, propertyKey),
        _extractArgs2 = _slicedToArray(_extractArgs, 2),
        propertyPath = _extractArgs2[0],
        options = _extractArgs2[1]; // There is no initializer, so stage 1 decorators actually
    // don't have the capability to do what I want :(
    // setupController(target);
    //
    // this means that in order to use any query param willy-nilly
    // we'll need to prevent the router from looking up the controller
    // to remove un-specified query params


    var result = {
      configurable: true,
      enumerable: true,
      get: function get() {
        // setupController(this, 'application');
        var service = (0, _helpers.ensureService)(this);
        var value = Ember.get(service, propertyPath);
        var deserialized = (0, _helpers.tryDeserialize)(value, options);
        return deserialized || (oldGet === null || oldGet === void 0 ? void 0 : oldGet()) || (initializer === null || initializer === void 0 ? void 0 : initializer());
      },
      set: function set(value) {
        // setupController(this, 'application');
        var service = (0, _helpers.ensureService)(this);
        var serialized = (0, _helpers.trySerialize)(value, options);
        Ember.set(service, propertyPath, serialized);
      }
    };
    return result;
  }
});