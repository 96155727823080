define("ember-svg-jar/inlined/more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9b9999\"><path data-name=\"Ellipse 25\" d=\"M1.5 0A1.5 1.5 0 110 1.5 1.5 1.5 0 011.5 0z\"/><path data-name=\"Ellipse 26\" d=\"M9.5 0A1.5 1.5 0 118 1.5 1.5 1.5 0 019.5 0z\"/><path data-name=\"Ellipse 27\" d=\"M17.5 0A1.5 1.5 0 1116 1.5 1.5 1.5 0 0117.5 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "3",
      "viewBox": "0 0 19 3"
    }
  };
  _exports.default = _default;
});