define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#34C46C\" fill-rule=\"evenodd\" d=\"M7 14A7 7 0 117 0a7 7 0 010 14zm-.616-5.33L4.263 6.547l-.708.707 2.829 2.829 4.95-4.95-.707-.707-4.243 4.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});