define("ember-validated-form/templates/components/validated-input/types/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asNIbN5B",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"label\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,\"checked\",[28,[37,3],[[33,4],[30,1,[\"key\"]]],null]],[16,2,[30,1,[\"key\"]]],[16,3,[36,5]],[16,1,[29,[[36,6],\"-\",[30,2]]]],[16,\"disabled\",[36,7]],[24,4,\"checkbox\"],[4,[38,8],[\"input\",[28,[37,9],[[30,0,[\"onUpdate\"]],[30,1,[\"key\"]]],null]],null],[12],[13],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"eq\",\"value\",\"name\",\"inputId\",\"disabled\",\"on\",\"fn\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/checkbox-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});