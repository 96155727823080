define("ember-hifi/hifi-connections/dummy-connection", ["exports", "ember-hifi/hifi-connections/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var ClassMethods = Ember.Mixin.create({
    setup: function setup() {},
    canPlay: function canPlay() {
      return true;
    },
    canUseConnection: function canUseConnection() {
      return true;
    },
    canPlayMimeType: function canPlayMimeType() {
      return true;
    },
    toString: function toString() {
      return 'Dummy Connection';
    }
  });

  var DummyConnection = _base.default.extend({
    debugName: 'dummyConnection',
    _position: 0,
    _tickInterval: 50,
    setup: function setup() {
      var _this = this;

      var _this$getInfoFromUrl = this.getInfoFromUrl(),
          result = _this$getInfoFromUrl.result;

      if (result === 'bad') {
        Ember.run.next(function () {
          return _this.trigger('audio-load-error', _this);
        });
      } else {
        Ember.run.next(function () {
          return _this.trigger('audio-ready', _this);
        });
      }
    },
    stopTicking: function stopTicking() {
      window.clearTimeout(this.tick);
    },
    startTicking: function startTicking() {
      var _this2 = this;

      if (!Ember.Test.checkWaiters || Ember.Test.checkWaiters()) {
        this.tick = window.setTimeout(Ember.run.bind(function () {
          _this2._setPosition((_this2._currentPosition() || 0) + _this2.get('_tickInterval'));

          _this2.startTicking();
        }), this.get('_tickInterval'));
      } else {
        this.stopTicking();
      }
    },
    getInfoFromUrl: function getInfoFromUrl() {
      if (!this.get('url')) {
        return {};
      } else if (this.get('url').startsWith('/')) {
        var _this$get$split = this.get('url').split('/'),
            _this$get$split2 = _slicedToArray(_this$get$split, 4),
            result = _this$get$split2[1],
            length = _this$get$split2[2],
            name = _this$get$split2[3];
        /*eslint no-console: 0 */


        if (!(result && length && name)) {
          console.error('[dummy-connection] url format should be "/:result/:length/:name"');
        } else {
          if (!(length === 'stream' || parseInt(length) > 0)) {
            console.error('[dummy-connection] url format should be "/:result/:length/:name"');
            console.error("[dummy-connection] length should be an integer or \"stream\". Was given ".concat(this.get('url')));
          }

          if (!(result === 'good' || result === 'bad')) {
            console.error('[dummy-connection] url format should be "/:result/:length/:name"');
            console.error("[dummy-connection] status should be 'good' or 'bad'. Was given ".concat(this.get('url')));
          }
        }

        return {
          result: result,
          length: length,
          name: name
        };
      } else {
        return {
          result: 'good',
          length: 1000,
          name: 'default'
        };
      }
    },
    play: function play() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          position = _ref.position;

      if (typeof position !== 'undefined') {
        this.set('_position', position);
      }

      this.trigger('audio-played', this);
      this.startTicking();
    },
    pause: function pause() {
      this.trigger('audio-paused', this);
      this.stopTicking();
    },
    stop: function stop() {
      this.trigger('audio-paused', this);
      this.stopTicking();
    },
    _setPosition: function _setPosition(duration) {
      var _this3 = this;

      duration = Math.max(0, duration);
      duration = Math.min(this._audioDuration(), duration);
      this.set('_position', duration);

      if (duration >= this._audioDuration()) {
        Ember.run.next(function () {
          _this3.trigger('audio-ended', _this3);

          _this3.stopTicking();
        });
      }

      return duration;
    },
    _currentPosition: function _currentPosition() {
      return this.get('_position');
    },
    _setVolume: function _setVolume(v) {
      this.set('volume', v);
    },
    _audioDuration: function _audioDuration() {
      var _this$getInfoFromUrl2 = this.getInfoFromUrl(),
          result = _this$getInfoFromUrl2.result,
          length = _this$getInfoFromUrl2.length;

      if (result === 'bad') {
        return;
      }

      if (length === 'stream') {
        return Infinity;
      } else {
        return parseInt(length, 10);
      }
    }
  });

  DummyConnection.reopenClass(ClassMethods);
  var _default = DummyConnection;
  _exports.default = _default;
});