define("ember-paper/templates/components/paper-card-header-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3vkQAM3D",
    "block": "[[[18,1,[[28,[37,1],null,[[\"title\",\"subhead\"],[[50,\"paper-card-header-title\",0,null,null],[50,\"paper-card-header-subhead\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-card-header-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});