define("ember-validated-form/templates/components/validated-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DfxAEhFD",
    "block": "[[[43,[50,[33,2],0,null,[[\"onclick\",\"disabled\",\"label\",\"type\",\"class\"],[[33,3],[33,4],[33,5],[33,6],[33,7]]]],[[[41,[48,[30,2]],[[[1,\"    \"],[46,[30,1],null,null,[[\"default\"],[[[[18,2,null]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[46,[30,1],null,null,null],[1,\"\\n\"]],[]]]],[1]],null]],[\"button\",\"&default\"],false,[\"with\",\"component\",\"buttonComponent\",\"action\",\"disabled\",\"label\",\"type\",\"class\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});