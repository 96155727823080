define("ember-paper/components/paper-select/ebd-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/hcRWyx",
    "block": "[[[41,[30,1,[\"isOpen\"]],[[[8,[39,1],null,[[\"@destinationElement\",\"@renderInPlace\"],[[30,0,[\"destinationElement\"]],[30,2]]],[[\"default\"],[[[[1,\"    \"],[8,[39,2],[[24,0,\"md-select-backdrop md-click-catcher\"]],[[\"@onClick\"],[[28,[37,3],[[30,0],[30,1,[\"actions\",\"close\"]]],null]]],null],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[8,[39,4],[[16,0,[29,[\"md-select-menu-container md-clickable \",[52,[30,0,[\"isActive\"]],\"md-active\"]]]],[17,3],[4,[38,5],[[30,0,[\"animateIn\"]]],null],[4,[38,6],[[30,0,[\"animateOut\"]]],null]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"@otherStyles\",\"@animationEnabled\",\"@shouldReposition\"],[[30,1],[30,4],[30,2],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,0,[\"customStyles\"]],false,[30,0,[\"shouldReposition\"]]]],[[\"default\"],[[[[1,\"\\n\\n\"],[1,\"  \"],[11,\"md-select-menu\"],[16,0,[29,[\"md-default-theme \",[52,[30,13],\"md-overflow\"]]]],[4,[38,7],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[12],[1,\"\\n    \"],[18,14,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@dropdown\",\"@renderInPlace\",\"&attrs\",\"@hPosition\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"@searchEnabled\",\"&default\"],false,[\"if\",\"maybe-in-element\",\"paper-backdrop\",\"action\",\"basic-dropdown-content\",\"did-insert\",\"will-destroy\",\"on\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-select/ebd-content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});