define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" fill-rule=\"evenodd\" d=\"M9 0C4.026 0 0 4.026 0 9s4.026 9 9 9 9-4.026 9-9-4.026-9-9-9zm0 17.053A8.046 8.046 0 01.947 9C.947 4.547 4.547.947 9 .947c4.453 0 8.053 3.6 8.053 8.053 0 4.453-3.6 8.053-8.053 8.053zM11.509 9c.263 0 .357-.178.192-.398L9.3 5.398c-.17-.226-.433-.22-.598 0L6.3 8.602c-.17.226-.08.398.192.398H8.5v3.497c0 .27.224.503.5.503.268 0 .5-.225.5-.503V9h2.009z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});