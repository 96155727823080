define("ember-paper/components/paper-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mtevoA7o",
    "block": "[[[11,\"md-divider\"],[24,0,\"md-default-theme\"],[16,\"md-inset\",[30,1]],[17,2],[12],[13]],[\"@inset\",\"&attrs\"],false,[]]",
    "moduleName": "ember-paper/components/paper-divider/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});