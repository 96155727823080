define("ember-svg-jar/inlined/arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M2.026 2.975a.5.5 0 010-.707L4.148.147a.5.5 0 01.707 0l2.121 2.12a.5.5 0 01-.707.708L5.001 1.707V8.5a.5.5 0 11-1 0V1.707L2.734 2.975a.5.5 0 01-.708 0z\" fill=\"#9EA2A3\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h9v9H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});