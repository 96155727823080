define("ember-paper/templates/components/paper-card-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3iSDB1+",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[10,0],[15,0,[29,[\"md-media-\",[36,2]]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"img\"],[15,0,[29,[\"md-media-\",[36,2]]]],[15,\"src\",[36,4]],[15,\"alt\",[36,5]],[15,\"title\",[36,6]],[12],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"size\",\"yield\",\"src\",\"alt\",\"title\"]]",
    "moduleName": "ember-paper/templates/components/paper-card-media.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});