define("ember-svg-jar/inlined/upload-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 4404\" fill=\"#9d9fa3\"><path data-name=\"music-file (1)\" d=\"M15 7.864v6.386c0 2.07-1.68 3.249-3.75 3.251h-7.5C1.68 17.5 0 16.32 0 14.25V3.75A3.754 3.754 0 013.75 0h3.386a5.216 5.216 0 013.712 1.538l2.613 2.613A5.216 5.216 0 0115 7.864zM9.788 2.6A3.742 3.742 0 009 2.013V5.25a.75.75 0 00.75.75h3.237a3.742 3.742 0 00-.586-.788zM13.5 7.864c0-.124-.024-.242-.035-.364H9.75A2.25 2.25 0 017.5 5.25V1.535c-.122-.011-.24-.035-.364-.035H3.75A2.25 2.25 0 001.5 3.75v10.5c0 1.243 1.007 1.833 2.25 1.833V17.5h7.5v-1.417c1.243 0 2.25-.59 2.25-1.833z\"/><path data-name=\"Trac&#xE9; 2778\" d=\"M4.399 11.637l1.941-2s.562-.672 1.08-.636 1.076.672 3.227 2.635a.751.751 0 010 .837.8.8 0 01-.866 0l-1.59-1.308v6.495a.8.8 0 01-.763.778.741.741 0 01-.737-.778v-6.495l-1.352 1.308a1.071 1.071 0 01-1.013 0 .758.758 0 01.073-.837z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "18.438",
      "viewBox": "0 0 15 18.438"
    }
  };
  _exports.default = _default;
});