define("ember-svg-jar/inlined/caution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5276\" fill=\"#f46442\"><path data-name=\"Trac&#xE9; 2753\" d=\"M11.496 14.755a1.183 1.183 0 101.148 1.183 1.183 1.183 0 00-1.148-1.183z\"/><path data-name=\"Trac&#xE9; 2754\" d=\"M22.44 18.943a4.026 4.026 0 00.009-4.081l-7.406-12.81a4.084 4.084 0 00-7.085 0L.542 14.871a4.09 4.09 0 003.551 6.127h14.791a4.074 4.074 0 003.556-2.057zm-1.609-.926a2.236 2.236 0 01-1.953 1.125H4.085a2.236 2.236 0 01-1.934-3.358L9.567 2.966a2.229 2.229 0 013.869 0l7.414 12.827a2.2 2.2 0 01-.017 2.224z\"/><path data-name=\"Trac&#xE9; 2755\" d=\"M11.203 6.463a1.287 1.287 0 00-.918 1.291c.029.374.052.752.08 1.126l.242 4.244a.869.869 0 00.889.833.887.887 0 00.889-.861c0-.293 0-.563.029-.86q.078-1.37.162-2.739c.029-.592.08-1.183.106-1.774a1.455 1.455 0 00-.106-.592 1.193 1.193 0 00-1.374-.668z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "21",
      "viewBox": "0 0 23 21"
    }
  };
  _exports.default = _default;
});