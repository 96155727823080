define("ember-svg-jar/inlined/catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.776.931a4 4 0 00-3.283-.866l-3.06.556a3.2 3.2 0 00-1.824 1.033A3.2 3.2 0 007.78.621L4.724.066A4 4 0 000 4v8.642a4 4 0 003.287 3.939l5.033.919a7.206 7.206 0 002.577 0l5.037-.915a4 4 0 003.283-3.939V4A3.99 3.99 0 0017.776.928zM8.808 15.957a5.958 5.958 0 01-.2-.033l-5.034-.914A2.4 2.4 0 011.6 12.646V4A2.4 2.4 0 014 1.6a2.467 2.467 0 01.432.039l3.063.561a1.6 1.6 0 011.313 1.573zm8.808-3.311a2.4 2.4 0 01-1.973 2.364l-5.032.914a8.312 8.312 0 01-.2.033V3.773A1.6 1.6 0 0111.724 2.2l3.059-.56A2.4 2.4 0 0117.616 4z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19.217",
      "height": "17.616",
      "viewBox": "0 0 19.217 17.616"
    }
  };
  _exports.default = _default;
});