define("ember-collapsible-panel/components/cp-panels/component", ["exports", "ember-collapsible-panel/components/cp-panels/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: 'cp-Panels',
    accordion: false,
    animate: true,
    _cpPanels: true,
    name: Ember.computed.oneWay('elementId')
  });

  _exports.default = _default;
});