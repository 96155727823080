define("ember-onbeforeunload/mixins/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _ensureConfirmationMixinOnRoute: Ember.on('init', function () {
      if (!(this instanceof Ember.Route)) {
        throw Error('ember-onbeforeunload ConfirmationMixin must be mixed into a Route.');
      }
    }),
    confirmationMessage: function confirmationMessage()
    /* model */
    {
      return 'Unsaved changes! Are you sure you would like to continue?';
    },
    onUnload: function onUnload() {
      /* intentionally left blank to implement own custom teardown logic */
    },
    onBeforeunload: function onBeforeunload(e) {
      if (this.isPageDirty(this.modelFor(this.routeName))) {
        var confirmationMessage = this.readConfirmation();
        e.returnValue = confirmationMessage; // Gecko and Trident

        return confirmationMessage; // Gecko and WebKit
      }
    },
    isPageDirty: function isPageDirty(model) {
      if (model) {
        return !!Ember.get(model, 'hasDirtyAttributes');
      } else {
        return false;
      }
    },
    handleEvent: function handleEvent(event) {
      var fnName = event.type.split('');

      if (fnName.length) {
        fnName[0] = fnName[0].toUpperCase();
        var fn = this['on' + fnName.join('')];

        if (typeof fn === 'function') {
          fn.apply(this, arguments);
        }
      }
    },
    activate: function activate() {
      var _super = this._super.apply(this, arguments);

      if (window && window.addEventListener) {
        window.addEventListener('beforeunload', this, false);
        window.addEventListener('unload', this, false);
      }

      return _super;
    },
    deactivate: function deactivate() {
      var _super = this._super.apply(this, arguments);

      if (window && window.removeEventListener) {
        window.removeEventListener('beforeunload', this, false);
        window.removeEventListener('unload', this, false);
      }

      return _super;
    },
    readConfirmation: function readConfirmation() {
      var msg = Ember.get(this, 'confirmationMessage');

      if (typeof msg === 'function') {
        var currentModel = this.modelFor(this.routeName);
        msg = msg.call(this, currentModel);
      }

      return msg;
    },
    shouldCheckIsPageDirty: function shouldCheckIsPageDirty(transition) {
      return transition.targetName.indexOf(this.routeName + '.') === 0;
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        this._super.apply(this, arguments);

        var allow = this.shouldCheckIsPageDirty(transition);

        if (!allow && this.isPageDirty(this.modelFor(this.routeName))) {
          var msg = this.readConfirmation();

          if (this.customConfirm) {
            if (this._transitionConfirmed) {
              this._transitionConfirmed = undefined;
              return true;
            }

            transition.abort();
            this.customConfirm(transition).then(function () {
              _this._transitionConfirmed = true;
              transition.retry();
            });
          } else if (window && window.confirm && !window.confirm(msg)) {
            transition.abort();
            return false;
          }
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});