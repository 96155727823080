define("ember-svg-jar/inlined/upload-audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"upload-audio (2)\" fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 3495\" d=\"M11.588 10.717a.892.892 0 01-.891.893h-.891v4.019A2.229 2.229 0 117.577 13.4a2.293 2.293 0 01.446.045V11.61a1.784 1.784 0 011.783-1.786h.894a.892.892 0 01.888.893zm6.24-1.353l-.047 5.723c0 .319 3.2.711 4.348 3.12.717 1.5-.5 5.031-1.33 5.674-.5.388-3.914 1.973-6.133.965-2.018-.915-2.861-4.488-3.078-4.345-.413.285 1.412.881.683.941l-7.814-.009A4.467 4.467 0 010 16.968V4.465A4.467 4.467 0 014.457 0h4.025A6.194 6.194 0 0112.9 1.832L16 4.943a6.217 6.217 0 011.828 4.421zm-5.978-6.71c-.28-.273-1.093-.292-1.435-.482v4.079c0 .493.68 1.313 1.172 1.313L16 7.749a13.318 13.318 0 00-.8-1.83zm4.45 6.71c0-.147.013-.288 0-.433h-4.712a2.677 2.677 0 01-2.674-2.679V1.4a41.929 41.929 0 00-4.457 0 3.338 3.338 0 00-3.012 3.065v12.5a3.341 3.341 0 003.012 3.07l7.746.019c.109.453.339 3.7 2.463 4.789 1.762.911 5.459-.4 6.133-1.679 0 0 2.084-2.94 1.33-4.96s-4.348-3.12-4.348-3.12c.069.242-1.465.258-1.465 0z\"/><path data-name=\"Trac&#xE9; 3077\" d=\"M17 15.3a4.7 4.7 0 104.7 4.7 4.7 4.7 0 00-4.7-4.7m0-1.3a6 6 0 11-6 6 6 6 0 016-6z\"/><g data-name=\"Groupe 4088\"><path data-name=\"Ligne 136\" d=\"M17 23.25a.75.75 0 01-.75-.75v-5a.75.75 0 011.5 0v5a.75.75 0 01-.75.75z\"/><path data-name=\"Ligne 137\" d=\"M13.75 20a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "26",
      "viewBox": "0 0 23 26"
    }
  };
  _exports.default = _default;
});