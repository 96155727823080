define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.86 7.41a2.659 2.659 0 00-2.556-1.916h-3.642L12.556 1.93a2.664 2.664 0 00-5.122 0L6.328 5.491H2.686A2.7 2.7 0 00.133 7.41a2.83 2.83 0 00.97 3.1l2.964 2.24-1.127 3.6a2.786 2.786 0 00.987 3.11 2.581 2.581 0 003.165-.011l2.9-2.206 2.9 2.2a2.621 2.621 0 003.166.016 2.829 2.829 0 00.987-3.107l-1.127-3.6 2.974-2.242a2.786 2.786 0 00.969-3.1zm-1.953 1.711l-3.454 2.608a.877.877 0 00-.3.961l1.313 4.193a1.076 1.076 0 01-.376 1.181 1 1 0 01-1.2-.006l-3.4-2.583a.812.812 0 00-.987 0l-3.4 2.583a1 1 0 01-1.208.011 1.076 1.076 0 01-.375-1.186l1.319-4.193a.877.877 0 00-.3-.961L2.083 9.121a1.076 1.076 0 01-.368-1.18 1.026 1.026 0 01.971-.731h4.251a.837.837 0 00.793-.6l1.292-4.154a1.013 1.013 0 011.947 0l1.292 4.159a.837.837 0 00.793.6h4.251a1.026 1.026 0 01.971.728 1.076 1.076 0 01-.368 1.18z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});