define("ember-svg-jar/inlined/24-bits-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5236\" transform=\"translate(-1108 -417)\"><rect data-name=\"Rectangle 2313\" width=\"20\" height=\"20\" rx=\"1\" transform=\"translate(1108 417)\" fill=\"#f8c740\"/><path data-name=\"Trac&#xE9; 3144\" d=\"M1112.39 430.9h5.02v-1.39h-2.7c1.16-.89 2.59-2.17 2.59-3.77a2.113 2.113 0 00-2.4-2.2 2.376 2.376 0 00-2.53 2.53h1.65c0-.69.3-1.09.81-1.09s.76.34.76.87c0 1.23-1.65 2.5-3.2 3.78zm9.17.1h1.71v-1.3h.82v-1.48h-.82v-4.42h-1.91l-3.16 4.52v1.38h3.36zm-1.56-2.78l1.68-2.54v2.54z\" fill=\"#fff\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});