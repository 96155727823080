define("ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/8Xdg3Zr",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"custom-control custom-checkbox\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,0,[29,[\"custom-control-input \",[52,[33,4],\"is-valid\"],\" \",[52,[33,5],\"is-invalid\"]]]],[16,\"checked\",[28,[37,6],[[30,1,[\"key\"]],[33,7]],null]],[16,3,[36,8]],[16,1,[29,[[36,9],\"-\",[30,2]]]],[16,\"disabled\",[36,10]],[24,4,\"checkbox\"],[4,[38,11],[\"input\",[28,[37,12],[[30,0,[\"onUpdate\"]],[30,1,[\"key\"]]],null]],null],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,0,\"custom-control-label\"],[15,\"for\",[29,[[36,9],\"-\",[30,2]]]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"if\",\"isValid\",\"isInvalid\",\"includes\",\"value\",\"name\",\"inputId\",\"disabled\",\"on\",\"fn\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/bootstrap/checkbox-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});