define("ember-svg-jar/inlined/xml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>xml</title><path d=\"M0 63.002v-62a1 1 0 011-1h32.768a.993.993 0 01.126 0h.194a1 1 0 01.707.292l11.912 11.893a1 1 0 01.293.708v50.107a1 1 0 01-1 1H1a1 1 0 01-1-1zm45-1V14.159H33.823a1 1 0 01-1-1V2.002H2v60h42.998zM34.823 3.416l8.742 8.743h-8.742V3.416zM12 35.577v-1.351l9.498-4.224v2.065L15.154 34.9l6.344 2.835V39.8L12 35.577zm22-1.351v1.351l-9.498 4.224v-2.065l6.344-2.835-6.344-2.834v-2.065L34 34.226z\" fill=\"#ED9493\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "47",
      "height": "64",
      "viewBox": "0 0 47 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});