define("ember-custom-actions/serializers/json-api", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && Ember.deprecate('Using ember-custom-actions `JSONAPISerializer` is no longer required and this class will be removed.', false, {
        id: 'ember-custom-actions.deprecate-jsonapi-serializer',
        until: '3.0.0'
      }));
    }
  });

  _exports.default = _default;
});