define("ember-svg-jar/inlined/upload-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.137 8.127l-3.073-2.293a1.587 1.587 0 00-1.68-.14 1.643 1.643 0 00-.882 1.461v4.588a1.641 1.641 0 00.882 1.465 1.586 1.586 0 001.681-.142l3.072-2.294a1.657 1.657 0 000-2.643z\" fill=\"#9b9999\"/><path d=\"M17.688 0H4.655C2.085 0 0 1.775 0 3.962v11.095c0 2.187 2.085 3.96 4.655 3.962h7.537c-.074-1.05 2.114-5 3.215-5.894.639-.491 5.473.271 6.953 1.419l-.017-10.582C22.34 1.775 20.258 0 17.688 0zM21 13.125c0 .533-5-.642-5.592 0l-3.215 4.8H4.655c-1.542 0-3.323-1.551-3.323-2.864V3.962c0-1.313 1.78-2.739 3.323-2.739h13.033c1.542 0 3.31 1.426 3.31 2.739z\" fill=\"#9b9999\"/><path data-name=\"Trac&#xE9; 3077\" d=\"M17.797 13.3a4.7 4.7 0 104.7 4.7 4.705 4.705 0 00-4.7-4.7m0-1.3a6 6 0 11-6 6 6 6 0 016-6z\" fill=\"#9b9999\"/><g data-name=\"Groupe 5514\" fill=\"#9b9999\"><path data-name=\"Ligne 136\" d=\"M17.797 21.25a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75.75.75 0 01.75.75v5a.75.75 0 01-.75.75z\"/><path data-name=\"Ligne 137\" d=\"M14.547 18a.75.75 0 01.75-.75h5a.75.75 0 01.75.75.75.75 0 01-.75.75h-5a.75.75 0 01-.75-.75z\"/></g>",
    "attrs": {
      "data-name": "Composant 252 – 2",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23.797",
      "height": "24",
      "viewBox": "0 0 23.797 24"
    }
  };
  _exports.default = _default;
});