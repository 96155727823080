define("ember-svg-jar/inlined/error-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5466\"><g data-name=\"Groupe 4383\"><circle data-name=\"Ellipse 34\" cx=\"17.5\" cy=\"17.5\" r=\"17.5\" fill=\"#e16c71\"/><g data-name=\"Groupe 5457\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-width=\"2\"><path data-name=\"Ligne 220\" d=\"M12.33 12.291l9.631 9.631\"/><path data-name=\"Ligne 221\" d=\"M21.961 12.291l-9.631 9.631\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35"
    }
  };
  _exports.default = _default;
});