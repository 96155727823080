define("ember-cable/core/consumer", ["exports", "ember-cable/core/subscriptions", "ember-cable/core/connection"], function (_exports, _subscriptions, _connection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // Services
    fastboot: Ember.inject.service(),
    // Default Values
    url: null,
    isConnecting: Ember.computed.readOnly('connection.isConnecting'),
    nextConnectionAt: Ember.computed.readOnly('connection.nextConnectionAt'),
    init: function init() {
      this._super.apply(this, arguments);

      this.subscriptions = _subscriptions.default.create(Ember.getOwner(this).ownerInjection(), {
        consumer: this
      });

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        this.connection = _connection.default.create(Ember.getOwner(this).ownerInjection(), {
          consumer: this
        });
      }
    },
    send: function send(data) {
      if (this.connection) {
        this.connection.send(data);
      }
    },
    willDestroy: function willDestroy() {
      this._super();

      this.subscriptions.destroy();

      if (this.connection) {
        this.connection.destroy();
      }
    }
  });

  _exports.default = _default;
});