define("ember-svg-jar/inlined/empty-illustration-video-ok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 10C0 4.477 4.477 0 10 0h180c5.523 0 10 4.477 10 10v100c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10zm109.951 60.999c.054-.55-.399-.999-.951-.999H91c-.552 0-1.005.449-.95.999C90.55 76.053 94.813 80 100 80c5.186 0 9.45-3.947 9.951-9.001zM83 55a4 4 0 100-8 4 4 0 000 8zm39-4a4 4 0 11-8 0 4 4 0 018 0zm57 55H36.83a3.001 3.001 0 00-5.66 0H21a1 1 0 000 2h10.17a3.001 3.001 0 005.66 0H179a1 1 0 000-2z\" fill=\"#8CB1E8\"/>",
    "attrs": {
      "width": "200",
      "height": "120",
      "viewBox": "0 0 200 120",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});