define("ember-svg-jar/inlined/informations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" d=\"M9 16A7 7 0 119 2a7 7 0 010 14zm0-1A6 6 0 109 3a6 6 0 000 12zM8 9.51a1 1 0 112 0v1.98a1 1 0 11-2 0V9.51zM8 6.5c0-.552.444-1 1-1 .552 0 1 .444 1 1 0 .552-.444 1-1 1-.552 0-1-.444-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});