define("ember-image-drop/templates/components/image-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TrKfDETy",
    "block": "[[[18,1,null],[1,\"\\n\"],[10,0],[14,0,\"text\"],[15,5,[36,1]],[12],[1,\"\\n  \"],[10,2],[14,0,\"placeholder\"],[12],[1,[34,2]],[13],[1,\"\\n  \"],[10,2],[14,0,\"help-text\"],[12],[1,[34,3]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"input\"],[15,\"accept\",[36,4]],[14,4,\"file\"],[12],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"textStyle\",\"placeholder\",\"helpText\",\"accept\"]]",
    "moduleName": "ember-image-drop/templates/components/image-drop.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});