define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" fill-rule=\"evenodd\" d=\"M9 10c4.661 0 8.5 3.5 9 8H0c.5-4.5 4.339-8 9-8zm7.796 7A8.044 8.044 0 009 11a8.044 8.044 0 00-7.796 6h15.592zM14.03 5c0 2.761-2.253 5-5.031 5-2.778 0-5.03-2.239-5.03-5S6.221 0 9 0c2.778 0 5.03 2.239 5.03 5zm-1.006 0c0 2.21-1.802 4-4.025 4-2.223 0-4.025-1.79-4.025-4S6.777 1 9 1c2.223 0 4.025 1.79 4.025 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});