define("ember-svg-jar/inlined/rounded-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.475 7.232a.5.5 0 00-.707-.707L9 8.293 7.232 6.525a.5.5 0 00-.707.707L8.293 9l-1.768 1.768a.5.5 0 00.707.707L9 9.707l1.768 1.768a.5.5 0 10.707-.707L9.707 9l1.768-1.768z\" fill=\"#9EA1A3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 17A8 8 0 109 1a8 8 0 000 16zm0 1A9 9 0 109 0a9 9 0 000 18z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});