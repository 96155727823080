define("ember-paper/templates/components/paper-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XQTyUQP3",
    "block": "[[[40,[[[6,[39,2],null,[[\"swipe\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\"],[[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9]]],[[\"default\"],[[[[1,\"    \"],[18,1,[[28,[37,11],null,[[\"text\"],[[50,\"paper-toast-text\",0,null,null]]]]]],[1,\"\\n\"]],[]]]]]],[]],\"%cursor:0%\",[33,1],null]],[\"&default\"],false,[\"in-element\",\"destinationEl\",\"paper-toast-inner\",\"swipeAction\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-toast.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});