define("ember-route-action-helper/helpers/route-action", ["exports", "ember-route-action-helper/-private/internals"], function (_exports, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function getCurrentInfos(router) {
    var routerLib = router._routerMicrolib || router.router;
    return {
      currentInfos: routerLib.currentRouteInfos || routerLib.currentHandlerInfos,
      mapBy: routerLib.currentRouteInfos && 'route' || 'handler'
    };
  }

  function getRoutes(router) {
    var _getCurrentInfos = getCurrentInfos(router),
        currentInfos = _getCurrentInfos.currentInfos,
        mapBy = _getCurrentInfos.mapBy;

    return Ember.A(currentInfos).mapBy(mapBy).reverse();
  }

  function getRouteWithAction(router, actionName) {
    var action;
    var handler = Ember.A(getRoutes(router)).find(function (route) {
      var actions = route.actions || route._actions;
      action = actions[actionName];
      return typeof action === 'function';
    });
    return {
      action: action,
      handler: handler
    };
  }

  var _default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          actionName = _ref2[0],
          params = _ref2.slice(1);

      var router = Ember.get(this, 'router');
      (false && !(router) && Ember.assert('[ember-route-action-helper] Unable to lookup router', router));
      Ember.runInDebug(function () {
        var _getRouteWithAction = getRouteWithAction(router, actionName),
            handler = _getRouteWithAction.handler;

        (false && !(handler) && Ember.assert("[ember-route-action-helper] Unable to find action ".concat(actionName), handler));
      });

      var routeAction = function routeAction() {
        var _Ember$run;

        var _getRouteWithAction2 = getRouteWithAction(router, actionName),
            action = _getRouteWithAction2.action,
            handler = _getRouteWithAction2.handler;

        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        var args = params.concat(invocationArgs);
        return (_Ember$run = Ember.run).join.apply(_Ember$run, [handler, action].concat(_toConsumableArray(args)));
      };

      routeAction[_internals.ACTION] = true;
      return routeAction;
    }
  });

  _exports.default = _default;
});