define("ember-cable/core/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Subscription = Ember.Object.extend({
    subscriptions: null,
    params: null,
    identifier: Ember.computed('params', function () {
      return JSON.stringify(Ember.get(this, 'params'));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'subscriptions').add(this);
    },
    perform: function perform(action) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      data.action = action;
      this.send(data);
    },
    send: function send(data) {
      Ember.get(this, 'subscriptions.consumer').send({
        command: 'message',
        identifier: Ember.get(this, 'identifier'),
        data: JSON.stringify(data)
      });
    },
    unsubscribe: function unsubscribe() {
      return Ember.get(this, 'subscriptions').remove(this);
    }
  });

  Subscription.toString = function () {
    return 'Subscription';
  };

  var _default = Subscription;
  _exports.default = _default;
});