define("ember-svg-jar/inlined/attention", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5281\" transform=\"translate(-984 -200)\"><circle data-name=\"Ellipse 62\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\" transform=\"translate(984 200)\" fill=\"#ffcb1d\"/><g transform=\"translate(979.501 198.513)\" fill=\"#fff\"><path data-name=\"Trac&#xE9; 3122\" d=\"M12.281 4.449c-.552 0-1 .413-1 .741v4.759a1.043 1.043 0 002 0V5.192c0-.328-.448-.743-1-.743z\"/><rect data-name=\"Rectangle 2322\" width=\"2\" height=\"2\" rx=\"1\" transform=\"translate(11.281 11)\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});