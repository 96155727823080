define("ember-paper/components/paper-select/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ANQDmOwE",
    "block": "[[[11,\"md-option\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-text\"],[12],[18,2,null],[13],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"paper-ripple\"]]",
    "moduleName": "ember-paper/components/paper-select/option/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});