define("ember-spreadsheet-export/utils/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = optionize;

  function optionize(options, defaultConfig) {
    if (Object.prototype.toString.call(options) === '[object Object]') {
      options = options || {};
    } else {
      options = {};
    }

    for (var property in defaultConfig) {
      options[property] = typeof options[property] !== 'undefined' ? options[property] : defaultConfig[property];
    }

    return options;
  }
});