define("perf-primitives/-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UNDEFINED_KEY = _exports.LARGE_ARRAY_LENGTH = _exports.SMALL_ARRAY_LENGTH = void 0;
  var SMALL_ARRAY_LENGTH = 200;
  _exports.SMALL_ARRAY_LENGTH = SMALL_ARRAY_LENGTH;
  var LARGE_ARRAY_LENGTH = 64000;
  _exports.LARGE_ARRAY_LENGTH = LARGE_ARRAY_LENGTH;
  var UNDEFINED_KEY = Object.create(null);
  _exports.UNDEFINED_KEY = UNDEFINED_KEY;
});