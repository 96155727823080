define("ember-svg-jar/inlined/picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Shape</title><path d=\"M38 24.9V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v24.2c0 .1.05.15.05.25v.05c.05.05.05.1.1.15 0 0 .05 0 .05.05.05.05.1.1.2.15h.05c.05.05.1.05.2.1H.7c.1 0 .15.05.25.05h36c.55 0 1-.45 1-1 .05-.05.05-.05.05-.1zM3 24L14 9.65l9.2 12.05 1.75 2.3H3zm24.5 0l-2.25-2.95 3.75-4.9L34.95 24H27.5zm8.5-1.95l-6.2-8.15c-.2-.25-.5-.4-.8-.4-.3 0-.6.15-.8.4L24 19.45 14.8 7.4c-.2-.25-.5-.4-.8-.4-.3 0-.6.15-.8.4L2 22.05V2h34v20.05zM24.5 5C22.55 5 21 6.55 21 8.5s1.55 3.5 3.5 3.5S28 10.45 28 8.5 26.4 5 24.5 5zm0 5c-.8 0-1.5-.7-1.5-1.5 0-.85.65-1.5 1.5-1.5s1.5.65 1.5 1.5c0 .8-.7 1.5-1.5 1.5z\" fill=\"#707070\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "38",
      "height": "26",
      "viewBox": "0 0 38 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});