define("ember-svg-jar/inlined/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.763 12.714a.485.485 0 00-.148.727 7.004 7.004 0 006.945 2.389c3.77-.857 6.088-4.659 5.28-8.407-.809-3.748-4.58-6.104-8.351-5.247a6.938 6.938 0 00-5.476 6.552l-1.097-1.09a.523.523 0 00-.754 0 .515.515 0 000 .75l2.1 2.088a.49.49 0 00.378.16c.108 0 .27-.053.377-.16l2.101-2.142a.515.515 0 000-.75.523.523 0 00-.754 0l-1.365 1.4A6.002 6.002 0 018.704 3.14c3.233-.75 6.411 1.285 7.166 4.498.754 3.212-1.293 6.425-4.526 7.121-2.272.497-4.506-.302-5.893-1.914a.55.55 0 00-.688-.13z\" fill=\"#9EA1A3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});