define("ember-paper/templates/components/paper-progress-linear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1NTK15Pv",
    "block": "[[[10,0],[15,0,[29,[\"md-container \",[36,0]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-dashed\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"md-bar md-bar1\"],[15,5,[36,1]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"md-bar md-bar2\"],[15,5,[36,2]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"queryModeClass\",\"bar1Style\",\"bar2Style\"]]",
    "moduleName": "ember-paper/templates/components/paper-progress-linear.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});