define("ember-svg-jar/inlined/play-solid-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.164 3.318L3.258.403A2.018 2.018 0 001.122.225 2.088 2.088 0 000 2.082v5.833a2.086 2.086 0 001.121 1.86 2.016 2.016 0 002.137-.184l3.906-2.915a2.106 2.106 0 000-3.36z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "10",
      "viewBox": "0 0 8 10"
    }
  };
  _exports.default = _default;
});