define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0)\"><path d=\"M4.501 0a.5.5 0 00-.5.5v6.793L2.734 6.025a.5.5 0 00-.708.707l2.122 2.121a.5.5 0 00.707 0l2.121-2.12a.5.5 0 00-.707-.708L5.001 7.293V.5a.5.5 0 00-.5-.5z\" fill=\"#9EA2A3\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h9v9H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});