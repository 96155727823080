define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E06C72\" fill-rule=\"evenodd\" d=\"M7 14A7 7 0 117 0a7 7 0 010 14zm3-9.25L9.25 4 7 6.25 4.75 4 4 4.75 6.25 7 4 9.25l.75.75L7 7.75 9.25 10l.75-.75L7.75 7 10 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});