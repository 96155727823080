define("ember-paper/templates/components/paper-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xE/zhIkr",
    "block": "[[[41,[33,1],[[[40,[[[1,\"    \"],[10,0],[14,0,\"md-panel-outer-wrapper md-panel-is-showing\"],[15,5,[36,4]],[12],[1,\"\\n\"],[6,[39,5],null,[[\"class\",\"position\",\"anchorElement\",\"hide\"],[[33,6],[33,7],[33,8],[33,9]]],[[\"default\"],[[[[1,\"        \"],[18,1,null],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[33,3],null]],[]],null]],[\"&default\"],false,[\"if\",\"renderTooltip\",\"in-element\",\"destinationEl\",\"containerStyle\",\"paper-tooltip-inner\",\"class\",\"position\",\"anchorElement\",\"hideTooltip\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-tooltip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});