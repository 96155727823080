define("ember-svg-jar/inlined/checked-big-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.728.293a1 1 0 00-1.414 0L4.536 8.07 1.707 5.243A1 1 0 00.293 6.657l3.535 3.535a1 1 0 001.425-.01l8.475-8.475a1 1 0 000-1.414z\" fill=\"#33C46C\"/>",
    "attrs": {
      "width": "15",
      "height": "11",
      "viewBox": "0 0 15 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});