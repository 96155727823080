define("ember-svg-jar/inlined/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 3247\" d=\"M17.124 6.879l-2.91-2.909a.75.75 0 10-1.061 1.06l2.91 2.91a1.543 1.543 0 01.225.292c-.011 0-.02-.006-.032-.006L4.492 8.25a.75.75 0 100 1.5l11.76-.024c.021 0 .038-.01.059-.012a1.5 1.5 0 01-.251.347l-2.907 2.909a.75.75 0 101.061 1.061l2.91-2.909a3 3 0 000-4.242z\" fill=\"#9ea2a3\"/><path data-name=\"Trac&#xE9; 3248\" d=\"M5.251 16.5h-1.5A2.25 2.25 0 011.5 14.25V3.75A2.25 2.25 0 013.751 1.5h1.5a.75.75 0 100-1.5h-1.5A3.755 3.755 0 000 3.75v10.5A3.755 3.755 0 003.751 18h1.5a.75.75 0 100-1.5z\" fill=\"#9ea2a3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});