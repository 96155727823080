define("ember-paper/templates/components/paper-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DxVsk1YD",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"-paper-underscore\",\"iconClass\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});