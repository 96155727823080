define("ember-paper/components/paper-ripple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N8c0r2be",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_parentFinder\"]]],null]],[1,\"\\n\\n\"],[11,0],[24,0,\"md-ripple-container\"],[4,[38,1],[[30,0,[\"setupContainer\"]]],null],[12],[13]],[],false,[\"unbound\",\"did-insert\"]]",
    "moduleName": "ember-paper/components/paper-ripple/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});