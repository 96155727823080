define("ember-useragent/services/user-agent", ["exports", "ua-parser-js"], function (_exports, _uaParserJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),
    isFastBoot: Ember.computed.readOnly('fastboot.isFastBoot'),
    userAgent: Ember.computed(function () {
      if (Ember.get(this, 'isFastBoot')) {
        var headers = Ember.get(this, 'fastboot.request.headers');
        var userAgent = headers.get('user-agent');
        (false && !(userAgent) && Ember.assert('No userAgent present in ember-useragent/services/user-agent (FastBoot)', userAgent));
        return userAgent;
      } else {
        if (window && window.navigator) {
          var _userAgent = window.navigator.userAgent;
          (false && !(_userAgent) && Ember.assert('No userAgent present in ember-useragent/services/user-agent (Browser)', _userAgent));
          return _userAgent;
        }
      }
    }),
    UAParser: Ember.computed('userAgent', function () {
      var userAgent = Ember.get(this, 'userAgent');

      if (Ember.get(this, 'isFastBoot')) {
        var _UAParser = FastBoot.require('ua-parser-js');

        return new _UAParser(userAgent);
      }

      return new _uaParserJs.default(userAgent);
    }),
    setupService: Ember.observer('UAParser', function () {
      var parser = Ember.get(this, 'UAParser');
      var browser = parser.getBrowser();
      var device = parser.getDevice();
      var engine = parser.getEngine();
      var os = parser.getOS();
      Ember.setProperties(this, Ember.assign({
        browser: {
          info: browser,
          isChrome: Ember.isEqual(browser.name, 'Chrome'),
          isChromeHeadless: Ember.isEqual(browser.name, 'Chrome Headless'),
          isEdge: Ember.isEqual(browser.name, 'Edge'),
          isFirefox: Ember.isEqual(browser.name, 'Firefox'),
          isIE: Ember.isEqual(browser.name, 'IE') || Ember.isEqual(browser.name, 'IE Mobile'),
          isSafari: Ember.isEqual(browser.name, 'Safari') || Ember.isEqual(browser.name, 'Mobile Safari')
        },
        device: {
          info: device,
          isConsole: Ember.isEqual(device.type, 'console'),
          isDesktop: !device.type,
          isMobile: Ember.isEqual(device.type, 'mobile'),
          isTablet: Ember.isEqual(device.type, 'tablet')
        },
        engine: {
          info: engine,
          isWebkit: Ember.isEqual(engine.name, 'WebKit')
        },
        os: {
          info: os,
          isAndroid: Ember.isEqual(os.name, 'Android'),
          isIOS: Ember.isEqual(os.name, 'iOS'),
          isLinux: ['CentOS', 'Fedora', 'Linpus', 'Linux', 'MeeGo', 'PCLinuxOS', 'RedHat', 'SUSE', 'Ubuntu', 'VectorLinux'].indexOf(os.name) > -1,
          isMacOS: Ember.isEqual(os.name, 'Mac OS'),
          isWindows: ['Windows', 'Windows Phone', 'Windows Mobile'].indexOf(os.name) > -1
        }
      }, parser));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setupService();
    }
  });

  _exports.default = _default;
});