define("ember-collapsible-panel/components/cp-panel-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3etQLrrX",
    "block": "[[[41,[33,1],[[[1,\"\\n\"],[6,[39,2],[[33,3]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"cp-Panel-body-inner\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[10,0],[14,0,\"cp-Panel-body-inner\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"shouldAnimate\",\"liquid-if\",\"isOpen\",\"yield\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panel-body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});