define("ember-changeset/utils/computed/object-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectEqual;
  var keys = Object.keys;
  /**
   * Shallow object comparison computed property. Checks all key/value pairs on
   * the first object and compares against the second object. Essentially, this
   * means that the second object must have the same key/values as the first, but
   * not vice versa.
   *
   * @public
   * @param  {String} sourceKey dependent key for first object
   * @param  {String} compareKey dependent key for second object
   * @return {Boolean}
   */

  function objectEqual(sourceKey
  /*: string */
  , compareKey
  /*: string */
  )
  /*: boolean */
  {
    return Ember.computed(sourceKey, compareKey, function () {
      var source = Ember.get(this, sourceKey);
      var compare = Ember.get(this, compareKey);
      return keys(source).reduce(function (acc, key) {
        return acc && Ember.get(source, key) === Ember.get(compare, key);
      }, true);
    }).readOnly();
  }
});