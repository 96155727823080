define("ember-promise-helpers/helpers/is-pending", ["exports", "ember-promise-helpers/helpers/await"], function (_exports, _await) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _await.default.extend({
    valueBeforeSettled: true,
    compute: function compute(params) {
      var _this = this;

      var maybePromise = params[0];
      return this.ensureLatestPromise(maybePromise, function (promise) {
        promise.catch(function () {}).finally(function () {
          _this.setValue(false, maybePromise);
        });
      });
    }
  });

  _exports.default = _default;
});