define("ember-changeset-cp-validations/index", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildChangeset = buildChangeset;
  _exports.default = createChangeset;

  function buildChangeset(model) {
    (false && !(Ember.typeOf(model.get('validations')) === 'instance') && Ember.assert('Object does not contain any validations', Ember.typeOf(model.get('validations')) === 'instance'));
    return {
      validationMap: model.get('validations.validatableAttributes').reduce(function (o, attr) {
        o[attr] = true;
        return o;
      }, {}),
      validateFn: function validateFn(_ref) {
        var key = _ref.key,
            newValue = _ref.newValue;
        return model.validateAttribute(key, newValue).then(function (_ref2) {
          var validations = _ref2.validations;
          return validations.get('isValid') ? true : validations.get('message');
        });
      }
    };
  }

  function createChangeset(model, fn) {
    var _buildChangeset = buildChangeset(model),
        validateFn = _buildChangeset.validateFn,
        validationMap = _buildChangeset.validationMap;

    var _fn;

    if (fn && typeof fn === 'function') {
      _fn = function _fn() {
        return fn.apply(void 0, Array.prototype.slice.call(arguments).concat([validateFn]));
      };
    }

    return new _emberChangeset.default(model, _fn || validateFn, validationMap);
  }
});