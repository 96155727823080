define("ember-paper/components/paper-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8cBUCR9L",
    "block": "[[[11,\"md-content\"],[16,0,[29,[\"md-default-theme \",[52,[30,1],\"md-padding\"]]]],[16,\"md-scroll-y\",[30,2]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@padding\",\"@scroll-y\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});