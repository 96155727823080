define("ember-collapsible-panel/components/cp-panels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1POcLLNI",
    "block": "[[[18,1,[[28,[37,1],null,[[\"panel\",\"name\"],[[50,\"cp-panel\",0,null,[[\"group\"],[[30,0]]]],[33,3]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"name\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panels/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});