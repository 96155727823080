define("ember-paper/templates/components/paper-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wOh+doVG",
    "block": "[[[43,[28,[37,1],null,[[\"checkbox\",\"button\",\"switch\",\"radio\"],[[50,\"paper-checkbox\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]],[50,\"paper-button\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\",\"skipProxy\"],[[30,0],false,true,true]]],[50,\"paper-switch\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]],[50,\"paper-radio-proxiable\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]]]]],[[[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"md-button md-no-style\"],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"class\",\"onClick\",\"href\",\"target\",\"onMouseEnter\",\"onMouseLeave\"],[\"md-no-style\",[33,6],[33,7],[33,8],[28,[37,9],[[30,0],[30,0,[\"handleMouseEnter\"]]],null],[28,[37,9],[[30,0],[30,0,[\"handleMouseLeave\"]]],null]]]]],[1,\"\\n      \"],[10,0],[14,0,\"md-list-item-inner\"],[12],[1,\"\\n        \"],[18,2,[[30,1]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,11],null,[[\"@noink\",\"@dimBackground\"],[[30,0,[\"noink\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"md-no-style md-list-item-inner\"],[12],[1,\"\\n      \"],[18,2,[[30,1]]],[1,\"\\n      \"],[8,[39,11],null,[[\"@noink\",\"@dimBackground\"],[[30,0,[\"noink\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[1]],null]],[\"controls\",\"&default\"],false,[\"with\",\"hash\",\"component\",\"if\",\"hasPrimaryAction\",\"paper-button\",\"onClick\",\"href\",\"target\",\"action\",\"yield\",\"paper-ripple\"]]",
    "moduleName": "ember-paper/templates/components/paper-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});