define("ember-svg-jar/inlined/filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(0 -3)\" fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2692\" d=\"M7 6h16a1 1 0 000-2H7a1 1 0 000 2z\"/><path data-name=\"Trac&#xE9; 2693\" d=\"M23 11.5H7a1 1 0 000 2h16a1 1 0 000-2z\"/><path data-name=\"Trac&#xE9; 2694\" d=\"M23 19H7a1 1 0 000 2h16a1 1 0 000-2z\"/><circle data-name=\"Ellipse 34\" cx=\"2\" cy=\"2\" r=\"2\" transform=\"translate(0 3)\"/><circle data-name=\"Ellipse 35\" cx=\"2\" cy=\"2\" r=\"2\" transform=\"translate(0 11)\"/><circle data-name=\"Ellipse 36\" cx=\"2\" cy=\"2\" r=\"2\" transform=\"translate(0 18)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 24 19"
    }
  };
  _exports.default = _default;
});