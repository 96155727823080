define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 5H7V1a1 1 0 00-1-1 1 1 0 00-1 1v4H1a1 1 0 00-1 1 1 1 0 001 1h4v4a1 1 0 001 1 1 1 0 001-1V7h4a1 1 0 001-1 1 1 0 00-1-1z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
  _exports.default = _default;
});