define("ember-svg-jar/inlined/illustration-empty-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M64 128c35.346 0 64-28.654 64-64 0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64zM52 69a5 5 0 11-10 0 5 5 0 0110 0zm3.34 18.588a1 1 0 01.708-1.224l17.387-4.66a1 1 0 01.517 1.933l-17.386 4.658a1 1 0 01-1.225-.707zM83 74a4 4 0 100-8 4 4 0 000 8z\" transform=\"translate(18 2)\" fill=\"url(#paint0_linear)\"/><path d=\"M17.519 46.351C26.56 19.41 52.014 0 82 0c29.987 0 55.44 19.41 64.481 46.351A9.252 9.252 0 01149 46c8.284 0 15 11.193 15 25s-6.716 25-15 25c-8.284 0-15-11.193-15-25 0-10.168 3.642-18.918 8.87-22.824C134.525 22.533 110.427 4 82 4S29.475 22.533 21.13 48.176C26.358 52.082 30 60.832 30 71c0 13.807-6.716 25-15 25C6.716 96 0 84.807 0 71s6.716-25 15-25c.858 0 1.7.12 2.519.351z\" fill=\"#363940\"/><path d=\"M156 93.116c4.758-4.191 8-12.523 8-22.116 0-9.594-3.242-17.925-8-22.116-4.758 4.191-8 12.522-8 22.116s3.242 17.925 8 22.116zM8 48.883c4.758 4.192 8 12.523 8 22.117 0 9.594-3.242 17.925-8 22.116C3.242 88.925 0 80.593 0 71c0-9.594 3.242-17.925 8-22.116z\" fill=\"#5F6570\"/><defs><linearGradient id=\"paint0_linear\" x2=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"matrix(0 128 -128 0 128 0)\"><stop stop-color=\"#ED9493\"/><stop offset=\"1\" stop-color=\"#E14C65\"/></linearGradient></defs>",
    "attrs": {
      "width": "164",
      "height": "130",
      "viewBox": "0 0 164 130",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});