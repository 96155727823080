define("ember-cable/services/cable", ["exports", "ember-cable/core/consumer"], function (_exports, _consumer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super();

      this._consumers = [];
    },
    createConsumer: function createConsumer(url) {
      var consumer = _consumer.default.create(Ember.getOwner(this).ownerInjection(), {
        url: url
      });

      this._consumers.push(consumer);

      return consumer;
    },
    willDestroy: function willDestroy() {
      this._super();

      this._consumers.forEach(function (consumer) {
        return consumer.destroy();
      });
    }
  });

  _exports.default = _default;
});