define("ember-cli-active-link-wrapper/mixins/active-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // these are not currently editable in Ember
  var transitioningInClass = 'ember-transitioning-in';
  var transitioningOutClass = 'ember-transitioning-out';

  var _default = Ember.Mixin.create({
    classNameBindings: ['_active', '_disabled', '_transitioningIn', '_transitioningOut'],
    linkSelector: 'a.ember-view',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('childLinkViews', Ember.A([]));
    },
    buildChildLinkViews: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var childLinkSelector = this.get('linkSelector');
        var childLinkElements = this.element.querySelectorAll(childLinkSelector);
        var viewRegistry = Ember.getOwner(this).lookup('-view-registry:main');
        var childLinkViews = Array.prototype.map.call(childLinkElements, function (view) {
          return viewRegistry[view.id];
        });
        this.set('childLinkViews', Ember.A(childLinkViews));
      });
    }),
    _transitioningIn: Ember.computed('childLinkViews.@each.transitioningIn', function () {
      if (this.get('childLinkViews').isAny('transitioningIn')) {
        return transitioningInClass;
      }
    }),
    _transitioningOut: Ember.computed('childLinkViews.@each.transitioningOut', function () {
      if (this.get('childLinkViews').isAny('transitioningOut')) {
        return transitioningOutClass;
      }
    }),
    hasActiveLinks: Ember.computed('childLinkViews.@each.active', function () {
      return this.get('childLinkViews').isAny('active');
    }),
    activeClass: Ember.computed('childLinkViews.@each.active', function () {
      var activeLink = this.get('childLinkViews').findBy('active');
      return activeLink ? activeLink.get('active') : 'active';
    }),
    _active: Ember.computed('hasActiveLinks', 'activeClass', function () {
      return this.get('hasActiveLinks') ? this.get('activeClass') : false;
    }),
    allLinksDisabled: Ember.computed('childLinkViews.@each.disabled', function () {
      return !Ember.isEmpty(this.get('childLinkViews')) && this.get('childLinkViews').isEvery('disabled');
    }),
    disabledClass: Ember.computed('childLinkViews.@each.disabled', function () {
      var disabledLink = this.get('childLinkViews').findBy('disabled');
      return disabledLink ? disabledLink.get('disabled') : 'disabled';
    }),
    _disabled: Ember.computed('allLinksDisabled', 'disabledClass', function () {
      return this.get('allLinksDisabled') ? this.get('disabledClass') : false;
    })
  });

  _exports.default = _default;
});