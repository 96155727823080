define("ember-paper/templates/components/paper-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bUzJi/mk",
    "block": "[[[40,[[[1,\"  \"],[1,[28,[35,2],null,[[\"locked-open\",\"opaque\",\"fixed\",\"class\",\"onClick\"],[[33,3],[33,4],[52,[51,[33,6]],true],\"md-dialog-backdrop\",[28,[37,7],[[30,0],\"outsideClicked\"],null]]]]],[1,\"\\n\"],[6,[39,8],null,[[\"class\",\"outsideClicked\"],[[28,[37,9],[[33,10]],null],[28,[37,7],[[30,0],\"outsideClicked\"],null]]],[[\"default\"],[[[[6,[39,11],null,[[\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\"],[[28,[37,9],[[33,12]],null],[33,13],[33,14],[33,15],[33,16],[33,17],[33,18]]],[[\"default\"],[[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[]]]]]],[]],\"%cursor:0%\",[33,1],null]],[\"&default\"],false,[\"in-element\",\"destinationEl\",\"paper-backdrop\",\"isLockedOpen\",\"opaque\",\"unless\",\"parent\",\"action\",\"paper-dialog-container\",\"readonly\",\"dialogContainerClass\",\"paper-dialog-inner\",\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});