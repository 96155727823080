define("ember-svg-jar/inlined/chevron-big-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.677 3.343a1 1 0 00-1.414 0L5.315 8.29l-.002.003a1 1 0 00.003 1.417l4.947 4.947a1 1 0 001.414-1.415L7.434 9l4.243-4.243a1 1 0 000-1.414z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});