define("ember-svg-jar/inlined/illustration-empty-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M64 128c35.346 0 64-28.654 64-64 0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64zM45 70c.552 0 1.01-.453.891-.992C45.261 66.166 41.968 64 38 64c-3.967 0-7.26 2.166-7.891 5.008-.12.539.339.992.891.992s.986-.461 1.18-.978C32.836 67.285 35.193 66 38 66c2.808 0 5.165 1.285 5.82 3.022.194.517.628.978 1.18.978zm28.95 8.999c.055-.55-.398-.999-.95-.999H55c-.552 0-1.005.449-.95.999C54.55 84.052 58.813 88 64 88c5.186 0 9.45-3.948 9.95-9.001zm23.941-9.991c.12.539-.339.992-.891.992s-.986-.461-1.18-.978C95.164 67.285 92.807 66 90 66c-2.808 0-5.165 1.285-5.82 3.022-.194.517-.628.978-1.18.978-.552 0-1.01-.453-.891-.992C82.739 66.166 86.033 64 90 64c3.968 0 7.26 2.166 7.891 5.008z\" transform=\"translate(18 2)\" fill=\"url(#paint0_linear)\"/><path d=\"M17.519 46.351C26.56 19.41 52.014 0 82 0c29.987 0 55.44 19.41 64.481 46.351A9.252 9.252 0 01149 46c8.284 0 15 11.193 15 25s-6.716 25-15 25c-8.284 0-15-11.193-15-25 0-10.168 3.642-18.918 8.87-22.824C134.525 22.533 110.427 4 82 4S29.475 22.533 21.13 48.176C26.358 52.082 30 60.832 30 71c0 13.807-6.716 25-15 25C6.716 96 0 84.807 0 71s6.716-25 15-25c.858 0 1.7.12 2.519.351z\" fill=\"#363940\"/><path d=\"M156 93.116c4.758-4.191 8-12.523 8-22.116 0-9.594-3.242-17.925-8-22.116-4.758 4.191-8 12.522-8 22.116s3.242 17.925 8 22.116zM8 48.883c4.758 4.192 8 12.523 8 22.117 0 9.594-3.242 17.925-8 22.116C3.242 88.925 0 80.593 0 71c0-9.594 3.242-17.925 8-22.116z\" fill=\"#5F6570\"/><defs><linearGradient id=\"paint0_linear\" x2=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"matrix(0 128 -128 0 128 0)\"><stop stop-color=\"#8CB1E8\"/><stop offset=\"1\" stop-color=\"#56A1F3\"/></linearGradient></defs>",
    "attrs": {
      "width": "164",
      "height": "130",
      "viewBox": "0 0 164 130",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});