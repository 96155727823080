define("ember-cli-swiper/templates/components/swiper-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eTnMWCtx",
    "block": "[[[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"slide\"],[[50,\"swiper-slide\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"  \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,5],[[[1,\"  \"],[10,0],[15,0,[29,[[36,6]]]],[12],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[36,7]]]],[12],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\",\"hasPagination\",\"hasNavigation\",\"nextElClass\",\"prevElClass\"]]",
    "moduleName": "ember-cli-swiper/templates/components/swiper-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});