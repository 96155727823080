define("ember-svg-jar/inlined/hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.204 9.184a17.277 17.277 0 00-3.651-4.266l3.032-3.055a1.1 1.1 0 000-1.543 1.077 1.077 0 00-1.531 0l-3.3 3.327a12.978 12.978 0 00-6.75-1.844c-6.709 0-10.529 4.624-12.21 7.381a5.39 5.39 0 000 5.633 17.277 17.277 0 003.65 4.265l-3.032 3.055a1.1 1.1 0 000 1.543 1.077 1.077 0 001.531 0l3.3-3.33a12.978 12.978 0 006.761 1.847c6.7 0 10.525-4.625 12.206-7.381a5.39 5.39 0 00-.006-5.632zm-22.565 4.49a3.2 3.2 0 010-3.348c1.445-2.363 4.71-6.341 10.365-6.341a10.871 10.871 0 015.161 1.271l-2.18 2.2a5.375 5.375 0 00-6.813.686 5.479 5.479 0 00-.681 6.865l-2.5 2.521a14.941 14.941 0 01-3.346-3.851zm13.609-1.677a3.261 3.261 0 01-3.244 3.277 3.176 3.176 0 01-1.392-.327l4.311-4.35a3.24 3.24 0 01.325 1.4zm-6.5 0a3.261 3.261 0 013.256-3.271 3.176 3.176 0 011.392.327l-4.315 4.348a3.24 3.24 0 01-.33-1.404zm13.609 1.674c-1.445 2.364-4.71 6.341-10.36 6.341a10.871 10.871 0 01-5.161-1.271l2.18-2.2a5.375 5.375 0 006.813-.686 5.479 5.479 0 00.683-6.858l2.5-2.521a14.941 14.941 0 013.346 3.851 3.2 3.2 0 010 3.348z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "24",
      "viewBox": "0 0 26 24"
    }
  };
  _exports.default = _default;
});