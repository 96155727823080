define("ember-truncate/templates/components/button-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwJoGI+c",
    "block": "[[[6,[39,0],null,[[\"destinationElement\",\"renderInPlace\"],[[33,1],[33,2]]],[[\"default\"],[[[[1,\"  \"],[10,\"button\"],[15,0,[29,[\"truncate-multiline--button \",[36,3]]]],[15,\"onclick\",[28,[37,4],[[30,0],[33,5]],null]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"ember-wormhole\",\"_destination\",\"_inPlace\",\"classNames\",\"action\",\"DOMClick\",\"yield\"]]",
    "moduleName": "ember-truncate/templates/components/button-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});