define("ember-svg-jar/inlined/minimize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 9a1 1 0 100-2h-4V3a1 1 0 10-2 0v5a1 1 0 001 1h5zM7 11H3a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-4z\" fill=\"#9EA1A3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});