define("ember-svg-jar/inlined/check-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Rectangle 11</title><path d=\"M2.08 4.402l4.243 4.242L4.91 10.06.666 5.816l1.415-1.414zm14.143-2.829l-9.9 9.9-1.414-1.414 9.9-9.9 1.414 1.414z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "17",
      "height": "12",
      "viewBox": "0 0 17 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});