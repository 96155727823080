define("ember-svg-jar/inlined/reportings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.5 4.5a.5.5 0 000 1h3a.5.5 0 000-1h-3zM12 6.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5.2.5.5.5c.85 0 1.5.65 1.5 1.5 0 .65-.4 1.2-1 1.4v.6h-1v-.6c-.6-.2-1-.75-1-1.4h1c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5c-.85 0-1.5-.65-1.5-1.5 0-.65.4-1.2 1-1.4v-.6h1v.6c.6.2 1 .75 1 1.4h-1zM5 9a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3A.5.5 0 015 9zM5.5 6.5a.5.5 0 000 1h3a.5.5 0 000-1h-3zM5 11a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4A.5.5 0 015 11zM5.5 12.5a.5.5 0 000 1h7a.5.5 0 000-1h-7z\" fill=\"#9D9FA3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 2H3v14h12V2zM3 1a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V2a1 1 0 00-1-1H3z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});