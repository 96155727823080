define("ember-svg-jar/inlined/button-arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined Shape</title><path d=\"M3 11H0l4 5 4-5H5V1.002a.999.999 0 10-2 0V11z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "8",
      "height": "16",
      "viewBox": "0 0 8 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});