define("ember-svg-jar/inlined/csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>xls</title><path d=\"M0 63.002v-62a1 1 0 011-1h32.768a.993.993 0 01.126 0h.194a1 1 0 01.707.292l11.912 11.893a1 1 0 01.293.708v50.107a1 1 0 01-1 1H1a1 1 0 01-1-1zm45-1V14.159H33.823a1 1 0 01-1-1V2.002H2v60h42.998zM34.823 3.416l8.742 8.743h-8.742V3.416zM12 25.993c0-1.1.89-1.991 1.991-1.991H32.01c1.1 0 1.991.89 1.991 1.991v18.018c0 1.1-.89 1.991-1.991 1.991H13.99A1.99 1.99 0 0112 44.011V25.993zm2 18.009h18v-18H14v18zm3-10h12v2H17v-2zm0 5h12v2H17v-2zm0-10h12v2H17v-2z\" fill=\"#70D598\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "47",
      "height": "64",
      "viewBox": "0 0 47 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});