define("ember-paper/templates/components/paper-optgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qCIcRuRX",
    "block": "[[[10,\"label\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"label\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-optgroup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});