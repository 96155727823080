define("ember-collapsible-panel/components/cp-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Anpz2TH9",
    "block": "[[[18,1,[[28,[37,1],null,[[\"toggle\",\"body\",\"name\",\"isOpen\"],[[50,\"cp-panel-toggle\",0,null,[[\"on-click\",\"isOpen\"],[[28,[37,3],[[30,0],\"toggleIsOpen\"],null],[33,4]]]],[50,\"cp-panel-body\",0,null,[[\"shouldAnimate\",\"isOpen\"],[[33,5],[33,4]]]],[33,6],[33,4]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\",\"isOpen\",\"shouldAnimate\",\"name\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panel/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});