define("ember-svg-jar/inlined/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2743\" d=\"M15 0H5C2.24 0 0 1.867 0 4.167v11.666C0 18.133 2.24 20 5 20h10c2.76 0 5-1.867 5-4.167V4.167C20 1.867 17.76 0 15 0zM5 1.667h10a2.784 2.784 0 013 2.5v5H2v-5a2.784 2.784 0 013-2.5zm10 16.666H5a2.784 2.784 0 01-3-2.5v-5h16v5a2.784 2.784 0 01-3 2.5z\"/><path data-name=\"Trac&#xE9; 2745\" d=\"M11.624 13.393H8.766a1.337 1.337 0 00-1.429 1.22 1.337 1.337 0 001.429 1.22h2.858a1.337 1.337 0 001.429-1.22 1.337 1.337 0 00-1.429-1.22z\"/><path data-name=\"Trac&#xE9; 2746\" d=\"M11.624 4.393H8.766a1.337 1.337 0 00-1.429 1.22 1.337 1.337 0 001.429 1.22h2.858a1.337 1.337 0 001.429-1.22 1.337 1.337 0 00-1.429-1.22z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});