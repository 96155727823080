define("ember-invoke-action/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.InvokeActionMixin = _exports.strictInvoke = _exports.invoke = _exports.strictInvokeAction = _exports.invokeAction = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var makeInvokeAction = function makeInvokeAction() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$strict = _ref.strict,
        strict = _ref$strict === void 0 ? false : _ref$strict;

    return function (object, actionName) {
      (false && !(_typeof(object) === 'object') && Ember.assert('The first argument passed to invokeAction must be an object', _typeof(object) === 'object'));
      var action;

      if (typeof actionName === 'string') {
        action = Ember.get(object, actionName);
      } else if (typeof actionName === 'function') {
        action = actionName;
      } else {
        (false && !(false) && Ember.assert('The second argument passed to invokeAction must be a string as actionName or a function', false));
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      if (typeof action === 'string') {
        object.sendAction.apply(object, [actionName].concat(args));
      } else if (typeof action === 'function') {
        return action.apply(void 0, args);
      } else if (strict) {
        (false && !(false) && Ember.assert("No invokable action ".concat(actionName, " was found"), false));
      }
    };
  };

  var getActions = function getActions(object) {
    return object.actions ? object.actions : object._actions;
  };

  var makeInvoke = function makeInvoke() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$strict = _ref2.strict,
        strict = _ref2$strict === void 0 ? false : _ref2$strict;

    return function (object, actionName) {
      var actions = getActions(object);
      var action = actions && actions[actionName];

      if (typeof action === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }

        return action.call.apply(action, [object].concat(args));
      } else if (strict) {
        (false && !(false) && Ember.assert("No invokable action ".concat(actionName, " was found"), false));
      }
    };
  };

  var _invokeAction = makeInvokeAction();

  _exports.invokeAction = _invokeAction;

  var _strictInvokeAction = makeInvokeAction({
    strict: true
  });

  _exports.strictInvokeAction = _strictInvokeAction;

  var _invoke = makeInvoke();

  _exports.invoke = _invoke;

  var _strictInvoke = makeInvoke({
    strict: true
  });

  _exports.strictInvoke = _strictInvoke;
  var InvokeActionMixin = Ember.Mixin.create({
    invokeAction: function invokeAction() {
      return _invokeAction.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    },
    strictInvokeAction: function strictInvokeAction() {
      return _strictInvokeAction.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    },
    invoke: function invoke() {
      return _invoke.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    },
    strictInvoke: function strictInvoke() {
      return _strictInvoke.apply(void 0, [this].concat(Array.prototype.slice.call(arguments)));
    }
  });
  _exports.InvokeActionMixin = InvokeActionMixin;
  var _default = _invokeAction;
  _exports.default = _default;
});