define("ember-singularity-mixins/mixins/resize-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var RESIZE = 'resize';

  var _default = Ember.Mixin.create((_Ember$Mixin$create = {
    unifiedEventHandler: Ember.inject.service('unified-event-handler')
  }, _defineProperty(_Ember$Mixin$create, RESIZE, undefined), _defineProperty(_Ember$Mixin$create, "resizeOnInsert", true), _defineProperty(_Ember$Mixin$create, "resizeEventInterval", undefined), _defineProperty(_Ember$Mixin$create, "registerResizeHandlers", Ember.on('didInsertElement', function () {
    // Bind 'this' context to the resize handler for when passed as a callback
    var resize = this.get(RESIZE).bind(this);
    this.set(RESIZE, resize);
    this.get('unifiedEventHandler').register('window', RESIZE, resize, this.get('resizeEventInterval'));
    this._resizeHandlerRegistered = true;

    if (this.get('resizeOnInsert')) {
      // Call the resize handler to make sure everything is in the correct state.
      // We do it after the current render, to avoid any side-effects.
      Ember.run.scheduleOnce('afterRender', this, function () {
        resize();
      });
    }
  })), _defineProperty(_Ember$Mixin$create, "unregisterResizeHandlers", Ember.on('willDestroyElement', function () {
    if (this._resizeHandlerRegistered) {
      var resize = this.get(RESIZE);
      this.get('unifiedEventHandler').unregister('window', RESIZE, resize);
      this._resizeHandlerRegistered = false;
    }
  })), _Ember$Mixin$create));

  _exports.default = _default;
});