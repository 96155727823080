define("ember-svg-jar/inlined/pen-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.114.887a3.033 3.033 0 00-4.286 0l-13.6 13.6A4.155 4.155 0 000 17.446v1.717a.836.836 0 00.836.836h1.717a4.153 4.153 0 002.957-1.224l13.6-13.6a3.033 3.033 0 000-4.285zM4.327 17.593a2.528 2.528 0 01-1.774.734h-.88v-.881a2.492 2.492 0 01.735-1.774L12.731 5.349l1.924 1.923zm13.6-13.6l-2.1 2.1-1.924-1.92 2.1-2.1a1.359 1.359 0 011.919 1.924z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});