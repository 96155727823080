define("ember-svg-jar/inlined/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.5 6h-1a.5.5 0 010-1h1a.5.5 0 010 1zM3 5.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM7.5 5a.5.5 0 000 1h1a.5.5 0 000-1h-1zM3 8a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1A.5.5 0 013 8zM3.5 10a.5.5 0 100 1h1a.5.5 0 100-1h-1zM12.5 8.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2a.5.5 0 100-1H13V9a.5.5 0 00-.5-.5z\" fill=\"#9EA1A3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 1.5a.5.5 0 011 0V2h2v-.5a.5.5 0 011 0V2h4a2 2 0 012 2v3.257A5.5 5.5 0 117.6 14H2a2 2 0 01-2-2V4a2 2 0 012-2h4v-.5zM7.207 13a5.503 5.503 0 01-.153-2.274.498.498 0 01.12-.606A5.47 5.47 0 017.89 8.5H7.5a.5.5 0 010-1h1c.066 0 .13.013.187.036A5.482 5.482 0 0112.5 6c.9 0 1.75.216 2.5.6V4a1 1 0 00-1-1h-4v.5a.5.5 0 01-1 0V3H7v.5a.5.5 0 01-1 0V3H2a1 1 0 00-1 1v8a1 1 0 001 1h5.207zm5.293 3a4.5 4.5 0 100-9 4.5 4.5 0 000 9z\" fill=\"#9EA1A3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});