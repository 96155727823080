define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "24bit": "24-Bit",
    "accounting": {
      "extracts": {
        "contract": {
          "label": "Contract",
          "title": "Choose a contract"
        },
        "failedRequest": "We were unable to fulfill your request, please try again.",
        "parameters": {
          "aggregated": {
            "end": "End",
            "period": "Period",
            "start": "Start",
            "type": "Aggregation Type"
          },
          "royalties": {
            "album": "Album",
            "albumHint": "If you choose a specific album, you will not receive the extract of all your albums",
            "salesPeriod": "Sales Period"
          },
          "scpp": {
            "year": "Year"
          },
          "title": "Define parameters"
        },
        "successfulRequest": "Extract was successfully requested, you will get it by email in a moment.",
        "title": "Extracts",
        "types": {
          "aggregated": {
            "company": "Contract (Default)",
            "details": "Concat sales reports in a single file.",
            "label": "Split by label",
            "name": "Aggregated reports",
            "product": "Split by album"
          },
          "royalties": {
            "details": "This report includes the sum of all generated revenues on each album from your catalog, for every closed semester.<br />This includes all revenues (revenues generated from an album as well as from its tracks, from download and streaming, on desktop and mobile, etc…). Each report is calculated from the various reports of one semester (not from actual transaction dates).<br />You will then find subtotals per country (as your royalties rates can differ from a country to another). For each album and/or country, you will find gross revenues as well as net revenues (your revenues after deduction of our commission).",
            "name": "\"Royalties\" extract"
          },
          "scpp": {
            "details": "Each of these report (one for tracks, one for albums) includes the total of digital sales per album reference according to the eligibility criteria determined by both sppf and scpp (France only transactions, download only, no streaming, no ringtones…).<br />They are computed from intra-year reports (not from actual transaction dates).",
            "name": "SCPP / SPPF extract"
          },
          "title": "Choose an extraction type"
        }
      },
      "reports": {
        "count": "{count, plural, =1 {# report} other {# reports}}",
        "placeholder": "Choose a contract",
        "salesCompanyResultModal": {
          "chooseFormat": "Choose a format",
          "title": "Download your sales"
        },
        "successfulRequest": "Your report was successfully requested, you will receive by email in a moment.",
        "title": "Reports",
        "tooManyRequest": "Too many reports was requested at the same time, please wait before requesting a new one."
      }
    },
    "activate": "Activate",
    "add": "Add",
    "addAll": "Add All",
    "addTagMessage": "Type at least 3 characters to search a tag",
    "all": "All",
    "analytics": {
      "column": {
        "amount": "Revenue"
      },
      "demographics": {
        "download": "Download as XLS",
        "subNav": {
          "cities": "Top Cities",
          "genders": "Gender & Age",
          "territories": "Top Countries"
        }
      },
      "filters": {
        "ageRange": "Release Lifecycle:",
        "ageRanges": {
          "backCatalogue": "Back Catalogue",
          "newRelease": "New",
          "recent": "Recent"
        },
        "exclude": "Albums, Tracks, Artists, Retailers, Countries...",
        "excludePlaceholder": "Exclude:",
        "paymentType": "Payment:",
        "productType": "Type :",
        "tag": "Filter by Albums, Tracks, Artists, Retailers, Countries...",
        "tooltip": {
          "addExclusion": "Add exclusions",
          "removeExclusion": "Remove exclusions"
        },
        "transactionType": "Usage:",
        "transactionTypes": {
          "contentproduction": "Content Production",
          "download": "Download",
          "fingerprint": "Fingerprint",
          "preorder": "Pre-order",
          "streaming": "Streaming",
          "title": "Type:"
        },
        "upc": "UPC:"
      },
      "fragments": {
        "ages": {
          "title": "Release Lifecycle"
        },
        "artists": {
          "title": "Artists"
        },
        "cities": {
          "title": "Cities"
        },
        "companies": {
          "title": "Contrats"
        },
        "dsps": {
          "title": "Platforms"
        },
        "genders": {
          "title": "Genders"
        },
        "labels": {
          "title": "Labels"
        },
        "marketShare": "Share (%)",
        "marketShareHint": "The 100% stacked area chart shows how the share of a set has evolved over time.",
        "origins": {
          "title": "Sources"
        },
        "playlists": {
          "title": "Playlists"
        },
        "productGenres": {
          "title": "Genres"
        },
        "products": {
          "title": "Albums"
        },
        "retails": {
          "title": "Platforms"
        },
        "seeAlbum": "See album",
        "table": {
          "columns": {
            "aLaCarte": "A la carte",
            "age": "Release Lifecycle",
            "artist": "Artist",
            "automated": "Automated",
            "city": "City",
            "combination": "Releases + Tracks",
            "contentProduction": "Content Production",
            "country": "Country",
            "curator": "Curator",
            "download": "Downloads",
            "editorial": "Editorial",
            "entrance": "Add Date",
            "followers": "Followers",
            "freemium": "Freemium",
            "hints": {
              "aLaCarte": "Single payment",
              "automated": "The Source has been automatically created by the platform or its algorithms. This includes artist or album pages that are automatically created by platforms upon product delivery as well as automated playlists and radio",
              "editorial": "The Source has been created by the editorial teams of the platform.",
              "none": "Cloud storage, upgrades, delivery fees...",
              "streamPerUsers": "Streams / Unique Users. Limited to streams with available user data",
              "uniqueUsers": "Based on {count}% of total streams (data from Spotify, Apple Music and Deezer)",
              "user": "The Source has been created by an end-user of a platform."
            },
            "name": "Name",
            "none": "None",
            "origin": "Sources",
            "premium": "Premium",
            "proportion": "Proportion",
            "rank": "#",
            "releases": "Releases",
            "retail": "Platforms",
            "revenue": "Revenue",
            "skipRate": "Skip Rate",
            "stream": "Streams",
            "streamPerUsers": "Streams Per User",
            "transactionType": "Transaction Type",
            "transactions": "Transactions",
            "trial": "Trial",
            "uniqueUsers": "Unique Users",
            "usage": "Usage",
            "user": "User",
            "volume": "Streams"
          },
          "rankings": {
            "revenueAlbums": "{revenue} from Bundles",
            "volumeAlbums": "incl. {volume, number} Bundles"
          }
        },
        "territories": {
          "quantityHint": "Shazam figures do not take territorial rights into account",
          "title": "Countries"
        },
        "tracks": {
          "title": "Tracks"
        },
        "trends": {
          "downloadUnitDetails": "If an album contains 10 tracks, sells 1 unit + 5 single tracks, it counts as 15 Tracks EQ.",
          "streamsByUsers": "Streams Per User",
          "subtitle": "{albumsQuantity, number} Bundles | {tracksQuantity, number} Single Tracks",
          "title": {
            "contentProduction": "Total Content Production",
            "copyScan": "Total Streamed Tracks",
            "download": "Total Tracks EQ",
            "stream": "Total Streamed Tracks"
          },
          "types": {
            "streams": "streams",
            "tracks": "tracks"
          },
          "uniqueUsers": "Unique Users"
        },
        "usages": {
          "title": "Revenue Types"
        },
        "users": {
          "header": {
            "hint": "Streams / Unique Users. Limited to streams where user data are available.",
            "subtitle": "Streams Per User: {count}",
            "title": "Total Unique Users"
          },
          "title": "Users"
        },
        "videos": {
          "title": "Videos"
        }
      },
      "nav": {
        "demographics": "Demographics",
        "index": "Overview",
        "map": "Demographics",
        "playlists": "Playlists & Stations",
        "rankings": "Rankings"
      },
      "nodata": "No data available",
      "overview": {
        "audience": {
          "title": "Audience"
        },
        "cities": {
          "subtitle": "Based on data from Spotify and Amazon",
          "title": "Top Cities"
        },
        "gender": {
          "female": "Female",
          "male": "Male",
          "subtitle": "Based on data from Spotify and Deezer",
          "title": "Gender & Age"
        },
        "lifecycle": {
          "column": {
            "lifecycle": "Release Lifecycle",
            "proportion": "Proportion",
            "volume": "Streams"
          },
          "title": "Release Lifecycle"
        },
        "nodata": "No data available",
        "origin": {
          "artist_or_album": "Artist or Album Page",
          "column": {
            "automated": "Automated",
            "editorial": "Editorial",
            "proportion": "Proportion",
            "source": "Source",
            "user": "User",
            "volume": "Streams"
          },
          "library": "Library",
          "playlist": "Playlist",
          "radio": "Radio",
          "search": "Search",
          "subtitle": "Based on data from Spotify, Apple Music, Amazon & Deezer",
          "title": "Source"
        },
        "retails": {
          "column": {
            "proportion": "Proportion",
            "retail": "Platforms",
            "transactionType": "Transaction Type",
            "volume": "Streams"
          }
        },
        "territories": {
          "subtitle": "Based on data from all platforms",
          "title": "Top Countries"
        },
        "units": {
          "downloads": "Downloads",
          "streams": "Streams"
        }
      },
      "playlists": {
        "column": {
          "artist": "Artist",
          "curator": "Curator",
          "entrance": "Add Date",
          "filter": "",
          "followers": "Followers",
          "name": "Name",
          "rank": "#",
          "second_metric": "Skip Rate",
          "streams": "Streams",
          "streamsSince": "streams since:"
        },
        "hint": "Based on playlists reported by Spotify, Apple Music, Deezer, Amazon Music and on Apple Music, Amazon and Pandora stations",
        "seePlaylist": "View playlist",
        "skipRateHint": "% of streams skipped before 90s",
        "title": "Total Streamed Tracks via Playlists and Stations",
        "tracks": "{count, plural, =1 {# track} other {# tracks}}"
      },
      "rankings": {
        "download": "Download as XLS",
        "seeSource": "View page ",
        "subNav": {
          "artists": "Top Artists",
          "companies": "Top Contracts",
          "dsps": "Top Platforms",
          "labels": "Top Labels",
          "productGenres": "Top Genres",
          "products": "Top Albums",
          "retails": "Top Retailers",
          "tracks": "Top Tracks",
          "videos": "Top Videos"
        }
      },
      "sales": {
        "aggregateTypes": {
          "financial": "Financial View",
          "hint": "Financial View: Date of monthly sales report to rights holders<br />Transaction View: Transaction date on streaming and download services",
          "transaction": "Transaction View"
        },
        "customCurrencyWarning": "Your sales value at today’s exchange rate.<br />Actual amount paid will vary according to exchange rate on date of payment.",
        "overview": {
          "pagesTitle": "Last 12 consolidated months result",
          "title": "Total Revenue",
          "unit": "Revenue"
        },
        "payments": {
          "aLaCarte": "A la carte",
          "freemium": "Freemium",
          "no": "None",
          "premium": "Premium",
          "trial": "Trial"
        },
        "ranking": {
          "revenueAlbums": "from Bundles",
          "volumeAlbums": "Bundles",
          "volumeAlbumsIncl": "incl."
        },
        "revenueTypes": {
          "commission": "Commission",
          "company": "Contract",
          "distributor": "Distributor",
          "title": "Revenue:"
        },
        "title": "Sales",
        "transactionTypes": {
          "contentProduction": "Content Production",
          "copyScan": "Fingerprint",
          "download": "Download",
          "nonInteractiveStream": "Radio",
          "onDemandStream": "Streaming"
        },
        "usages": {
          "column": {
            "aLaCarte": "A la carte",
            "freemium": "Freemium",
            "hints": {
              "aLaCarte": "Single payment",
              "none": "Cloud storage, upgrades, delivery fees..."
            },
            "none": "None",
            "premium": "Premium",
            "proportion": "Proportion",
            "revenue": "Revenue",
            "trial": "Trial",
            "usage": "Usage"
          },
          "contentProduction": "Content Production",
          "download": "Download",
          "fingerprint": "Fingerprint",
          "keys": {
            "copy_scan": "copyScan",
            "download": "download",
            "non_interactive_stream": "nonInteractiveStream",
            "on_demand_stream": "onDemandStream"
          },
          "radio": "Radio",
          "streaming": "Streaming"
        }
      },
      "trends": {
        "title": "Trends"
      },
      "units": {
        "bundles": "Bundles",
        "contentProduction": "Content Production",
        "copyScan": "Streams",
        "download": "Tracks EQ",
        "singleTracks": "Single Tracks",
        "stream": "Streams"
      }
    },
    "artists": {
      "availabilities": {
        "delete": {
          "message": "You will delete the Artist ID. Are you sure?",
          "success": "Artist ID has been removed",
          "title": "Delete Artist ID"
        },
        "dsp": "Platform",
        "external": "ID",
        "new": {
          "appleArtists": {
            "appleId": "Apple ID",
            "create": {
              "error": "Unable to create artist page",
              "success": "Artist page has been requested. Please come back in a few minutes and you will be able to associate it"
            },
            "existing": "Existing Artist pages",
            "genre": "Genre",
            "hint": "Choose an existing ID matching your artist and fill the input below. If you don't find any artist ID matching, you can ",
            "hint_not_found": "No artist has been found. You can ",
            "locale": "Language",
            "name": "Name",
            "request": "request a new Apple artist page"
          },
          "artistID": "Artist ID",
          "dsp": "Platform",
          "spotifyArtists": {
            "existing": "Existing Spotify pages",
            "hint": "Choose an existing ID matching your artist and fill the input below.",
            "hint_not_found": "No artist has been found.",
            "name": "Name",
            "spotifyId": "Spotify ID"
          },
          "success": "Artist ID has been updated",
          "title": "New Artist ID"
        },
        "title": "Artist IDs"
      },
      "filters": {
        "tag": "Filter by Contracts, Labels..."
      },
      "nav": {
        "analytics": "Analytics",
        "availabilities": "Artist IDs",
        "index": "Albums",
        "playPages": "Play Page",
        "socialAccounts": "Social Accounts"
      },
      "products": {
        "empty": "No available albums yet."
      },
      "title": "Artists"
    },
    "asset": {
      "associated": {
        "success": "Asset has been associated to selected track. It will be updated soon"
      },
      "ignore": {
        "success": "Asset has been ignored"
      }
    },
    "assets": {
      "associate": "Track to assign",
      "associatePlaceholder": "Choose a track...",
      "back": "Videos Index",
      "filters": {
        "tag": "Filter by Videos..."
      },
      "ignore": {
        "confirm": {
          "message": "Are you sure you don't want to assign this video to any track ?",
          "title": "Do not assign"
        },
        "hint": "Do not assign"
      },
      "save": "Validate assignation",
      "title": "Incomplete Youtube Assets",
      "uncomplete": {
        "empty": {
          "message": "Congrats!",
          "title": "No incomplete asset"
        }
      },
      "video": "Video",
      "videoId": "Video ID : {asset}"
    },
    "backTo": "Back to Index",
    "cancel": "Cancel",
    "catalog": {
      "albums": {
        "appleCheck": {
          "success": "You will receive the result of the check by email"
        },
        "approve": {
          "confirm": {
            "message": "Are you sure you want to approve the submission for this album ?",
            "title": "Approve the Submission"
          },
          "failed": "An error occured, please try again",
          "success": "The Submission for this album has been successfully approved"
        },
        "delete": {
          "confirm": {
            "message": "Are you sure you want to delete this album ?",
            "title": "Delete this product"
          },
          "error": "An error occured, your album is not deleted. Please, try again",
          "failed": "Failed to delete this product",
          "success": "Album successfully deleted"
        },
        "duplicate": {
          "confirm": {
            "message": "Are you sure you want to duplicate this album ?",
            "title": "Duplicate this product"
          },
          "failed": "Failed to duplicate this product",
          "success": "Album successfully duplicated"
        },
        "filters": {
          "sort": {
            "createdAt": "Sort by: Last created",
            "name": "Sort by: Name",
            "releaseDate": "Sort by: Release date",
            "updatedAt": "Sort by: Last updated"
          },
          "state": {
            "all": "Show All Albums",
            "notSubmitted": "Show Unsubmitted Albums Only",
            "submitted": "Show Submitted Albums Only"
          },
          "status": {
            "active": "Show Active only",
            "all": "Show all Album status",
            "inactive": "Show Inactive only"
          }
        },
        "index": {
          "createdAt": "Created on",
          "delete": "Delete",
          "duplicate": "Duplicate",
          "editAlbum": "Edit",
          "inactive": "Inactive album",
          "pause": "Pause",
          "play": "Play",
          "releaseDate": "Release date:",
          "seeAlbum": "See album",
          "upcCode": "UPC:",
          "updatedAt": "Last updated on"
        },
        "informations": {
          "author": "Author:",
          "booklet": "Booklet",
          "calendar": {
            "apple": "Add to your Apple calendar",
            "google": "Add to your Google calendar",
            "outlook": "Add to your Outlook calendar",
            "tooltip": "Add this date into your calendar"
          },
          "calendarTitle": "Release of",
          "closed": "Show More Artists",
          "composer": "Composer:",
          "contributor": "Contributor:",
          "copyrightLine": "© Copyright Line:",
          "emptyTracklisting": "There is no track associated with this album",
          "featuring": "Featured Artists:",
          "genre1": "Genre:",
          "genre2": "Genre 2:",
          "hideDetails": "Hide details",
          "label": "Label:",
          "labelComments": "Label Comments",
          "labelInternalReference": "Catalog Number:",
          "language": "Metadata Language:",
          "masteredBy": "Sound Engineer Email:",
          "opened": "Show Less Artists",
          "originalDate": "Original Release Date:",
          "preorderDate": "Preorder Date:",
          "productType": "Product Type:",
          "productionLine": "℗ Production Line:",
          "productionYear": "Production year:",
          "publisher": "Publisher:",
          "releaseDate": "Release Date:",
          "remixer": "Remixer:",
          "showDetails": "Show details",
          "tags": "Tags",
          "track": {
            "artist": "Artist",
            "audioLanguage": "Audio Language",
            "clean": "Clean",
            "conflict": "ISRC Conflict",
            "duration": "Duration",
            "explicit": "Parental Advisory",
            "explicitContent": "Explicit Content",
            "hiRes": "24-Bit",
            "informations": "Information",
            "isrcCode": "ISRC",
            "iswcCode": "ISWC",
            "iswcConflict": "ISWC Conflict",
            "lowRes": "16-Bit",
            "lyrics": "Lyrics",
            "lyricsDetails": "{track} - Lyrics",
            "lyricsTtml": "Lyrics in TTML",
            "masterTypes": "Master Type",
            "previewEnd": "Preview End",
            "previewStart": "Preview Start",
            "productionDate": "Production Date",
            "productionLine": "Production Line",
            "publisher": "Publisher",
            "resolution": "Resolution",
            "rolesTitle": "Roles",
            "subtitle": "Version",
            "title": "Title",
            "video": "Video Track",
            "videoModalTitle": "{track} - Videos associated",
            "videos": {
              "duration": "Duration",
              "name": "Video Name",
              "resolution": "Resolution",
              "retailer": "Platform",
              "retails": "Platforms"
            },
            "work": "Work"
          },
          "upc": "UPC:",
          "viewComments": "View Comments",
          "volume": "Volume "
        },
        "links": {
          "appleId": "Apple ID:",
          "copyID": "Copy Platform ID",
          "copyItunes": "Copy Apple ID",
          "copySpotify": "Copy Spotify URI",
          "copyURL": "Copy URL",
          "delivered": "Online",
          "notDelivered": "Offline",
          "platformId": "Platform ID:",
          "spotifyURI": "URI:",
          "status": "Status:",
          "url": "URL:"
        },
        "show": {
          "comments": {
            "title": "Comments"
          },
          "commentsTitle": "Comments ({number})",
          "edit": "Edit",
          "submission": {
            "approve": "Approve",
            "reject": "Reject",
            "title": "Submission Pending"
          }
        }
      },
      "assets": {
        "additionalInformations": "Additional informations",
        "assetType": "Asset type",
        "companyName": "Company",
        "title": "Title",
        "youtubeId": "ID YouTube"
      },
      "products": {
        "metadata": {
          "failedRequest": "Ahem, an error occured. Please try again",
          "links": {
            "products": "Albums xls",
            "tracks": "Tracks xls",
            "xml": "xml",
            "xmlInpro": "xml Inpro"
          },
          "successfulRequest": "Catalog successfully requested, you will receive it by email once it's processed.",
          "title": "Download your Catalog"
        },
        "show": {
          "appleCheck": {
            "launch": "Start an Apple check"
          },
          "artists": {
            "index": {
              "name": "Artist",
              "roles": "Roles",
              "title": "Artist's list"
            }
          },
          "metadata": "Download your Metadata",
          "pause": "Pause",
          "play": "Play",
          "preview": "Preview",
          "submission": "Submission:",
          "submissionPriority": "Priority",
          "submissionStatus": "Status",
          "tag": "Add a tag"
        },
        "toolbar": {
          "metadata": "Download your Catalog",
          "new": "New Album"
        }
      },
      "videos": {
        "failedExtract": "An error occurred. The videos extract cannot be requested",
        "informations": {
          "emptyTracklisting": "There is no track associated with this video",
          "format": "Format:",
          "id": "ID Labelcamp:",
          "isrc": "ISRC Video:",
          "isrcAudio": "ISRC Audio:",
          "label": "Label:",
          "length": "Length:",
          "resolution": "Resolution:",
          "upc": "UPC:",
          "weight": "Weight:"
        },
        "successfulExtract": "Videos extract has been successfully requested, you will get it by email in a few moments.",
        "toolbar": {
          "extract": "Extract Videos",
          "new": "New Video"
        },
        "tracklisting": {
          "album": "Album",
          "isrc": "ISRC",
          "length": "Length",
          "track": "Track associated"
        }
      }
    },
    "changelog": {
      "title": "What's new"
    },
    "claims": {
      "actions": {
        "approve": "Approve",
        "reject": "Reject"
      },
      "back": "Back to claims index",
      "commentTemplate": "Your reference has conflicting ownership information. Immediate action is required.\n\nPlease provide the correct ownership information by approving or rejecting this conflict:\n\n- Approve: you approve this ownership conflict. A deletion will be sent to the mentioned platform. The claimer will be notified by email for the withdrawal of rights.\n- Reject: you reject this ownership conflict. An email asserting your rights will be sent to the claimant. An email confirming your rights is sent to the claimer.\n\nRegards,\n\nIDOL Rights Management",
      "confirm": {
        "accept": {
          "message": "This will sent a deletion to all DSPs and territories mentioned. Are you sure to approve this ownership conflict?",
          "title": "Approve ownership conflict"
        },
        "refuse": {
          "message": "This will reject the claim and you must take the mentioned actions in comment. Are you sure to reject this ownership conflict?",
          "title": "Reject ownership conflict"
        }
      },
      "create": {
        "title": "Create a claim"
      },
      "edit": {
        "title": "Edit a claim"
      },
      "empty": {
        "title": "No claims to review yet"
      },
      "filters": {
        "resolved": "Resolved",
        "tag": "Filter by album, label, contract or platform...",
        "unresolved": "Unresolved"
      },
      "form": {
        "claimer": {
          "hint": "The claimer is the person who claims the album. He will be notified by email.",
          "title": "Claimer"
        },
        "claimerEmail": "Claimer Email",
        "claimerName": "Claimer Name",
        "comment": "Comment",
        "dsps": "Platforms",
        "generalInformation": {
          "title": "General Information"
        },
        "owner": "Rights Holder",
        "product": "Album claimed",
        "rightHolder": {
          "hint": "This is a person from the label who owns the album rights. He will receive email and nofitication via Labelcamp.",
          "title": "Right Holder"
        },
        "territories": "Territories",
        "track": "Track claimed"
      },
      "notifications": {
        "save": {
          "failed": "Unable to save claim, please try again.",
          "success": "Claim successfully saved"
        },
        "update": {
          "failed": "Unable to save claim, please try again.",
          "success": "Claim successfully saved"
        }
      },
      "show": {
        "approveOrReject": "Do you approve or reject this ownership conflict?",
        "title": "Claim Information",
        "viewAs": "View as a label"
      },
      "table": {
        "accept": "Accept",
        "allPlatforms": "All platforms",
        "claimer": "Claimer",
        "comment": "Comment",
        "createdAt": "Created On",
        "owner": "Rights Holder",
        "platforms": "Platforms",
        "product": "Album",
        "refuse": "Refuse",
        "status": "Status",
        "statuses": {
          "accepted": "Accepted",
          "refused": "Refused"
        },
        "territories": "{count, plural, =1 {# territory} other {# territories}}"
      },
      "title": "Claims",
      "toolbar": {
        "new": "New Claim"
      }
    },
    "clone": "Duplicate",
    "clones": {
      "labels": {
        "label": "Destination label"
      },
      "multiple": {
        "success": "{count} singles were successfully created:"
      },
      "platforms": {
        "label": "Platforms",
        "placeholder": "Select one or more platforms"
      },
      "title": "Duplicate Album",
      "tracks": {
        "ep": "Create an album with selected tracks",
        "label": "Tracks to duplicate",
        "placeholder": "Select one or more tracks"
      },
      "type": {
        "product": "Whole Album",
        "track": "Single Track"
      }
    },
    "cloning": "Duplicating...",
    "close": "Close",
    "collection": {
      "artistMissing": "Artist missing",
      "artists": {
        "empty": {
          "message": "Your artists will appear here after you've added your first album",
          "title": "No artists found"
        },
        "emptyFilters": {
          "message": "Look likes there’s no artist matching. Try with different filters",
          "title": "Uh oh !"
        },
        "platformId": {
          "empty": {
            "message": "Click on the top right button to associate this artist to a platform",
            "title": "No artist ID associated yet"
          }
        }
      },
      "assets": {
        "empty": {
          "message": "You don't have any incomplete Youtube Asset",
          "title": "It's all good !"
        }
      },
      "companies": {
        "empty": {
          "message": "Click on the right bottom button to create your first contract",
          "title": "No contract added yet !"
        },
        "emptyFilters": {
          "message": "Look likes there’s no contract matching. Try with different filters",
          "title": "Uh oh !"
        }
      },
      "count": {
        "artist": "{count, plural, =1 {# artist} other {# artists}}",
        "asset": "{count, plural, =1 {# asset} other {# assets}}",
        "label": "{count, plural, =1 {# label} other {# labels}}",
        "product": "{count, plural, =0 {no album} =1 {# album} other {# albums}}",
        "products": {
          "zero": "No album"
        },
        "video": "{count, plural, =1 {# video} other {# videos}}"
      },
      "labelMissing": "Label missing",
      "labels": {
        "empty": {
          "message": "Click on the right bottom button to create your first label",
          "title": "No label added yet !"
        },
        "emptyFilters": {
          "message": "Look likes there’s no label matching. Try with different filters",
          "title": "Uh oh !"
        }
      },
      "list": {
        "table": {
          "releaseDate": "Release Date"
        }
      },
      "nameMissing": "Name missing",
      "products": {
        "empty": {
          "message": "Click on the top right button to get started and add your first album",
          "title": "No album added yet !"
        },
        "emptyFilters": {
          "message": "Look likes there’s no album matching. Try with different filters or add a new one",
          "title": "Uh oh !"
        }
      },
      "videos": {
        "empty": {
          "message": "Click on the top right button to get started and add your first video",
          "title": "No video added yet !"
        },
        "emptyFilters": {
          "message": "Look likes there’s no video matching. Try with different filters or add a new one",
          "title": "Uh oh !"
        }
      }
    },
    "comments": {
      "delete": "Delete",
      "edit": "Edit",
      "pin": "Pin",
      "pinnedBy": "Pinned By",
      "placeholder": "Write a comment or @mention someone...",
      "post": "Post Comment",
      "privateReply": "Add as a private comment",
      "unpin": "Unpin",
      "update": "Update",
      "updated": "Updated",
      "updatedOn": "Update on",
      "user": {
        "basic": "added a comment",
        "private": "added a private comment"
      }
    },
    "contracts": {
      "associations": {
        "albums": "Albums Count",
        "albumsCount": "{count, plural, =1 {# album} other {# albums}}",
        "association": "Labels",
        "create": {
          "hint": "Search and associate a label. Or ",
          "hintLink": "create a new one",
          "resource": "Label"
        },
        "empty": "No label associated to this contract yet",
        "new": "New Label",
        "title": "Labels",
        "type": "Type"
      },
      "contract": {
        "failedCreate": "An error occured, the contract is not created. Please try again",
        "failedDestroy": "An error occured, the contract is not deleted. Please try again",
        "failedUpdate": "An error occured, the contract is not updated. Please try again",
        "successfulCreate": "The contract has been successfully created",
        "successfulDestroy": "The contract has been successfully deleted",
        "successfulUpdate": "The contract has been successfully updated"
      },
      "create": {
        "comments": "Comments",
        "name": "Name",
        "title": "Create a Contract",
        "user": "Label Manager",
        "vatApplicable": "Subject to VAT"
      },
      "delete": {
        "failed": "An error occured, the contract is not deleted. Please try again",
        "message": "Are you sure you want to delete this contract ?",
        "success": "The contract has been successfully deleted",
        "title": "Delete Contract"
      },
      "edit": {
        "title": "Edit Contract"
      },
      "failedExtract": "An error occurred. The contracts extract cannot be requested",
      "filters": {
        "rate": {
          "all": "All",
          "withRate": "With rate",
          "withoutRate": "Without rate"
        },
        "rates": "Rates:",
        "tag": "Filter by Labels or Albums..."
      },
      "index": {
        "contract": "Contracts",
        "label": "{count, plural, =1 {# label} other {# labels}}",
        "labels": "Labels Count",
        "rate": "Rates",
        "rateMultiple": "Multiple"
      },
      "nav": {
        "associations": "Associations",
        "rates": "Rates",
        "rights": "Rights"
      },
      "rates": {
        "create": {
          "endDate": "End Date",
          "maxRevenue": "Maximum Revenue",
          "maxRevenueHint": "Leave blank if you have only one rate. If you have revenue tiers, leave this input blank only on your last tier",
          "minRevenue": "Minimum Revenue",
          "rate": "Rate",
          "ratePlaceholder": "e.g: 0.3",
          "startDate": "Start Date",
          "title": "Add a New Rate"
        },
        "delete": {
          "message": "Are you sure you want to delete this rate ?",
          "title": "Delete Rate"
        },
        "edit": {
          "title": "Edit Rate"
        },
        "empty": "No rate associated to this contract yet",
        "endDate": "End Date",
        "failedCreate": "An error occured, the rate is not created. Please try again",
        "failedDestroy": "An error occured, the rate is not deleted. Please try again",
        "failedUpdate": "An error occured, the rate is not updated. Please try again",
        "maxRevenue": "Maximum Revenue",
        "minRevenue": "Minimum Revenue",
        "new": "New Rate",
        "rate": "Rate",
        "startDate": "Start Date",
        "successfulCreate": "The rate has been successfully created",
        "successfulDestroy": "The rate has been successfully deleted",
        "successfulUpdate": "The rate has been successfully updated",
        "title": {
          "current": "Current rate",
          "expired": "Expired rate"
        }
      },
      "successfulExtract": "Contracts extract has been successfully requested, you will get it by email in a few moments.",
      "title": "Contracts",
      "toolbar": {
        "extract": "Extract Contracts",
        "new": "New Contract"
      }
    },
    "create": "Create",
    "creating": "Creating...",
    "dashboard": {
      "blog": {
        "title": "Blog"
      },
      "catalog": {
        "newAlbum": "New Album",
        "number": "{count, plural, =0 {-} =1 {# album} other {# albums}}",
        "title": "Catalog"
      },
      "releases": {
        "number": "{count, plural, =0 {-} =1 {# release} other {# releases}}",
        "upcoming": "upcoming on {date}"
      },
      "starterKit": {
        "close": "Ok, I get it. Show me my dashboard instead",
        "create": "Add a new album to your catalog",
        "description": "Here are few steps that will help you getting started.",
        "help": "Read our help articles",
        "mail": "If you have any questions, feel free to drop us an email",
        "profile": "Edit your profile (avatar, email, name...)",
        "title": "Welcome on board !",
        "users": "Add members to your team"
      },
      "suggestions": {
        "extracts": {
          "body": "Simplify your royalties calculation with our consolidated sales extracts.",
          "button": "Generate an extraction",
          "title": "Consolidated Extractions"
        },
        "ideas": {
          "body": "Feel free to send us your suggestions to improve Labelcamp !",
          "button": "Give us your opinion",
          "title": "Your Ideas Matter"
        },
        "pagePlay": {
          "body": "Discover our Play Page, the best way to promote your releases on social networks",
          "button": "Discover this Play Page"
        },
        "premium": {
          "body": "Offer up to 500 free digital albums to a targeted audience",
          "button": "Create a Premium"
        },
        "share": {
          "body": "Share your albums on Youtube with our dedicated tools",
          "button": "Share this album"
        },
        "title": "Suggestions"
      }
    },
    "dateRangePicker": {
      "apply": "Apply",
      "applyToAll": "Apply to all",
      "end": "To",
      "options": {
        "12months": "Last 12 months",
        "30days": "Last 30 Days",
        "30firstdays": "First 30 Days",
        "365firstdays": "First 12 months",
        "7days": "Last 7 Days",
        "7firstdays": "First 7 Days",
        "90firstdays": "First 90 Days",
        "allTime": "All Time",
        "any": "Any",
        "currentYear": "This Year",
        "next30days": "Next 30 days",
        "next7days": "Next 7 days",
        "previousYear": "Last Year"
      },
      "start": "From"
    },
    "deactivate": "Deactivate",
    "default": "Default",
    "delete": "Delete",
    "deselectAll": "Deselect All",
    "distribution": {
      "submissions": {
        "actions": {
          "approve": "Approve",
          "reject": "Reject"
        },
        "comments": "{count, plural, =1 {# comment} other {# comments}}",
        "contentCommentDate": "Email sent on",
        "count": "Submitted {count, number} times",
        "countExisting": "{count, number} more existing",
        "currentSubmission": "Current Submission",
        "noAssignee": "No Assignee",
        "products": {
          "approve": {
            "confirm": {
              "message": "This will set album to Active. Are you sure to approve this submission ?",
              "title": "Approve the Submission"
            },
            "title": "Approve the Submission"
          }
        },
        "rejectSubmission": "Send album back to the label for changes",
        "rejectedOn": "Rejected on {date}",
        "reminderDate": "Reminded on",
        "sentBack": "Send an email to the label",
        "sentReminder": "Reminder",
        "submittedBy": "Submitted by {login} on {date}",
        "submittedOn": " submitted on {date}",
        "update": {
          "assignUser": {
            "failed": "Failed to update the submission's user",
            "success": "Assigned user succesfully updated"
          },
          "priority": {
            "failed": "Failed to update the submission's priority",
            "success": "Prioritization updated successfully"
          },
          "status": {
            "failed": "Failed to update the submission's status",
            "rejected_message": "Are you sure you want to reject this album?",
            "rejected_submessage": "In such case, it is recommended to post a comment detailing the reasons of the rejection. The concerned user will be notified of your comment.",
            "rejected_title": "Reject the Submission",
            "success": "Status updated successfully"
          }
        }
      }
    },
    "distributors": {
      "nav": {
        "webhooks": "Webhooks"
      },
      "webhooks": {
        "actions": {
          "dspStateStatusUpdate": "Dsp State",
          "importTaskStatusUpdate": "Import Task",
          "productCreate": "Product",
          "sendTaskStatusUpdate": "Send Task"
        },
        "columns": {
          "eventType": "Event Type",
          "selectEvent": "Select Event",
          "url": "URL"
        },
        "confirm": {
          "actions": {
            "delete": "Delete",
            "validate": "Validate"
          },
          "remove": {
            "message": "Are you sure you want to delete this Webhook ?",
            "title": "Remove a Webhook"
          }
        },
        "empty": {
          "subtitle1": "Read the docs",
          "subtitle2": "to find out more.",
          "title": "No Webhook Have Been Defined Yet !"
        },
        "new": "+ New Webhook",
        "notifications": {
          "create": {
            "failed": "Unable to save this Webhook",
            "success": "Webhook successfully created",
            "taskError": "Can't save this webhook beacause no task was selected"
          },
          "delete": {
            "failed": "Unable to delete this Webhook",
            "success": "Webhook successfully deleted"
          }
        },
        "subtitle": "Setup several webhook endpoints to be notified of such events.",
        "title": "Manage Webhooks"
      }
    },
    "dspStates": {
      "availability": {
        "available": "Online",
        "notAvailable": "Offline"
      },
      "form": {
        "dspUploadIdentifications": "platform",
        "isAvailable": "Status"
      },
      "new": "New Platform Status",
      "notifications": {
        "failed": "Unable to save this platform status",
        "success": "Platform status successfully saved"
      },
      "spotifyURI": "Spotify URI",
      "status": {
        "offline": "Offline",
        "online": "Online"
      },
      "table": {
        "batch": "Batch",
        "links": "Links",
        "platform": "Platforms",
        "platformID": "Platform IDs",
        "status": "Status",
        "updatedAt": "Updated At"
      },
      "title": "Platform status & links",
      "tracksAvailabilities": {
        "see": "See track IDs",
        "table": {
          "spotifyURI": "Track URI",
          "title": "Title"
        },
        "title": "{name} Track IDs"
      }
    },
    "dspUploadIdentifications": {
      "filters": {
        "statuses": "Status:"
      },
      "index": {
        "columns": {
          "DolbyAtmos": "Dolby Atmos",
          "HDAudio": "HD Audio",
          "XMLValidation": "XML Validation",
          "allRolesSupported": "Advanced Roles",
          "artistId": "Artist ID",
          "blockPreview": "Block Preview",
          "booklet": "Booklet",
          "bundleRelease": "Bundled Release",
          "bundleTracks": "Bundled Tracks",
          "copyScan": "Copy Scan",
          "ddexVersion": "DDEX Version",
          "delivery": "Delivery",
          "download": "Download",
          "lyrics": "Lyrics",
          "name": "Name",
          "nonInteractiveStream": "Radio",
          "preorder": "Pre-order",
          "preventSendingBeforeReleaseInDays": "Prevent delivery",
          "productionStart": "Start Date",
          "profile": "Profile",
          "redelivery": "Redelivery",
          "releaseTime": "Release Time",
          "streaming": "Streaming",
          "takedown": "Delete",
          "territories": "Territory Restriction",
          "territoryPrice": "Price by Territory",
          "territoryReleaseDate": "Release Date by Territory",
          "territoryRights": "Territory Rights",
          "titleVersion": "Track/Album Title Version",
          "trackGenre": "Track-level Genre",
          "trackReleaseDate": "Track Release Date",
          "update": "Update",
          "video": "Video"
        },
        "days": {
          "count": "{count, plural, =1 {# day} other {# days}}"
        },
        "platformsList": "Platforms List",
        "searchPlaceholder": "Search by name, status and profile...",
        "table": {
          "active": "Active",
          "closed": "Archived",
          "standby": "Stand-by",
          "unknown": "No Feed"
        },
        "territories": {
          "count": "{count, plural, =1 {# territory} other {# territories}}"
        }
      },
      "mappings": {
        "empty": "No mappings found.",
        "genres": {
          "notifications": {
            "error": "Unable to map genre, please try again",
            "success": "Successfully mapped genre"
          },
          "title": "Genres"
        },
        "options": {
          "placeholders": {
            "distributorPriceCode": "Select a price",
            "distributorProductSubgenre": "Select a genre"
          }
        },
        "prices": {
          "notifications": {
            "error": "Unable to map price, please try again",
            "success": "Successfully mapped price"
          },
          "title": "Album Prices"
        },
        "trackPrices": {
          "notifications": {
            "error": "Unable to map track price, please try again",
            "success": "Successfully mapped track price"
          },
          "title": "Track Prices"
        }
      },
      "nav": {
        "genres": "Genres",
        "mappings": "Mappings",
        "prices": "Album Prices",
        "trackPrices": "Track Prices"
      },
      "statuses": {
        "active": "Active",
        "closed": "Archived",
        "standby": "Stand-by",
        "unknown": "No Feed",
        "unknownHint": "Available upon Request"
      },
      "title": "Platforms"
    },
    "duplicate": {
      "errors": {
        "noTrack": "You must select a track"
      },
      "oneTrack": {
        "hint": "If you choose this option, the release date, the preorder date and the price won't be duplicated. Furthermore, your album title will be the selected track title",
        "placeholder": "Choose a track",
        "title": "Duplicate only one track"
      },
      "title": "Duplicate"
    },
    "edit": "Edit",
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can't be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "copyright": "{description} must match this format : 2016 Lorem ipsum",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "passwordConfirmation": "Your password confirmation doesn't match your password",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "unexpected": "An unexpected error occured. Please try again.",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)",
      "year": "{description} must be a valid year"
    },
    "featureNotAvailable": "This feature will be available soon!",
    "fileUploaded": "File uploaded",
    "follow": {
      "alreadyFollowingParent": "You are already subscribed to {type}",
      "follow": "Subscribe",
      "notifications": {
        "follow": "You succesfully subscribed to this {type}",
        "unfollow": "You successfully unsubscribed to this {type}"
      },
      "unfollow": "Unsubscribe"
    },
    "form": {
      "pending": "Saving..."
    },
    "format": "{format} format",
    "header": {
      "changelog": "What's new",
      "group": "Groups & Users",
      "new": {
        "album": "New Album",
        "button": "New +",
        "contract": "New Contract",
        "group": "New Group",
        "label": "New Label",
        "user": "New User",
        "video": "New Video"
      },
      "preferences": "Preferences",
      "producer": "Producer",
      "search": "Search"
    },
    "help": "Help",
    "home": {
      "title": "Home"
    },
    "importTasks": {
      "columns": {
        "createdAt": "Created On",
        "masterType": "Master Type",
        "resolution": "Resolution",
        "status": "Status",
        "trackNumber": "Track Number",
        "updatedAt": "Updated On",
        "user": "User",
        "volumeNumber": "Volume"
      },
      "delete": {
        "failed": "An error occured. Unable to destroy this import task",
        "message": "Are you sure you want to delete this import task ?",
        "success": "Import Task was successfully deleted",
        "title": "Import Task"
      },
      "empty": "No import tasks yet to show",
      "status": {
        "canceled": "Canceled",
        "cancelled": "Canceled",
        "completed": "Completed",
        "encoding": "Encoding",
        "error": "Error",
        "importing": "Importing",
        "processing": "Processing",
        "uploaded": "Uploaded",
        "uploading": "Uploading",
        "waiting": "Waiting"
      }
    },
    "invitation": {
      "login": {
        "placeholder": "Username"
      },
      "message": "You're about to join a new team. Choose a username and a password to start.",
      "password": {
        "placeholder": "Password"
      },
      "passwordConfirmation": {
        "placeholder": "Password Confirmation"
      },
      "title": "Invitation"
    },
    "invite": "Invite",
    "labels": {
      "filters": {
        "tag": "Filter by Contracts or Albums..."
      },
      "informations": {
        "information": {
          "contract": "Associated Contract",
          "description": "Description",
          "genres": "Main Genre",
          "title": "Information"
        },
        "logo": {
          "colorspace": "RGB colorspace",
          "download": "Download logo",
          "format": "Format .png or .jpeg",
          "hint": "Click on the drag area or drag & drop to upload a logo",
          "size": "Minimum size 500px, recommanded 1200px (width or height)",
          "title": "Logo"
        },
        "usages": {
          "title": "Usages"
        },
        "website": {
          "new": "+ Add a new website",
          "placeholder": "Add an URL",
          "title": "Websites",
          "url": "URL"
        }
      },
      "nav": {
        "analytics": "Analytics",
        "index": "Albums",
        "informations": "Information",
        "playPages": "Play Page",
        "premiums": "Premiums",
        "rights": "Rights",
        "socialAccounts": "Social Accounts",
        "socialTrends": "Social Trends"
      },
      "products": {
        "empty": "No available albums yet."
      },
      "title": "Labels"
    },
    "language": {
      "acoli": "Acoli",
      "afrikaans": "Afrikaans",
      "akan": "Akan",
      "algerianArabic": "Algerian Arabic",
      "amharic": "Amharic",
      "arabic": "Arabic",
      "arma": "Arma",
      "armenian": "Armenian",
      "bambara": "Bambara",
      "bamileke": "Bamileke",
      "basque": "Basque",
      "bassa": "Bassa",
      "belarusian": "Belarusian",
      "bengali": "Bengali",
      "benin": "Benin",
      "bhojpuri": "Bhojpuri",
      "bosnian": "Bosnian",
      "breton": "Breton",
      "bulgarian": "Bulgarian",
      "cantonese": "Cantonese",
      "catalan": "Catalan",
      "chinese": "Chinese",
      "chinese(Simplified)": "Chinese (Simplified)",
      "chinese(Traditional)": "Chinese (Traditional)",
      "corsican": "Corsican",
      "creole(Guadeloupe)": "Guadeloupe French Creole",
      "creole(Martinique)": "Martinique French Creole",
      "creole(Reunion)": "Reunion French Creole",
      "croatian": "Croatian",
      "czech": "Czech",
      "dagaariDioula": "Dagaari Dioula",
      "danish": "Danish",
      "dholuo": "Dholuo",
      "dmn": "Mande",
      "duala": "Duala",
      "dutch": "Dutch",
      "dyula(BurkinaFaso)": "Dyula (Burkina Faso)",
      "english": "English",
      "estonian": "Estonian",
      "finnish": "Finnish",
      "fon": "Fon",
      "french": "French",
      "frenchBasedCreolesAndPidgins": "French-based creoles and pidgins",
      "fulah(Burkina Faso)": "Fulah (Burkina Faso)",
      "fulah(Guinea)": "Fulah (Guinea)",
      "fulah(Mali)": "Fulah (Mali)",
      "fulah(Nigeria)": "Fulah (Nigeria)",
      "fulah(Senegal)": "Fulah(Senegal)",
      "fulfulde": "Fulfulde",
      "gaelic": "Gaelic",
      "gagnoaBete": "Gagnoa Bete",
      "galician": "Galician",
      "georgian": "Georgian",
      "german": "German",
      "ghanaianPidginEnglish": "Ghanaian Pidgin English",
      "gikuyu": "Gikuyu",
      "greek": "Greek",
      "guadeloupeFrenchCreole": "Guadeloupe French Creole",
      "guianeseCreoleFrench": "Guianese Creole French",
      "gujarati": "Gujarati",
      "haitian": "Haitian",
      "haryanvi": "Haryanvi",
      "hausa": "Hausa",
      "hebrew": "Hebrew",
      "hindi": "Hindi",
      "humburiSenniSonghay": "Humburi Senni Songhay",
      "hungarian": "Hungarian",
      "icelandic": "Icelandic",
      "igbo": "Igbo",
      "indonesian": "Indonesian",
      "instrumental": "Instrumental",
      "irish": "Irish",
      "italian": "Italian",
      "japanese": "Japanese",
      "kabyle": "Kabyle",
      "kamba(Kenya)": "Kamba (Kenya)",
      "kannada": "Kannada",
      "kazakh": "Kazakh",
      "kikuyu": "Kikuyu",
      "kimbundu": "Kimbundu",
      "korean": "Korean",
      "koyraChiiniSonghay": "Koyra Chiini Songhay",
      "lao": "Lao",
      "latin": "Latin",
      "latvian": "Latvian",
      "lingala": "Lingala",
      "lingala(Angola)": "Lingala (Angola)",
      "lingala(CentralAfricanRepublic)": "Lingala (Central African Republic)",
      "lingala(DemocraticRepublicOfTheCongo)": "Lingala (Democratic Republic Of The Congo)",
      "lingala(RepublicOfTheCongo)": "Lingala (Congo)",
      "lithuanian": "Lithuanian",
      "lubaKatanga": "Luba-Katanga",
      "luo(KenyaAndTanzania)": "Luo (Kenya and Tanzania)",
      "magahi": "Magahi",
      "maithili": "Maithili",
      "malagasy": "Malagasy",
      "malay": "Malay",
      "mandigo": "Mandigo",
      "maori": "Maori",
      "marathi": "Marathi",
      "moroccanArabic": "Moroccan Arabic",
      "multilingual": "Multilingual",
      "neapolitan": "Neapolitan",
      "ngazidjaComorian": "Ngazidja Comorian",
      "northernSotho": "Northern Sotho",
      "norwegian": "Norwegian",
      "occitan": "Occitan",
      "persian": "Persian",
      "polish": "Polish",
      "portuguese": "Portuguese",
      "punjabi": "Punjabi",
      "rajasthani": "Rajasthani",
      "romanian": "Romanian",
      "russian": "Russian",
      "sanskrit": "Sanskrit",
      "serbian": "Serbian",
      "shona": "Shona",
      "slovak": "Slovak",
      "slovenian": "Slovenian",
      "soninke": "Soninke",
      "soninke(Gambia)": "Soninke (Gambia)",
      "soninke(Mali)": "Soninke (Mali)",
      "soninke(Mauritania)": "Soninke (Mauritania)",
      "soninke(Senegal)": "Soninke (Senegal)",
      "southernSotho": "Southern Sotho",
      "spanish": "Spanish",
      "spanish(LatinAmerica)*": "Spanish (Latin America)",
      "susu": "Susu",
      "susu(Guinea)": "Susu (Guinea)",
      "susu(Sierra Leone)": "Susu (Sierra Leone)",
      "swahili": "Swahili",
      "swahili(DemocraticRepublicOfTheCongo)": "Swahili (Democratic Republic Of The Congo)",
      "swahili(Kenya)": "Swahili (Kenya)",
      "swahili(Uganda)": "Swahili (Uganda)",
      "swedish": "Swedish",
      "tagalog": "Tagalog",
      "tamasheq": "Tamasheq",
      "tamil": "Tamil",
      "telugu": "Telugu",
      "thai": "Thai",
      "tswana": "Tswana",
      "tunisianArabic": "Tunisian Arabic",
      "turkish": "Turkish",
      "ukrainian": "Ukrainian",
      "undefined": "Undefined",
      "urdu": "Urdu",
      "vietnamese": "Vietnamese",
      "welsh": "Welsh",
      "wolof": "Wolof",
      "wolof(Gambia)": "Wolof (Gambia)",
      "wolof(Mauritania)": "Wolof (Mauritania)",
      "wolof(Senegal)": "Wolof (Senegal)",
      "xhosa": "Xhosa",
      "yoruba": "Yoruba",
      "zulu": "Zulu"
    },
    "mail": {
      "placeholderMessage": "Say something",
      "subject": "Subject",
      "to": "To"
    },
    "models": {
      "company": "Contract",
      "distributor": "Distributor",
      "label": "Label",
      "product": "Album"
    },
    "multiselectCheckboxesDropdown": {
      "allSelected": "All",
      "apply": "Apply",
      "deselectAll": "Deselect All",
      "selectAll": "Select All"
    },
    "nav": {
      "accounting": {
        "name": "Accounting",
        "subnav": {
          "extracts": "Extracts",
          "reports": "Reports"
        }
      },
      "analytics": {
        "name": "Analytics",
        "subnav": {
          "leaks": "Leaks",
          "sales": "Sales",
          "trends": "Trends"
        }
      },
      "catalog": {
        "name": "Catalog",
        "subnav": {
          "assets": "Assets",
          "products": "Albums",
          "videos": "Videos"
        }
      },
      "changelog": "What's new",
      "contracts": {
        "name": "Contracts"
      },
      "dashboard": {
        "name": "Dashboard"
      },
      "distribution": {
        "name": "Distribution",
        "subnav": {
          "claims": "Claims",
          "deliveries": "Deliveries",
          "dspUploadIdentifications": "Platforms",
          "releases": "Releases",
          "submissions": "Submissions"
        }
      },
      "help": "Help",
      "producer": "Producer",
      "promotion": {
        "name": "Promotion"
      },
      "roster": {
        "name": "Roster",
        "subnav": {
          "artists": "Artist",
          "labels": "Labels"
        }
      }
    },
    "newVersion": "Please reload the page to update to the new version of Labelcamp",
    "no": "No",
    "noCancel": "No, cancel",
    "notifications": {
      "all": "All",
      "empty": "You have no notifications",
      "mentioned": "Mentioned",
      "read": "Mark as read",
      "readAll": "Mark all as read"
    },
    "offer": {
      "allPlatformName": "All platforms",
      "defaultName": "Default",
      "preorder": "Pre-order",
      "releaseType": {
        "normal": "",
        "post-release": "Post-Release",
        "pre-release": "Pre-Release"
      }
    },
    "offers": {
      "confirmStatus": {
        "message": "Are you sure you want to change offer's status ?",
        "title": "Offer's status"
      },
      "defaultOffers": "Default Offers",
      "delete": {
        "error": "Offer can't be deleted",
        "message": "Are you sure you want to delete this offer ?",
        "success": "Offer was successfully deleted",
        "title": "Delete offer"
      },
      "form": {
        "dspHint": "If you leave this field blank, it will create a new default offer for all platforms, unless one or multiple territories are specified in the dedicated field",
        "dspUploadIdentifications": "Platform",
        "failed": "Offer can't be saved",
        "originalReleaseDate": "Original Release Date",
        "pitchDate": "Pitch Date",
        "pitchDateHint": "If the album has a distant release date but needs to be pitched early to the DSPs, you can set a \"pitch date\" that the delivery prioritization algorithm will take into account. Please use it only when relevant to avoid disrupting the automatic delivery prioritization.",
        "preorderDate": "Preorder Date",
        "previewBeforeReleaseDate": "Authorize Preview Before Release?",
        "priceCode": "Price",
        "releaseDate": "Release Date",
        "sectionTitle": {
          "albumInfos": "Album Information",
          "attributes": "General Information",
          "tracksInfos": "Tracks Information"
        },
        "status": "Status",
        "statusHint": "If you set the status to Draft, your offer won't be active and will block Deliveries on the concerned platforms. If you set it to Archived, the Offer won't be applied anymore and won't block Deliveries.",
        "subgenres": "Main Genre",
        "subgenres2": "Secondary Genre",
        "success": "Offer was successfully saved",
        "territories": "Territories",
        "territoriesHint": "If you leave this field blank, territories will be set as same as the album's Rights",
        "title": "Name",
        "trackPriceCode": "Track Price",
        "tracks": {
          "copyScan": "Copy Scan",
          "disc": "Volume",
          "download": "Download",
          "genre": "Genre",
          "nonInteractiveStream": "Radio",
          "number": "#",
          "onDemandStream": "Streaming",
          "priceCode": "Price",
          "releaseDate": "Release Date",
          "title": "Title"
        },
        "usage": {
          "copyScan": "Copy Scan",
          "download": "Download",
          "nonInteractiveStream": "Radio",
          "onDemandStream": "Streaming"
        },
        "usages": "Usages"
      },
      "genre": "Genre:",
      "hints": {
        "cannotUpdateStatusInvalidOffer": "This offer is incomplete, you can't update it. Verify that you have at least a release date, a genre, an album price and a track price.",
        "cantDeleteActiveOffer": "You can’t delete an active offer. Archive it first in order to delete it.",
        "cantDeleteUniqDefaultOffer": "You can’t delete an unique default offer. Create a new default one, it will archive the current one automatically.",
        "cantUpdateStatusUniqDefaultOffer": "You can’t update the status of an unique default offer. Create a new one, it will archive the current one automatically."
      },
      "newOffer": "New Offer",
      "otherOffers": "Other Offers",
      "platforms": "Platforms",
      "preorderDate": "Preorder Date",
      "price": "Price:",
      "releaseDate": "Release Date",
      "sameAsRights": "Same as rights",
      "status": "Status",
      "statuses": {
        "active": "Active",
        "archived": "Archived",
        "draft": "Draft"
      },
      "territories": "Territories",
      "trackPrice": "Track price:",
      "updateStatus": {
        "failedRequest": "Status can't be updated",
        "successfulRequest": "Status was successfully updated"
      },
      "usages": "Usages"
    },
    "password": {
      "form": {
        "reset": "Reset Password"
      },
      "new": {
        "placeholder": "password",
        "placeholderConfirmation": "password confirmation",
        "title": "New Password"
      },
      "reset": {
        "hint": "Fill out the form below and instructions to reset your password will be emailed to you:",
        "passwordRecovered": "I have found my password back",
        "placeholder": "Email",
        "success": "An email has been sent with the instructions to reset your password",
        "title": "Reset Password"
      }
    },
    "player": {
      "error": "An unexpected error happened. It's likely that audio files are not available at the moment.",
      "popover": {
        "close": "Close",
        "content": "You can now easily play an album in Labelcamp just by clicking on its cover. Enjoy !",
        "title": "Discover the new player!"
      }
    },
    "product": {
      "notSendable": "Not Validated",
      "sendable": "Validated"
    },
    "products": {
      "builder": {
        "form": {
          "label": "Destination label"
        },
        "title": "Create a new album"
      },
      "button": {
        "addExistingTrack": "Add Track From my Catalog",
        "addNewTrack": "Add New Track",
        "addTrack": "Add",
        "addWork": "Add Work",
        "edit": "Edit",
        "hideDetails": "Hide Details",
        "hint": {
          "classicalRelease": "For classical releases",
          "submit": "Your album will be reviewed by our teams.",
          "submitOption": "This album will be saved with a reviewing status.",
          "validateOption": "This album and its default offer will be saved with an active status."
        },
        "importTrack": "Import Track",
        "next": "Next Step",
        "numberPlaceholder": "ex: 5",
        "previous": "Previous Step",
        "removeWork": "Remove work",
        "saveNext": "Save & Go To Next Step",
        "savePrevious": "Save & Go To Previous Step",
        "showDetails": "Show Details",
        "submit": "Submit",
        "submitting": "Submitting...",
        "validate": "Validate",
        "validating": "Validating..."
      },
      "confirm": {
        "discard": {
          "message": "There are unsaved changes.\r\nAre you sure you want to discard them?",
          "title": "Discard changes"
        },
        "productImport": {
          "message": "There are unsaved changes and importing you metadata file will override these.\r\nAre you sure you want to proceed?",
          "title": "Discard changes"
        }
      },
      "exportExcelHint": "Download metadata",
      "filters": {
        "sort": {
          "createdAt": "Last Created",
          "name": "Name",
          "releaseDate": "Release Date",
          "updatedAt": "Last Updated"
        },
        "sortBy": "Sort by:",
        "status": {
          "active": "Active",
          "archived": "Archived",
          "draft": "Draft",
          "reviewing": "Reviewing"
        },
        "statuses": "Album Status:",
        "tag": "Filter by Artists, Contracts, Labels, Genres, Tags..."
      },
      "form": {
        "appleDigitalMaster": "Apple Digital Master",
        "artists": "Artists",
        "artistsSubtitle": "Enter the name of the main artist",
        "booklet": "Booklet",
        "company": "Contract",
        "compilation": "Compilation",
        "contributors": "Contributors",
        "contributorsSubtitle": "Add contributor roles and fill in their names",
        "copyrightLine": "© Copyright Line",
        "cover": "Cover Art",
        "date": "Release Date",
        "emailADM": "Sound Engineer Email",
        "generateUpc": "Generate UPC",
        "generatingUpc": "Generating UPC...",
        "genre": "Main Genre",
        "genre2": "Secondary Genre",
        "hint": {
          "appleDigitalMaster": "Reserved for Apple Digital Masters. Please contact us for further information",
          "booklet": "PDF required – Size < 10 Mb – 28x21 cm format – 4 pages minimum – 72 dpi ",
          "cannotDuplicateEmptyTrack": "Cannot duplicate because album doesn't have any tracks",
          "clean": "Check only if there is  an explicit version of this track",
          "company": "A Contract change will affect rights, sales reports, analytics",
          "contract": "A contract change will affect rights, sales reports and analytics",
          "contributors": "Add all contributors that have participated to *all* the tracks on this release. (e.g. Featured artists, instrumentalists, vocalists, producer, remixer, etc.)",
          "copyrightLine": "Artwork Copyright Holder: the name of the current exclusive owner (person or entity) of the artwork, preceded by the year of its first publication (physical or digital)",
          "cover": "JPEG required – File Size < 20 Mb – Square format between 3000x3000 px and 4500x4500 px – RGB",
          "labelInternalReference": "Your label's catalog number used for this release",
          "originalReleaseDate": "Mandatory if the album has already been released before (digitally or physically)",
          "productionLine": "Phonographic Copyright Holder: the name of the current exclusive owner (person or entity) of the content, preceded by the year of its first publication (physical or digital)",
          "subtitle": "Leave blank unless multiple versions of this album exist, in which case, indicate the correct version (e.g. Live, Instrumental)",
          "ttmlLyrics": "Timed Text Markup Language – You must provide a .ttml file, if you aren't familiar with this format you can use this <a href=\"https://help.apple.com/itc/videoaudioassetguide/#/itc421fbba5f\" target=\"_blank\">documentation</a>",
          "upcCode": "An UPC is a unique code that every release must have."
        },
        "information": "Information",
        "isADM": "The Sound Engineer for this album is listed as an Apple Digital Masters Provider.",
        "isCompilation": "This album is a compilation",
        "label": "Label",
        "labelInternalReference": "Catalog Number",
        "language": "Metadata Language",
        "musicGenre": "Music Genre",
        "name": "Album Title",
        "originalReleaseDate": "Original Release Date",
        "preorderDate": "Preorder Date (optional)",
        "price": "Album Price",
        "productType": "Product Type",
        "productionLine": "℗ Production Line",
        "productionYear": "Production Year",
        "releaseDate": "Release Date",
        "subtitle": "Title Version (optional)",
        "title": "Title",
        "trackPriceCode": "Track Price",
        "upcCode": "UPC",
        "usage": "Usage",
        "work": "Work"
      },
      "import": {
        "errors": {
          "title": "Metadata Import Errors"
        },
        "notifications": {
          "success": "Metadata successfully imported"
        }
      },
      "importExcelHint": "Import metadata",
      "index": {
        "toolbar": {
          "hitlists": {
            "amazon": "Amazon",
            "apple": "Apple",
            "deezer": "Deezer",
            "title": "Platforms Pitching Sheets"
          }
        }
      },
      "leaving": {
        "body": "An encoding is ongoing, are you sure you want to leave ?"
      },
      "nav": {
        "analytics": "Analytics",
        "claims": "Claims",
        "distribution": "Distribution",
        "dspStates": "Platform Status",
        "email": "Email Share",
        "generalInformation": "General Information",
        "importTasks": "Import Tasks",
        "index": "Information",
        "leaks": "Leaks",
        "offers": "Offers",
        "others": "Other Video Share",
        "playPage": "Play Page",
        "premiums": "Premiums",
        "promotion": "Promotion",
        "rights": "Rights",
        "sendTasks": "Deliveries",
        "share": "Share",
        "soundcloud": "SoundCloud",
        "summary": "Summary",
        "territoryAndPlatformRights": "Territory & Platform Rights",
        "tracksAndAssets": "Tracks & Assets",
        "youtube": "YouTube"
      },
      "new": {
        "placeholder": "Add An Album"
      },
      "reorderTracks": {
        "notifications": {
          "error": "An unexpected error occured and tracks were not reordered<br />Please try again",
          "success": "Tracks were successfully reordered"
        }
      },
      "rights": {
        "confirm": {
          "option": {
            "no": "No, I'd like to request a change.",
            "yes": "Yes, they do."
          },
          "placeholder": "Please describe the requested change in the most accurate way possible.\nExamples: WW - UK; WW except UK, Spotify only; all platforms except Spotify",
          "subtitle": "Do the above rights apply to your album?",
          "title": "Confirm Your Album Rights"
        },
        "hint": {
          "defaultRight": "Default rights are inherited from label: {label}"
        },
        "notDistributed": "Not Distributed",
        "platforms": "Platforms",
        "territories": "Territories",
        "title": "Distributed Territories and Platforms"
      },
      "rolesManager": {
        "addNewRole": "Add New Contributor"
      },
      "show": {
        "albumStatus": "Album Status",
        "artists": {
          "contributors": "Name",
          "cta": "Show Artists List",
          "empty": "No artists detected for this album",
          "role": "Select a role",
          "title": "Artists List"
        },
        "backTo": "Back to Index",
        "submission": {
          "approve": "Approve",
          "priority": "Priority",
          "reject": "Reject",
          "status": "Status",
          "user": "Assignee"
        }
      },
      "statuses": {
        "active": "Active",
        "archived": "Archived",
        "draft": "Draft",
        "reviewing": "Reviewing"
      },
      "summary": {
        "artwork": "Artwork",
        "audio": "Audio",
        "hasBooklet": "Booklet Uploaded",
        "hasCover": "Cover Uploaded",
        "meta": "Album Metadata",
        "noComment": "No Comment",
        "noFile": "No File",
        "rights": "Territory and Platform Rights",
        "volume": "Volume"
      },
      "title": "Albums",
      "tracklisting": {
        "actions": {
          "classicView": "Classic View",
          "columnView": "Column View",
          "hideDetails": "Hide Details",
          "showDetails": "Show Details"
        },
        "columns": {
          "accordion": "Accordion",
          "acousticGuitar": "Acoustic Guitar",
          "actor": "Actor",
          "altoSaxophone": "Alto Saxophone",
          "altoVocals": "Alto (Vocals)",
          "archlute": "Archlute",
          "arranger": "Arranger",
          "artist": "Artist",
          "audioLanguage": "Audio Language",
          "author": "Author",
          "backgroundVocals": "Background Vocals",
          "baglama": "Baglama",
          "balafon": "Balafon",
          "bandoneon": "Bandoneon",
          "banjo": "Banjo",
          "baritoneSaxophone": "Baritone Saxophone",
          "baritoneVocals": "Baritone (Vocals)",
          "baroqueGuitar": "Baroque Guitar",
          "bass": "Bass (Classical Music)",
          "bassClarinet": "Bass Clarinet",
          "bassGuitar": "Bass Guitar",
          "bassTrombone": "Bass Trombone",
          "bassVocals": "Bass (Vocals)",
          "bassoon": "Bassoon",
          "bongos": "Bongos",
          "bouzouki": "Bouzouki",
          "cajon": "Cajon",
          "cello": "Cello",
          "chamberMusicEnsemble": "Chamber Music Ensemble",
          "choir": "Choir",
          "choirMaster": "Choir Master",
          "chorus": "Chorus",
          "clarinet": "Clarinet",
          "classicalGuitar": "Classical Guitar",
          "clavier": "Clavier",
          "clean": "Clean",
          "composer": "Composer",
          "conductor": "Conductor",
          "conflict": "ISRC Conflict",
          "congas": "Congas",
          "contraltoVocals": "Contralto (Vocals)",
          "contributor": "Contributor",
          "cornet": "Cornet",
          "counterTenor": "Counter Tenor (Vocals)",
          "dj": "DJ",
          "djembe": "Djembe",
          "doubleBass": "Double Bass",
          "drums": "Drums",
          "duration": "Duration",
          "electricGuitar": "Electric Guitar",
          "explicit": "Parental Advisory",
          "explicitContent": "Explicit Content",
          "featuring": "Featured Artists",
          "fiddle": "Fiddle",
          "firstViolin": "First Violin",
          "flugelhorn": "Flugelhorn",
          "flute": "Flute",
          "fretlessElectricBassGuitar": "Fretless Electric Bass Guitar",
          "guitar": "Guitar",
          "guitarTechnician": "Guitar Technician",
          "hammondOrgan": "Hammond Organ",
          "handclaps": "Handclaps",
          "harmonica": "Harmonica",
          "harmonium": "Harmonium",
          "harmonyVocals": "Harmony Vocals",
          "harp": "Harp",
          "harpsichord": "Harpsichord",
          "hasLyricsTtml": "Lyrics TTML",
          "hiRes": "24-Bit",
          "horn": "Horn",
          "informations": "Information",
          "isVideo": "Video",
          "isrc": "ISRC",
          "iswcCode": "ISWC",
          "keyboards": "Keyboards",
          "kora": "Kora",
          "leadGuitar": "Lead Guitar",
          "leadVocals": "Lead Vocals",
          "librettist": "Librettist",
          "lowRes": "16-Bit",
          "lute": "Lute",
          "lyricist": "Lyricist",
          "lyrics": "Lyrics",
          "lyricsDetails": "{track} - Lyrics",
          "mandolin": "Mandolin",
          "marimba": "Marimba",
          "masterTypes": "Master Type",
          "masteringEngineer": "Mastering Engineer",
          "melodica": "Melodica",
          "mezzoSopranoVocals": "Mezzo-soprano (Vocals)",
          "mixingEngineer": "Mixing Engineer",
          "musicDirector": "Music Director",
          "musicalEnsemble": "Musical Ensemble",
          "number": "#",
          "oboe": "Oboe",
          "omnichord": "Omnichord",
          "orchestra": "Orchestra",
          "organ": "Organ",
          "oud": "Oud",
          "pedalSteelGuitar": "Pedal Steel Guitar",
          "percussion": "Percussion",
          "performer": "Performer",
          "piano": "Piano",
          "piccolo": "Piccolo",
          "previewEnd": "Preview End",
          "previewStart": "Preview Start",
          "producer": "Producer",
          "productionAssistant": "Production Assistant",
          "productionDate": "Production Date",
          "productionLine": "Production",
          "publisher": "Publisher",
          "recorder": "Recorder",
          "recordingEngineer": "Recording Engineer",
          "remixer": "Remixer",
          "resolution": "Resolution",
          "rhodesPiano": "Rhodes Piano",
          "rhythmGuitar": "Rhythm Guitar",
          "saxophone": "Saxophone",
          "saz": "Saz",
          "secondViolin": "Second Violin",
          "shakers": "Shakers",
          "sitar": "Sitar",
          "soloist": "Soloist",
          "songwriter": "Songwriter",
          "sopranoSaxophone": "Soprano Saxophone",
          "sopranoSolo": "Soprano",
          "soundEngineer": "Sound Engineer",
          "steelGuitar": "Steel Guitar",
          "subtitle": "Version",
          "synthesizer": "Synthetizer",
          "tabla": "Tabla",
          "tambourine": "Tambourine",
          "tenorSaxophone": "Tenor Saxophone",
          "tenorSolo": "Tenor",
          "timbales": "Timbales",
          "timpani": "Timpani",
          "title": "Title",
          "transcriber": "Transcriber",
          "trombone": "Trombone",
          "trumpet": "Trumpet",
          "tuba": "Tuba",
          "ukulele": "Ukulele",
          "uprightBass": "Upright Bass",
          "vibraphone": "Vibraphone",
          "video": "Video Track",
          "viol": "Viol",
          "viola": "Viola",
          "violaDaGamba": "Viola Da Gamba",
          "violin": "Violin",
          "vocalAdaptation": "Vocal Adaptation",
          "vocalEngineer": "Vocal Engineer",
          "vocalSolo": "Vocal Solo",
          "work": "Work",
          "wurlitzer": "Wurlitzer",
          "xylophone": "Xylophone"
        }
      },
      "validate": {
        "error": "You have {count, plural, =1 {# error} other {# errors}} error(s) to fix.",
        "title": "Error"
      },
      "volume": {
        "errors": {
          "duplicatedFiles": "There are duplicated tracks number in your audio files' name. Please check your source, it must not contains audio files with the same track number.",
          "filesCountMismatch": "The number of audio files present on selected source do not match the number of tracks to encode. Check your source, it must contain numbered audio files in WAV format (01 - xxx.wav, 02 - yyy.wav, etc.)",
          "multipleErrors": "errors",
          "orphanTracks": "Tracks  can't be associated with an audio files. Please check your source, audio file names should match their track number.",
          "singleError": "error",
          "unsupportedFileNames": "Impossible to parse some of your audio files name. Check your source, it must contains numbered audio files name (01 - xxx.wav, 02 - yyy.wav, etc.)",
          "unsupportedFileTypes": "Unsupported file types, your audios must be WAV, FLAC (16 or 24 bits) or AIFF"
        },
        "newVolume": "New Volume",
        "title": "Volume"
      }
    },
    "quit": "Quit",
    "reactivate": "Reactivate",
    "records": {
      "confirm": {
        "delete": {
          "message": "Are you sure you want to delete this audio file?",
          "title": "Delete audio file"
        }
      }
    },
    "releases": {
      "album": {
        "backCatalogue": "Back Catalogue ({originalReleaseDate})",
        "tagTargets": "Tags:",
        "upc": "UPC:"
      },
      "albumStatus": {
        "active": "Active",
        "archived": "Archived",
        "draft": "Draft",
        "reviewing": "Reviewing",
        "submitted": "Submitted"
      },
      "columns": {
        "album": "Album Details",
        "delivery": "Delivery Status",
        "productStatus": "Album Status",
        "release": "Offers"
      },
      "deliveredPlatforms": "Delivered Platforms",
      "deliveryStatus": {
        "completed": "Delivered: ",
        "error": "Error: ",
        "sending": "Sending: ",
        "stuck": "Stuck: ",
        "total": "Total: ",
        "waiting": "Waiting: "
      },
      "empty": {
        "message": "You don't have any new release coming",
        "title": "No releases planned"
      },
      "emptyFilters": {
        "message": "Look likes there’s no release matching. Try with different filters",
        "title": "Uh oh !"
      },
      "filters": {
        "statuses": "Offers Status:",
        "tag": "Filter by Artists, Labels, Genres...",
        "type": {
          "all": "All",
          "backCatalogue": "Back Catalogue",
          "new": "New Releases"
        },
        "types": "Release Type:"
      },
      "idolcampProductLink": "See album on Idolcamp",
      "offerStatuses": {
        "active": "{count, plural, =1 {# active} other {# actives}}",
        "archived": "{count, plural, =1 {# archived} other {# archived}}",
        "draft": "{count, plural, =1 {# draft} other {# draft}}"
      },
      "offersDetailsModal": {
        "title": "Offers details"
      },
      "releasesCount": "{count, plural, =1 {# offer} other {# offers}}",
      "showDetails": "See details",
      "title": "Releases"
    },
    "reload": "Reload",
    "removeAll": "Remove All",
    "request": "Request",
    "resend": "Resend",
    "resetting": "Resetting...",
    "resources": {
      "artist": "Artist",
      "company": "Contract",
      "companyTag": "Contract tags",
      "continent": "Continents",
      "creator": "Creator",
      "distributor": "Distributor",
      "distributorProductSubgenre": "Genre",
      "dsp": "Platforms",
      "dspTag": "DSP Profiles",
      "gender": "Gender",
      "group": "Groups",
      "label": "Label",
      "playlist": "Playlists",
      "product": "Album",
      "productGenre": "Genre",
      "retail": "Retailer",
      "sendTaskFactory": "Bulk Deliveries",
      "source": "Source",
      "tag": "Album tags",
      "tagTarget": "Album tags",
      "territory": "Country",
      "track": "Track",
      "user": "Users",
      "userAgeRange": "User Age Ranges",
      "video": "Video"
    },
    "revoke": "Revoke",
    "rights": {
      "delete": {
        "confirm": {
          "message": "Are you sure you want to delete this distribution rule ?",
          "title": "Delete Distribution Rule"
        }
      },
      "distributor": {
        "title": "Distributor"
      },
      "rules": {
        "empty": "No distribution rule added yet",
        "header": {
          "platforms": "Platforms",
          "rule": "Rule",
          "territories": "Territories"
        },
        "labelComments": "Label Comments:",
        "new": "New Rule",
        "note": {
          "add": "Add a note",
          "edit": "Edit note",
          "view": "See Note"
        },
        "placeholder": {
          "aggregatedDspsPlaceholder": "Add platforms or platforms profiles",
          "aggregatedTerritoriesPlaceholder": "Add territories (continent or country)",
          "comments": "Write a note",
          "except": "Except",
          "exceptHint": "Add an exception"
        },
        "searchMessage": "Type at least 3 characters to search",
        "title": "Distribution Rules",
        "types": {
          "add": "Add",
          "clear": "Remove parent rights",
          "remove": "Remove"
        }
      },
      "territories": {
        "title": "Territory Rights"
      }
    },
    "roles": {
      "aandr": "A&R",
      "aandrAdministration": "A&R Administration",
      "accordion": "Accordion",
      "acousticBaritoneGuitar": "Acoustic Baritone Guitar",
      "acousticBassGuitar": "Acoustic Bass Guitar",
      "acousticFretlessGuitar": "Acoustic Fretless Guitar",
      "acousticGuitar": "Acoustic Guitar",
      "actor": "Actor",
      "agent": "Agent",
      "altoClarinet": "Alto Clarinet",
      "altoFlute": "Alto Flute",
      "altoHorn": "Alto Horn",
      "altoRecorder": "Alto Recorder",
      "altoSaxophone": "Alto Saxophone",
      "altoTrombone": "Alto Trombone",
      "altoViolin": "Alto Violin",
      "altoVocals": "Alto (Vocals)",
      "appalachianDulcimer": "Appalachian Dulcimer",
      "archlute": "Archlute",
      "arranger": "Arranger",
      "artDirection": "Art Direction",
      "artist": "Main Artist",
      "artistManagement": "Artist Management",
      "artwork": "Artwork",
      "assistantConductor": "Assistant Conductor",
      "assistantDirector": "Assistant Director",
      "assistantMasteringEngineer": "Assistant Mastering Engineer",
      "assistantMixer": "Assistant Mixer",
      "assistantMixingEngineer": "Assistant Mixing Engineer",
      "assistantProducer": "Assistant Producer",
      "assistantRecordingEngineer": "Assistant Recording Engineer",
      "assistantSoundEngineer": "Assistant Sound Engineer",
      "author": "Author",
      "autoharp": "Autoharp",
      "backgroundVocals": "Background Vocals",
      "baglama": "Baglama",
      "bagpipes": "Bagpipes",
      "balafon": "Balafon",
      "balalaika": "Balalaika",
      "band": "Band",
      "bandoneon": "Bandoneon",
      "bandurria": "Bandurria",
      "banjo": "Banjo",
      "bansuri": "Bansuri",
      "barbat": "Barbat",
      "baritoneGuitar": "Baritone Guitar",
      "baritoneHorn": "Baritone Horn",
      "baritoneSaxophone": "Baritone Saxophone",
      "baritoneVocals": "Baritone (Vocals)",
      "baroqueGuitar": "Baroque guitar",
      "barrelOrgan": "Barrel Organ",
      "bass": "Bass (Classical Music)",
      "bassClarinet": "Bass Clarinet",
      "bassDrum": "Bass Drum",
      "bassFlute": "Bass Flute",
      "bassGuitar": "Bass Guitar",
      "bassHarmonica": "Bass Harmonica",
      "bassOboe": "Bass Oboe",
      "bassRecorder": "Bass Recorder",
      "bassSaxophone": "Bass Saxophone",
      "bassTrombone": "Bass Trombone",
      "bassTrumpet": "Bass Trumpet",
      "bassVocals": "Bass (Vocals)",
      "bassetClarinet": "Basset Clarinet",
      "bassetHorn": "Basset Horn",
      "bassoon": "Bassoon",
      "bataDrums": "Batá Drums",
      "beatBoxing": "Beat Boxing",
      "bells": "Bells",
      "bendir": "Bendir",
      "berimbau": "Berimbau",
      "biwa": "Biwa",
      "bonang": "Bonang",
      "bongos": "Bongos",
      "bouzouki": "Bouzouki",
      "bugle": "Bugle",
      "buttonAccordion": "Button Accordion",
      "cMelodySax": "C-Melody Sax",
      "cajon": "Cajon",
      "calliope": "Calliope",
      "cameraOperator": "Camera Operator",
      "castanets": "Castanets",
      "cavaquinho": "Cavaquinho",
      "caxixi": "Caxixi",
      "celeste": "Celeste",
      "cello": "Cello",
      "chamberMusicEnsemble": "Chamber Music Ensemble",
      "chamberOrgan": "Chamber Organ",
      "chamberlin": "Chamberlin",
      "chantVocals": "Chant Vocals",
      "chapmanStick": "Chapman Stick",
      "charango": "Charango",
      "chekere": "Chekere",
      "chimes": "Chimes",
      "choir": "Choir",
      "choirConductor": "Choir Conductor",
      "choirMaster": "Choir Master",
      "chorus": "Chorus",
      "chorusMaster": "Chorus Master",
      "chromaticHarmonica": "Chromatic Harmonica",
      "cimbalom": "Cimbalom",
      "cinemaphotographer": "Cinemaphotographer",
      "cittern": "Cittern",
      "clarinet": "Clarinet",
      "classicalGuitar": "Classical Guitar",
      "claves": "Claves",
      "clavichord": "Clavichord",
      "clavinet": "Clavinet",
      "clean": "Clean",
      "coProducer": "Co-Producer",
      "composer": "Composer",
      "concertHarp": "Concert Harp",
      "concertina": "Concertina",
      "conchShells": "Conch Shells",
      "conductor": "Conductor",
      "confirm": {
        "applyOnAllTracks": {
          "message": "Are you sure you want to apply this role on all created tracks ?",
          "title": "Duplicate the role"
        },
        "remove": {
          "message": "Are you sure you want to delete this role ?",
          "title": "Remove a role"
        }
      },
      "congas": "Congas",
      "contrabass": "Contrabass",
      "contrabassClarinet": "Contrabass Clarinet",
      "contrabassFlute": "Contrabass Flute",
      "contrabassRecorder": "Contrabass Recorder",
      "contrabassSarrusophone": "Contrabass Sarrusophone",
      "contrabassSaxophone": "Contrabass Saxophone",
      "contrabassTrombone": "Contrabass Trombone",
      "contrabassoon": "Contrabassoon",
      "contraltoClarinet": "Contralto Clarinet",
      "contraltoVocals": "Contralto Vocals",
      "contributor": "Contributor",
      "copyrightLine": "© Copyright",
      "cornet": "Cornet",
      "costumeDesign": "Costume Design",
      "counterTenor": "Counter Tenor (Vocals)",
      "coverArt": "Cover Art",
      "coverDesign": "Cover Design",
      "cowbell": "Cowbell",
      "creativeDirector": "Creative Director",
      "crotales": "Crotales",
      "crumhorn": "Crumhorn",
      "cuatro": "Cuatro",
      "cuesnophone": "Cuesnophone",
      "curtal": "Curtal",
      "cymbals": "Cymbals",
      "daf": "Daf",
      "dance": "Dance",
      "dancer": "Dancer",
      "design": "Design",
      "dhol": "Dhol",
      "diddleyBow": "Diddley Bow",
      "didgeridoo": "Didgeridoo",
      "director": "Director",
      "dj": "DJ",
      "djembe": "Djembe",
      "dobroGuitar": "Dobro Guitar",
      "doubleBass": "Double Bass",
      "doumbek": "Doumbek",
      "drumMachine": "Drum Machine",
      "drumProgramming": "Drum Programming",
      "drums": "Drums",
      "duduk": "Duduk",
      "dulcimer": "Dulcimer",
      "eBow": "E-Bow",
      "editor": "Editor",
      "effects": "Effects",
      "eightStringGuitar": "8-String Guitar",
      "electricBassGuitar": "Electric Bass Guitar",
      "electricCello": "Electric Cello",
      "electricFretlessGuitar": "Electric Fretless Guitar",
      "electricGuitar": "Electric Guitar",
      "electricPiano": "Electric Piano",
      "electricSitar": "Electric Sitar",
      "electricUprightBass": "Electric Upright Bass",
      "electricViola": "Electric Viola",
      "electricViolin": "Electric Violin",
      "electronicValveInstrument": "Electronic Valve Instrument",
      "electronicWindInstrument": "Electronic Wind Instrument",
      "engineer": "Engineer",
      "englishHorn": "English Horn",
      "ensemble": "Ensemble",
      "euphonium": "Euphonium",
      "executiveProducer": "Executive Producer",
      "farfisa": "Farfisa",
      "featuring": "Featured Artist",
      "fenderRhodes": "Fender Rhodes",
      "fiddle": "Fiddle",
      "fife": "Fife",
      "fingerCymbals": "Finger Cymbals",
      "fingerSnaps": "Finger Snaps",
      "firstCello": "First Cello",
      "firstViolin": "First Violin",
      "flamencoGuitar": "Flamenco Guitar",
      "flugelhorn": "Flugelhorn",
      "flute": "Flute",
      "forCompanyId": "Contract",
      "fortepiano": "Fortepiano",
      "fourStringGuitar": "4-String Guitar",
      "frameDrum": "Frame Drum",
      "frenchHorn": "French Horn",
      "fretlessElectricBassGuitar": "Fretless Electric Bass Guitar",
      "gaffer": "Gaffer",
      "gender": "Gendèr",
      "ghatam": "Ghatam",
      "gitern": "Gitern",
      "glockenspiel": "Glockenspiel",
      "gong": "Gong",
      "graphicDesigner": "Graphic Designer",
      "group": "Group",
      "guestVocals": "Guest Vocals",
      "guiro": "Güiro",
      "guitar": "Guitar",
      "guitarTechnician": "Guitar Technician",
      "guitars": "Guitars",
      "gutStringGuitar": "Gut-String Guitar",
      "haghouge": "Hag'houge",
      "hammeredDulcimer": "Hammered Dulcimer",
      "hammondOrgan": "Hammond Organ",
      "handclaps": "Handclaps",
      "hardingfele": "Hardingfele",
      "harmonica": "Harmonica",
      "harmonium": "Harmonium",
      "harmonyVocals": "Harmony Vocals",
      "harp": "Harp",
      "harpsichord": "Harpsichord",
      "horn": "Horn",
      "hornSection": "Horn Section",
      "horns": "Horns",
      "hurdyGurdy": "Hurdy Gurdy",
      "illustration": "Illustration",
      "kalimba": "Kalimba",
      "karkabaMetalCastanets": "Karkaba (Metal Castanets)",
      "kazoo": "Kazoo",
      "kendang": "Kendang",
      "kenongKethuk": "Kenong/Kethuk",
      "keyGrip": "Key Grip",
      "keyboardBass": "Keyboard Bass",
      "keyboards": "Keyboards",
      "keytar": "Keytar",
      "kora": "Kora",
      "koto": "Koto",
      "lapSteelGuitar": "Lap Steel Guitar",
      "leadGuitar": "Lead Guitar",
      "leadViolin": "Lead Violin",
      "leadVocals": "Lead Vocals",
      "librettist": "Librettist",
      "lightingDirector": "Lighting Director",
      "linerNotes": "Liner Notes",
      "lowWhistle": "Low Whistle",
      "lute": "Lute",
      "lyricist": "Lyricist",
      "lyricon": "Lyricon",
      "lyrics": "Lyrics",
      "mandocello": "Mandocello",
      "mandola": "Mandola",
      "mandolin": "Mandolin",
      "manzello": "Manzello",
      "maracas": "Maracas",
      "marimba": "Marimba",
      "masteringEngineer": "Mastering Engineer",
      "mc": "MC",
      "mellophone": "Mellophone",
      "mellophonium": "Mellophonium",
      "mellotron": "Mellotron",
      "melodica": "Melodica",
      "mezzoSopranoVocals": "Mezzo-soprano (Vocals)",
      "mixingEngineer": "Mixing Engineer",
      "modularSynth": "Modular Synth",
      "motionDesigner": "Motion Designer",
      "mouthOrgan": "Mouth Organ",
      "mridangam": "Mridangam",
      "musette": "Musette",
      "musicDirector": "Music Director",
      "musicalEnsemble": "Musical Ensemble",
      "name": "Album Title",
      "narrator": "Narrator",
      "ney": "Ney",
      "noises": "Noises",
      "nylonStringGuitar": "Nylon-String Guitar",
      "oboe": "Oboe",
      "oboeDamore": "Oboe D'Amore",
      "ocarina": "Ocarina",
      "omnichord": "Omnichord",
      "ondesMartenot": "Ondes Martenot",
      "orchestra": "Orchestra",
      "organ": "Organ",
      "oud": "Oud",
      "packageDesign": "Package Design",
      "panFlute": "Pan Flute",
      "panpipes": "Panpipes",
      "parlourGuitar": "Parlour Guitar",
      "pedalSteelGuitar": "Pedal Steel Guitar",
      "peking": "Peking",
      "pennyWhistle": "Penny Whistle",
      "percussion": "Percussion",
      "performer": "Performer",
      "photographer": "Photographer",
      "piano": "Piano",
      "piccolo": "Piccolo",
      "piccoloBass": "Piccolo Bass",
      "piccoloOboe": "Piccolo Oboe",
      "piccoloTrumpet": "Piccolo Trumpet",
      "pikasaGuitar": "Pikasa Guitar",
      "pipeOrgan": "Pipe Organ",
      "portugueseGuitar": "Portuguese Guitar",
      "postProduction": "Post-Production",
      "preparedPiano": "Prepared Piano",
      "producer": "Producer",
      "productManager": "Product Manager",
      "productionAssistant": "Production Assistant",
      "productionCoordinator": "Production Coordinator",
      "productionLine": "℗ Production",
      "productionYear": "Production Year",
      "programmer": "Programmer",
      "programmingEngineer": "Programming Engineer",
      "projectCoordinator": "Project Coordinator",
      "projectManager": "Project Manager",
      "psaltery": "Psaltery",
      "publisher": "Publisher",
      "rap": "Rap",
      "rebab": "Rebab",
      "rebec": "Rebec",
      "recorder": "Recorder",
      "recordingEngineer": "Recording Engineer",
      "reedOrgan": "Reed Organ",
      "remixer": "Remixer",
      "requintoGuitar": "Requinto Guitar",
      "rhodesPiano": "Rhodes Piano",
      "rhythmGuitar": "Rhytm Guitar",
      "sackbut": "Sackbut",
      "sampler": "Sampler",
      "santoor": "Santoor",
      "santur": "Santur",
      "sarangi": "Sarangi",
      "sarod": "Sarod",
      "saron": "Saron",
      "sarrusophone": "Sarrusophone",
      "saxello": "Saxello",
      "saxophone": "Saxophone",
      "saz": "Saz",
      "secondCello": "Second Cello",
      "secondSopranoVocals": "Second Soprano Vocals",
      "secondViolin": "Second violon",
      "serpent": "Serpent",
      "setDesigner": "Set Designer",
      "sevenStringGuitar": "7-String Guitar",
      "shakers": "Shakers",
      "shakuhachi": "Shakuhachi",
      "shawm": "Shawm",
      "shekere": "Shekere",
      "shenai": "Shenai",
      "sitar": "Sitar",
      "sixStringAcousticGuitar": "6-String Acoustic Guitar",
      "sixStringGuitar": "6-String Guitar",
      "sleighBells": "Sleigh Bells",
      "slenthem": "Slenthem",
      "slideGuitar": "Slide Guitar",
      "slideSaxophone": "Slide Saxophone",
      "slideTrumpet": "Slide Trumpet",
      "slideWhistle": "Slide Whistle",
      "snareDrum": "Snare Drum",
      "soloFlute": "Solo Flute",
      "soloGuitar": "Solo Guitar",
      "soloist": "Solo",
      "songwriter": "Songwriter",
      "sopraninoClarinet": "Sopranino Clarinet",
      "sopraninoSaxophone": "Sopranino Saxophone",
      "soprano": "Soprano",
      "sopranoClarinet": "Soprano Clarinet",
      "sopranoFlute": "Soprano Flute",
      "sopranoRecorder": "Soprano Recorder",
      "sopranoSaxophone": "Soprano Saxophone",
      "sopranoSolo": "Soprano",
      "sopranoViolin": "Soprano Violin",
      "sopranoVocals": "Soprano Vocals",
      "soundEditor": "Sound Editor",
      "soundEngineer": "Sound Engineer",
      "sousaphone": "Sousaphone",
      "spanishGuitar": "Spanish Guitar",
      "spinet": "Spinet",
      "spokenWord": "Spoken word",
      "spoons": "Spoons",
      "steelDrums": "Steel Drums",
      "steelGuitar": "Steel Guitar",
      "stringArranger": "String Arranger",
      "strings": "Strings",
      "stringsConductor": "Strings Conductor",
      "stritch": "Stritch",
      "subtitle": "Album Version",
      "surdo": "Surdo",
      "synclavier": "Synclavier",
      "synthesizer": "Synthesizer",
      "tabla": "Tabla",
      "taborPipe": "Tabor Pipe",
      "tackPiano": "Tack Piano",
      "talkbox": "Talkbox",
      "talkingDrum": "Talking Drum",
      "tamborim": "Tamborim",
      "tamboura": "Tamboura",
      "tambourine": "Tambourine",
      "tanpura": "Tanpura",
      "tarogato": "Tarogato",
      "templeBlocks": "Temple Blocks",
      "tenorBanjo": "Tenor Banjo",
      "tenorDrum": "Tenor Drum",
      "tenorGuitar": "Tenor Guitar",
      "tenorHorn": "Tenor Horn",
      "tenorRecorder": "Tenor Recorder",
      "tenorSaxophone": "Tenor Saxophone",
      "tenorSolo": "Tenor",
      "tenorTrombone": "Tenor Trombone",
      "tenorViolin": "Tenor Violin",
      "tenorVocals": "Tenor Vocals",
      "thavil": "Thavil",
      "theorbo": "Theorbo",
      "theremin": "Theremin",
      "timbales": "Timbales",
      "timpani": "Timpani",
      "tinWhistle": "Tin Whistle",
      "tonmeister": "Tonmeister",
      "toyPiano": "Toy Piano",
      "transcriber": "Transcriber",
      "tres": "Tres",
      "triangle": "Triangle",
      "trombone": "Trombone",
      "trombonium": "Trombonium",
      "trumpet": "Trumpet",
      "tuba": "Tuba",
      "tubularBells": "Tubular Bells",
      "turntable": "Turntable",
      "twelveStringAcousticGuitar": "12-String Acoustic Guitar",
      "twelveStringElectricGuitar": "12-String Electric Guitar",
      "twelveStringGuitar": "12-String Guitar",
      "udu": "Udu",
      "uilleannPipes": "Uilleann Pipes",
      "ukulele": "Ukulele",
      "uprightBass": "Upright Bass",
      "uprightPiano": "Upright Piano",
      "valveTrombone": "Valve Trombone",
      "veena": "Veena",
      "vibraphone": "Vibraphone",
      "vibraslap": "Vibraslap",
      "videoDirector": "Video Director",
      "videoEditor": "Video Editor",
      "videoProducer": "Video Producer",
      "vielle": "Vielle",
      "viol": "Viol",
      "viola": "Viola",
      "violaDaGamba": "Viola Da Gamba",
      "violin": "Violin",
      "violoncello": "Violoncello",
      "visualEffects": "Visual Effects",
      "vocalAdaptation": "Vocal Adaptation",
      "vocalEngineer": "Vocal Engineer",
      "vocalSolo": "Vocal solo",
      "vocals": "Vocals",
      "vocoder": "Vocoder",
      "washboard": "Washboard",
      "whistle": "Whistle",
      "woodBlock": "Wood Block",
      "woodFlute": "Wood Flute",
      "work": "Work",
      "wurlitzer": "Wurlitzer",
      "xylophone": "Xylophone",
      "zarb": "Zarb",
      "zither": "Zither"
    },
    "roster": {
      "labels": {
        "create": {
          "contract": "Associated contract",
          "name": "Name",
          "title": "Create a label",
          "usages": "Usages",
          "usagesHint": "If you change an usage setting at label level, it will only affect your new created albums"
        },
        "edit": {
          "title": "Update a label"
        },
        "failedCreate": "We were unable to create this label",
        "failedExtract": "An error occurred. The labels extract cannot be requested",
        "failedUpdate": "We were unable to update this label",
        "successfulCreate": "Label successfully created",
        "successfulExtract": "Labels extract has been successfully requested, you will get it by email in a few moments.",
        "successfulUpdate": "Label successfully updated",
        "toolbar": {
          "extract": "Extract Labels",
          "new": "New Label"
        }
      }
    },
    "save": "Save",
    "saveAndQuit": "Save and Quit",
    "saving": "Saving...",
    "schedule": "Schedule",
    "search": {
      "columns": {
        "artist": "Artists",
        "company": "Contracts",
        "label": "Labels",
        "product": "Albums",
        "track": "Tracks",
        "video": "Videos"
      },
      "defaultFilters": {
        "artist": "artist",
        "company": "contract",
        "distributorProductSubgenre": "genre",
        "label": "label",
        "product": "album",
        "role": "role",
        "tagTarget": "tag",
        "track": "track",
        "video": "video"
      },
      "existingTrack": {
        "placeholder": "Search by Title, ISRC, UPC..."
      },
      "noResults": {
        "subtitle": "It seems we can’t find any results based on your search.",
        "title": "No results found"
      },
      "placeholder": "Search for anything, or use any combination of filters below",
      "resultTitle": "Results",
      "results": "{count, plural, =1 {# result} other {# results}}",
      "searching": "Searching...",
      "sidebar": {
        "cleanRecents": "Clean recents searches",
        "filters": "Filters",
        "less": "less...",
        "more": "more...",
        "recents": "Recents"
      },
      "tabs": {
        "artist": "Artists",
        "company": "Contracts",
        "label": "Labels",
        "playlist": "Playlists",
        "product": "Albums",
        "track": "Tracks",
        "video": "Videos"
      },
      "title": "Search"
    },
    "searchMessage": "Type at least 3 characters to search a filter",
    "seeDetails": "See Details",
    "seeList": "See list",
    "seeLogs": "See logs",
    "seeMore": "See More",
    "selectAll": "Select All",
    "send": "Send",
    "sendTaskLocks": {
      "table": {
        "message": "Message",
        "object": "Object",
        "objects": {
          "dsp": "Dsp",
          "offer": "Offer",
          "product": "Product",
          "right": "Right",
          "send_task": "Delivery"
        },
        "rank": "",
        "type": "Type",
        "types": {
          "permanent": "Permanent",
          "temporary": "Temporary"
        }
      }
    },
    "sendTaskStates": {
      "table": {
        "createdAt": "Created at",
        "expiresAt": "Expire at",
        "lockCount": "Block(s) count",
        "ready": "Ready"
      }
    },
    "sendTasks": {
      "back": "Deliveries Index",
      "confirmLeaving": "There is ongoing deliveries operations. Are you sure you want to leave ?",
      "deliverable": "Validate",
      "delivery": "Delivery",
      "deliveryTypes": {
        "delete": "Delete",
        "delivery": "Delivery",
        "redelivery": "Redelivery",
        "update": "Update"
      },
      "distribution": {
        "back": "Deliveries Index",
        "nav": {
          "collection": "All Deliveries",
          "sendTaskFactories": "Bulk Deliveries"
        },
        "new": {
          "content": "Content",
          "contentSubtitle": "You can deliver your entire catalog, a contract, a label one or a specified albums list.",
          "delete": "Delete",
          "deliverySetting": "Settings",
          "duplicate": "Clone",
          "note": "Add a note",
          "options": "Options",
          "optionsSubtitle": "If a release date/price is filled, a new offer will be automatically created for each relevant platform to replace the existing album release date/price."
        },
        "title": "New Bulk Deliveries"
      },
      "edit": {
        "title": "{dsp} Delivery"
      },
      "errors": {
        "showDetails": "Show details",
        "title": "Error Logs"
      },
      "filters": {
        "status": "Status:",
        "statuses": "Statuses",
        "tags": "Filter by platforms, albums, labels, contracts...",
        "types": "Type:",
        "validation": "Validation:"
      },
      "form": {
        "addRights": "Add platform’s rights on selected albums",
        "allReleases": "Deliver an entire catalog?",
        "comment": "Note",
        "dspUploadIdentifications": "Platforms",
        "name": "Name",
        "note": {
          "title": "Add a note"
        },
        "priceCode": "Price",
        "priority": "Priority",
        "priorityHint": "If set as Urgent, this delivery will precede other pending album deliveries and be processed  as soon as possible, depending on available resources and platform's servers availability.",
        "releaseDate": "Release Date",
        "releases": {
          "company": "Contract",
          "distributor": "Distributor",
          "label": "Label",
          "searchMessage": "Type to search for a Distributor, Contract or Label"
        },
        "removeRights": "Remove platform’s rights on selected albums",
        "schedule": {
          "title": "Schedule Bulk Deliveries"
        },
        "sendDate": "Delivery Start Date",
        "submit": {
          "message": "Bulk Deliveries are validated by default at creation. Are you sure you want to create these deliveries?",
          "title": "Create Bulk Deliveries"
        },
        "type": "Type",
        "upc": "Paste album UPC(s) here",
        "waitingIssue": "Waiting Status Issue"
      },
      "names": {
        "target": "{deliveryType} of {target}",
        "upcs": "{deliveryType} of {count} albums"
      },
      "new": {
        "options": {
          "title": "Options"
        },
        "platforms": {
          "filters": {
            "all": "All",
            "notSent": "Not Sent",
            "offline": "Offline",
            "online": "Online",
            "sent": "Sent",
            "withRights": "With Rights",
            "withoutRights": "Without Rights"
          },
          "notSent": "Not Sent",
          "rights": "Has Rights",
          "sent": "Sent",
          "status": "Platform Status",
          "title": "Platforms"
        },
        "title": "New Deliveries",
        "type": {
          "deleteHint": "For a takedown from the platform",
          "deliveryHint": "For a first delivery",
          "redeliveryHint": "For an update regarding assets (cover, booklet, audios, videos)",
          "title": "Type",
          "updateHint": "For an update regarding metadata only"
        }
      },
      "newDelivery": "New Delivery",
      "notifications": {
        "delete": {
          "failed": "We were unable to delete this delivery",
          "success": "Delivery successfully deleted"
        },
        "devalidate": {
          "failed": "We were unable to update this delivery",
          "success": "Delivery successfully updated"
        },
        "extract": {
          "failed": "An error occurred. The Deliveries extract cannot be requested",
          "success": "Deliveries extract has been successfully requested, you will get it by email in a few moments."
        },
        "reset": {
          "failed": "We were unable to reset this delivery",
          "success": "Delivery successfully reseted"
        },
        "sendTask": {
          "failed": "We were unable to save this delivery",
          "success": "Delivery successfully saved"
        },
        "sendTaskFactory": {
          "failed": "We were unable to create these deliveries",
          "success": "Deliveries successfully created"
        },
        "sendTasksFactory": {
          "delete": {
            "failed": "We were unable to delete these deliveries",
            "success": "Deliveries successfully deleted"
          }
        },
        "update": {
          "failed": "We were unable to update this delivery",
          "success": "Delivery successfully updated"
        },
        "validate": {
          "failed": "We were unable to update this delivery",
          "success": "Delivery successfully updated"
        }
      },
      "priority": {
        "normal": "Normal",
        "now": "Now",
        "urgent": "Urgent"
      },
      "selectAllPlatforms": "Add All Platforms",
      "sendTaskStates": "Attempts",
      "show": {
        "actions": {
          "clone": "Clone",
          "comment": {
            "button": "Note",
            "noNote": "No note to display",
            "seeNote": "See note",
            "title": "Add a Note"
          },
          "delete": {
            "message": "Are you sure you want to delete this bulk delivery?",
            "title": "Delete this Bulk Delivery",
            "tooltip": "Delete",
            "tooltipNotDeletable": "Bulk delivery delete disabled because there is at least one sending delivery"
          }
        },
        "batch": "Internal Batch ID:",
        "bulkLogs": {
          "message": "We successfully created {count} deliveries. See why we didn't manage to create all deliveries below:",
          "title": "Bulk Logs"
        },
        "content": "Content",
        "contentTable": "Content:",
        "createdBy": "Created By:",
        "createdOn": "Created On: ",
        "deliveries": "Deliveries",
        "deliveriesDetails": "Deliveries Details",
        "delivreyPath": "Delivery Path:",
        "offer": {
          "genre": "Genre:",
          "preorderDate": "Preorder date:",
          "preview": "Preview Before Release:",
          "previewBoolean": {
            "false": "no",
            "true": "Yes"
          },
          "price": "Price:",
          "releaseDate": "Release date:",
          "secondaryGenre": "Secondary Genre:",
          "territories": "Territories:",
          "title": "Distribution Information",
          "trackPrice": "Tracks Price:"
        },
        "options": "Content & Options",
        "packagedBy": "Packaged by:",
        "platforms": "Platforms:",
        "priceCode": "Price: ",
        "priority": "Priority:",
        "releaseDate": "Release Date: ",
        "scheduling": "Scheduled on:",
        "sentBy": "Sent by:",
        "settings": "Settings",
        "status": "Status",
        "statusComplete": "Sent on",
        "statusError": "Error on",
        "technical": "Technical Information",
        "title": "{dsp} Delivery",
        "type": "Type:",
        "upcs": {
          "title": "Bulk Albums List"
        }
      },
      "status": {
        "completed": "Delivered",
        "error": "Error",
        "packaged": "Packaged",
        "packaging": "Packaging",
        "sending": "Sending",
        "sent": "Sent",
        "stuck": "Stuck",
        "waiting": "Waiting"
      },
      "table": {
        "action": {
          "delete": {
            "message": "Are you sure you want to delete these deliveries?",
            "name": "Delete Deliveries",
            "title": "Delete Deliveries"
          },
          "devalidate": {
            "message": "Are you sure you want to unvalidate these deliveries?",
            "name": "Unvalidate Deliveries",
            "title": "Unvalidate Deliveries"
          },
          "validate": {
            "message": "Are you sure you want to validate these deliveries?",
            "name": "Validate Deliveries",
            "title": "Validate Deliveries"
          }
        },
        "actions": "",
        "album": "Album",
        "clone": "Clone",
        "closedPlatform": "Closed Platform",
        "content": {
          "company": "Contract",
          "distributor": "Distributor",
          "label": "Label",
          "title": "Content"
        },
        "delete": "Delete",
        "deliverable": "Validation",
        "deliveries": "Deliveries",
        "edit": "Edit",
        "name": "Name",
        "noDeliveries": "No deliveries created yet",
        "now": "Now",
        "options": "Options",
        "platform": "Platforms",
        "priceCode": "Price: ",
        "releaseDate": "Release Date: ",
        "reset": "Reset",
        "schedule": "Scheduled delivery on",
        "see": "See",
        "status": "Status",
        "statusComplete": "Sent on",
        "statusError": "Error on",
        "type": "Type",
        "urgent": "Urgent"
      },
      "title": "Deliveries",
      "toolbar": {
        "metadata": "Download as {format}",
        "new": "New Bulk Deliveries",
        "reset": "Reset this delivery"
      },
      "type": {
        "delete": "Delete",
        "delivery": "Delivery",
        "redelivery": "Redelivery",
        "update": "Update"
      },
      "validation": {
        "all": "All",
        "not-validated": "Not-Validated",
        "validated": "Validated"
      }
    },
    "showLess": "Show Less",
    "showMore": "Show More",
    "sidebar": {
      "collapse": "Collapse Menu",
      "expand": "Expand Menu"
    },
    "signin": {
      "error": {
        "inactiveUser": "Your account is inactive and you're therefore not allowed to sign in with it anymore",
        "invalidGrant": "Either your username or password is invalid"
      },
      "form": {
        "passwordForgotten": "Password forgotten ?",
        "pending": "Signing in...",
        "placeholder": {
          "password": "Password",
          "user": "Username or Email"
        },
        "signin": "Sign in"
      },
      "title": "Sign In"
    },
    "signingin": "Signing In...",
    "signup": "Sign Up",
    "submissions": {
      "actions": {
        "approved": "Approve",
        "rejected": "Reject"
      },
      "confirm": {
        "approve": {
          "message": "This will set album status to Active. Are you sure to approve this submission?",
          "title": "Approve the submission"
        },
        "reject": {
          "message": "This will set album statuses to Draft and the submitter will be able to edit it again. Are you sure to reject these submissions ?",
          "title": "Reject the submission"
        }
      },
      "empty": {
        "message": "There are no ongoing submissions",
        "title": "It's all good !"
      },
      "emptyFilters": {
        "message": "Look likes there’s no submission matching. Try with different filters",
        "title": "Uh oh !"
      },
      "filters": {
        "priorities": "Priorities:",
        "statuses": "Submission statuses:",
        "tag": "Filter by Artists, Labels, Users...",
        "type": {
          "all": "All",
          "backCatalogue": "Back Catalogue",
          "new": "New Releases"
        },
        "types": "Release Type:",
        "update": "Update Results"
      },
      "innerNavigation": {
        "pending": "Pending",
        "rejected": "Rejected"
      },
      "lastComment": {
        "follow": "Follow up?",
        "sent": "Sent"
      },
      "noAssignee": "No assignee",
      "priorities": {
        "high": "High",
        "low": "Low",
        "normal": "Normal",
        "urgent": "Urgent"
      },
      "statuses": {
        "approved": "Approved",
        "doing": "Doing",
        "incoming": "New",
        "ready": "Ready",
        "rejected": "Rejected",
        "standby": "Stand-by",
        "waitingForCustomer": "Waiting for Customer"
      },
      "table": {
        "action": "Action",
        "addTag": "+ Add a tag",
        "album": "Album",
        "assignee": "Assignee",
        "dueDate": "Due date",
        "dueDateTooltip": "The Due Date is the Release Date or the Preorder Date, if applicable",
        "priority": "Priority",
        "productStatus": "Album status",
        "rejectionDate": "Rejected on",
        "status": "Status",
        "submissionDate": "Submitted on"
      },
      "timeline": {
        "count": "{count, plural, =1 {# submission} other {# submissions}}",
        "countExisting": "{count} more existing",
        "rejectedOn": "Rejected on {date}",
        "submittedOn": " submitted on {date}"
      },
      "title": "Submissions"
    },
    "successCopy": "{what} successfully copied to clipboard",
    "switchContracts": {
      "form": {
        "company": {
          "label": "New Contract",
          "placeholder": "Contract to be switched to"
        },
        "startAt": {
          "label": "Start Date",
          "placeholder": "New contract's start date"
        },
        "submit": {
          "label": "Switch to New Contract"
        }
      },
      "history": {
        "company": "Contract",
        "startAt": "Start Date"
      },
      "nav": {
        "history": "History",
        "switch": "Switch"
      },
      "notifications": {
        "error": "Unable to switch to selected contract, please try again",
        "success": "Successfully switched to new contract"
      },
      "title": "Contract",
      "tooltip": "Switch Contract"
    },
    "tags": {
      "searchMessage": "Type to add a tag"
    },
    "tracks": {
      "audio": {
        "16Bits": "16-bit",
        "24Bits": "24-bit",
        "bitRate": "Bit rate",
        "channels": "Channels",
        "dolbyAtmos": "Dolby Atmos",
        "duration": "Duration",
        "fileSize": "Size",
        "sampleRate": "Sample Rate"
      },
      "button": {
        "addAudio": "Upload audio",
        "addExistingTrack": "Track From my Catalog",
        "addNewAudioTrack": "Audio Track",
        "addNewVideoTrack": "Video Track",
        "addNewVolume": "Add Volume",
        "addTrack": "Add",
        "addVideo": "Importer une vidéo",
        "applyThisTitle": "Apply this title",
        "batchUpload": "Batch Upload",
        "cancel": "Cancel",
        "delete": "Delete",
        "deleting": "Deleting",
        "done": "Done",
        "generateIsrc": "Generate ISRC",
        "here": "here",
        "isrcConflict": "ISRC conflict",
        "iswcConflict": "ISWC conflict",
        "move": "Move",
        "moveTo": "Move to",
        "moving": "Moving",
        "next": "Next Track",
        "pause": "Pause",
        "play": "Play",
        "previous": "Previous Track",
        "replace": "Replace",
        "reset": "Reset",
        "retry": "Retry"
      },
      "column": {
        "album": "Album",
        "artist": "Main Artist",
        "assets": "Assets",
        "audio": "Audio",
        "featuring": "Featuring",
        "isrcCode": "ISRC",
        "remixer": "Remixer",
        "title": "Title",
        "trackTitle": "Track Title",
        "upcCode": "UPC"
      },
      "confirm": {
        "cancel": {
          "message": "Are you sure to cancel this upload ?",
          "title": "Cancel upload"
        },
        "discard": {
          "message": "There are unsaved changes.\r\nAre you sure you want to discard them?",
          "title": "Discard changes"
        },
        "remove": {
          "message": "Are you sure you want to delete the selected track ?",
          "title": "Delete Track"
        }
      },
      "contextMenu": {
        "selectedTrack": "track selected",
        "selectedTracks": "tracks selected",
        "volumeSelected": "Volume selected"
      },
      "drawer": {
        "header": {
          "existingTrack": "From my catalog",
          "newVideo": "New Video",
          "track": "Track n°{number}"
        },
        "nav": {
          "assets": "Assets",
          "metadata": "Metadata"
        }
      },
      "form": {
        "artists": {
          "appliedOnAllTracks": "The {role} role is already applied on all tracks",
          "applyOnAllTracks": "Apply {role} on all tracks",
          "contributors": "Contributors",
          "title": "Artists"
        },
        "audio": {
          "immersiveAudio": "Immersive Audio",
          "stereoAudio": "Stereo Audio"
        },
        "audioLanguage": "Audio Language",
        "distribution": {
          "genre": "Genre",
          "previewEnd": "Preview End Time",
          "previewStart": "Preview Start Time",
          "priceCode": "Price",
          "title": "Distribution",
          "trackVideo": "This a video track",
          "usages": "Usages",
          "video": "Video"
        },
        "generateIsrc": "Generate ISRC",
        "generatingIsrc": "Generating ISRC...",
        "information": {
          "title": "Information"
        },
        "isrcCode": "ISRC",
        "iswcCode": "ISWC",
        "lyrics": {
          "clean": "Clean",
          "explicitContent": "Explicit Content",
          "parentalAdvisory": "Parental Advisory",
          "placeholder": "Example: \n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n\n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n",
          "title": "Lyrics",
          "ttmlLyrics": "TTML Lyrics"
        },
        "productionLine": "Production",
        "publisher": "Publisher",
        "secondaryISRC": "Secondary ISRC (optional)",
        "subtitle": "Title Version (optional)",
        "title": "Title",
        "video": {
          "click": "Click",
          "newVideo": "to create a new video.",
          "subtitle": "Associate a video to this audio track"
        },
        "work": "Work"
      },
      "helpPopover": {
        "batchUpload": {
          "intro": "Your audio files should be numbered in the correct order to be validated during the import.",
          "linkDetails": "about importing audios.",
          "readMore": "Read more",
          "readMoreLink": "https://help.labelcamp.io/submitting-content/albums/index/edit-a-draft-release/tracks-and-audio/import-audio#batch-upload",
          "title": "Batch Upload"
        },
        "buttonHide": "Do not show again",
        "examples": "Examples:"
      },
      "hint": {
        "applyThisTitle": "The title will be applied to all track in conflict",
        "contributors": "Add all contributors that have participated to this particular track. (e.g. Featured Artists, Instrumentalists, Vocalists, Producer, Remixer, etc.)",
        "subtitle": "Leave blank unless you need to differentiate from the original track title (e.g. Live, Radio Edit, Bonus Track, etc)"
      },
      "message": {
        "error": {
          "network": "Something went wrong. Please try again."
        },
        "isrcConflict": {
          "message": "The ISRC {isrcCode} already exists in other(s) product(s)",
          "title": "ISRC Conflict",
          "track": "Selected track",
          "trackConflict": "Track(s) in conflict ({count, number})"
        },
        "step": {
          "processing": "Processing. Please wait. ",
          "uploading": "Uploading"
        }
      },
      "search": {
        "title": "Start your search"
      },
      "title": "Track",
      "trackTypes": {
        "audio": "Audio",
        "video": "Video"
      },
      "video": {
        "form": {
          "cropBottom": "Bottom",
          "cropLeft": "Left",
          "cropRight": "Right",
          "cropTop": "Top",
          "isrcAudio": "Audio ISRC",
          "isrcVideo": "Video ISRC",
          "label": "Label",
          "name": "Title",
          "upcCode": "UPC"
        },
        "title": "Video Track"
      },
      "videoLink": {
        "columns": {
          "dsp": "Linked Platforms",
          "name": "Name",
          "retailHint": "Allows to link a selected Video file to one or several Platforms specifically. Leave blank to allow delivery of the Video to any Platforms without restriction."
        },
        "create": "add a new one",
        "description": "Search for a video in Labelcamp to associate it with this track or",
        "dspSearchPlaceholder": "Type to add a Platforms",
        "newVideo": "New Video",
        "saved": "Saved",
        "search": "Start your search",
        "searchPlaceholder": "Search a video by name or ID",
        "title": "Associate a video"
      }
    },
    "update": "Update",
    "updating": "Updating...",
    "uploadCenter": {
      "header": "Upload Center",
      "notifications": {
        "error": "An error occured while processing {name} {type, select, volume {volume} track {track} other {}}",
        "success": "Successfully {status, select, uploaded {uploaded} completed {processed} other {}} {name} {type, select, volume {volume} track {track} other {}}"
      },
      "placeholder": {
        "empty": "No Upload"
      },
      "track": {
        "label": "Track",
        "title": "Track Title"
      }
    },
    "usages": {
      "copyScan": "Copy Scan",
      "download": "Download",
      "nonInteractiveStream": "Radio",
      "onDemandStream": "Streaming"
    },
    "users": {
      "customisation": {
        "accentColor": "Main color",
        "accentColorHint": "This color will be the principal color of the app. It will be used for links, buttons and navigation elements",
        "emailAddress": "Default from address",
        "emailFooter": "Footer informations (You can insert html tags to customize it)",
        "emails": "Emails",
        "favicon": {
          "format": "Format .png or .jpeg",
          "hint": "Click on the drag area or drag & drop to upload an image",
          "size": "Should be at a size of 32 x 32px",
          "title": "Favicon"
        },
        "logo": {
          "format": "Format .svg",
          "hint": "Click on the drag area or drag & drop to upload an image",
          "margin": "Minimum 2px margin",
          "ratio": "Rectangular ratio (2:1)",
          "size": "Recommended size 160 x 60px",
          "title": "Logo"
        },
        "successRequest": "Your changes are saved. They will be effective soon"
      },
      "groups": {
        "archive": {
          "confirm": {
            "message": "Do you want to archive this group ?",
            "title": "Archive This Group"
          }
        },
        "details": {
          "expiration": "Exp.:",
          "groupsCount": "{count, plural, =1 {# group} other {# groups}}",
          "usersCount": "{count, plural, =1 {# user} other {# users}}"
        },
        "edit": {
          "language": "Language",
          "name": "Name",
          "parent": "Parent",
          "rights": "Rights",
          "title": "Edit Group"
        },
        "empty": {
          "message": "Click on the right bottom button to create your first group",
          "title": "No group created yet"
        },
        "failedDestroy": "An error occurred, the group has not been archived",
        "failedReactivate": "An error occurred, the group has not been reactivated",
        "inactive": "Archived Groups",
        "new": {
          "title": "Create a Group"
        },
        "reactivate": {
          "confirm": {
            "message": "Do you want to reactivate this group ?",
            "title": "Reactivate This Group"
          }
        },
        "search": "Search a group or a user",
        "subNav": {
          "customisation": "Customization",
          "groups": "Groups",
          "users": "Users"
        },
        "successfulDestroy": "The group has been successfully archived",
        "successfulReactivate": "The group has been successfully reactivated",
        "toolbar": {
          "new": "Create a Group"
        },
        "users": {
          "activate": {
            "confirm": {
              "message": "Do you want to activate this user ?",
              "title": "Activate this User"
            }
          },
          "archive": {
            "confirm": {
              "message": "Do you want to archive and make this user inactive ?",
              "title": "Archive User"
            },
            "title": "Archive User"
          },
          "create": {
            "acls": "Custom Rights",
            "aclsHint": "If you had custom rights, the user will have only access to the content of what's added in this field. It won't inherit of its parents group rights anymore",
            "email": "Email",
            "invite": "Invite",
            "parent": "Parent Group",
            "rightDescription": {
              "adminUser": "Can invite, manage and delete users & groups",
              "catalogManagement": "Can manage catalog",
              "distribution": "Can access release table",
              "financial": "Can access to reports and extractions",
              "sales": "Can access the Sales dashboard",
              "salesAdmin": "Can add sales reports and close financial period",
              "trends": "Can access the Trends dashboard"
            },
            "rightTitle": {
              "accounting": "Accounting",
              "analytics": "Analytics",
              "catalog": "Catalog",
              "distribution": "Distribution",
              "users": "Users"
            },
            "role": "Role",
            "roleTitle": {
              "accountant": "Accountant",
              "admin": "Administrator",
              "contentEditor": "Editor",
              "custom": "Custom",
              "manager": "Manager",
              "viewer": "Viewer"
            },
            "title": "Invite a user",
            "update": "Update"
          },
          "edit": {
            "title": "Update a user"
          },
          "empty": {
            "message": "Click on the right bottom button to invite your first user to join your team",
            "title": "No user added yet"
          },
          "failedActivate": "An error occurred. The user has not been updated",
          "failedExtract": "An error occurred. The users extract cannot be requested",
          "failedInvite": "An error occurred. Invitation has not been sent",
          "failedUpdate": "An error occurred. The user has not been updated",
          "inactive": "Inactive users",
          "noRole": "No role assigned",
          "pendingInvitation": "Pending Invitation",
          "resend": {
            "confirm": {
              "message": "Do you want to resend the invitation to this user ?",
              "title": "Resent the invitation"
            }
          },
          "restricted": "Custom rights",
          "revoke": {
            "confirm": {
              "message": "Do you want to cancel the invitation to this user ?",
              "title": "Cancel the invitation"
            }
          },
          "successfulActivate": "The user was successfully activated.",
          "successfulDestroy": "The user has been successfully deleted",
          "successfulExtract": "Users extract has been successfully requested, you will get it by email in a few moments.",
          "successfulInvite": "Invitation has been successfully sent",
          "successfulUpdate": "User has been successfully updated",
          "toolbar": {
            "extract": "Extract Users",
            "new": "Invite a User"
          },
          "viewAs": {
            "button": "View As",
            "confirm": {
              "message": "You are about to log in as an another user, are you sure ?",
              "title": "View As this User"
            },
            "title": "View As this User"
          }
        }
      },
      "nav": {
        "group": "Users",
        "groups": "Groups & Users",
        "preferences": "Preferences"
      },
      "preferences": {
        "avatar": {
          "format": "Format .png or .jpeg",
          "hint": "Click on the drag area or drag & drop to upload a profile photo",
          "ratio": "Square ratio",
          "size": "Minimum size 200 x 200px",
          "title": "Profile photo"
        },
        "failedRequest": "An error occured, your preferences are not updated",
        "form": {
          "changelogByEmail": "Receive Labelcamp news by email",
          "currency": "Currency",
          "email": "Email",
          "firstName": "First Name",
          "language": "Language",
          "login": "Username",
          "name": "Last Name",
          "passwordConfirmation": "Confirm new password",
          "passwordNew": "New Password"
        },
        "informations": "Basic information",
        "password": "Change Your Password",
        "passwordItems": {
          "digit": "One number",
          "length": "8 characters minimum",
          "lowercase": "One lowercase character",
          "uppercase": "One uppercase character"
        },
        "preferences": "Preferences",
        "successRequest": "Your preferences are updated"
      },
      "signout": "Sign Out"
    },
    "video": {
      "definition": "Definition",
      "edit": "Edit",
      "format": "Format",
      "id": "Labelcamp ID",
      "informations": "Information",
      "isrc": "ISRC",
      "label": "Label",
      "length": "Length",
      "name": "Name",
      "upc": "UPC",
      "weight": "Weight"
    },
    "videos": {
      "filters": {
        "tag": "Filter by Tracks, Contracts, Labels, Genres..."
      },
      "index": {
        "assets": "Assign Youtube assets"
      },
      "nav": {
        "analytics": "Analytics",
        "informations": "Information",
        "share": "Share"
      },
      "new": {
        "placeholder": "Add a video"
      },
      "title": "Videos",
      "uploaded": "User Upload"
    },
    "welcomeModal": {
      "swiper": {
        "cancel": "Skip",
        "next": "Next",
        "slide1": {
          "imagePath": "/assets/images/welcome/step-first-en.png",
          "imagePath2": "/assets/images/welcome/step-first-en@2x.png",
          "text": "We're pleased to announce that we have just included gender, age and cities to our <a href='https://labelcamp.idol.io/analytics/trends' target='s'>Trends Overview</a>",
          "title": "New Data in Trends!"
        }
      }
    },
    "yes": "Yes"
  }], ["fr", {
    "24bit": "24 bits",
    "accounting": {
      "extracts": {
        "contract": {
          "label": "Contrat",
          "title": "Sélectionnez votre contrat"
        },
        "failedRequest": "Un problème est survenu, merci de tenter une nouvelle extraction",
        "parameters": {
          "aggregated": {
            "end": "Fin",
            "period": "Période",
            "start": "Début",
            "type": "Type d'aggregation"
          },
          "royalties": {
            "album": "Album",
            "albumHint": "Si vous choisissez un album spécifique, vous ne recevrez pas l'extraction de tous vos albums",
            "salesPeriod": "Période financière"
          },
          "scpp": {
            "year": "Année"
          },
          "title": "Definissez les parametres"
        },
        "successfulRequest": "La demande d'extraction a bien été prise en compte, vous la recevrez bientôt par email",
        "title": "Extractions",
        "types": {
          "aggregated": {
            "company": "Contrat (Défaut)",
            "details": "Concaténation des reportings de ventes en un seul fichier.",
            "label": "Ventilation par label",
            "name": "Reportings agrégés",
            "product": "Ventilation par album"
          },
          "royalties": {
            "details": "Ce reporting comprend la somme de tous les revenus générés sur chacun des albums de votre catalogue sur chaque semestre clôturé. Cela inclut la totalité des revenus (revenus générés par l'album mais aussi par ses titles, téléchargement et streaming, pc et mobile, etc.). Chaque reporting est calculé à partir des reportings d'un semestre (pas à partir des dates de transaction). Vous trouverez ensuite des sous-totaux par pays (vos taux de royauté pouvant varier selon les pays). Pour chaque album, et/ou pays, nous vous communiquons les revenus bruts (revenus IDOL) et nets (votre revenu après commission IDOL).",
            "name": "Extraction royautés"
          },
          "scpp": {
            "details": "Ces reportings (un pour les pistes, un pour les albums) comprennent chacun le total des ventes digitales par référence selon les critères déterminés par la sppf et la scpp (France uniquement, téléchargements uniquement, pas de streaming, pas de sonneries...). Ils sont calculés à partir des reportings d'une année (pas à partir des dates de transaction).",
            "name": "Extraction SCPP / SPPF"
          },
          "title": "Sélectionnez votre type d'extraction"
        }
      },
      "reports": {
        "count": "{count, plural, =1 {# report} other {# reports}}",
        "placeholder": "Choisissez un contrat",
        "salesCompanyResultModal": {
          "chooseFormat": "Choisissez un format",
          "title": "Télécharger vos rapports de ventes"
        },
        "successfulRequest": "Votre rapport a bien été demandé, vous le recevrez par email dans un moment.",
        "title": "Compte-rendus",
        "tooManyRequest": "Trop de rapports on été demandé, merci d'attendre avant d'en demander un nouveau"
      }
    },
    "activate": "Activer",
    "add": "Ajouter",
    "addAll": "Tout ajouter",
    "addTagMessage": "Taper au moins 3 caractères pour chercher un tag",
    "all": "Tous",
    "analytics": {
      "column": {
        "amount": "Montant"
      },
      "demographics": {
        "download": "Télécharger en XLS",
        "subNav": {
          "cities": "Top Villes",
          "genders": "Genre & Âge",
          "territories": "Top Pays"
        }
      },
      "filters": {
        "ageRange": "Âge de l'album :",
        "ageRanges": {
          "backCatalogue": "Back Catalogue",
          "newRelease": "Nouveauté",
          "recent": "Récent"
        },
        "exclude": "Albums, pistes, artistes, plateformes, territoires...",
        "excludePlaceholder": "Exclure :",
        "paymentType": "Paiement :",
        "productType": "Type :",
        "tag": "Filtrer par albums, pistes, artistes, plateformes, territoires...",
        "tooltip": {
          "addExclusion": "Ajouter des exclusions",
          "removeExclusion": "Retirer les exclusions"
        },
        "transactionType": "Usage :",
        "transactionTypes": {
          "contentproduction": "Content Production",
          "download": "Téléchargement",
          "fingerprint": "Fingerprint",
          "preorder": "Précommande",
          "streaming": "Streaming",
          "title": "Type :"
        },
        "upc": "UPC :"
      },
      "fragments": {
        "ages": {
          "title": "Âge de l'album"
        },
        "artists": {
          "title": "Artistes"
        },
        "cities": {
          "title": "Villes"
        },
        "companies": {
          "title": "Contrats"
        },
        "dsps": {
          "title": "Plateformes"
        },
        "genders": {
          "title": "Genres"
        },
        "labels": {
          "title": "Labels"
        },
        "marketShare": "Part (%)",
        "marketShareHint": "Le graphique en aires empilées à 100% montre comment la part a évoluée au cours du temps.",
        "origins": {
          "title": "Sources"
        },
        "playlists": {
          "title": "Playlists"
        },
        "productGenres": {
          "title": "Genres"
        },
        "products": {
          "title": "Albums"
        },
        "retails": {
          "title": "Plateformes"
        },
        "seeAlbum": "Voir l'album",
        "table": {
          "columns": {
            "aLaCarte": "A la carte",
            "age": "Release Lifecycle",
            "artist": "Artiste",
            "automated": "Automatisé",
            "city": "Ville",
            "combination": "Sorties + Pistes",
            "contentProduction": "Content Production",
            "country": "Pays",
            "curator": "Curator",
            "download": "Téléchargements",
            "editorial": "Editorial",
            "entrance": "Date d'ajout",
            "followers": "Followers",
            "freemium": "Freemium",
            "hints": {
              "aLaCarte": "Single payment",
              "automated": "La source a été automatiquement créée par la plateforme ou ses algorithmes. Cela inclut les pages d'artistes ou d'albums qui sont automatiquement créées par les platesformes lors de la livraison du produit, ainsi que les playlists et radio automatisées.",
              "editorial": "La source a été créée par l'équipe éditoriale de la plateforme",
              "none": "Cloud storage, upgrades, delivery fees...",
              "streamPerUsers": "Streams / Utilisateurs uniques. Limité aux streams avec données d'utilisateurs disponibles",
              "uniqueUsers": "Basé sur {count}% des streams totaux (données de Spotify, Apple Music et Deezer)",
              "user": "La source a été créée par un utilisateur de la plateforme"
            },
            "name": "Nom",
            "none": "Aucun",
            "origin": "Source",
            "premium": "Premium",
            "proportion": "Proportion",
            "rank": "#",
            "releases": "Sorties",
            "retail": "Plateformes",
            "revenue": "Revenue",
            "skipRate": "Skip Rate",
            "stream": "Streams",
            "streamPerUsers": "Streams par utilisateur",
            "transactionType": "Type de transaction",
            "transactions": "Transactions",
            "trial": "Trial",
            "uniqueUsers": "Utilisateurs unique",
            "usage": "Usage",
            "user": "Utilisateurs",
            "volume": "Streams"
          },
          "rankings": {
            "revenueAlbums": "{revenue} issus de bundles",
            "volumeAlbums": "dont {volume, number} bundles"
          }
        },
        "territories": {
          "quantityHint": "Les chiffres de Shazam ne tiennent pas compte des droits territoriaux",
          "title": "Pays"
        },
        "tracks": {
          "title": "Pistes"
        },
        "trends": {
          "downloadUnitDetails": "Si un album contient 10 pistes, est acheté une fois + 5 pistes pistes à l'unité, cela compte comme 15 pistes EQ.",
          "streamsByUsers": "Streams par utilisateur",
          "subtitle": "{albumsQuantity, number} bundles | {tracksQuantity, number} pistes",
          "title": {
            "contentProduction": "Total Content Production",
            "copyScan": "Total pistes streamées",
            "download": "Total pistes EQ",
            "stream": "Total pistes streamées"
          },
          "types": {
            "streams": "streams",
            "tracks": "pistes"
          },
          "uniqueUsers": "Utilisateurs uniques"
        },
        "usages": {
          "title": "Type de revenues"
        },
        "users": {
          "header": {
            "hint": "Streams / Utilisateurs uniques. Limité aux streams avec données d'utilisateurs disponibles",
            "subtitle": "Stream par utilisateur : {count}",
            "title": "Total d'utilisateurs uniques"
          },
          "title": "Utilisateurs"
        },
        "videos": {
          "title": "Vidéos"
        }
      },
      "nav": {
        "demographics": "Démographiques",
        "index": "Vue d'ensemble",
        "map": "Démographiques",
        "playlists": "Playlists & Stations",
        "rankings": "Classements"
      },
      "nodata": "Pas de données disponibles",
      "overview": {
        "audience": {
          "title": "Audience"
        },
        "cities": {
          "subtitle": "Basé sur les données de Spotify et Amazon",
          "title": "Top Villes"
        },
        "gender": {
          "female": "Femme",
          "male": "Homme",
          "subtitle": "Basé sur les données de Spotify et Deezer",
          "title": "Sexes & Âge"
        },
        "lifecycle": {
          "column": {
            "lifecycle": "Âge de l'album",
            "proportion": "Part",
            "volume": "Streams"
          },
          "title": "Âge de l'album"
        },
        "nodata": "Pas de données disponibles",
        "origin": {
          "artist_or_album": "Artiste ou Album",
          "column": {
            "automated": "Automatisé",
            "editorial": "Editorial",
            "proportion": "Part",
            "source": "Source",
            "user": "Utilisateur",
            "volume": "Streams"
          },
          "library": "Bibliothèque",
          "playlist": "Playlist",
          "radio": "Radio",
          "search": "Recherche",
          "subtitle": "Basé sur les données de Spotify, Apple Music, Amazon & Deezer",
          "title": "Source"
        },
        "retails": {
          "column": {
            "proportion": "Part",
            "retail": "Plateformes",
            "transactionType": "Type de transaction",
            "volume": "Streams"
          }
        },
        "territories": {
          "subtitle": "Basé sur les données de toutes les plateformes",
          "title": "Top Pays"
        },
        "units": {
          "downloads": "Téléchargements",
          "streams": "Streams"
        }
      },
      "playlists": {
        "column": {
          "artist": "Artiste",
          "curator": "Curateur",
          "entrance": "Date d'entrée",
          "filter": "",
          "followers": "Abonnés",
          "name": "Nom",
          "rank": "#",
          "second_metric": "Taux de skip",
          "streams": "Streams",
          "streamsSince": "streams depuis :"
        },
        "hint": "Basé sur les playlists Spotify, Apple Music, Deezer & Amazon Music et sur les stations Apple Music, Amazon et Pandora",
        "seePlaylist": "Voir la playlist",
        "skipRateHint": "% d'écoutes inférieurs à 90s",
        "title": "Total de streams via playlists et stations",
        "tracks": "{count, plural, =1 {# piste} other {# pistes}}"
      },
      "rankings": {
        "download": "Télécharger en XLS",
        "seeSource": "Voir la page",
        "subNav": {
          "artists": "Top Artistes",
          "companies": "Top Contrats",
          "dsps": "Top Plateformes",
          "labels": "Top Labels",
          "productGenres": "Top Genres",
          "products": "Top Albums",
          "retails": "Top Retails",
          "tracks": "Top Pistes",
          "videos": "Top Vidéos"
        }
      },
      "sales": {
        "aggregateTypes": {
          "financial": "Vue financière",
          "hint": "Vue financière : Date du rapport de ventes mensuel aux ayant-droits<br />Vue transaction : Date de transaction sur les services de streaming et téléchargement",
          "transaction": "Vue transaction"
        },
        "customCurrencyWarning": "La valeur de vos ventes au taux de change du jour.<br />Le montant réel payé diffèrera en fonction du taux change à date du paiement.",
        "overview": {
          "pagesTitle": "Revenu consolidé sur les 12 derniers mois",
          "title": "Revenu Total",
          "unit": "revenus"
        },
        "payments": {
          "aLaCarte": "A la carte",
          "freemium": "Freemium",
          "no": "Aucun",
          "premium": "Premium",
          "trial": "Trial"
        },
        "ranking": {
          "revenueAlbums": "issus de bundles",
          "volumeAlbums": "bundles",
          "volumeAlbumsIncl": "dont"
        },
        "revenueTypes": {
          "commission": "Commission",
          "company": "Contrat",
          "distributor": "Distributeur",
          "title": "Revenu :"
        },
        "title": "Sales",
        "transactionTypes": {
          "contentProduction": "Content Production",
          "copyScan": "Fingerprint",
          "download": "Download",
          "nonInteractiveStream": "Radio",
          "onDemandStream": "Streaming"
        },
        "usages": {
          "column": {
            "aLaCarte": "A la carte",
            "freemium": "Freemium",
            "hints": {
              "aLaCarte": "Paiement unitaire",
              "none": "Hébergement cloud, upgrades, frais de livraison..."
            },
            "none": "Aucun",
            "premium": "Premium",
            "proportion": "Proportion",
            "revenue": "Revenu",
            "trial": "Trial",
            "usage": "Usage"
          },
          "contentProduction": "Content Production",
          "download": "Téléchargement",
          "fingerprint": "Fingerprint",
          "keys": {
            "copy_scan": "copyScan",
            "download": "download",
            "non_interactive_stream": "nonInteractiveStream",
            "on_demand_stream": "onDemandStream"
          },
          "radio": "Radio",
          "streaming": "Streaming"
        }
      },
      "trends": {
        "title": "Trends"
      },
      "units": {
        "bundles": "bundles",
        "contentProduction": "content production",
        "copyScan": "streams",
        "download": "pistes EQ",
        "singleTracks": "pistes",
        "stream": "streams"
      }
    },
    "artists": {
      "availabilities": {
        "delete": {
          "message": "Vous allez supprimer l'ID Artiste pour cette plateforme. Êtes-vous sûr(e) ?",
          "success": "L'ID Artiste a bien été supprimé",
          "title": "Supprimer ID Artiste"
        },
        "dsp": "Plateforme",
        "external": "ID",
        "new": {
          "appleArtists": {
            "appleId": "Apple ID",
            "create": {
              "error": "Impossible de créer la page artiste",
              "success": "Votre demande a bien été prise en compte. Vous pourrez revenir dans quelques minutes pour l'associer"
            },
            "existing": "Pages artistes existantes",
            "genre": "Genre",
            "hint": "Choisissez un ID existant et remplissez le champ ci-dessous. Si vous ne trouvez aucun artiste correspondant, vous pouvez ",
            "hint_not_found": "Aucun artiste n'a été trouvé. Vous pouvez ",
            "locale": "Langue",
            "name": "Nom",
            "request": "demander la création d'une nouvelle page artiste"
          },
          "artistID": "ID Artiste",
          "dsp": "Plateforme",
          "spotifyArtists": {
            "existing": "Pages artistes existantes",
            "hint": "Choisissez un ID existant et remplissez le champ ci-dessous.",
            "hint_not_found": "Aucun artiste n'a été trouvé.",
            "name": "Nom",
            "spotifyId": "Spotify ID"
          },
          "success": "L'ID Artiste a été mis à jour",
          "title": "Nouvel ID Artiste"
        },
        "title": "IDs Artiste"
      },
      "filters": {
        "tag": "Filtrer par contrats, labels..."
      },
      "nav": {
        "analytics": "Analytics",
        "availabilities": "IDs Artiste",
        "index": "Albums",
        "playPages": "Page Play",
        "socialAccounts": "Comptes sociaux"
      },
      "products": {
        "empty": "Pas d'albums disponibles"
      },
      "title": "Artistes"
    },
    "asset": {
      "associated": {
        "success": "L'asset a été associé à la piste sélectionnée, il va être mis à jour rapidement"
      },
      "ignore": {
        "success": "L'asset a été ignoré"
      }
    },
    "assets": {
      "associate": "Piste à associer",
      "associatePlaceholder": "Choisissez une piste...",
      "back": "Index vidéos",
      "filters": {
        "tag": "Filtrer par vidéos.."
      },
      "ignore": {
        "confirm": {
          "message": "Êtes-vous sûr de ne pas vouloir associer cette vidéo à une piste ?",
          "title": "Ne pas associer"
        },
        "hint": "Ne pas associer"
      },
      "save": "Valider l'association",
      "title": "Assets Youtube incomplets",
      "uncomplete": {
        "empty": {
          "message": "Vous n'avez aucun Asset Youtube à compléter",
          "title": "Tout est ok !"
        }
      },
      "video": "Vidéo",
      "videoId": "ID video : {asset}"
    },
    "backTo": "Retour à l'index",
    "cancel": "Annuler",
    "catalog": {
      "albums": {
        "appleCheck": {
          "success": "Vous recevrez le résultat de la vérification par email"
        },
        "approve": {
          "confirm": {
            "message": "Êtes vous sur de vouloir approuver la soumission pour cet album ?",
            "title": "Approuver la soumission"
          },
          "failed": "Une erreur est survenue, la soumission n'a pas pu être approuvée",
          "success": "La soumission à bien été approuvée"
        },
        "delete": {
          "confirm": {
            "message": "Êtes-vous sûr de vouloir supprimer cet album ?",
            "title": "Supprimer cet album"
          },
          "error": "Une erreur est survenue, l'album n'a pas pu être supprimé. Merci de réessayer",
          "failed": "L'album n'a pas été supprimé",
          "success": "L'album a été supprimé avec succès"
        },
        "duplicate": {
          "confirm": {
            "message": "Êtes-vous sûr de vouloir dupliquer cet album ?",
            "title": "Dupliquer cet album"
          },
          "failed": "L'album n'a pas été dupliqué",
          "success": "L'album a été dupliqué avec succès"
        },
        "filters": {
          "sort": {
            "createdAt": "Trier par : Dernier crés",
            "name": "Trier par : Nom",
            "releaseDate": "Trier par : Date de sortie",
            "updatedAt": "Trier par : Derniers mis à jour"
          },
          "state": {
            "all": "Voir tous les albums",
            "notSubmitted": "Voir seulement les albums non-soumis",
            "submitted": "Voir seulement les albums soumis"
          },
          "status": {
            "active": "Actifs",
            "all": "Statut : Tous",
            "inactive": "Inactifs"
          }
        },
        "index": {
          "createdAt": "Créé le",
          "delete": "Supprimer",
          "duplicate": "Dupliquer",
          "editAlbum": "Éditer l'album",
          "inactive": "Album inactif",
          "pause": "Pause",
          "play": "Écouter",
          "releaseDate": "Date de sortie :",
          "seeAlbum": "Voir l'album",
          "upcCode": "UPC :",
          "updatedAt": "Mis à jour le"
        },
        "informations": {
          "author": "Écrivain :",
          "booklet": "Livret",
          "calendar": {
            "apple": "Ajouter à votre calendrier Apple",
            "google": "Ajouter à votre calendrier Google",
            "outlook": "Ajouter à votre calendrier Outlook",
            "tooltip": "Ajouter cette date dans votre calendrier"
          },
          "calendarTitle": "Sortie de",
          "closed": "Montrer plus d'artistes",
          "composer": "Compositeur :",
          "contributor": "Contributeur :",
          "copyrightLine": "© Copyright :",
          "emptyTracklisting": "Aucune piste associée à album",
          "featuring": "Featuring :",
          "genre1": "Genre :",
          "genre2": "Genre 2 :",
          "hideDetails": "Cacher les détails",
          "label": "Label :",
          "labelComments": "Commentaires label",
          "labelInternalReference": "Référence catalogue :",
          "language": "Langue des métadonnées :",
          "masteredBy": "Ingénieur du son :",
          "opened": "Montrer moins d'artistes",
          "originalDate": "Date de sortie originale :",
          "preorderDate": "Date de pré-commande :",
          "productType": "Type de produit :",
          "productionLine": "℗ Production :",
          "productionYear": "Année de production :",
          "publisher": "Éditeur :",
          "releaseDate": "Date de sortie :",
          "remixer": "Remixeur :",
          "showDetails": "Afficher les détails",
          "tags": "Tags",
          "track": {
            "artist": "Artiste",
            "audioLanguage": "Langue audio",
            "clean": "Clean",
            "conflict": "Conflit ISRC",
            "duration": "Durée",
            "explicit": "Accord parental",
            "explicitContent": "Contenu explicite",
            "hiRes": "24 bits",
            "informations": "Informations",
            "isrcCode": "ISRC",
            "iswcCode": "ISWC",
            "iswcConflict": "Conflit ISWC",
            "lowRes": "16 bits",
            "lyrics": "Paroles",
            "lyricsDetails": "{track} - Paroles",
            "lyricsTtml": "Paroles en TTML",
            "masterTypes": "Type de master",
            "previewEnd": "Fin de la preview",
            "previewStart": "Début de la preview",
            "productionDate": "Date de production",
            "productionLine": "Production",
            "publisher": "Éditeur",
            "resolution": "Resolution",
            "rolesTitle": "Rôles",
            "subtitle": "Version",
            "title": "Titre",
            "video": "Piste Vidéo",
            "videoModalTitle": "{track} - Vidéos associées",
            "videos": {
              "duration": "Durée",
              "name": "Nom de la vidéo",
              "resolution": "Résolution",
              "retailer": "Plateforme",
              "retails": "Plateformes"
            },
            "work": "Oeuvre"
          },
          "upc": "UPC :",
          "viewComments": "Voir les Commentaires",
          "volume": "Support "
        },
        "links": {
          "appleId": "Apple ID :",
          "copyID": "Copier l'ID de la plateforme",
          "copyItunes": "Copier l'Apple ID",
          "copySpotify": "Copier l'URI Spotify",
          "copyURL": "Copier l'URL",
          "delivered": "En ligne",
          "notDelivered": "Hors ligne",
          "platformId": "Plateforme ID :",
          "spotifyURI": "URI :",
          "status": "Statut :",
          "url": "URL :"
        },
        "show": {
          "comments": {
            "title": "Commentaires"
          },
          "commentsTitle": "Commentaires ({number})",
          "edit": "Éditer",
          "submission": {
            "approve": "Approuver",
            "reject": "Rejeter",
            "title": "Soumission en attente"
          }
        }
      },
      "assets": {
        "additionalInformations": "Informations complémentaires",
        "assetType": "Type d'asset",
        "companyName": "Contrat",
        "title": "Title",
        "youtubeId": "ID YouTube"
      },
      "products": {
        "metadata": {
          "failedRequest": "Ahem, une erreur s'est produite. Merci de réessayer",
          "links": {
            "products": "Albums xls",
            "tracks": "Pistes xls",
            "xml": "xml",
            "xmlInpro": "xml Inpro"
          },
          "successfulRequest": "Votre catalogue a bien été demandé, vous le recevrez par email dès qu'il aura été traité.",
          "title": "Téléchargez votre catalogue"
        },
        "show": {
          "appleCheck": {
            "launch": "Lancer une vérification Apple"
          },
          "artists": {
            "index": {
              "name": "Artiste",
              "roles": "Rôles",
              "title": "Liste des artistes de l'album"
            }
          },
          "metadata": "Télécharger vos métadata",
          "pause": "Pause",
          "play": "Jouer",
          "preview": "Aperçu",
          "submission": "Soumission :",
          "submissionPriority": "Priorité",
          "submissionStatus": "Statut",
          "tag": "Ajouter un tag"
        },
        "toolbar": {
          "metadata": "Télécharger votre catalogue",
          "new": "Nouvel album"
        }
      },
      "videos": {
        "failedExtract": "Une erreur est suvenue. La demande d'extraction des vidéos a échoué.",
        "informations": {
          "emptyTracklisting": "Aucune piste associée à cette vidéo",
          "format": "Format :",
          "id": "ID Labelcamp :",
          "isrc": "ISRC Vidéo :",
          "isrcAudio": "ISRC Audio :",
          "label": "Label :",
          "length": "Durée :",
          "resolution": "Résolution :",
          "upc": "UPC :",
          "weight": "Poids :"
        },
        "successfulExtract": "L'extraction des vidéos a bien été demandé. Vous la recevrez par email dans quelques instants.",
        "toolbar": {
          "extract": "Extraire les vidéos",
          "new": "Nouvelle vidéo"
        },
        "tracklisting": {
          "album": "Album",
          "isrc": "ISRC",
          "length": "Durée",
          "track": "Piste associée"
        }
      }
    },
    "changelog": {
      "title": "Nouveautés"
    },
    "claims": {
      "actions": {
        "approve": "Accepter",
        "reject": "Refuser"
      },
      "back": "Retour à l'index des revendications",
      "commentTemplate": "Votre référence a un conflit de propriété. Une action est requise pour le traiter.\nMerci d’indiquer vos droits en approuvant ou en rejetant ce conflit:\n\n- Accepter : Vous acceptez le conflit de propriété. Vous confirmez ne plus avoir les droits, une suppression est envoyée à la plateforme en conséquence. Le label plaignant est aussi alerté du retrait des droits sur ce produit.\n\n- Refuser : Vous refusez le conflit de propriété. Vous confirmez vos droits sur la référence auprès de la plateforme et du label plaignant concerné. Un email confirmant vos droits est envoyé au plaignant.\n\nCordialement,\n\nLe Service de Gestion des Droits IDOL",
      "confirm": {
        "accept": {
          "message": "Cette action entraînera une supression sur toutes les plateformes et territoires mentionnés. Êtes-vous sûr de vouloir accepter cette revendication ?",
          "title": "Conflit de propriété accepté"
        },
        "refuse": {
          "message": "Cette action rejettera la revendication et vous devez être en mesure de prendre en compte les actions mentionnées dans le commentaire. Êtes-vous sûr de vouloir refuser cette revendication ?",
          "title": "Conflit de propriété refusé"
        }
      },
      "create": {
        "title": "Créer une revendication"
      },
      "edit": {
        "title": "Editer une revendication"
      },
      "empty": {
        "title": "Aucune revendication à vérifier"
      },
      "filters": {
        "resolved": "Résolu",
        "tag": "Filtrer par album, label, contrat ou plateforme...",
        "unresolved": "Non résolu"
      },
      "form": {
        "claimer": {
          "hint": "Le plaignant est la personne qui revendique les droits sur l'album. Il sera également averti par mail.",
          "title": "Plaignant"
        },
        "claimerEmail": "Email du plaignant",
        "claimerName": "Nom du plaignant",
        "comment": "Commentaire",
        "dsps": "Plateformes",
        "generalInformation": {
          "title": "Informations générales"
        },
        "owner": "Ayant droit",
        "product": "Album revendiqué",
        "rightHolder": {
          "hint": "L'ayant droit est la personne du label propriétaire des droits. Il sera informé par mail et notification via Labelcamp.",
          "title": "Ayant Droit"
        },
        "territories": "Territories",
        "track": "Piste revendiquée"
      },
      "notifications": {
        "save": {
          "failed": "Impossible de sauver la revendication.",
          "success": "La revendication a été sauvegardée avec succès."
        },
        "update": {
          "failed": "Impossible de sauver la revendication.",
          "success": "La revendication a été sauvegardée avec succès."
        }
      },
      "show": {
        "approveOrReject": "Souhaitez-vous accepter ou refuser le conflit de propriété ?",
        "title": "Informations sur la revendication",
        "viewAs": "Voir en tant que label"
      },
      "table": {
        "accept": "Accepter",
        "allPlatforms": "Toutes les plateformes",
        "claimer": "Plaignant",
        "comment": "Commentaire",
        "createdAt": "Date de création",
        "owner": "Ayant droit",
        "platforms": "Plateformes",
        "product": "Album",
        "refuse": "Refuser",
        "status": "Statut",
        "statuses": {
          "accepted": "Accepté",
          "refused": "Refusé"
        },
        "territories": "{count, plural, =1 {# territoire} other {# territoires}}"
      },
      "title": "Revendications",
      "toolbar": {
        "new": "Nouvelle revendication"
      }
    },
    "clone": "Dupliquer",
    "clones": {
      "labels": {
        "label": "Label de destination"
      },
      "multiple": {
        "success": "{count} singles ont été créés avec succès :"
      },
      "platforms": {
        "label": "Plateformes",
        "placeholder": "Sélectionner une ou plusieurs plateformes"
      },
      "title": "Dupliquer l'album",
      "tracks": {
        "ep": "Créer un seul album avec les pistes sélectionnées",
        "label": "Pistes à dupliquer",
        "placeholder": "Sélectionner une ou plusieurs pistes"
      },
      "type": {
        "product": "Album",
        "track": "Single"
      }
    },
    "cloning": "Duplication...",
    "close": "Fermer",
    "collection": {
      "artistMissing": "Artiste manquant",
      "artists": {
        "empty": {
          "message": "La liste de vos artistes s'afficheront ici quand vous aurez créé votre premier album",
          "title": "Aucun artiste trouvé"
        },
        "emptyFilters": {
          "message": "Essayez avec différents filtres pour afficher des résultats",
          "title": "Aucun artiste trouvé"
        },
        "platformId": {
          "empty": {
            "message": "Associez cet artiste à une plateforme en cliquant sur le bouton en haut à droite",
            "title": "Aucun ID Artiste associé"
          }
        }
      },
      "assets": {
        "empty": {
          "message": "Et c'est tant mieux",
          "title": "Aucun asset à associer !"
        }
      },
      "companies": {
        "empty": {
          "message": "Ajoutez un contrat en cliquant sur le bouton en bas à droite",
          "title": "Aucun contrat ajouté !"
        },
        "emptyFilters": {
          "message": "Essayez avec différents filtres pour afficher des résultats",
          "title": "Aucun contrat trouvé"
        }
      },
      "count": {
        "artist": "{count, plural, =1 {# artiste} other {# artistes}}",
        "asset": "{count, plural, =1 {# asset} other {# assets}}",
        "label": "{count, plural, =1 {# label} other {# labels}}",
        "product": "{count, plural, =0 {Aucun album} =1 {# album} other {# albums}}",
        "products": {
          "zero": "Aucun album"
        },
        "video": "{count, plural, =1 {# vidéo} other {# vidéos}}"
      },
      "labelMissing": "Label manquant",
      "labels": {
        "empty": {
          "message": "Ajoutez un label en cliquant sur le bouton en bas à droite",
          "title": "Aucun label ajouté !"
        },
        "emptyFilters": {
          "message": "Essayez avec différents filtres pour afficher des résultats",
          "title": "Aucun label trouvé"
        }
      },
      "list": {
        "table": {
          "releaseDate": "Date de sortie"
        }
      },
      "nameMissing": "Nom manquant",
      "products": {
        "empty": {
          "message": "Créez votre premier album en cliquant sur le bouton en haut à droite",
          "title": "Aucun album ajouté !"
        },
        "emptyFilters": {
          "message": "Essayez avec différents filtres pour afficher des résultats",
          "title": "Aucun album trouvé"
        }
      },
      "videos": {
        "empty": {
          "message": "Ajoutez votre première vidéo en cliquant sur le bouton en haut à droite",
          "title": "Aucune vidéo ajoutée !"
        },
        "emptyFilters": {
          "message": "Essayez avec différents filtres pour afficher des résultats ou ajoutez une nouvelle vidéo",
          "title": "Aucune vidéo trouvée"
        }
      }
    },
    "comments": {
      "delete": "Supprimer",
      "edit": "Éditer",
      "pin": "Épingler",
      "pinnedBy": "Épinglé par",
      "placeholder": "Écrivez un commentaire ou @mentionnez quelqu'un...",
      "post": "Poster un commentaire",
      "privateReply": "Ajouter en tant que commentaire privé",
      "unpin": "Dépingler",
      "update": "Mettre à jour",
      "updated": "Modifié",
      "updatedOn": "Modifié le",
      "user": {
        "basic": "a ajouté un commentaire",
        "private": "a ajouté un commentaire privé"
      }
    },
    "contracts": {
      "associations": {
        "albums": "Nombre d'albums",
        "albumsCount": "{count, plural, =1 {# album} other {# albums}}",
        "association": "Labels",
        "create": {
          "hint": "Rechercher et ajouter un nouveau label à associer. Ou ",
          "hintLink": "Créez-en un nouveau",
          "resource": "Label"
        },
        "empty": "Aucun labels n'est associé avec ce contrat pour le moment",
        "new": "Nouveau label",
        "title": "Labels",
        "type": "Type"
      },
      "contract": {
        "failedCreate": "Un problème est survenu, le contrat n'a pas pu être ajouté. Merci de réessayer",
        "failedDestroy": "Un problème est survenu, le contrat n'a pas pu être supprimé. Merci de réessayer",
        "failedUpdate": "Un problème est survenu, le contrat n'a pas été mis à jour. Merci de réessayer",
        "successfulCreate": "Le contrat à bien été créé",
        "successfulDestroy": "Le contrat à bien été supprimé",
        "successfulUpdate": "Le contrat à bien été mis à jour"
      },
      "create": {
        "comments": "Commentaires",
        "name": "Nom",
        "title": "Créer un contrat",
        "user": "Label Manager",
        "vatApplicable": "Soumis à la TVA"
      },
      "delete": {
        "failed": "Un problème est survenu, le contrat n'a pas pu être supprimé. Merci de réessayer",
        "message": "Êtes-vous sûr de vouloir supprimer ce contrat ?",
        "success": "Le contrat à bien été supprimé",
        "title": "Supprimer ce contrat"
      },
      "edit": {
        "title": "Éditer un contrat"
      },
      "failedExtract": "Une erreur est suvenue. La demande d'extraction des contrats a échoué.",
      "filters": {
        "rate": {
          "all": "Tous",
          "withRate": "Avec taux",
          "withoutRate": "Sans taux"
        },
        "rates": "Taux :",
        "tag": "Filtrer par label, albums..."
      },
      "index": {
        "contract": "Contrats",
        "label": "{count, plural, =1 {# label} other {# labels}}",
        "labels": "Nombre de labels",
        "rate": "Taux",
        "rateMultiple": "Multiples"
      },
      "nav": {
        "associations": "Labels",
        "rates": "Taux",
        "rights": "Droits"
      },
      "rates": {
        "create": {
          "endDate": "Date de fin",
          "maxRevenue": "Revenu maximum",
          "maxRevenueHint": "Veuillez laisser ce champ vide si vous ne renseignez qu'un seul taux. Si vous ajoutez des paliers de revenus, laissez ce champs vide sur votre dernier palier.",
          "minRevenue": "Revenu minimum",
          "rate": "Taux",
          "ratePlaceholder": "ex : 0.3",
          "startDate": "Date de début",
          "title": "Ajouter un nouveau taux"
        },
        "delete": {
          "message": "Êtes-vous sûr de vouloir supprimer ce taux ?",
          "title": "Supprimer le taux"
        },
        "edit": {
          "title": "Éditer le taux"
        },
        "empty": "Aucun taux associé avec ce contrat pour le moment",
        "endDate": "Date de fin",
        "failedCreate": "Un problème est survenu, le taux n'a pas pu être ajouté. Merci de réessayer",
        "failedDestroy": "Un problème est survenu, le taux n'a pas pu être supprimé. Merci de réessayer",
        "failedUpdate": "Un problème est survenu, le taux n'a pas été mis à jour. Merci de réessayer",
        "maxRevenue": "Revenu maximum",
        "minRevenue": "Revenu minimum",
        "new": "Nouveau taux",
        "rate": "Taux",
        "startDate": "Date de début",
        "successfulCreate": "Le taux à bien été créé",
        "successfulDestroy": "Le taux à bien été supprimé",
        "successfulUpdate": "Le taux à bien été mis à jour",
        "title": {
          "current": "Taux en cours",
          "expired": "Taux expirés"
        }
      },
      "successfulExtract": "L'extraction des contrats a bien été demandé. Vous la recevrez par email dans quelques instants.",
      "title": "Contrats",
      "toolbar": {
        "extract": "Extraire les contrats",
        "new": "Nouveau contrat"
      }
    },
    "create": "Créer",
    "creating": "Création...",
    "dashboard": {
      "blog": {
        "title": "Blog"
      },
      "catalog": {
        "newAlbum": "Nouvel album",
        "number": "{count, plural, =1 {# album} other {# albums}}",
        "title": "Catalogue"
      },
      "releases": {
        "number": "{count, plural, =1 {# sortie} other {# sorties}}",
        "upcoming": "à venir le {date}"
      },
      "starterKit": {
        "close": "Merci, je souhaite voir mon dashboard à la place",
        "create": "Ajoutez un nouvel album à votre catalogue",
        "description": "Vous trouverez ici quelques étapes à suivre pour vous aider à débuter.",
        "help": "Lisez nos articles d'aide",
        "mail": "Si vous avez des questions, n'hésitez pas à nous envoyer un email",
        "profile": "Éditez votre profil (photo, email, nom...)",
        "title": "Bienvenue à bord !",
        "users": "Ajoutez d'autres utilisateurs à votre équipe"
      },
      "suggestions": {
        "extracts": {
          "body": "Simplifiez-vous le calcul des royautés en générant des extractions consolidées de ventes",
          "button": "Générer une extraction",
          "title": "Extractions consolidées"
        },
        "ideas": {
          "body": "N'hésitez pas à nous faire part de vos suggestions pour améliorer Labelcamp !",
          "button": "Donnez votre avis",
          "title": "Vos idées comptent"
        },
        "pagePlay": {
          "body": "Découvrez nos page Play, le meilleur moyen de promouvoir vos sorties sur les réseaux sociaux",
          "button": "Découvrez cette page Play"
        },
        "premium": {
          "body": "Offrez jusqu'à 500 copies digitales de vos sorties à une audience ciblée",
          "button": "Créer un Premium"
        },
        "share": {
          "body": "Partagez vos albums sur Youtube grâce à nos outils dédiés",
          "button": "Partagez cet album"
        },
        "title": "Suggestions"
      }
    },
    "dateRangePicker": {
      "apply": "Appliquer",
      "applyToAll": "Appliquer à tout",
      "end": "au",
      "options": {
        "12months": "12 derniers mois",
        "30days": "30 derniers jours",
        "30firstdays": "30 premiers jours",
        "365firstdays": "12 premiers mois",
        "7days": "7 derniers jours",
        "7firstdays": "7 premiers jours",
        "90firstdays": "90 premiers jours",
        "allTime": "Depuis le début",
        "any": "Tout",
        "currentYear": "Année en cours",
        "next30days": "30 prochains jours",
        "next7days": "7 prochains jours",
        "previousYear": "Année précédente"
      },
      "start": "Du"
    },
    "deactivate": "Désactiver",
    "default": "Défaut",
    "delete": "Supprimer",
    "deselectAll": "Tout désélectionner",
    "distribution": {
      "submissions": {
        "actions": {
          "approve": "Approuver",
          "reject": "Rejeter"
        },
        "comments": "{count, plural, =1 {# commentaire} other {# commentaires}}",
        "contentCommentDate": "Email envoyé le",
        "count": "Soumis {count, number} fois",
        "countExisting": "{count, number} déjà existante(s)",
        "currentSubmission": "Soumission en cours",
        "noAssignee": "Pas d'assignation",
        "products": {
          "approve": {
            "confirm": {
              "message": "Cela rendra l'album actif. Êtes vous sur de vouloir approuver la soumission ?",
              "title": "Approuver la soumission"
            },
            "title": "Valider l'album"
          }
        },
        "rejectSubmission": "Renvoyer l'album au label pour modifications",
        "rejectedOn": "Rejeté le {date}",
        "reminderDate": "Relancé le",
        "sentBack": "Envoyer un email au label",
        "sentReminder": "Rappel",
        "submittedBy": "Soumis par {login} le {date}",
        "submittedOn": " a soumis le {date}",
        "update": {
          "assignUser": {
            "failed": "Une erreur est survenue, l'utilisateur n'a pas pu être changé",
            "success": "Assignation mis à jour avec succès"
          },
          "priority": {
            "failed": "Une erreur est survenue, la priorisation n'a pas pu être changée",
            "success": "Priorisation mis à jour avec succès"
          },
          "status": {
            "failed": "Une erreur est survenue, le statut n'a pas pu être changé",
            "rejected_message": "Êtes vous sur de vouloir rejeter la soumission pour cet album ?",
            "rejected_submessage": "Si oui, n'oubliez pas d'ajouter un commentaire pour guider la personne chargée d'effectuer les changements",
            "rejected_title": "Rejeter la soumission",
            "success": "Statut mis à jour avec succès"
          }
        }
      }
    },
    "distributors": {
      "nav": {
        "webhooks": "Webhooks"
      },
      "webhooks": {
        "actions": {
          "dspStateStatusUpdate": "Dsp State",
          "importTaskStatusUpdate": "Import Task",
          "productCreate": "Product",
          "sendTaskStatusUpdate": "Send Task"
        },
        "columns": {
          "eventType": "Type d'évènements",
          "selectEvent": "Sélectionner un évènement",
          "url": "URL"
        },
        "confirm": {
          "actions": {
            "delete": "Supprimer",
            "validate": "Valider"
          },
          "remove": {
            "message": "Êtes-vous sûr de vouloir supprimer ce Webhook ?",
            "title": "Supprimer un Webhook"
          }
        },
        "empty": {
          "subtitle1": "Lire la documentation",
          "subtitle2": "pour en savoir plus.",
          "title": "Aucun Webhook n'a été défini pour le moment !"
        },
        "new": "+ Nouveau Webhook",
        "notifications": {
          "create": {
            "failed": "Impossible de sauver le Webhook",
            "success": "Webhook sauvé avec succès",
            "taskError": "Impossible de sauver le Webhook car aucune tâche n'a été selectionnée"
          },
          "delete": {
            "failed": "Impossible de supprimer le Webhook",
            "success": "Webhook supprimé avec succès"
          }
        },
        "subtitle": "Paramétrez de nouveaux webhooks pour être notifier de certains évènements.",
        "title": "Gérer les Webhooks"
      }
    },
    "dspStates": {
      "availability": {
        "available": "En ligne",
        "notAvailable": "Indisponible"
      },
      "form": {
        "dspUploadIdentifications": "plateforme",
        "isAvailable": "Statut"
      },
      "new": "Nouveau statut plateforme",
      "notifications": {
        "failed": "Impossible de sauver le statut plateforme",
        "success": "Statut plateforme sauvé avec succès"
      },
      "spotifyURI": "URI Spotify",
      "status": {
        "offline": "Indisponible",
        "online": "En ligne"
      },
      "table": {
        "batch": "Lot",
        "links": "Liens",
        "platform": "Plateformes",
        "platformID": "ID plateforme",
        "status": "Statuts",
        "updatedAt": "Mis à jour"
      },
      "title": "Statuts et liens platformes",
      "tracksAvailabilities": {
        "see": "Voir les IDs pistes",
        "table": {
          "spotifyURI": "Piste URI",
          "title": "Titre"
        },
        "title": "IDs pistes {name}"
      }
    },
    "dspUploadIdentifications": {
      "filters": {
        "statuses": "Status :"
      },
      "index": {
        "columns": {
          "DolbyAtmos": "Dolby Atmos",
          "HDAudio": "HD Audio",
          "XMLValidation": "Validation XML",
          "allRolesSupported": "Tous les rôles",
          "artistId": "Artist ID",
          "blockPreview": "Block Preview",
          "booklet": "Booklet",
          "bundleRelease": "Bundle Release",
          "bundleTracks": "Bundle Tracks",
          "copyScan": "Copy Scan",
          "ddexVersion": "Version DDEX",
          "delivery": "Livraison",
          "download": "Téléchargement",
          "lyrics": "Lyrics",
          "name": "Nom",
          "nonInteractiveStream": "Radio",
          "preorder": "Précommande",
          "preventSendingBeforeReleaseInDays": "Restriction de livraison",
          "productionStart": "Date de début",
          "profile": "Profil",
          "redelivery": "Relivraison",
          "releaseTime": "Heure de sortie",
          "streaming": "Streaming",
          "takedown": "Suppression",
          "territories": "Restrictions territoires",
          "territoryPrice": "Prix territoire",
          "territoryReleaseDate": "Date de sortie territoire",
          "territoryRights": "Droits territoires",
          "titleVersion": "Track/Album version de titre",
          "trackGenre": "Genre de track",
          "trackReleaseDate": "Date de sortie piste",
          "update": "Update",
          "video": "Video"
        },
        "days": {
          "count": "{count, plural, =1 {# jour} other {# jours}}"
        },
        "platformsList": "Liste des plateformes",
        "searchPlaceholder": "Chercher par nom, statut et profil...",
        "table": {
          "active": "Active",
          "closed": "Archivée",
          "standby": "En attente",
          "unknown": "Aucun flux"
        },
        "territories": {
          "count": "{count, plural, =1 {# territoire} other {# territoires}}"
        }
      },
      "mappings": {
        "empty": "Pas de correspondances trouvées.",
        "genres": {
          "notifications": {
            "error": "Impossible d'enregistrer la correspondance de genre",
            "success": "Correspondance de genre enregistré avec succès"
          },
          "title": "Genres"
        },
        "options": {
          "placeholders": {
            "distributorPriceCode": "Sélectionnez un prix",
            "distributorProductSubgenre": "Sélectionnez un genre"
          }
        },
        "prices": {
          "notifications": {
            "error": "Impossible d'enregistrer la correspondance de prix",
            "success": "Correspondance de prix enregistré avec succès"
          },
          "title": "Prix album"
        },
        "trackPrices": {
          "notifications": {
            "error": "Impossible d'enregistrer la correspondance de prix piste",
            "success": "Correspondance de prix piste enregistré avec succès"
          },
          "title": "Prix piste"
        }
      },
      "nav": {
        "genres": "Genres",
        "mappings": "Correspondances",
        "prices": "Prix album",
        "trackPrices": "Prix piste"
      },
      "statuses": {
        "active": "Active",
        "closed": "Archivé",
        "standby": "En attente",
        "unknown": "Aucun flux",
        "unknownHint": "Disponible sur demande"
      },
      "title": "Plateformes"
    },
    "duplicate": {
      "errors": {
        "noTrack": "Vous devez séléctionner une piste"
      },
      "oneTrack": {
        "hint": "Si vous choisissez cette option, la date de sortie, de pré-commande et le prix ne seront pas dupliqueés. De plus, le titre de votre album sera le titre de la piste choisie",
        "placeholder": "Choisissez une piste",
        "title": "Dupliquer une seule piste"
      },
      "title": "Dupliquer"
    },
    "edit": "Éditer",
    "errors": {
      "accepted": "{description} doit être accepté",
      "after": "{description} doit être après {after}",
      "before": "{description} doit être avant {before}",
      "blank": "{description} ne peut pas être vide",
      "collection": "{description} doit être une collection",
      "confirmation": "{description} ne correspond pas à {on}",
      "copyright": "{description} doit être de ce format : 2016 Lorem ipsum",
      "date": "{description} doit être une date valide",
      "description": "Ce champs",
      "email": "{description} doit être une adresse email valide",
      "empty": "{description} ne peut pas être vide",
      "equalTo": "{description} doit être égal à {is}",
      "even": "{description} doit être pair",
      "exclusion": "{description} est réservé",
      "greaterThan": "{description} doit être supérieur à {gt}",
      "greaterThanOrEqualTo": "{description} doit être supérieur à ou égal à {gte}",
      "inclusion": "{description} n'est pas inclus dans la liste",
      "invalid": "{description} n'est pas valide",
      "lessThan": "{description} doit être inférieur à {lt}",
      "lessThanOrEqualTo": "{description} doit être inférieur ou égal à {lte}",
      "notANumber": "{description} doit être une nombre",
      "notAnInteger": "{description} doit être un entier",
      "odd": "{description} doit être impair",
      "otherThan": "{description} doit être autre chose que {value}",
      "passwordConfirmation": "Votre confirmation de mot de passe ne correspond pas à votre mot de passe",
      "phone": "{description} doit être un téléphone valide",
      "positive": "{description} doit être positif",
      "present": "{description} doit être vide",
      "singular": "{description} ne peut pas être une collection",
      "tooLong": "{description} est trop long (maximum de {max} caractères)",
      "tooShort": "{description} est trop court (minimum de {min} caractères)",
      "unexpected": "Une erreur inattendue s'est produite. Merci de réessayer.",
      "url": "{description} doit être une url valide",
      "wrongDateFormat": "{description} doit être dans le format {format}",
      "wrongLength": "{description} a la mauvaise longueur (devrait être {is} caractères)",
      "year": "{description} doit être une année valide"
    },
    "featureNotAvailable": "Cette fonctionnalité sera bientôt disponible !",
    "fileUploaded": "Fichier importé",
    "follow": {
      "alreadyFollowingParent": "Vous êtes déjà abonné au {type}",
      "follow": "S'abonner",
      "notifications": {
        "follow": "You succesfully subscribed to this {type}",
        "unfollow": "You successfully unsubscribed to this {type}"
      },
      "unfollow": "Se désabonner"
    },
    "form": {
      "pending": "Sauvegarde..."
    },
    "format": "format {format}",
    "header": {
      "changelog": "Nouveautés",
      "group": "Groupes & Utilisateurs",
      "new": {
        "album": "Nouvel album",
        "button": "Nouveau +",
        "contract": "Nouveau contrat",
        "group": "Nouveau groupe",
        "label": "Nouveau label",
        "user": "Nouveau utilisateur",
        "video": "Nouvelle vidéo"
      },
      "preferences": "Préférences",
      "producer": "Producer",
      "search": "Chercher"
    },
    "help": "Aide",
    "home": {
      "title": "Accueil"
    },
    "importTasks": {
      "columns": {
        "createdAt": "Créé",
        "masterType": "Type de master",
        "resolution": "Résolution",
        "status": "Status",
        "trackNumber": "Piste",
        "updatedAt": "Mis à jour",
        "user": "Utilisateur",
        "volumeNumber": "Volume"
      },
      "delete": {
        "failed": "Une erreur s'est produite. Impossible de supprimer cette numérisation",
        "message": "Êtes-vous sûr de vouloir supprimer cette numérisation ?",
        "success": "La numérisation est supprimé avec succès",
        "title": "Numérisation"
      },
      "empty": "Aucune numérisation effectuées pour le moment",
      "status": {
        "canceled": "Annulé",
        "cancelled": "Annulé",
        "completed": "Importé",
        "encoding": "En cours de traitement",
        "error": "Erreur",
        "importing": "Transfert en cours",
        "processing": "En cours de traitement",
        "uploaded": "Transfert terminé",
        "uploading": "Transfert en cours",
        "waiting": "En attente"
      }
    },
    "invitation": {
      "login": {
        "placeholder": "Login"
      },
      "message": "Choisissez un login et un mot de passe pour commencer.",
      "password": {
        "placeholder": "Mot de passe"
      },
      "passwordConfirmation": {
        "placeholder": "Confirmation de mot de passe"
      },
      "title": "Invitation"
    },
    "invite": "Inviter",
    "labels": {
      "filters": {
        "tag": "Filtrer par contrats ou albums..."
      },
      "informations": {
        "information": {
          "contract": "Contrat associé",
          "description": "Description",
          "genres": "Genre principal",
          "title": "Informations"
        },
        "logo": {
          "colorspace": "Espace colorimétrique RGB",
          "download": "Télécharger le logo",
          "format": "Format .png ou .jpg",
          "hint": "Cliquez sur la zone ou cliquez-dépossez une image",
          "size": "Taille minimale de 500px, recommandée de 1200px (hauteur ou largeur)",
          "title": "Logo"
        },
        "usages": {
          "title": "Usages"
        },
        "website": {
          "new": "+ Ajouter un nouveau site internet",
          "placeholder": "Ajouter une URL",
          "title": "Sites internet",
          "url": "URL"
        }
      },
      "nav": {
        "analytics": "Analytics",
        "index": "Albums",
        "informations": "Informations",
        "playPages": "Page Play",
        "premiums": "Premiums",
        "rights": "Droits",
        "socialAccounts": "Compte Sociaux",
        "socialTrends": "Social Trends"
      },
      "products": {
        "empty": "Pas d'albums disponible."
      },
      "title": "Labels"
    },
    "language": {
      "acoli": "Acoli",
      "afrikaans": "Afrikaans",
      "akan": "Akan",
      "algerianArabic": "Arabe algérien",
      "amharic": "Amharic",
      "arabic": "Arabe",
      "arma": "Arma",
      "armenian": "Arménien",
      "bambara": "Bambara",
      "bamileke": "Bamileke",
      "basque": "Basque",
      "bassa": "Bassa",
      "belarusian": "Biélorusse",
      "bengali": "Bengali",
      "benin": "Beninois",
      "bhojpuri": "Bhojpuri",
      "bosnian": "Bosniaque",
      "breton": "Breton",
      "bulgarian": "Bulgare",
      "cantonese": "Cantonais",
      "catalan": "Catalan",
      "chinese": "Chinois",
      "chinese(Simplified)": "Chinois (Simplifié)",
      "chinese(Traditional)": "Chinois (Traditionnel)",
      "corsican": "Corsican",
      "creole(Guadeloupe)": "Créole (Guadeloupe)",
      "creole(Martinique)": "Créole (Martinique)",
      "creole(Reunion)": "Créole (Réunion)",
      "croatian": "Croate",
      "czech": "Tchèque",
      "dagaariDioula": "Dagaari Dioula",
      "danish": "Danois",
      "dholuo": "Dholuo",
      "dmn": "Mandé",
      "duala": "Douala",
      "dutch": "Hollandais",
      "dyula(BurkinaFaso)": "Dyula (Burkina Faso)",
      "english": "Anglais",
      "estonian": "Estonien",
      "finnish": "Filandais",
      "fon": "Fon",
      "french": "Français",
      "frenchBasedCreolesAndPidgins": "Créoles et pidgins basés sur le français",
      "fulah(Burkina Faso)": "Fulah (Burkina Faso)",
      "fulah(Guinea)": "Fulah (Guinée)",
      "fulah(Mali)": "Fulah (Mali)",
      "fulah(Nigeria)": "Fulah (Nigéria)",
      "fulah(Senegal)": "Fulah(Sénégal)",
      "fulfulde": "Fulfulde",
      "gaelic": "Gaélique",
      "gagnoaBete": "Gagnoa Bété",
      "galician": "Galicien",
      "georgian": "Géorgien",
      "german": "Allemand",
      "ghanaianPidginEnglish": "Anglais Pidgin Ghanéen",
      "gikuyu": "Gikuyu",
      "greek": "Grec",
      "guadeloupeFrenchCreole": "Créole guadeloupéen",
      "guianeseCreoleFrench": "Créole guyanais",
      "gujarati": "Gujarati",
      "haitian": "Haitian",
      "haryanvi": "Haryanvi",
      "hausa": "Hausa",
      "hebrew": "Hébreu",
      "hindi": "Hindi",
      "humburiSenniSonghay": "Humburi Senni Songhay",
      "hungarian": "Hongrois",
      "icelandic": "Islandais",
      "igbo": "Igbo",
      "indonesian": "Indonésien",
      "instrumental": "Instrumental",
      "irish": "Irlandais",
      "italian": "Italien",
      "japanese": "Japonais",
      "kabyle": "Kabyle",
      "kamba(Kenya)": "Kamba (Kenya)",
      "kannada": "Kannada",
      "kazakh": "Kazakh",
      "kikuyu": "Kikuyu",
      "kimbundu": "Kimbundu",
      "korean": "Coréen",
      "koyraChiiniSonghay": "Koyra Chiini Songhay",
      "lao": "Laotien",
      "latin": "Latin",
      "latvian": "Letton",
      "lingala": "Lingala",
      "lingala(Angola)": "Lingala (Angola)",
      "lingala(CentralAfricanRepublic)": "Lingala (République Centrafricaine)",
      "lingala(DemocraticRepublicOfTheCongo)": "Lingala (République Démocratique du Congo)",
      "lingala(RepublicOfTheCongo)": "Lingala (Congo)",
      "lithuanian": "Lituanien",
      "lubaKatanga": "Luba-Katanga",
      "luo(KenyaAndTanzania)": "Luo (Kenya and Tanzania)",
      "magahi": "Magahi",
      "maithili": "Maithili",
      "malagasy": "Malgache",
      "malay": "Malay",
      "mandigo": "Malinké",
      "maori": "Maori",
      "marathi": "Marathi",
      "moroccanArabic": "Arabe marocain",
      "multilingual": "Multilingue",
      "neapolitan": "Neapolitan",
      "ngazidjaComorian": "Grand comorien",
      "northernSotho": "Sotho du Nord",
      "norwegian": "Norvégien",
      "occitan": "Occitan",
      "persian": "Perse",
      "polish": "Polonais",
      "portuguese": "Portugais",
      "punjabi": "Punjabi",
      "rajasthani": "Rajasthani",
      "romanian": "Romanian",
      "russian": "Russe",
      "sanskrit": "Sanskrit",
      "serbian": "Serbe",
      "shona": "Shona",
      "slovak": "Slovaque",
      "slovenian": "Slovénien",
      "soninke": "Soninke",
      "soninke(Gambia)": "Soninke (Gambie)",
      "soninke(Mali)": "Soninke (Mali)",
      "soninke(Mauritania)": "Soninke (Mauritanie)",
      "soninke(Senegal)": "Soninke (Sénégal)",
      "southernSotho": "Sotho du Sud",
      "spanish": "Espagnol",
      "spanish(LatinAmerica)*": "Espagnol (Amérique Latine)",
      "susu": "Susu",
      "susu(Guinea)": "Susu (Guinée)",
      "susu(Sierra Leone)": "Susu (Sierra Léone)",
      "swahili": "Swahili",
      "swahili(DemocraticRepublicOfTheCongo)": "Swahili (République Démocratique du Congo)",
      "swahili(Kenya)": "Swahili (Kenya)",
      "swahili(Uganda)": "Swahili (Ouganda)",
      "swedish": "Suédois",
      "tagalog": "Tagalog",
      "tamasheq": "Tamasheq",
      "tamil": "Tamil",
      "telugu": "Telugu",
      "thai": "Thai",
      "tswana": "Tswana",
      "tunisianArabic": "Arabe tunisien",
      "turkish": "Turque",
      "ukrainian": "Ukrainien",
      "undefined": "Indéfini",
      "urdu": "Urdu",
      "vietnamese": "Vietnamien",
      "welsh": "Gallois",
      "wolof": "Wolof",
      "wolof(Gambia)": "Wolof (Gambie)",
      "wolof(Mauritania)": "Wolof (Mauritanie)",
      "wolof(Senegal)": "Wolof (Sénégal)",
      "xhosa": "Xhosa",
      "yoruba": "Yoruba",
      "zulu": "Zulu"
    },
    "mail": {
      "placeholderMessage": "Écrivez quelque-chose",
      "subject": "Objet",
      "to": "À"
    },
    "models": {
      "company": "Contrat",
      "distributor": "Distributeur",
      "label": "Label",
      "product": "Album"
    },
    "multiselectCheckboxesDropdown": {
      "allSelected": "Tous",
      "apply": "Appliquer",
      "deselectAll": "Tout désélectionner",
      "selectAll": "Tout sélectionner"
    },
    "nav": {
      "accounting": {
        "name": "Comptabilité",
        "subnav": {
          "extracts": "Extractions",
          "reports": "Compte-rendus"
        }
      },
      "analytics": {
        "name": "Analytics",
        "subnav": {
          "leaks": "Leaks",
          "sales": "Sales",
          "trends": "Trends"
        }
      },
      "catalog": {
        "name": "Catalogue",
        "subnav": {
          "assets": "Assets",
          "products": "Albums",
          "videos": "Vidéos"
        }
      },
      "changelog": "Nouveautés",
      "contracts": {
        "name": "Contrats"
      },
      "dashboard": {
        "name": "Dashboard"
      },
      "distribution": {
        "name": "Distribution",
        "subnav": {
          "claims": "Revendications",
          "deliveries": "Livraisons",
          "dspUploadIdentifications": "Plateformes",
          "releases": "Sorties",
          "submissions": "Soumissions"
        }
      },
      "help": "Aide",
      "producer": "Producer",
      "promotion": {
        "name": "Promotion"
      },
      "roster": {
        "name": "Roster",
        "subnav": {
          "artists": "Artistes",
          "labels": "Labels"
        }
      }
    },
    "newVersion": "Veuillez recharger la page pour obtenir une nouvelle version de l'application",
    "no": "Non",
    "noCancel": "Non, annuler",
    "notifications": {
      "all": "Tout",
      "empty": "Vous n'avez aucune notifications",
      "mentioned": "Mentionné",
      "read": "Marquer comme lu",
      "readAll": "Tout marquer comme lu"
    },
    "offer": {
      "allPlatformName": "Toutes les plateformes",
      "defaultName": "Par défaut",
      "preorder": "Précommande",
      "releaseType": {
        "normal": "",
        "post-release": "Post-Sortie",
        "pre-release": "Pré-Sortie"
      }
    },
    "offers": {
      "confirmStatus": {
        "message": "Êtes-vous sûr de vouloir changer le status de l'offre ?",
        "title": "Status de l'offre"
      },
      "defaultOffers": "Offres par défaut",
      "delete": {
        "error": "L'offre ne peut pas être supprimé",
        "message": "Êtes-vous sûr de vouloir supprimer cette offre ?",
        "success": "L'offre a été supprimé avec succès",
        "title": "Supprimer l'offre"
      },
      "form": {
        "dspHint": "Si vous laissez ce champ vide une nouvelle offre par défaut portant sur l'ensemble des plateformes sera créée, à moins que vous ne renseigniez un ou plusieurs territoires dans le champ dédié",
        "dspUploadIdentifications": "Plateforme",
        "failed": "L'offre n'a pas pu être sauvegardée",
        "originalReleaseDate": "Date de sortie originale",
        "pitchDate": "Date de pitch",
        "pitchDateHint": "Si l'album a une date de sortie éloignée mais qu'il doit être pitché plus tôt aux DSP, vous pouvez fixer une \"date de pitch\" dont l'algorithme de priorisation de la livraison tiendra compte. Veuillez ne l'utiliser que lorsque c'est pertinent pour éviter de perturber la priorisation automatique des livraisons.",
        "preorderDate": "Date de pré-commande",
        "previewBeforeReleaseDate": "Autoriser la preview avant la sortie ?",
        "priceCode": "Prix",
        "releaseDate": "Date de sortie",
        "sectionTitle": {
          "albumInfos": "Informations album",
          "attributes": "Informations générales",
          "tracksInfos": "Informations pistes"
        },
        "status": "Statut",
        "statusHint": "Lorsque votre offre est en brouillon, elle n'est pas active et bloque les envois aux plateformes concernées. Si vous l'archivez, elle n'est plus active et ne bloque plus les envois éventuels",
        "subgenres": "Genre principal",
        "subgenres2": "Genre secondaire",
        "success": "L'offre a été sauvegardée avec succès",
        "territories": "Territoires",
        "territoriesHint": "Si vous laissez ce champ vide, les territoires seront par défaut les mêmes que ceux de l'album",
        "title": "Nom",
        "trackPriceCode": "Prix par piste",
        "tracks": {
          "copyScan": "Copy Scan",
          "disc": "Support",
          "download": "Téléchargement",
          "genre": "Genre",
          "nonInteractiveStream": "Radio",
          "number": "#",
          "onDemandStream": "Streaming",
          "priceCode": "Prix",
          "releaseDate": "Date de sortie",
          "title": "Titre"
        },
        "usage": {
          "copyScan": "Copy Scan",
          "download": "Téléchargement",
          "nonInteractiveStream": "Radio",
          "onDemandStream": "Streaming"
        },
        "usages": "Usages"
      },
      "genre": "Genre :",
      "hints": {
        "cannotUpdateStatusInvalidOffer": "Cette offre est incomplète, vous ne pouvez pas l'updater. Vérifiez qu'elle possède au moins une date de sortie, un genre, un prix album et un prix piste.",
        "cantDeleteActiveOffer": "Vous ne pouvez pas supprimer une offre active. Archivez la dans un premier temps pour pouvoir la supprimer.",
        "cantDeleteUniqDefaultOffer": "Vous ne pouvez pas supprimer une offre par défaut unique. Créez en une nouvelle, cela archivera l'offre par défaut actuelle automatiquement.",
        "cantUpdateStatusUniqDefaultOffer": "Vous ne pouvez pas mettre à jour le statut d'une offre par défaut unique. Créez en une nouvelle, cela archivera l'offre par défaut actuelle automatiquement."
      },
      "newOffer": "Nouvelle offre",
      "otherOffers": "Autres offres",
      "platforms": "Plateformes",
      "preorderDate": "Pré-commande",
      "price": "Prix :",
      "releaseDate": "Date de sortie",
      "sameAsRights": "Comme les droits",
      "status": "Statuts",
      "statuses": {
        "active": "Active",
        "archived": "Archivée",
        "draft": "Brouillon"
      },
      "territories": "Territoires",
      "trackPrice": "Prix piste :",
      "updateStatus": {
        "failedRequest": "Le statut n'a pas pu être mis à jour",
        "successfulRequest": "Le statut a bien été mis à jour"
      },
      "usages": "Usages"
    },
    "password": {
      "form": {
        "reset": "Réinitialisation du mot de passe"
      },
      "new": {
        "placeholder": "Mot de passe",
        "placeholderConfirmation": "Confirmation du mot de passe",
        "title": "Nouveau mot de passe"
      },
      "reset": {
        "hint": "Remplissez le formulaire ci-dessous et les instructions pour réinitialiser votre mot de passe vous seront envoyé par email :",
        "passwordRecovered": "J'ai retrouvé mon mot de passe",
        "placeholder": "Email",
        "success": "Un email avec les instructions pour réinitialiser votre mot de passe vous a été envoyé",
        "title": "Réinitialisation du mot de passe"
      }
    },
    "player": {
      "error": "Une erreur est survenue. Il est probable que les audios ne soient pas disponible pour le moment.",
      "popover": {
        "close": "Fermer",
        "content": "Jouez maintenant n'importe quel album au sein de Labelcamp en cliquant sur sa pochette. Bonne écoute !",
        "title": "Nouveau player !"
      }
    },
    "product": {
      "notSendable": "Non validé",
      "sendable": "Validé"
    },
    "products": {
      "builder": {
        "form": {
          "label": "Label de destination"
        },
        "title": "Créer un nouvel album"
      },
      "button": {
        "addExistingTrack": "Importer des pistes depuis mon catalogue",
        "addNewTrack": "Ajouter de nouvelles pistes",
        "addTrack": "Ajouter",
        "addWork": "Ajouter un oeuvre",
        "edit": "Éditer",
        "hideDetails": "Cacher les détails",
        "hint": {
          "classicalRelease": "Réservé à la musique classique",
          "submit": "Votre album sera traité par nos équipes.",
          "submitOption": "Cet album sera enregistré avec un statut en cours de traitement.",
          "validateOption": "Cet album et son offre par défaut seront enregistrés avec un statut actif."
        },
        "importTrack": "Importer",
        "next": "Étape suivante",
        "numberPlaceholder": "ex: 5",
        "previous": "Étape précédente",
        "removeWork": "Retirer l'oeuvre",
        "saveNext": "Enregistrer & aller à l'étape suivante",
        "savePrevious": "Enregistrer & aller à l'étape précédente",
        "showDetails": "Afficher les détails",
        "submit": "Soumettre",
        "submitting": "Soumission...",
        "validate": "Valider",
        "validating": "Validation..."
      },
      "confirm": {
        "discard": {
          "message": "Il y a des modifications non sauvegardées.\r\nÊtes-vous sûr de vouloir les ignorer ?",
          "title": "Ignorer les modifications"
        },
        "productImport": {
          "message": "Il y a des modifications non sauvegardées et 'import de votre fichier de métadonnées les écrasera.\r\nÊtes-vous sûr de vouloir l'importer ?",
          "title": "Ignorer les modifications"
        }
      },
      "exportExcelHint": "Exporter les métadonnées",
      "filters": {
        "sort": {
          "createdAt": "Derniers créés",
          "name": "Nom",
          "releaseDate": "Date de sortie",
          "updatedAt": "Derniers mis à jours"
        },
        "sortBy": "Trier par :",
        "status": {
          "active": "Actif",
          "archived": "Archivé",
          "draft": "Brouillon",
          "reviewing": "En cours de traitement"
        },
        "statuses": "Status Album :",
        "tag": "Filtrer par artistes, contrats, labels, genres, tags..."
      },
      "form": {
        "appleDigitalMaster": "Apple Digital Master",
        "artists": "Artistes",
        "artistsSubtitle": "Renseigner les artistes principaux",
        "booklet": "Livret",
        "company": "Contrat",
        "compilation": "Compilation",
        "contributors": "Contributeurs",
        "contributorsSubtitle": "Renseigner le rôle de chaque contributeurs",
        "copyrightLine": "© Copyright",
        "cover": "Pochette",
        "date": "Date de sortie",
        "emailADM": "Email de l'ingénieur son",
        "generateUpc": "Générer un UPC",
        "generatingUpc": "Génération de l'UPC...",
        "genre": "Genre principal",
        "genre2": "Genre secondaire",
        "hint": {
          "appleDigitalMaster": "Réservé pour les sorties Apple Digital Masters.",
          "booklet": "PDF requis – Taille du fichier < 10 Mo – Format 28x21 cm – 4 pages minimum – 72 dpi",
          "cannotDuplicateEmptyTrack": "Impossible de dupliquer car l'album n'a pas de pistes",
          "clean": "Cocher seulement s'il existe une version explicite de cette piste",
          "company": "Un changement de contrat affectera les droits, les rapports de vente ainsi que les analytics",
          "contract": "Un changement de contrat affectera les droits, les rapports de vente ainsi que les analytics",
          "contributors": "Renseigner tous les contributeurs ayant participé à toutes les pistes de cet album (ex: Producteur, Guitare, Ingénieur du son)",
          "copyrightLine": "Détenteur des droits de la pochette : Indiquer le nom de l'actuel détenteur exclusif (personne ou entité) du contenu de la pochette, précédé de l'année de sa première parution (physique ou digitale)",
          "cover": "JPEG requis – Taille du fichier < 20 Mo - Format carré entre 3000x3000 px et 4500x4500 px – RVB",
          "labelInternalReference": "Il s'agit de la référence utilisée dans votre label pour cette sortie",
          "originalReleaseDate": "Obligatoire si l'album est déjà sorti auparavant (digital ou physique)",
          "productionLine": "Détenteur des droits phonograpiques : Indiquer le nom de l'actuel détenteur exclusif (personne ou entité) du contenu du phonogramme, précédé de l'année de sa première parution (physique ou digitale)",
          "subtitle": "Laisser vide sauf pour différencier d'une version originale du morceau (ex: Live, Acoustique, Radio Edit, etc)",
          "ttmlLyrics": "Timed Text Markup Language – Vous devez founir un fichier .ttml, si vous ne connaissez pas ce format vous pouvez vous aider de cette <a href=\"https://help.apple.com/itc/videoaudioassetguide/#/itc421fbba5f\" target=\"_blank\">documentation</a>",
          "upcCode": "L'UPC est le code barre unique que doit avoir chaque sortie."
        },
        "information": "Informations",
        "isADM": "L'ingénieur du son de cet album est agréé Apple Digital Master",
        "isCompilation": "Cet album est une compilation",
        "label": "Label",
        "labelInternalReference": "Référence catalogue",
        "language": "Langue des métadonnées",
        "musicGenre": "Genre musical",
        "name": "Titre de l'album",
        "originalReleaseDate": "Date de sortie originale",
        "preorderDate": "Date de précommande (optionnel)",
        "price": "Prix album",
        "productType": "Type de produit",
        "productionLine": "℗ Production",
        "productionYear": "Année de production",
        "releaseDate": "Date de sortie",
        "subtitle": "Version du titre (optionnel)",
        "title": "Titre",
        "trackPriceCode": "Prix piste",
        "upcCode": "UPC",
        "usage": "Usage",
        "work": "Oeuvre"
      },
      "import": {
        "errors": {
          "title": "Erreurs à l'import des métadonnées"
        },
        "notifications": {
          "success": "Les metadonnées ont été importé avec succès"
        }
      },
      "importExcelHint": "Importer les métadonnées",
      "index": {
        "toolbar": {
          "hitlists": {
            "amazon": "Amazon",
            "apple": "Apple",
            "deezer": "Deezer",
            "title": "Formulaires de pitchs plateforme"
          }
        }
      },
      "leaving": {
        "body": "Une numérisation est en cours, êtes vous sûr de vouloir quitter ?"
      },
      "nav": {
        "analytics": "Analytics",
        "claims": "Revendications",
        "distribution": "Distribution",
        "dspStates": "Statuts plateforme",
        "email": "Partage email",
        "generalInformation": "Informations Générales",
        "importTasks": "Numérisations",
        "index": "Information",
        "leaks": "Leaks",
        "offers": "Offres",
        "others": "Autre partages vidéo",
        "playPage": "Page Play",
        "premiums": "Premiums",
        "promotion": "Promotion",
        "rights": "Droits",
        "sendTasks": "Livraisons",
        "share": "Partage",
        "soundcloud": "SoundCloud",
        "summary": "Résumé",
        "territoryAndPlatformRights": "Droits Territoriaux & Plateformes",
        "tracksAndAssets": "Pistes & Fichiers",
        "youtube": "YouTube"
      },
      "new": {
        "placeholder": "Ajouter un album"
      },
      "reorderTracks": {
        "notifications": {
          "error": "Une erreur inattendue s'est produite et les pistes n'ont pas été réordonnées<br />Merci de réessayer",
          "success": "Les pistes ont été réordonnées avec succès"
        }
      },
      "rights": {
        "confirm": {
          "option": {
            "no": "Non, je souhaite demander une modification.",
            "yes": "Oui, c'est le cas."
          },
          "placeholder": "Veuillez décrire le changement demandé de la manière la plus précise possible.\nExemples : WW - UK; WW sauf UK, Spotify uniquement; toutes les plateformes sauf Spotify",
          "subtitle": "Les droits ci-dessus s'appliquent-ils à votre album ?",
          "title": "Confirmez les droits de votre album"
        },
        "hint": {
          "defaultRight": "Les droits par défauts sont hérités du label : {label}"
        },
        "notDistributed": "Non distribué",
        "platforms": "Plateformes",
        "territories": "Territoires",
        "title": "Plateformes et territoires distribués"
      },
      "rolesManager": {
        "addNewRole": "Nouveau Contributeur"
      },
      "show": {
        "albumStatus": "Statut album",
        "artists": {
          "contributors": "nom",
          "cta": "Voir les artistes",
          "empty": "Aucun artistes détectés sur cet album",
          "role": "Sélectionner un rôle",
          "title": "Liste des artistes"
        },
        "backTo": "Retour à l'index",
        "submission": {
          "approve": "Approuver",
          "priority": "Priorité",
          "reject": "Rejeter",
          "status": "Statut",
          "user": "Assigné"
        }
      },
      "statuses": {
        "active": "Actif",
        "archived": "Archivé",
        "draft": "Brouillon",
        "reviewing": "En cours de traitement"
      },
      "summary": {
        "artwork": "Artwork",
        "audio": "Pistes et Audios",
        "hasBooklet": "Livret importé",
        "hasCover": "Pochette importée",
        "meta": "Informations sur l'album",
        "noComment": "Aucun commentaire",
        "noFile": "Aucun fichier",
        "rights": "Droits plateformes et territoriaux",
        "volume": "Support"
      },
      "title": "Albums",
      "tracklisting": {
        "actions": {
          "classicView": "Vue classique",
          "columnView": "Vue Colonne",
          "hideDetails": "Cacher les détails",
          "showDetails": "Afficher les détails"
        },
        "columns": {
          "accordion": "Accordion",
          "acousticGuitar": "Acoustic Guitar",
          "actor": "Actor",
          "altoSaxophone": "Alto Saxophone",
          "altoVocals": "Alto (Vocals)",
          "archlute": "Archlute",
          "arranger": "Arranger",
          "artist": "Artist",
          "audioLanguage": "Audio Language",
          "author": "Author",
          "backgroundVocals": "Background Vocals",
          "baglama": "Baglama",
          "balafon": "Balafon",
          "bandoneon": "Bandoneon",
          "banjo": "Banjo",
          "baritoneSaxophone": "Baritone Saxophone",
          "baritoneVocals": "Baritone (Vocals)",
          "baroqueGuitar": "Baroque Guitar",
          "bass": "Bass (Classical Music)",
          "bassClarinet": "Bass Clarinet",
          "bassGuitar": "Bass Guitar",
          "bassTrombone": "Bass Trombone",
          "bassVocals": "Bass (Vocals)",
          "bassoon": "Bassoon",
          "bongos": "Bongos",
          "bouzouki": "Bouzouki",
          "cajon": "Cajon",
          "cello": "Cello",
          "chamberMusicEnsemble": "Chamber Music Ensemble",
          "choir": "Choir",
          "choirMaster": "Choir Master",
          "chorus": "Chorus",
          "clarinet": "Clarinet",
          "classicalGuitar": "Classical Guitar",
          "clavier": "Clavier",
          "clean": "Clean",
          "composer": "Composer",
          "conductor": "Conductor",
          "conflict": "ISRC Conflict",
          "congas": "Congas",
          "contraltoVocals": "Contralto (Vocals)",
          "contributor": "Contributor",
          "cornet": "Cornet",
          "counterTenor": "Counter Tenor (Vocals)",
          "dj": "DJ",
          "djembe": "Djembe",
          "doubleBass": "Double Bass",
          "drums": "Drums",
          "duration": "Duration",
          "electricGuitar": "Electric Guitar",
          "explicit": "Parental Advisory",
          "explicitContent": "Explicit Content",
          "featuring": "Featured Artists",
          "fiddle": "Fiddle",
          "firstViolin": "First Violin",
          "flugelhorn": "Flugelhorn",
          "flute": "Flute",
          "fretlessElectricBassGuitar": "Fretless Electric Bass Guitar",
          "guitar": "Guitar",
          "guitarTechnician": "Guitar Technician",
          "hammondOrgan": "Hammond Organ",
          "handclaps": "Handclaps",
          "harmonica": "Harmonica",
          "harmonium": "Harmonium",
          "harmonyVocals": "Harmony Vocals",
          "harp": "Harp",
          "harpsichord": "Harpsichord",
          "hasLyricsTtml": "Lyrics TTML",
          "hiRes": "24-Bit",
          "horn": "Horn",
          "informations": "Information",
          "isVideo": "Video",
          "isrc": "ISRC",
          "iswcCode": "ISWC",
          "keyboards": "Keyboards",
          "kora": "Kora",
          "leadGuitar": "Lead Guitar",
          "leadVocals": "Lead Vocals",
          "librettist": "Librettist",
          "lowRes": "16-Bit",
          "lute": "Lute",
          "lyricist": "Lyricist",
          "lyrics": "Lyrics",
          "lyricsDetails": "{track} - Lyrics",
          "mandolin": "Mandolin",
          "marimba": "Marimba",
          "masterTypes": "Master Type",
          "masteringEngineer": "Mastering Engineer",
          "melodica": "Melodica",
          "mezzoSopranoVocals": "Mezzo-soprano (Vocals)",
          "mixingEngineer": "Mixing Engineer",
          "musicDirector": "Music Director",
          "musicalEnsemble": "Musical Ensemble",
          "number": "#",
          "oboe": "Oboe",
          "omnichord": "Omnichord",
          "orchestra": "Orchestra",
          "organ": "Organ",
          "oud": "Oud",
          "pedalSteelGuitar": "Pedal Steel Guitar",
          "percussion": "Percussion",
          "performer": "Performer",
          "piano": "Piano",
          "piccolo": "Piccolo",
          "previewEnd": "Preview End",
          "previewStart": "Preview Start",
          "producer": "Producer",
          "productionAssistant": "Production Assistant",
          "productionDate": "Production Date",
          "productionLine": "Production",
          "publisher": "Publisher",
          "recorder": "Recorder",
          "recordingEngineer": "Recording Engineer",
          "remixer": "Remixer",
          "resolution": "Resolution",
          "rhodesPiano": "Rhodes Piano",
          "rhythmGuitar": "Rhythm Guitar",
          "saxophone": "Saxophone",
          "saz": "Saz",
          "secondViolin": "Second Violin",
          "shakers": "Shakers",
          "sitar": "Sitar",
          "soloist": "Soloist",
          "songwriter": "Songwriter",
          "sopranoSaxophone": "Soprano Saxophone",
          "sopranoSolo": "Soprano",
          "soundEngineer": "Sound Engineer",
          "steelGuitar": "Steel Guitar",
          "subtitle": "Version",
          "synthesizer": "Synthetizer",
          "tabla": "Tabla",
          "tambourine": "Tambourine",
          "tenorSaxophone": "Tenor Saxophone",
          "tenorSolo": "Tenor",
          "timbales": "Timbales",
          "timpani": "Timpani",
          "title": "Title",
          "transcriber": "Transcriber",
          "trombone": "Trombone",
          "trumpet": "Trumpet",
          "tuba": "Tuba",
          "ukulele": "Ukulele",
          "uprightBass": "Upright Bass",
          "vibraphone": "Vibraphone",
          "video": "Video Track",
          "viol": "Viol",
          "viola": "Viola",
          "violaDaGamba": "Viola Da Gamba",
          "violin": "Violin",
          "vocalAdaptation": "Vocal Adaptation",
          "vocalEngineer": "Vocal Engineer",
          "vocalSolo": "Vocal Solo",
          "work": "Work",
          "wurlitzer": "Wurlitzer",
          "xylophone": "Xylophone"
        }
      },
      "validate": {
        "error": "Vous avez {count, plural, =1 {# erreur} other {# erreurs}} à corriger.",
        "title": "Erreur"
      },
      "volume": {
        "errors": {
          "duplicatedFiles": "Il y a des numéros de piste dupliqué dans vos fichiers audio. Vérifiez votre source, il ne doit pas y avoir de fichier audio avec le même numéro de piste.",
          "filesCountMismatch": "Le nombre d'audio présent sur la source sélectionnée ne correspond pas au nombre de pistes à numeriser. Vérifiez la source sélectionnée, elle doit contenir des audios en WAV numérotés (01xxx.wav, 02yyy.wav, etc.)",
          "multipleErrors": "erreurs",
          "orphanTracks": "Les pistes  n'ont pas pu être associé à un fichier audio. Vérifiez votre source, le nom de vos fichiers audio doivent correspondre à celui de leur piste.",
          "singleError": "erreur",
          "unsupportedFileNames": "Impossible to parse some of your audio files name. Check your source, it must contains numbered audio files name (01 - xxx.wav, 02 - yyy.wav, etc.)",
          "unsupportedFileTypes": "Type de fichiers invalides, vos audios doivent être des WAV, FLAC (16 ou 24 bits) ou AIFF"
        },
        "newVolume": "Nouveau support",
        "title": "Support"
      }
    },
    "quit": "Quitter",
    "reactivate": "Réactiver",
    "records": {
      "confirm": {
        "delete": {
          "message": "Êtes-vous sûr de vouloir supprimer ce fichier audio ?",
          "title": "Supprimer le fichier audio"
        }
      }
    },
    "releases": {
      "album": {
        "backCatalogue": "Back catalogue ({originalReleaseDate})",
        "tagTargets": "Tags :",
        "upc": "UPC :"
      },
      "albumStatus": {
        "active": "Actif",
        "archived": "Archivé",
        "draft": "Brouillon",
        "reviewing": "En cours de traitement",
        "submitted": "Soumis"
      },
      "columns": {
        "album": "Détails de l'album",
        "delivery": "Statut de livraison",
        "productStatus": "Statut d'album",
        "release": "Offres"
      },
      "deliveredPlatforms": "Plateformes livrées",
      "deliveryStatus": {
        "completed": "Livrées : ",
        "error": "Erreurs : ",
        "sending": "En cours d'envoi : ",
        "stuck": "Bloquées : ",
        "total": "Total : ",
        "waiting": "En attente : "
      },
      "empty": {
        "message": "Vous n'avez aucune sortie prévue à partir de cette date",
        "title": "Pas de sortie planifiée"
      },
      "emptyFilters": {
        "message": "Aucune sortie ne correspond. Essayez avec différents filtres",
        "title": "Ooops !"
      },
      "filters": {
        "statuses": "Status d'offres :",
        "tag": "Filtrer par artistes, labels, genres...",
        "type": {
          "all": "Toutes",
          "backCatalogue": "Back catalogue",
          "new": "Nouveautés"
        },
        "types": "Type de sortie :"
      },
      "idolcampProductLink": "Voir l'album sur Idolcamp",
      "offerStatuses": {
        "active": "{count, plural, =1 {# active} other {# actives}}",
        "archived": "{count, plural, =1 {# archivée} other {# archivées}}",
        "draft": "{count, plural, =1 {# brouillon} other {# brouillons}}"
      },
      "offersDetailsModal": {
        "title": "Détails des offres"
      },
      "releasesCount": "{count, plural, =1 {# offre} other {# offres}}",
      "showDetails": "Voir les détails",
      "title": "Sorties"
    },
    "reload": "Recharger",
    "removeAll": "Tout retirer",
    "request": "Demander",
    "resend": "Renvoyer",
    "resetting": "Réinitialisation...",
    "resources": {
      "artist": "Artiste",
      "company": "Contrat",
      "companyTag": "Tags contrat",
      "continent": "Continents",
      "creator": "Créateur",
      "distributor": "Distributeur",
      "distributorProductSubgenre": "Genre",
      "dsp": "Plateformes",
      "dspTag": "Profils plateforme",
      "gender": "Sexe",
      "group": "Groupes",
      "label": "Label",
      "playlist": "Playlists",
      "product": "Album",
      "productGenre": "Genre",
      "retail": "Plateforme",
      "sendTaskFactory": "Livraisons groupées",
      "source": "Source",
      "tag": "Tags Albums",
      "tagTarget": "Tags album",
      "territory": "Pays",
      "track": "Piste",
      "user": "Utilisateurs",
      "userAgeRange": "Âges",
      "video": "Vidéo"
    },
    "revoke": "Révoquer",
    "rights": {
      "delete": {
        "confirm": {
          "message": "Êtes-vous sûr de vouloir supprimer cette régle de distribution ?",
          "title": "Supprimer la règle de distribution"
        }
      },
      "distributor": {
        "title": "Distributeur"
      },
      "rules": {
        "empty": "Aucune règle de distribution ajoutée",
        "header": {
          "platforms": "Plateformes",
          "rule": "Règle",
          "territories": "Territoires"
        },
        "labelComments": "Commentaires label :",
        "new": "Nouvelle règle",
        "note": {
          "add": "Ajouter une note",
          "edit": "Éditer la note",
          "view": "Voir la note"
        },
        "placeholder": {
          "aggregatedDspsPlaceholder": "Ajouter des plateformes ou profils plateformes",
          "aggregatedTerritoriesPlaceholder": "Ajouter des territoires (continent ou pays)",
          "comments": "Écrivez une note",
          "except": "Sauf",
          "exceptHint": "Ajouter une exception"
        },
        "searchMessage": "Taper au moins 3 caractères pour chercher",
        "title": "Règles de distribution",
        "types": {
          "add": "Ajouter",
          "clear": "Enlever les droits parents",
          "remove": "Enlever"
        }
      },
      "territories": {
        "title": "Droits territoriaux"
      }
    },
    "roles": {
      "aandr": "A&R",
      "aandrAdministration": "Administration A&R",
      "accordion": "Accordéon",
      "acousticBaritoneGuitar": "Guitare acoustique baryton",
      "acousticBassGuitar": "Guitare basse acoustique",
      "acousticFretlessGuitar": "Guitare acoustique sans frête",
      "acousticGuitar": "Guitare acoustique",
      "actor": "Acteur",
      "agent": "Agent",
      "altoClarinet": "Clarinette alto",
      "altoFlute": "Flûte alto",
      "altoHorn": "Cor alto",
      "altoRecorder": "Flûte à bec alto",
      "altoSaxophone": "Saxophone Alto",
      "altoTrombone": "Trombone alto",
      "altoViolin": "Violon alto",
      "altoVocals": "Alto (Voix)",
      "appalachianDulcimer": "Dulcimer des Appalaches",
      "archlute": "Archiluth",
      "arranger": "Arrangeur",
      "artDirection": "Direction artistique",
      "artist": "Artiste principal",
      "artistManagement": "Management des artistes",
      "artwork": "Pochette",
      "assistantConductor": "Chef d'orchestre assistant",
      "assistantDirector": "Directeur adjoint",
      "assistantMasteringEngineer": "Ingénieur mastering assistant",
      "assistantMixer": "Mixer assistant",
      "assistantMixingEngineer": "Ingénieur mixage assistant",
      "assistantProducer": "Producteur assistant",
      "assistantRecordingEngineer": "Assistant ingénieur du son",
      "assistantSoundEngineer": "Assistant ingénieur",
      "author": "Écrivain",
      "autoharp": "Autoharpe",
      "backgroundVocals": "Chœurs de fond",
      "baglama": "Baglama",
      "bagpipes": "Cornemuse",
      "balafon": "Balafon",
      "balalaika": "Balalaïka",
      "band": "Groupe",
      "bandoneon": "Bandonéon",
      "bandurria": "Bandurria",
      "banjo": "Banjo",
      "bansuri": "Bansouri",
      "barbat": "Barbat",
      "baritoneGuitar": "Guitare baryton",
      "baritoneHorn": "Saxophone baryton",
      "baritoneSaxophone": "Saxophone baryton",
      "baritoneVocals": "Baryton (Voix)",
      "baroqueGuitar": "Guitare baroque",
      "barrelOrgan": "Orgue de Barbarie",
      "bass": "Basse (Musique classique)",
      "bassClarinet": "Clarinette basse",
      "bassDrum": "Gosse caisse",
      "bassFlute": "Flûte basse",
      "bassGuitar": "Guitare basse",
      "bassHarmonica": "Harmonica basse",
      "bassOboe": "Hautbois baryton",
      "bassRecorder": "Flûte à bec basse",
      "bassSaxophone": "Saxophone basse",
      "bassTrombone": "Trombone basse",
      "bassTrumpet": "Trompette basse",
      "bassVocals": "Basse (Voix)",
      "bassetClarinet": "Clarinette de basset",
      "bassetHorn": "Cor de basset",
      "bassoon": "Basson",
      "bataDrums": "Batá",
      "beatBoxing": "Beatboxing",
      "bells": "Cloche",
      "bendir": "Bendir",
      "berimbau": "Berimbau",
      "biwa": "Biwa",
      "bonang": "Bonang",
      "bongos": "Bongos",
      "bouzouki": "Bouzouki",
      "bugle": "Bugle",
      "buttonAccordion": "Accordéon à boutons",
      "cMelodySax": "Saxophone C-mélody",
      "cajon": "Cajón",
      "calliope": "Calliope",
      "cameraOperator": "Cadreur",
      "castanets": "Castagnettes",
      "cavaquinho": "Cavaquinho",
      "caxixi": "Caxixi",
      "celeste": "Célesta",
      "cello": "Violoncelle",
      "chamberMusicEnsemble": "Ensemble de musique de chambre",
      "chamberOrgan": "Orgue de chambre",
      "chamberlin": "Chamberlin",
      "chantVocals": "Chant / Voix",
      "chapmanStick": "Chapman stick",
      "charango": "Charango",
      "chekere": "Chékéré",
      "chimes": "Chimes",
      "choir": "Chorale",
      "choirConductor": "Chef de chorale",
      "choirMaster": "Maître de chœur",
      "chorus": "Chœurs",
      "chorusMaster": "Chef de chœur",
      "chromaticHarmonica": "Harmonica chromatique",
      "cimbalom": "Cymbalum",
      "cinemaphotographer": "Directeur de la photographie",
      "cittern": "Cistre",
      "clarinet": "Clarinette",
      "classicalGuitar": "Guitare classique",
      "claves": "Claves",
      "clavichord": "Clavicorde",
      "clavinet": "Clavinet",
      "clean": "Clean",
      "coProducer": "Coproducteur",
      "composer": "Compositeur",
      "concertHarp": "Harpe de concert",
      "concertina": "Concertina",
      "conchShells": "Conque",
      "conductor": "Chef d'orchestre",
      "confirm": {
        "applyOnAllTracks": {
          "message": "Êtes-vous sûr de vouloir appliquer ce rôle à toutes les pistes créées?",
          "title": "Dupliquer le rôle "
        },
        "remove": {
          "message": "Êtes-vous sûr de vouloir supprimer ce rôle?",
          "title": "Supprimer un rôle"
        }
      },
      "congas": "Congas",
      "contrabass": "Contrebasse",
      "contrabassClarinet": "Clarinette contrebasse",
      "contrabassFlute": "Flûte basse",
      "contrabassRecorder": "Flûte à bec contrebasse",
      "contrabassSarrusophone": "Sarrussophone",
      "contrabassSaxophone": "Saxophone contrebasse",
      "contrabassTrombone": "Trombone contrebasse",
      "contrabassoon": "Contrebasson",
      "contraltoClarinet": "Clarinette contralto",
      "contraltoVocals": "Contralto Voix",
      "contributor": "Contributeur",
      "copyrightLine": "© Copyright",
      "cornet": "Cornet",
      "costumeDesign": "Design des costumes",
      "counterTenor": "Contreténor (Voix)",
      "coverArt": "Pochette",
      "coverDesign": "Design de la pochette",
      "cowbell": "Sonnaille",
      "creativeDirector": "Directeur artistique",
      "crotales": "Crotales",
      "crumhorn": "Cromorne",
      "cuatro": "Cuatro",
      "cuesnophone": "Couesnophone",
      "curtal": "Douçaine",
      "cymbals": "Cymbales",
      "daf": "Daf",
      "dance": "Danse",
      "dancer": "Danseur",
      "design": "Design",
      "dhol": "Dohol",
      "diddleyBow": "Diddley bow",
      "didgeridoo": "Didjeridoo",
      "director": "Directeur musical",
      "dj": "DJ",
      "djembe": "Djembé",
      "dobroGuitar": "Guitare dobro",
      "doubleBass": "Contrebasse",
      "doumbek": "Doumbek",
      "drumMachine": "Batterie électronique",
      "drumProgramming": "Programmation de batterie électronique",
      "drums": "Batterie",
      "duduk": "Doudouk",
      "dulcimer": "Dulcimer",
      "eBow": "Ebow",
      "editor": "Éditeur",
      "effects": "Effets",
      "eightStringGuitar": "Guitare à 8 cordes",
      "electricBassGuitar": "Guitare basse électrique",
      "electricCello": "Violoncelle électrique",
      "electricFretlessGuitar": "Guitare électrique sans frête",
      "electricGuitar": "Guitare Électrique",
      "electricPiano": "Piano électrique",
      "electricSitar": "Sitar électrique",
      "electricUprightBass": "Contrebasse électrique",
      "electricViola": "Alto électrique",
      "electricViolin": "Violon électrique",
      "electronicValveInstrument": "Instrument à valve électronique",
      "electronicWindInstrument": "Instrument à vent électronique",
      "engineer": "Ingénieur",
      "englishHorn": "Cor anglais",
      "ensemble": "Ensemble",
      "euphonium": "Euphonium",
      "executiveProducer": "Producteur exécutif",
      "farfisa": "Farfisa",
      "featuring": "Featuring",
      "fenderRhodes": "Fender Rhodes",
      "fiddle": "Fiddle",
      "fife": "Fifre",
      "fingerCymbals": "Cymbales à doigts",
      "fingerSnaps": "Claquements de doigts",
      "firstCello": "Premier voloncelle",
      "firstViolin": "Premier Violon",
      "flamencoGuitar": "Guitare flamenca",
      "flugelhorn": "Flügelhorn",
      "flute": "Flûte",
      "forCompanyId": "Contrat",
      "fortepiano": "Pianoforte",
      "fourStringGuitar": "Guitare à 4 cordes",
      "frameDrum": "Tambour sur cadre",
      "frenchHorn": "Cor d'harmonie",
      "fretlessElectricBassGuitar": "Guitare basse électrique sans frettes",
      "gaffer": "Chef électricien",
      "gender": "Gendèr",
      "ghatam": "Ghatam",
      "gitern": "Guiterne",
      "glockenspiel": "Glockenspiel",
      "gong": "Gong",
      "graphicDesigner": "Designer graphique",
      "group": "Groupe",
      "guestVocals": "Voix guests",
      "guiro": "Güiro",
      "guitar": "Guitare",
      "guitarTechnician": "Technicien guitare",
      "guitars": "Guitares",
      "gutStringGuitar": "Guitare à cordes de boyau",
      "haghouge": "Hag'houge",
      "hammeredDulcimer": "Hackbrett valaisan",
      "hammondOrgan": "Orgue Hammond",
      "handclaps": "Handclaps",
      "hardingfele": "Violon Hardanger",
      "harmonica": "Harmonica",
      "harmonium": "Harmonium",
      "harmonyVocals": "Harmonies vocales",
      "harp": "Harpe",
      "harpsichord": "Clavecin",
      "horn": "Cor",
      "hornSection": "Section de cuivres",
      "horns": "Cors",
      "hurdyGurdy": "Vielle à roue",
      "illustration": "Illustration",
      "kalimba": "Kalimba",
      "karkabaMetalCastanets": "Kerkeba (castagnettes métalliques)",
      "kazoo": "Kazoo",
      "kendang": "Kendang",
      "kenongKethuk": "Kenong/ketuk",
      "keyGrip": "Chef machiniste",
      "keyboardBass": "Bass synthé",
      "keyboards": "Claviers",
      "keytar": "Keytar",
      "kora": "Kora",
      "koto": "Koto",
      "lapSteelGuitar": "Lapsteel",
      "leadGuitar": "Guitare solo",
      "leadViolin": "Premier violon",
      "leadVocals": "Voix principale",
      "librettist": "Librettiste",
      "lightingDirector": "Chef éclairagiste",
      "linerNotes": "Notes de livret",
      "lowWhistle": "Whistle de concert",
      "lute": "Luth",
      "lyricist": "Auteur",
      "lyricon": "Lyricon",
      "lyrics": "Paroles",
      "mandocello": "Mandoloncelle",
      "mandola": "Mandole",
      "mandolin": "Mandoline",
      "manzello": "Manzello",
      "maracas": "Maracas",
      "marimba": "Marimba",
      "masteringEngineer": "Ingénieur Mastering",
      "mc": "MC",
      "mellophone": "Mellophone",
      "mellophonium": "Mellophonium",
      "mellotron": "Mellotron",
      "melodica": "Melodica",
      "mezzoSopranoVocals": "Mezzo-soprano (Voix)",
      "mixingEngineer": "Ingénieur mixeur",
      "modularSynth": "Synthétiseur modulaire",
      "motionDesigner": "Motion designer",
      "mouthOrgan": "Harmonica",
      "mridangam": "Mridangam",
      "musette": "Musette",
      "musicDirector": "Directeur musical",
      "musicalEnsemble": "Ensemble",
      "name": "Titre de l'album",
      "narrator": "Narrateur",
      "ney": "Ney",
      "noises": "Bruits",
      "nylonStringGuitar": "Guitare à cordes de nylon",
      "oboe": "Hautbois",
      "oboeDamore": "Hautbois d'amour",
      "ocarina": "Ocarina",
      "omnichord": "Omnichord",
      "ondesMartenot": "Ondes Martenot",
      "orchestra": "Orchestre",
      "organ": "Orgue",
      "oud": "Oud",
      "packageDesign": "Design du package",
      "panFlute": "Flûte de pan",
      "panpipes": "Syrinx",
      "parlourGuitar": "Guitare de salon",
      "pedalSteelGuitar": "Guitare pédale Steel",
      "peking": "Saron panerus",
      "pennyWhistle": "Flûteau",
      "percussion": "Percussions",
      "performer": "Interprète",
      "photographer": "Photographe",
      "piano": "Piano",
      "piccolo": "Piccolo",
      "piccoloBass": "Basse piccolo",
      "piccoloOboe": "Hautbois piccolo",
      "piccoloTrumpet": "Trompette piccolo",
      "pikasaGuitar": "Guitare Pikasso à 42 cordes",
      "pipeOrgan": "Orgue à tuyaux",
      "portugueseGuitar": "Guitare portugaise",
      "postProduction": "Post-production",
      "preparedPiano": "Piano préparé",
      "producer": "Producteur artistique (réalisateur)",
      "productManager": "Chef de produit",
      "productionAssistant": "Assistant producteur",
      "productionCoordinator": "Coordinateur de production",
      "productionLine": "℗ Production",
      "productionYear": "Année de production",
      "programmer": "Programmateur",
      "programmingEngineer": "Ingénieur de programmation",
      "projectCoordinator": "Coordinateur du projet",
      "projectManager": "Chef de projet",
      "psaltery": "Psaltérion",
      "publisher": "Éditeur",
      "rap": "Rap",
      "rebab": "Rebab",
      "rebec": "Rebec",
      "recorder": "Flûte à bec",
      "recordingEngineer": "Ingénieur son studio",
      "reedOrgan": "Harmonium",
      "remixer": "Remixeur",
      "requintoGuitar": "Guitare Requinto",
      "rhodesPiano": "Piano Rhodes",
      "rhythmGuitar": "Guitare rythmique",
      "sackbut": "Sacqueboute",
      "sampler": "Sampleur",
      "santoor": "Santour",
      "santur": "Santour",
      "sarangi": "Sarangi",
      "sarod": "Sarode",
      "saron": "Saron",
      "sarrusophone": "Sarrussophone",
      "saxello": "Saxello",
      "saxophone": "Saxophone",
      "saz": "Saz",
      "secondCello": "Second violoncelle",
      "secondSopranoVocals": "Seconde voix soprano",
      "secondViolin": "Second violon",
      "serpent": "Serpent",
      "setDesigner": "Scénographe",
      "sevenStringGuitar": "Guitare à 7 cordes",
      "shakers": "Shakers",
      "shakuhachi": "Shakuhachi",
      "shawm": "Hautbois",
      "shekere": "Shekeré",
      "shenai": "Shinai",
      "sitar": "Sitar",
      "sixStringAcousticGuitar": "Guitare acoustique à 6 cordes",
      "sixStringGuitar": "Guitare à 6 cordes",
      "sleighBells": "Grelots",
      "slenthem": "Slentem",
      "slideGuitar": "Guitare slide",
      "slideSaxophone": "Saxophone sans clés",
      "slideTrumpet": "Trompette à coulisse",
      "slideWhistle": "Flûte à coulisse",
      "snareDrum": "Caisse claire",
      "soloFlute": "Flûte solo",
      "soloGuitar": "Guitare solo",
      "soloist": "Solo",
      "songwriter": "Paroles",
      "sopraninoClarinet": "Clarinette sopranino",
      "sopraninoSaxophone": "Saxophone sopranino",
      "soprano": "Soprano",
      "sopranoClarinet": "Clarinette soprano",
      "sopranoFlute": "Flûte soprano",
      "sopranoRecorder": "Flûte à bec soprano",
      "sopranoSaxophone": "Saxophone soprano",
      "sopranoSolo": "Soprano",
      "sopranoViolin": "Violon soprano",
      "sopranoVocals": "Voix soprano",
      "soundEditor": "Monteur son",
      "soundEngineer": "Ingénieur du son",
      "sousaphone": "Soubassophone",
      "spanishGuitar": "Guitare espagnole",
      "spinet": "Épinette",
      "spokenWord": "Spoken word",
      "spoons": "Cuillères",
      "steelDrums": "Steel pan",
      "steelGuitar": "Guitare Steel",
      "stringArranger": "Arrangeur de cordes",
      "strings": "Cordes",
      "stringsConductor": "Chef d'orchestre à cordes",
      "stritch": "Stritch",
      "subtitle": "Version du titre",
      "surdo": "Surdo",
      "synclavier": "Synclavier",
      "synthesizer": "Synthé",
      "tabla": "Tabla",
      "taborPipe": "Flûte à trois trous",
      "tackPiano": "Piano bastringue",
      "talkbox": "Talkbox",
      "talkingDrum": "Tama",
      "tamborim": "Tamborim",
      "tamboura": "Tamboura",
      "tambourine": "Tambourin",
      "tanpura": "Tanpura",
      "tarogato": "Tarogato",
      "templeBlocks": "Temple blocks",
      "tenorBanjo": "Banjo ténor",
      "tenorDrum": "Caisse roulante",
      "tenorGuitar": "Guitare ténor",
      "tenorHorn": "Cor ténor",
      "tenorRecorder": "Flûte à bec ténor",
      "tenorSaxophone": "Saxophone ténor",
      "tenorSolo": "Ténor",
      "tenorTrombone": "Trombone ténor",
      "tenorViolin": "Violon ténor",
      "tenorVocals": "Voix ténor",
      "thavil": "Thavil",
      "theorbo": "Théorbe",
      "theremin": "Thérémine",
      "timbales": "Timbales",
      "timpani": "Timpani",
      "tinWhistle": "Flûte irlandaise",
      "tonmeister": "Tonmeister",
      "toyPiano": "Piano jouet",
      "transcriber": "Transcripteur",
      "tres": "Très",
      "triangle": "Triangle",
      "trombone": "Trombone",
      "trombonium": "Trombonium",
      "trumpet": "Trompette",
      "tuba": "Tuba",
      "tubularBells": "Carillon tubulaire",
      "turntable": "Platine tourne-disques",
      "twelveStringAcousticGuitar": "Guitare acoustique à 12 cordes",
      "twelveStringElectricGuitar": "Guitare électrique à 12 cordes",
      "twelveStringGuitar": "Guitare à 12 cordes",
      "udu": "Udu",
      "uilleannPipes": "Cornemuse irlandaise",
      "ukulele": "Ukulélé",
      "uprightBass": "Contrebasse",
      "uprightPiano": "Piano droit",
      "valveTrombone": "Trombone à pistons",
      "veena": "Vînâ",
      "vibraphone": "Vibraphone",
      "vibraslap": "Vibraslap",
      "videoDirector": "Directeur de vidéo",
      "videoEditor": "Éditeur de vidéo",
      "videoProducer": "Producteur vidéo",
      "vielle": "Vièle",
      "viol": "Viole",
      "viola": "Violon alto",
      "violaDaGamba": "Viole de gambe",
      "violin": "Violon",
      "violoncello": "Violoncelle",
      "visualEffects": "Effets visuels",
      "vocalAdaptation": "Adaptation",
      "vocalEngineer": "Ingénieur vocal",
      "vocalSolo": "Vocal solo",
      "vocals": "Chant",
      "vocoder": "Vocodeur",
      "washboard": "Washboard",
      "whistle": "Sifflet",
      "woodBlock": "Wood-block",
      "woodFlute": "Flûte en bois",
      "work": "Œuvre",
      "wurlitzer": "Piano Wurlitzer",
      "xylophone": "Xylophone",
      "zarb": "Tombak",
      "zither": "Cithare"
    },
    "roster": {
      "labels": {
        "create": {
          "contract": "Contrat associé",
          "name": "Nom",
          "title": "Créer un label ",
          "usages": "Usages",
          "usagesHint": "Si vous changez un paramètre des usages au niveau label, cela se répercutera seulement sur vos nouveaux albums créés"
        },
        "edit": {
          "title": "Mettre à jour un label"
        },
        "failedCreate": "Impossible de créer le label pour le moment ",
        "failedExtract": "Une erreur est suvenue. La demande d'extraction des labels a échoué.",
        "failedUpdate": "Impossible de mettre à jour le label pour le moment",
        "successfulCreate": "Label créé avec succès",
        "successfulExtract": "L'extraction des labels a bien été demandé. Vous la recevrez par email dans quelques instants.",
        "successfulUpdate": "Label mis à jour avec succès",
        "toolbar": {
          "extract": "Extraire les labels",
          "new": "Nouveau label"
        }
      }
    },
    "save": "Sauvegarder",
    "saveAndQuit": "Enregistrer et quitter",
    "saving": "Enregistrement...",
    "schedule": "Programmer",
    "search": {
      "columns": {
        "artist": "Artistes",
        "company": "Contrats",
        "label": "Labels",
        "product": "Albums",
        "track": "Pistes",
        "video": "Vidéos"
      },
      "defaultFilters": {
        "artist": "artiste",
        "company": "contrat",
        "distributorProductSubgenre": "genre",
        "label": "label",
        "product": "album",
        "role": "rôle",
        "tagTarget": "tag",
        "track": "piste",
        "video": "video"
      },
      "existingTrack": {
        "placeholder": "Rechercher par titre, ISRC, UPC..."
      },
      "noResults": {
        "subtitle": "Il semble que nous ne puissions pas trouver de résultats basés sur votre recherche.",
        "title": "Aucun résultat trouvé"
      },
      "placeholder": "Tapez directement votre recherche ou utilisez une combinaison de filtres",
      "resultTitle": "Résultats",
      "results": "{count, plural, =1 {# résultat} other {# résultats}}",
      "searching": "Recherche en cours...",
      "sidebar": {
        "cleanRecents": "Effacer les recherches récentes",
        "filters": "Filtres",
        "less": "moins...",
        "more": "plus...",
        "recents": "Récents"
      },
      "tabs": {
        "artist": "Artistes",
        "company": "Contrats",
        "label": "Labels",
        "playlist": "Playlists",
        "product": "Albums",
        "track": "Pistes",
        "video": "Vidéos"
      },
      "title": "Recherche"
    },
    "searchMessage": "Taper au moins 3 caractères pour chercher un filtre",
    "seeDetails": "Voir le détail",
    "seeList": "Voir la liste",
    "seeLogs": "Voir les logs",
    "seeMore": "Voir plus",
    "selectAll": "Tout sélectionner",
    "send": "Envoyer",
    "sendTaskLocks": {
      "table": {
        "message": "Message",
        "object": "Objet",
        "objects": {
          "dsp": "Dsp",
          "offer": "Offre",
          "product": "Produit",
          "right": "Droit",
          "send_task": "Livraison"
        },
        "rank": "",
        "type": "Type",
        "types": {
          "permanent": "Permanent",
          "temporary": "Temporaire"
        }
      }
    },
    "sendTaskStates": {
      "table": {
        "createdAt": "Créé le",
        "expiresAt": "Expire le",
        "lockCount": "Nombre de blocage(s)",
        "ready": "Prêt"
      }
    },
    "sendTasks": {
      "back": "Index des livraisons",
      "confirmLeaving": "Il y a des opérations en cours sur les mises en ligne. Êtes-vous sûr de vouloir quitter la page ?",
      "deliverable": "Validée",
      "delivery": "Livraison",
      "deliveryTypes": {
        "delete": "Suppression",
        "delivery": "Livraison",
        "redelivery": "Relivraison",
        "update": "Mise à jour"
      },
      "distribution": {
        "back": "Index des livraisons",
        "nav": {
          "collection": "Toutes livraisons",
          "sendTaskFactories": "Livraisons groupées"
        },
        "new": {
          "content": "Contenu",
          "contentSubtitle": "Vous pouvez choisir de livrer votre catalogue entier, celui d'un contrat, d'un label ou bien une liste d'albums.",
          "delete": "Supprimer",
          "deliverySetting": "Paramètres",
          "duplicate": "Cloner",
          "note": "Ajouter une note",
          "options": "Options",
          "optionsSubtitle": "Si vous renseignez un date de sortie et/ou un prix, une nouvelle offre sera automatiquement créée pour chaque plateforme choisie à la place de la date de sortie et/ou du prix existant."
        },
        "title": "Nouvelles livraisons groupées"
      },
      "edit": {
        "title": "Livraison {dsp}"
      },
      "errors": {
        "showDetails": "Montrer les détails",
        "title": "Logs d'erreurs"
      },
      "filters": {
        "status": "Status :",
        "statuses": "Statut",
        "tags": "Filtrer par plateformes, albums, labels, contrats...",
        "types": "Type :",
        "validation": "Validation :"
      },
      "form": {
        "addRights": "Ajouter les droits plateformes sur les albums concernés",
        "allReleases": "Livrer tout un catalogue ?",
        "comment": "Note",
        "dspUploadIdentifications": "Plateformes",
        "name": "Nom",
        "note": {
          "title": "Ajouter une note"
        },
        "priceCode": "Prix",
        "priority": "Priorité",
        "priorityHint": "Si vous choisissez une priorité Urgente, la livraison sera prioritaire par rapport aux autres livraisons en attente. Elle sera effectuée le plus tôt possible en fonction des resources et des disponibilités des plateformes.",
        "releaseDate": "Date de sortie",
        "releases": {
          "company": "Contrat",
          "distributor": "Distributeur",
          "label": "Label",
          "searchMessage": "Chercher un distributeur, un contrat ou un label à ajouter"
        },
        "removeRights": "Retirer les droits plateformes sur les albums concernés",
        "schedule": {
          "title": "Programmer les livraions groupés"
        },
        "sendDate": "Date de livraison",
        "submit": {
          "message": "Les livraisons groupées sont validées par défault. Êtes-vous sûr de vouloir les créer ?",
          "title": "Créer des livraisons groupées"
        },
        "type": "Type",
        "upc": "Coller les UPC des albums ici",
        "waitingIssue": "Problème de livraison"
      },
      "names": {
        "target": "{deliveryType} de {target}",
        "upcs": "{deliveryType} de {count} albums"
      },
      "new": {
        "options": {
          "title": "Options"
        },
        "platforms": {
          "filters": {
            "all": "Tous",
            "notSent": "Non envoyé",
            "offline": "Supprimé",
            "online": "En ligne",
            "sent": "Envoyé",
            "withRights": "Avec droits",
            "withoutRights": "Sans droits"
          },
          "notSent": "Non envoyé",
          "rights": "Droits",
          "sent": "Envoyé",
          "status": "Statut plateforme",
          "title": "Plateformes"
        },
        "title": "Nouvelles livraisons",
        "type": {
          "deleteHint": "Pour retirer l'album d'une plateforme",
          "deliveryHint": "Pour une première livraison",
          "redeliveryHint": "Mise à jour concernant la pochette, le livret, les audios ou les vidéos",
          "title": "Type",
          "updateHint": "Mise à jour concernant les métadata uniquement"
        }
      },
      "newDelivery": "Nouvelle livraison",
      "notifications": {
        "delete": {
          "failed": "Impossible de supprimer la livraison",
          "success": "Livraison supprimée avec succès"
        },
        "devalidate": {
          "failed": "Impossible de mettre à jour la livraison",
          "success": "Livraison mise à jour avec succès"
        },
        "extract": {
          "failed": "Une erreur est suvenue. La demande d'extraction des livraisons a échoué.",
          "success": "L'extraction des livraisons a bien été demandé. Vous la recevrez par email dans quelques instants."
        },
        "reset": {
          "failed": "Impossible de réinitialiser la livraison",
          "success": "Livraison réinitialisée avec succès"
        },
        "sendTask": {
          "failed": "Impossible de sauvegarder les livraisons",
          "success": "Livraisons sauvegarder avec succès"
        },
        "sendTaskFactory": {
          "failed": "Impossible de créer les livraisons",
          "success": "Livraisons créées avec succès"
        },
        "sendTasksFactory": {
          "delete": {
            "failed": "Impossible de supprimer les livraisons",
            "success": "Livraisons suppriméées avec succès"
          }
        },
        "update": {
          "failed": "Impossible de mettre à jour la livraison",
          "success": "Livraison mise à jour avec succès"
        },
        "validate": {
          "failed": "Impossible de mettre à jour la livraison",
          "success": "Livraison mise à jour avec succès"
        }
      },
      "priority": {
        "normal": "Normal",
        "now": "Immédiate",
        "urgent": "Urgente"
      },
      "selectAllPlatforms": "Ajouter toutes les plateformes",
      "sendTaskStates": "Tentatives",
      "show": {
        "actions": {
          "clone": "Dupliquer",
          "comment": {
            "button": "Note",
            "noNote": "Aucune note à afficher",
            "seeNote": "Voir note",
            "title": "Ajouter une note"
          },
          "delete": {
            "message": "Êtes-vous sûr de vouloir supprimer cette livraison groupée ?",
            "title": "Supprimer cette livraison groupée",
            "tooltip": "Supprimer",
            "tooltipNotDeletable": "La suppression de cette livraison groupée est désactivée car au moins une livraison a été créée et est en file d'attente ou déjà envoyée"
          }
        },
        "batch": "Lot :",
        "bulkLogs": {
          "message": "Nous avons crée {count} livraisons avec succès. Voici la liste des livraisons que nous n'avons pas pu créer:",
          "title": "Logs de création de livraison"
        },
        "content": "Contenu",
        "contentTable": "Contenu :",
        "createdBy": "Créé par :",
        "createdOn": "Créé le :",
        "deliveries": "Livraisons",
        "deliveriesDetails": "Détails des livraisons",
        "delivreyPath": "Chemin de livraison :",
        "offer": {
          "genre": "Genre :",
          "preorderDate": "Date de précommande :",
          "preview": "Preview avant sortie :",
          "previewBoolean": {
            "false": "Non",
            "true": "Oui"
          },
          "price": "Prix :",
          "releaseDate": "Date de sortie :",
          "secondaryGenre": "Genre secondaire :",
          "territories": "Territoires :",
          "title": "Informations de distribution",
          "trackPrice": "Prix par piste :"
        },
        "options": "Contenu & Options",
        "packagedBy": "Préparé par :",
        "platforms": "Plateformes :",
        "priceCode": "Prix :",
        "priority": "Priorité :",
        "releaseDate": "Date de sortie :",
        "scheduling": "Livraison programmée :",
        "sentBy": "Envoyé par :",
        "settings": "Paramètres",
        "status": "Statut",
        "statusComplete": "Envoyé le",
        "statusError": "Erreur le",
        "technical": "Informations techniques",
        "title": "Livraison {dsp}",
        "type": "Type :",
        "upcs": {
          "title": "Liste des UPC"
        }
      },
      "status": {
        "completed": "Livrée",
        "error": "Erreur",
        "packaged": "Prêt",
        "packaging": "En préparation",
        "sending": "En cours d'envoi",
        "sent": "Envoyée",
        "stuck": "Bloqué",
        "waiting": "En attente"
      },
      "table": {
        "action": {
          "delete": {
            "message": "Êtes-vous sûr de vouloir supprimer ces livraisons ?",
            "name": "Supprimer les livraisons",
            "title": "Supprimer les livraisons"
          },
          "devalidate": {
            "message": "Êtes-vous sûr de vouloir devalider ces livraisons ?",
            "name": "Dévalider les livraisons",
            "title": "Dévalider les livraisons"
          },
          "validate": {
            "message": "Êtes-vous sûr de vouloir valider ces livraisons ?",
            "name": "Valider les livraisons",
            "title": "Valider les livraisons"
          }
        },
        "actions": "",
        "album": "Album",
        "clone": "Dupliquer",
        "closedPlatform": "Plateforme fermée",
        "content": {
          "company": "Contrat",
          "distributor": "Distributeur",
          "label": "Label",
          "title": "Contenu"
        },
        "delete": "Supprimer",
        "deliverable": "Validation",
        "deliveries": "Livraisons",
        "edit": "Éditer",
        "name": "Nom",
        "noDeliveries": "Aucune livraisons créées",
        "now": "Immédiate",
        "options": "Options",
        "platform": "Plateformes",
        "priceCode": "Prix :",
        "releaseDate": "Date de sortie :",
        "reset": "Reset",
        "schedule": "Livraison programmée le",
        "see": "Voir",
        "status": "Statut",
        "statusComplete": "Envoyé le",
        "statusError": "Erreur le",
        "type": "Type",
        "urgent": "Urgente"
      },
      "title": "Livraisons",
      "toolbar": {
        "metadata": "Télécharger en {format}",
        "new": "Nouvelles livraisons groupées",
        "reset": "Réinitialiser cette livraison"
      },
      "type": {
        "delete": "Suppression",
        "delivery": "Livraison",
        "redelivery": "Relivraison",
        "update": "Mise à jour"
      },
      "validation": {
        "all": "Toutes",
        "not-validated": "Non validées",
        "validated": "Validées"
      }
    },
    "showLess": "Afficher moins",
    "showMore": "Afficher plus",
    "sidebar": {
      "collapse": "Réduire le menu",
      "expand": "Étendre le menu"
    },
    "signin": {
      "error": {
        "inactiveUser": "Votre compte est inactif et vous n'êtes plus autorisé à vous connecter",
        "invalidGrant": "Votre nom d'utilisateur ou votre mot de passe est invalide"
      },
      "form": {
        "passwordForgotten": "Mot de passe oublié ?",
        "pending": "Connexion...",
        "placeholder": {
          "password": "Mot de passe",
          "user": "Nom d'utilisateur ou email"
        },
        "signin": "Connexion"
      },
      "title": "Connexion"
    },
    "signingin": "Connexion...",
    "signup": "Inscription",
    "submissions": {
      "actions": {
        "approved": "Approver",
        "rejected": "Rejeter"
      },
      "confirm": {
        "approve": {
          "message": "En confirmant, le statut de l'album deviendra Actif. Êtes vous sur de vouloir approuver la soumission ?",
          "title": "Approuver la soumission"
        },
        "reject": {
          "message": "En confirmant, le statut de l'album deviendra Brouillon et l'utilisateur à l'origine de la soumission sera en mesure de l'éditer à nouveau. Êtes vous sur de vouloir rejeter la soumission ?",
          "title": "Rejeter la soumission"
        }
      },
      "empty": {
        "message": "Il n'y a aucune soumissions en cours",
        "title": "Tout est ok !"
      },
      "emptyFilters": {
        "message": "Aucune soumission à afficher. Essayez avec différents filtres",
        "title": "Oooops !"
      },
      "filters": {
        "priorities": "Priorités :",
        "statuses": "Status de soumissions :",
        "tag": "Filtrer par artistes, labels, utilisateurs...",
        "type": {
          "all": "Toutes",
          "backCatalogue": "Back catalogue",
          "new": "Nouveautés"
        },
        "types": "Type de sortie :",
        "update": "Filtrer les résultats"
      },
      "innerNavigation": {
        "pending": "En attente",
        "rejected": "Rejetées"
      },
      "lastComment": {
        "follow": "Relancer ?",
        "sent": "Envoyé"
      },
      "noAssignee": "Non assigné",
      "priorities": {
        "high": "Élevée",
        "low": "Basse",
        "normal": "Normale",
        "urgent": "Urgente"
      },
      "statuses": {
        "approved": "Approuvé",
        "doing": "En cours",
        "incoming": "Nouveau",
        "ready": "Prêt",
        "rejected": "Rejeté",
        "standby": "Stand-by",
        "waitingForCustomer": "En attente du label"
      },
      "table": {
        "action": "Action",
        "addTag": "+ Ajouter un tag",
        "album": "Album",
        "assignee": "Assigné",
        "dueDate": "Date d'échéance",
        "dueDateTooltip": "La date d'échéance est le date de sortie ou la date de précommande, le cas échéant",
        "priority": "Priorité",
        "productStatus": "Statut album",
        "rejectionDate": "Rejeté le",
        "status": "Statut",
        "submissionDate": "Soumis le"
      },
      "timeline": {
        "count": "{count, plural, =1 {# soumission} other {# soumissions}}",
        "countExisting": "{count, plural, =1 {# déjà existante} other {# déjà existantes}}",
        "rejectedOn": "Rejeté le {date}",
        "submittedOn": " a soumis le {date}"
      },
      "title": "Soumissions"
    },
    "successCopy": "{what} copié dans le presse-papiers",
    "switchContracts": {
      "form": {
        "company": {
          "label": "Nouveau contrat",
          "placeholder": "Transférer vers le contrat"
        },
        "startAt": {
          "label": "Date de début",
          "placeholder": "Date de début du nouveau contrat"
        },
        "submit": {
          "label": "Transférer le contrat"
        }
      },
      "history": {
        "company": "Contrat",
        "startAt": "Date de début"
      },
      "nav": {
        "history": "Historique",
        "switch": "Transfert"
      },
      "notifications": {
        "error": "Impossible de transférer le contrat, merci de réessayer",
        "success": "Contrat transféré avec succès"
      },
      "title": "Contrat",
      "tooltip": "Transfert de contrat"
    },
    "tags": {
      "searchMessage": "Taper pour ajouter un tag"
    },
    "tracks": {
      "audio": {
        "16Bits": "16 bits",
        "24Bits": "24 bits",
        "bitRate": "Bits par éch.",
        "channels": "Canaux",
        "dolbyAtmos": "Dolby Atmos",
        "duration": "Durée",
        "fileSize": "Taille",
        "sampleRate": "Taux d'éch."
      },
      "button": {
        "addAudio": "Importer un audio",
        "addExistingTrack": "Piste audio depuis mon catalogue",
        "addNewAudioTrack": "Piste audio",
        "addNewVideoTrack": "Piste vidéo",
        "addNewVolume": "Ajouter un support",
        "addTrack": "Ajouter",
        "addVideo": "Importer une vidéo",
        "applyThisTitle": "Appliquer ce titre",
        "batchUpload": "Importer plusieurs audios",
        "cancel": "Annuler",
        "delete": "Supprimer",
        "deleting": "Supression en cours",
        "done": "Terminer",
        "generateIsrc": "Générer un ISRC",
        "here": "ici",
        "isrcConflict": "Conflit ISRC",
        "iswcConflict": "Conflit ISWC",
        "move": "Déplacer",
        "moveTo": "Déplacer vers",
        "moving": "Déplacement en cours",
        "next": "Piste suivante",
        "pause": "Pause",
        "play": "Écouter",
        "previous": "Piste précédente",
        "replace": "Remplacer",
        "reset": "Réinitialiser",
        "retry": "Réessayer"
      },
      "column": {
        "album": "Album",
        "artist": "Artiste principal",
        "assets": "Assets",
        "audio": "Audio",
        "featuring": "Featuring",
        "isrcCode": "ISRC",
        "remixer": "Remixeur",
        "title": "Titre",
        "trackTitle": "Titre de la piste",
        "upcCode": "UPC"
      },
      "confirm": {
        "cancel": {
          "message": "Êtes-vous sûr de vouloir annuler l'import ?",
          "title": "Annuler l'import"
        },
        "discard": {
          "message": "Il y a des modifications non sauvegardées.\r\nÊtes-vous sûr de vouloir les ignorer ?",
          "title": "Ignorer les modifications"
        },
        "remove": {
          "message": "Êtes-vous sûr de vouloir supprimer la ou les piste(s) sélectionnée(s) ?",
          "title": "Suppression de piste"
        }
      },
      "contextMenu": {
        "selectedTrack": "piste sélectionnée",
        "selectedTracks": "pistes sélectionnées",
        "volumeSelected": "Support sélectionné"
      },
      "drawer": {
        "header": {
          "existingTrack": "Depuis mon catalogue",
          "newVideo": "Nouvelle Vidéo",
          "track": "Piste n°{number}"
        },
        "nav": {
          "assets": "Assets",
          "metadata": "Métadonnées"
        }
      },
      "form": {
        "artists": {
          "appliedOnAllTracks": "Le rôle {role} est déjà appliqué sur toutes les pistes",
          "applyOnAllTracks": "Appliquer {role} sur toutes les pistes",
          "contributors": "Contributeurs",
          "title": "Artistes"
        },
        "audio": {
          "immersiveAudio": "Immersive Audio",
          "stereoAudio": "Stereo Audio"
        },
        "audioLanguage": "Langue audio",
        "distribution": {
          "genre": "Genre",
          "previewEnd": "Fin de l'extrait",
          "previewStart": "Début de l'extrait",
          "priceCode": "Code prix",
          "title": "Distribution",
          "trackVideo": "C'est une piste vidéo",
          "usages": "Usages",
          "video": "Vidéo"
        },
        "generateIsrc": "Générer un ISRC",
        "generatingIsrc": "Génération de l'ISRC...",
        "information": {
          "title": "Information"
        },
        "isrcCode": "ISRC",
        "iswcCode": "ISWC",
        "lyrics": {
          "clean": "Clean",
          "explicitContent": "Contenu explicite",
          "parentalAdvisory": "Avert. parental",
          "placeholder": "Exemple: \n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n\n Lorem ipsum dolor sit amet\n Lorem ipsum dolor sit amet\n",
          "title": "Paroles",
          "ttmlLyrics": "Paroles TTML"
        },
        "productionLine": "Production",
        "publisher": "Éditeur",
        "secondaryISRC": "ISRC Secondaire (optionnel)",
        "subtitle": "Version du titre (optionnel)",
        "title": "Titre",
        "video": {
          "click": "Cliquer",
          "newVideo": "pour créer une nouvelle vidéo.",
          "subtitle": "Chercher une vidéo dans Labelcamp à associer à cette piste"
        },
        "work": "Oeuvre"
      },
      "helpPopover": {
        "batchUpload": {
          "intro": "Vos fichiers audio doivent être numérotés dans le bon ordre pour être validés lors de l'import.",
          "linkDetails": "sur l'import des audios.",
          "readMore": "En savoir plus",
          "readMoreLink": "https://help.labelcamp.io/submitting-content/albums/index/edit-a-draft-release/tracks-and-audio/import-audio#batch-upload",
          "title": "Batch Upload"
        },
        "buttonHide": "Ne plus afficher",
        "examples": "Exemple :"
      },
      "hint": {
        "applyThisTitle": "Le titre sera appliqué à toutes les pistes en conflit",
        "contributors": "Renseigner tous les contributeurs ayant participé à cette piste (ex: Producteur, Guitare, Ingénieur du son, etc)",
        "subtitle": "Laisser vide sauf pour différencier d'une version originale du morceau (ex: Live, Acoustique, Radio Edit, etc.)"
      },
      "message": {
        "error": {
          "network": "Un problème est survenu. Veuillez réessayer."
        },
        "isrcConflict": {
          "message": "L'ISRC {isrcCode} que vous avez saisi existe déjà dans d'autre(s) produit(s)",
          "title": "Conflit ISRC",
          "track": "Piste sélectionnée",
          "trackConflict": "Piste(s) en conflit ({count, number})"
        },
        "step": {
          "processing": "En cours de traitement. Veuillez patienter. ",
          "uploading": "Import en cours"
        }
      },
      "search": {
        "title": "Commencer votre recherche"
      },
      "title": "Piste",
      "trackTypes": {
        "audio": "Audio",
        "video": "Vidéo"
      },
      "video": {
        "form": {
          "cropBottom": "Bas",
          "cropLeft": "Gauche",
          "cropRight": "Droite",
          "cropTop": "Haut",
          "isrcAudio": "ISRC Audio",
          "isrcVideo": "ISRC Vidéo",
          "label": "Label",
          "name": "Titre",
          "upcCode": "UPC"
        },
        "title": "Piste Vidéo"
      },
      "videoLink": {
        "columns": {
          "dsp": "Plateformes liées",
          "name": "Nom",
          "retailHint": "Vous pouvez associer une vidéo à une ou plusieurs plateformes. Laissez ce champs vide si vous souhaitez que la vidéo soit livrée à toutes les plateformes."
        },
        "create": "en ajouter une",
        "description": "Chercher une vidéo dans Labelcamp à associer à cette piste ou",
        "dspSearchPlaceholder": "Chercher une plateforme",
        "newVideo": "Nouvelle Vidéo",
        "saved": "Sauvegardé",
        "search": "Commencer votre recherche",
        "searchPlaceholder": "Rechercher une vidéo par son nom ou ID",
        "title": "Associer une vidéo"
      }
    },
    "update": "Mettre à jour",
    "updating": "Mise à jour...",
    "uploadCenter": {
      "header": "Centre d'import",
      "notifications": {
        "error": "Une erreur s'est produite pendant le traitement {type, select, volume {du volume} track {de la piste} other {}} {name}",
        "success": "{type, select, volume {Le volume de l'album} track {La piste} other {}} {name} a été {status, select, uploaded {téléchargé} completed {traité} other {}} avec succès"
      },
      "placeholder": {
        "empty": "Aucun import en cours"
      },
      "track": {
        "label": "Piste",
        "title": "Titre de la piste"
      }
    },
    "usages": {
      "copyScan": "Copy Scan",
      "download": "Téléchargement",
      "nonInteractiveStream": "Radio",
      "onDemandStream": "Streaming"
    },
    "users": {
      "customisation": {
        "accentColor": "Couleur principale",
        "accentColorHint": "Cette couleur sera la couleur principale de l'application. Elle sera utilisée pour les liens, les boutons et les éléments de navigation",
        "emailAddress": "Adresse d'envoi par défaut",
        "emailFooter": "Informations de pied de page (Vous pouvez insérer du code html pour le customiser)",
        "emails": "E-mails",
        "favicon": {
          "format": "Format .png ou .jpeg",
          "hint": "Cliquez sur la zone ou cliquez-dépossez une image",
          "size": "Doit avoir une taille obligatoire de 32 x 32px",
          "title": "Favicon"
        },
        "logo": {
          "format": "Format .svg",
          "hint": "Cliquez sur la zone ou cliquez-dépossez une image",
          "margin": "2px de marge",
          "ratio": "Ratio rectangulaire (2:1)",
          "size": "Taille recommandée de 160 x 60px",
          "title": "Logo"
        },
        "successRequest": "Vos changements sont sauvegardés. Ils seront effectifs très bientôt"
      },
      "groups": {
        "archive": {
          "confirm": {
            "message": "Êtes-vous sûr de vouloir archiver ce groupe ?",
            "title": "Archiver le groupe"
          }
        },
        "details": {
          "expiration": "Exp. :",
          "groupsCount": "{count, plural, =1 {# groupe} other {# groupes}}",
          "usersCount": "{count, plural, =1 {# utilisateur} other {# utilisateurs}}"
        },
        "edit": {
          "language": "Langue",
          "name": "Nom",
          "parent": "Groupe parent",
          "rights": "Droits",
          "title": "Éditer le groupe"
        },
        "empty": {
          "message": "Créez votre premier groupe en cliquant sur le bouton en bas à droite",
          "title": "Aucun groupe trouvé"
        },
        "failedDestroy": "Une erreur est survenue, le groupe n'a pas pu être archivé",
        "failedReactivate": "Une erreur est survenue, le groupe n'a pas pu être réactivé",
        "inactive": "Groupes archivés",
        "new": {
          "title": "Nouveau groupe"
        },
        "reactivate": {
          "confirm": {
            "message": "Êtes-vous sûr de vouloir réactiver ce groupe ?",
            "title": "Réactiver le groupe"
          }
        },
        "search": "Chercher un groupe ou utilisateur",
        "subNav": {
          "customisation": "Personnalisation",
          "groups": "Groupes",
          "users": "Utilisateurs"
        },
        "successfulDestroy": "Le groupe a bien été archivé",
        "successfulReactivate": "Le groupe a bien été réactivé",
        "toolbar": {
          "new": "Nouveau groupe"
        },
        "users": {
          "activate": {
            "confirm": {
              "message": "Êtes-vous sûr de vouloir réactiver cet utilisateur ?",
              "title": "Réactiver l'utilisateur"
            }
          },
          "archive": {
            "confirm": {
              "message": "Êtes-vous sûr de vouloir archiver et rendre inactif cet utilisateur ?",
              "title": "Archiver l'utilisateur"
            },
            "title": "Archiver l'utilisateur"
          },
          "create": {
            "acls": "Droits personnalisés",
            "aclsHint": "Si vous ajoutez un ou des droit(s) personnalisé(s), l'utilisateur ne verra que le contenu en rapport avec ce(s) droits. Il n'héritera plus des droits de son groupe",
            "email": "Email",
            "invite": "Inviter",
            "parent": "Groupe parent",
            "rightDescription": {
              "adminUser": "Peut inviter, gérer et supprimer des groupes et des utilisateurs",
              "catalogManagement": "Peut gérer le catalogue",
              "distribution": "Peut accéder au tableau de sorties",
              "financial": "Peut accéder aux reportings et extractions",
              "sales": "Peut accéder au dashboard de Sales",
              "salesAdmin": "Peut ajouter des reportings de vente et clotûrer la période financière",
              "trends": "Peut accéder au dashboard de Trends"
            },
            "rightTitle": {
              "accounting": "Comptabilité",
              "analytics": "Analytics",
              "catalog": "Catalogue",
              "distribution": "Distribution",
              "users": "Utilisateurs"
            },
            "role": "Rôle",
            "roleTitle": {
              "accountant": "Comptable",
              "admin": "Administrateur",
              "contentEditor": "Editeur",
              "custom": "Custom",
              "manager": "Manageur",
              "viewer": "Lecteur"
            },
            "title": "Nouvel utilisateur",
            "update": "Mettre à jour"
          },
          "edit": {
            "title": "Mettre à jour l'utilisateur"
          },
          "empty": {
            "message": "Invitez votre premier utilisateur à rejoindre votre équipe en cliquant sur le bouton en bas à droite",
            "title": "Aucun utilisateur ajouté"
          },
          "failedActivate": "Une erreur est suvenue. L'utilisateur n'a pas été activé",
          "failedExtract": "Une erreur est suvenue. La demande d'extraction des utilisateurs a échoué.",
          "failedInvite": "Une erreur est survenue. L'invitation n'a pas pu être envoyée",
          "failedUpdate": "Une erreur est survenue. L'utilisateur n'a pas pu être mis à jour",
          "inactive": "Utilisateurs inactifs",
          "noRole": "Pas de rôle attribué",
          "pendingInvitation": "Invitation en attente",
          "resend": {
            "confirm": {
              "message": "Êtes-vous sûr de vouloir renvoyer une invitation pour cet utilisateur ?",
              "title": "Renvoyer l'invitation"
            }
          },
          "restricted": "Droits personnalisés",
          "revoke": {
            "confirm": {
              "message": "Êtes-vous sûr de vouloir annuler l'invitation pour cet utilisateur ?",
              "title": "Annuler l'invitation"
            }
          },
          "successfulActivate": "L'utilisateur a bien été activé",
          "successfulDestroy": "L'utilisateur a bien été archivé",
          "successfulExtract": "L'extraction des utilisateurs a bien été demandé. Vous la recevrez par email dans quelques instants.",
          "successfulInvite": "L'invitation a bien été envoyée",
          "successfulUpdate": "L'utilisateur a bien été mis à jour",
          "toolbar": {
            "extract": "Extraire les utilisateurs",
            "new": "Nouvel utilisateur"
          },
          "viewAs": {
            "button": "Voir en tant que",
            "confirm": {
              "message": "Vous allez vous connecter en tant qu'un autre utilisateur, êtes-vous sûr ?",
              "title": "Voir en tant que cet utilisateur"
            },
            "title": "Voir en tant que cet utilisateur"
          }
        }
      },
      "nav": {
        "group": "Utilisateurs",
        "groups": "Groupes & Utilisateurs",
        "preferences": "Préférences"
      },
      "preferences": {
        "avatar": {
          "format": "Format .png ou .jpeg",
          "hint": "Cliquez sur la zone ou cliquez-dépossez votre photo de profil",
          "ratio": "Ratio carré",
          "size": "Taille minimum 200 x 200px",
          "title": "Photo de profil"
        },
        "failedRequest": "Une erreur est présente, vos préférences n'ont pas été mises à jour",
        "form": {
          "changelogByEmail": "Recevoir les nouveautés Labelcamp par email",
          "currency": "Devise",
          "email": "Email",
          "firstName": "Prénom",
          "language": "Langue",
          "login": "Nom d'utilisateur",
          "name": "Nom",
          "passwordConfirmation": "Confirmez votre nouveau mot de passe",
          "passwordNew": "Nouveau mot de passe"
        },
        "informations": "Informations de base",
        "password": "Changer votre mot de passe",
        "passwordItems": {
          "digit": "Un chiffre",
          "length": "8 lettres minimum",
          "lowercase": "Une lettre en minuscule",
          "uppercase": "Une lettre en majuscule"
        },
        "preferences": "Préférences",
        "successRequest": "Vos préférences ont été mises à jour"
      },
      "signout": "Déconnexion"
    },
    "video": {
      "definition": "Résolution",
      "edit": "Editer",
      "format": "Format",
      "id": "ID Labelcamp",
      "informations": "Informations",
      "isrc": "ISRC",
      "label": "Label",
      "length": "Durée",
      "name": "Nom",
      "upc": "UPC",
      "weight": "Poid"
    },
    "videos": {
      "filters": {
        "tag": "Filtrer par pistes, contrats, labels, genres..."
      },
      "index": {
        "assets": "Associer les Assets Youtube"
      },
      "nav": {
        "analytics": "Analytics",
        "informations": "Informations",
        "share": "Partage"
      },
      "new": {
        "placeholder": "Ajouter une vidéo"
      },
      "title": "Vidéos",
      "uploaded": "Upload Utilisateur"
    },
    "welcomeModal": {
      "swiper": {
        "cancel": "Passer",
        "next": "Suivant",
        "slide1": {
          "imagePath": "/assets/images/welcome/step-first.png",
          "imagePath2": "/assets/images/welcome/step-first@2x.png",
          "text": "Nous sommes heureux de vous annoncer que nous avons ajouté le genre, l'âge et les villes dans Trends. Ces données sont accessibles dès maintenant sur <a href='https://labelcamp.idol.io/analytics/trends' target='blank'>le dashboard de Trends</a>",
          "title": "Nouvelles données dans Trends !"
        }
      }
    },
    "yes": "Oui"
  }]];
  _exports.default = _default;
});