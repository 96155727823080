define("ember-svg-jar/inlined/play-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.515 9.277a.5.5 0 01-.139.139l-4.599 3.066A.5.5 0 017 12.066V5.934a.5.5 0 01.777-.416l4.599 3.066a.5.5 0 01.139.693zM8 11.132L11.197 9 8 6.869v4.263z\" fill=\"#9D9FA3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 9a9 9 0 1018 0A9 9 0 000 9zm17 0A8 8 0 111 9a8 8 0 0116 0z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});