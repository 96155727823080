define("liquid-wormhole/components/liquid-wormhole", ["exports", "liquid-wormhole/templates/components/liquid-wormhole"], function (_exports, _liquidWormhole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _liquidWormhole.default,
    to: Ember.computed.reads('destination'),
    liquidWormholeService: Ember.inject.service('liquid-wormhole'),
    stack: Ember.computed(function () {
      return Ember.guidFor(this);
    }),
    // Truthy value by default
    value: true,
    init: function init() {
      var wormholeClass = this.get('class');
      var wormholeId = this.get('stack') || this.get('id');
      this.set('wormholeClass', wormholeClass);
      this.set('wormholeId', wormholeId);

      if (Ember.typeOf(this.get('send')) !== 'function') {
        this.set('hasSend', true);
      }

      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.get('liquidWormholeService').removeWormhole(this, this.get('to'));
      this.get('liquidWormholeService').appendWormhole(this, this.get('to'));
    },
    didInsertElement: function didInsertElement() {
      var nodes = this.$().children();
      this.set('nodes', nodes);
      this.element.className = 'liquid-wormhole-container';
      this.element.id = '';
      this.get('liquidWormholeService').appendWormhole(this, this.get('to'));

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('liquidWormholeService').removeWormhole(this, this.get('to'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});