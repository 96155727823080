define("ember-paper/components/paper-menu/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5zYk1901",
    "block": "[[[8,[39,0],[[24,0,\"md-menu\"],[24,\"tabindex\",\"-1\"],[17,1]],[[\"@htmlTag\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-menu\",[30,2],[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n  \"],[18,6,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&default\"],false,[\"basic-dropdown-trigger\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-menu/trigger/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});