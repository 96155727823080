define("ember-truncate/templates/components/truncate-multiline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nb238o9V",
    "block": "[[[41,[48,[30,1]],[[[41,[33,2],[[[1,\"    \"],[18,1,[[28,[37,4],null,[[\"target\",\"button\",\"isTruncated\",\"neededTruncating\"],[[50,\"ember-truncate/truncation-target\",0,null,null],[50,\"ember-truncate/button-toggle\",0,null,[[\"_destination\",\"_inPlace\",\"onClick\"],[[33,6],[33,7],[28,[37,8],[[30,0],\"toggleTruncate\"],null]]]],[33,9],[33,10]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,1,[[28,[37,4],null,[[\"target\",\"button\",\"isTruncated\",\"neededTruncating\"],[[50,\"ember-truncate/truncation-noop\",0,null,null],[50,\"ember-truncate/button-toggle\",0,null,[[\"_destination\",\"_inPlace\",\"onClick\"],[[33,6],[33,7],[28,[37,8],[[30,0],\"toggleTruncate\"],null]]]],[33,9],[33,10]]]]]],[1,\"\\n\"]],[]]]],[]],[[[41,[33,2],[[[6,[39,11],null,null,[[\"default\"],[[[[1,\"      \"],[1,[34,12]],[1,\"\\n\"]],[]]]]],[41,[33,10],[[[6,[39,13],null,[[\"_destination\",\"_inPlace\",\"onClick\"],[[33,6],[33,7],[28,[37,8],[[30,0],\"toggleTruncate\"],null]]],[[\"default\"],[[[[1,\"        see more\\n\"]],[]]]]]],[]],null]],[]],[[[1,\"    \"],[1,[34,12]],[1,\"\\n\"],[41,[33,10],[[[6,[39,13],null,[[\"_inPlace\",\"onClick\"],[true,[28,[37,8],[[30,0],\"toggleTruncate\"],null]]],[[\"default\"],[[[[1,\"        see less\\n\"]],[]]]]]],[]],null]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"_truncate\",\"yield\",\"hash\",\"component\",\"_buttonDestination\",\"_buttonInPlace\",\"action\",\"isTruncated\",\"neededTruncating\",\"ember-truncate/truncation-target\",\"text\",\"ember-truncate/button-toggle\"]]",
    "moduleName": "ember-truncate/templates/components/truncate-multiline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});