define("ember-validated-form/templates/components/validated-input/types/-themes/uikit/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p40Gt2jx",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[41,[51,[28,[37,4],[[30,2],0],null]],[[[10,\"br\"],[12],[13]],[]],null],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[\"uk-form-label \",[52,[33,6],\"uk-text-success\"],\" \",[52,[33,7],\"uk-text-danger\"]]]],[12],[1,\"\\n    \"],[11,\"input\"],[24,0,\"uk-checkbox uk-margin-small-right\"],[16,\"checked\",[28,[37,8],[[30,1,[\"key\"]],[33,9]],null]],[16,3,[36,10]],[16,1,[29,[[36,11],\"-\",[30,2]]]],[16,\"disabled\",[36,12]],[24,4,\"checkbox\"],[4,[38,13],[\"input\",[28,[37,14],[[30,0,[\"onUpdate\"]],[30,1,[\"key\"]]],null]],null],[12],[13],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"option\",\"i\"],false,[\"each\",\"-track-array\",\"options\",\"unless\",\"eq\",\"if\",\"isValid\",\"isInvalid\",\"includes\",\"value\",\"name\",\"inputId\",\"disabled\",\"on\",\"fn\"]]",
    "moduleName": "ember-validated-form/templates/components/validated-input/types/-themes/uikit/checkbox-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});