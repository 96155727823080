define("ember-svg-jar/inlined/arrow-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.145.376a.503.503 0 000 .708L3.65 4.619a.493.493 0 00.7 0l3.505-3.535a.503.503 0 000-.708.493.493 0 00-.701 0L4 3.558.846.376a.493.493 0 00-.7 0z\" fill=\"#9EA2A3\"/>",
    "attrs": {
      "width": "8",
      "height": "5",
      "viewBox": "0 0 8 5",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});