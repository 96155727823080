define("ember-svg-jar/inlined/follow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2727\" d=\"M22.297 6.152A12.761 12.761 0 0011.504 0a12.761 12.761 0 00-10.8 6.152 4.273 4.273 0 000 4.7 12.761 12.761 0 0010.8 6.152 12.761 12.761 0 0010.8-6.152 4.273 4.273 0 00-.007-4.7zm-1.633 3.743a10.9 10.9 0 01-9.16 5.286 10.9 10.9 0 01-9.168-5.286 2.541 2.541 0 010-2.791 10.9 10.9 0 019.168-5.285 10.888 10.888 0 019.16 5.286 2.541 2.541 0 010 2.79z\"/><path data-name=\"Trac&#xE9; 2728\" d=\"M11.5 3.952A4.674 4.674 0 006.71 8.5a4.674 4.674 0 004.79 4.552 4.674 4.674 0 004.79-4.548 4.679 4.679 0 00-4.79-4.552zm0 7.277A2.8 2.8 0 018.626 8.5 2.8 2.8 0 0111.5 5.771 2.8 2.8 0 0114.374 8.5a2.8 2.8 0 01-2.874 2.729z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "17",
      "viewBox": "0 0 23 17"
    }
  };
  _exports.default = _default;
});