define("ember-svg-jar/inlined/filter-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2716\" d=\"M4.5 17.5H2a2 2 0 00-2 2V22a2 2 0 002 2h2.5a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5H2v-2.5h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2717\" d=\"M22 17.5h-2.5a2 2 0 00-2 2V22a2 2 0 002 2H22a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5h-2.5v-2.5H22z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2718\" d=\"M4.5 8.75H2a2 2 0 00-2 2v2.5a2 2 0 002 2h2.5a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5H2v-2.5h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2719\" d=\"M22 8.75h-2.5a2 2 0 00-2 2v2.5a2 2 0 002 2H22a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5h-2.5v-2.5H22z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2720\" d=\"M4.5 0H2a2 2 0 00-2 2v2.5a2 2 0 002 2h2.5a2 2 0 002-2V2a2 2 0 00-2-2zm0 4.5H2V2h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2721\" d=\"M13.25 17.5h-2.5a2 2 0 00-2 2V22a2 2 0 002 2h2.5a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5h-2.5v-2.5h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2722\" d=\"M13.25 8.75h-2.5a2 2 0 00-2 2v2.5a2 2 0 002 2h2.5a2 2 0 002-2v-2.5a2 2 0 00-2-2zm0 4.5h-2.5v-2.5h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2723\" d=\"M13.25 0h-2.5a2 2 0 00-2 2v2.5a2 2 0 002 2h2.5a2 2 0 002-2V2a2 2 0 00-2-2zm0 4.5h-2.5V2h2.5z\" fill=\"#9d9fa3\"/><path data-name=\"Trac&#xE9; 2724\" d=\"M22 0h-2.5a2 2 0 00-2 2v2.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 4.5h-2.5V2H22z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});