define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dE5DL6JX",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"api\",\"head\",\"body\",\"foot\"],[[33,2],[50,\"ember-thead\",0,null,[[\"api\"],[[33,2]]]],[50,\"ember-tbody\",0,null,[[\"api\"],[[33,2]]]],[50,\"ember-tfoot\",0,null,[[\"api\"],[[33,2]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"api\",\"component\"]]",
    "moduleName": "ember-table/components/ember-table/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});