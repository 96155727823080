define("ember-svg-jar/inlined/move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#575757\"><path d=\"M6.951 5.04a.6.6 0 00.973 0l2.264-2.822v8.925a.787.787 0 00.692.856.787.787 0 00.687-.857V2.215l2.263 2.824a.6.6 0 00.972 0 1.013 1.013 0 000-1.213L12.333.754a1.788 1.788 0 00-2.917 0L6.951 3.828a1.013 1.013 0 000 1.212z\"/><path data-name=\"fi-rr-arrow-small-left\" d=\"M.201 6.961a.6.6 0 01.973 0l2.264 2.822V.858a.787.787 0 01.692-.859.787.787 0 01.687.857v8.927l2.259-2.822a.6.6 0 01.972 0 1.013 1.013 0 010 1.213l-2.465 3.074a1.788 1.788 0 01-2.917 0L.201 8.174a1.013 1.013 0 010-1.212z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "12",
      "viewBox": "0 0 15 12"
    }
  };
  _exports.default = _default;
});