define("ember-svg-jar/inlined/female", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EC9493\" fill-rule=\"evenodd\" d=\"M13.22 20.445c.733 0 1.326-.398 1.326-.89 0-.493-.593-.891-1.326-.891-.733 0-1.327.398-1.327.89 0 .493.594.891 1.327.891zm-3.537-4.008c0-.739-.594-1.337-1.327-1.337-.732 0-1.326.598-1.326 1.337 0 .738.594 1.336 1.326 1.336.733 0 1.327-.598 1.327-1.336zm8.4-1.337c-.732 0-1.326.598-1.326 1.337 0 .738.594 1.336 1.326 1.336.733 0 1.327-.598 1.327-1.336 0-.739-.594-1.337-1.327-1.337zm5.704 20V19.485a3.07 3.07 0 002.653-3.047 3.07 3.07 0 00-2.653-3.048V9.755c0-5.38-4.344-9.755-9.683-9.755h-1.768c-5.34 0-9.683 4.376-9.683 9.755V13.389A3.07 3.07 0 000 16.437a3.07 3.07 0 002.653 3.047V35.1C1.555 36.22.788 37.53.463 38.965L.229 40H26.21l-.234-1.035c-.326-1.435-1.092-2.745-2.19-3.864zm-8.754-1.737c0 .761-.615 1.38-1.371 1.38h-.884c-.756 0-1.37-.619-1.37-1.38v-2.317c.305.029.615.046.928.046h1.768a9.7 9.7 0 00.929-.046v2.317zm7.923-15.548l-.85-.01v3.531c0 4.446-3.589 8.063-8.002 8.063h-1.768c-4.413 0-8.003-3.617-8.003-8.063v-3.529l-1.282.01c-.756 0-1.37-.62-1.37-1.38 0-.762.614-1.381 1.37-1.381l1.282.01v-2.284a9.628 9.628 0 009.108-4.488 9.629 9.629 0 008.18 4.535c.158 0 .32-.007.486-.017v2.255l.85-.01.432-.001c.756 0 1.37.62 1.37 1.38 0 .762-.614 1.381-1.37 1.381-.15 0-.293 0-.433-.002zm-9.775 9.757c3.424 0 4.863-3.118 4.824-4.899l-9.648.002c-.04 1.78 1.397 4.897 4.824 4.897zm.039-2.672c.977 0 1.768.3 1.768.668 0 .369-.791.668-1.768.668s-1.769-.3-1.769-.668c0-.369.792-.668 1.769-.668z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "40",
      "viewBox": "0 0 27 40"
    }
  };
  _exports.default = _default;
});