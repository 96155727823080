define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2706\" d=\"M16.1 1.694h-.847V.847a.847.847 0 00-1.694 0v.847H6.778V.847a.847.847 0 10-1.694 0v.847h-.848A4.241 4.241 0 000 5.93V16.1a4.241 4.241 0 004.236 4.236H16.1a4.241 4.241 0 004.233-4.236V5.93A4.241 4.241 0 0016.1 1.694zM1.694 5.93a2.542 2.542 0 012.542-2.541H16.1a2.542 2.542 0 012.538 2.541v.847H1.694zM16.1 18.638H4.236A2.542 2.542 0 011.694 16.1V8.472h16.944V16.1a2.542 2.542 0 01-2.538 2.538z\" fill=\"#9b9999\"/><circle data-name=\"Ellipse 38\" cx=\"1.271\" cy=\"1.271\" r=\"1.271\" transform=\"translate(8.895 11.437)\" fill=\"#9b9999\"/><circle data-name=\"Ellipse 39\" cx=\"1.271\" cy=\"1.271\" r=\"1.271\" transform=\"translate(4.66 11.437)\" fill=\"#9b9999\"/><circle data-name=\"Ellipse 40\" cx=\"1.271\" cy=\"1.271\" r=\"1.271\" transform=\"translate(13.131 11.437)\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20.333",
      "height": "20.333",
      "viewBox": "0 0 20.333 20.333"
    }
  };
  _exports.default = _default;
});