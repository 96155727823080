define("ember-svg-jar/inlined/rounded-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 17A8 8 0 109 1a8 8 0 000 16zm0 1A9 9 0 109 0a9 9 0 000 18z\" fill=\"#9D9FA3\"/><path d=\"M6 8.5a.5.5 0 000 1h6a.5.5 0 000-1H6z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});