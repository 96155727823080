define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" fill-rule=\"evenodd\" d=\"M7.941 10.416L6.174 8.648a.5.5 0 10-.708.707l2.122 2.121a.498.498 0 00.707 0l4.243-4.242a.5.5 0 00-.708-.708l-3.889 3.89z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});