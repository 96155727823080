define("ember-paper/templates/components/paper-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VVzTtLv5",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[8,[39,4],null,[[\"@fitRipple\",\"@center\",\"@dimBackground\"],[[30,1],[30,1],[28,[37,5],[[30,1]],null]]],null],[1,\"\\n\"]],[\"@iconButton\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"label\",\"paper-ripple\",\"not\"]]",
    "moduleName": "ember-paper/templates/components/paper-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});