define("ember-svg-jar/inlined/pinned", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.8 4.183V.857h.8c.22 0 .4-.192.4-.429C8 .192 7.82 0 7.6 0H.4C.18 0 0 .192 0 .428c0 .237.18.429.4.429h.8v3.326C.445 4.832 0 5.815 0 6.857c0 .236.18.428.4.428h3.2v4.286c0 .237.18.429.4.429.22 0 .4-.192.4-.429V7.285h3.2c.22 0 .4-.192.4-.428 0-1.042-.445-2.026-1.2-2.674z\" fill=\"#FFB61A\"/>",
    "attrs": {
      "width": "8",
      "height": "12",
      "viewBox": "0 0 8 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});