define("ember-svg-jar/inlined/ideas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined Shape</title><path d=\"M1.391 45.913A1.39 1.39 0 010 44.522V8.346C0 3.744 3.743 0 8.345 0h33.396c4.602 0 8.346 3.748 8.346 8.356v6.98a1.39 1.39 0 01-1.391 1.392 1.39 1.39 0 01-1.392-1.392v-6.98c0-3.073-2.496-5.573-5.563-5.573H8.345a5.57 5.57 0 00-5.562 5.563v33.205l7.735-6.447c.25-.207.565-.321.89-.321h19.41a1.39 1.39 0 110 2.783H11.911l-9.629 8.026a1.402 1.402 0 01-.892.321zM34.783 64a1.39 1.39 0 01-1.391-1.391V26.433c0-4.602 3.742-8.346 8.344-8.346h33.396c4.602 0 8.346 3.744 8.346 8.346v20.873c0 4.602-3.744 8.346-8.346 8.346H45.304l-9.63 8.027a1.402 1.402 0 01-.891.321zm6.953-43.13a5.57 5.57 0 00-5.562 5.563v33.205l7.736-6.447c.249-.207.564-.321.89-.321h30.332a5.57 5.57 0 005.564-5.564V26.433a5.57 5.57 0 00-5.564-5.563H41.736z\" fill=\"#EC9493\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "84",
      "height": "64",
      "viewBox": "0 0 84 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});