define("ember-changeset/utils/computed/object-to-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectToArray;

  /*::
  import type Change from 'ember-changeset/-private/change';
  import type Err from 'ember-changeset/-private/err';
  */
  var keys = Object.keys;
  /**
   * Compute the array form of an object.
   *
   * Each value of the object is transformed by a passed-in `transform`
   * function.
   *
   * Returns a list of objects, where each object has the form
   * `{ key, value }`. If `flattenObjects` is true and the result of
   * `transform` is an Object, the resulting object has the form
   * `{ key, ...transformResult }`.
   */

  function objectToArray
  /*:: <T> */
  (objKey
  /*: string             */
  )
  /*: Array<{ key: string }> */
  {
    var transform
    /*: (T) => (mixed) */
    = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (a) {
      return a;
    };
    var flattenObjects
    /*: boolean            */
    = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return Ember.computed(objKey, function () {
      var obj = Ember.get(this, objKey);
      return keys(obj).map(function (key) {
        var value = transform(obj[key]);

        if (flattenObjects && Ember.typeOf(value) === 'object') {
          return Ember.assign({
            key: key
          }, value);
        }

        return {
          key: key,
          value: value
        };
      });
    }).readOnly();
  }
});