define("ember-paper/components/paper-select/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSCVyd2g",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"md-select-header\"],[14,0,\"ember-power-select-search md-searchbox\"],[12],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[24,\"spellcheck\",\"false\"],[24,\"role\",\"combobox\"],[24,0,\"ember-power-select-search-input md-searchinput\"],[16,2,[30,2,[\"searchText\"]]],[16,\"aria-controls\",[30,3]],[16,\"placeholder\",[30,4]],[24,4,\"search\"],[4,[38,1],[\"input\",[30,5]],null],[4,[38,1],[\"focus\",[30,6]],null],[4,[38,1],[\"blur\",[30,7]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeydown\"]]],null],[4,[38,2],[[30,0,[\"focusInput\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@searchEnabled\",\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\"],false,[\"if\",\"on\",\"did-insert\"]]",
    "moduleName": "ember-paper/components/paper-select/search/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});