define("ember-content-loader/templates/components/content-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v15oFjoE",
    "block": "[[[8,[39,0],[[17,1]],[[\"@animate\",\"@ariaLabel\",\"@baseUrl\",\"@gradientRatio\",\"@height\",\"@interval\",\"@preserveAspectRatio\",\"@primaryColor\",\"@primaryOpacity\",\"@rtl\",\"@secondaryColor\",\"@secondaryOpacity\",\"@speed\",\"@width\"],[[28,[37,1],[[30,2],true],null],[28,[37,1],[[30,3],\"Loading interface...\"],null],[28,[37,1],[[30,4],\"\"],null],[28,[37,1],[[30,5],2],null],[28,[37,1],[[30,6],130],null],[28,[37,1],[[30,7],0.25],null],[28,[37,1],[[30,8],\"none\"],null],[28,[37,1],[[30,9],\"#f0f0f0\"],null],[28,[37,1],[[30,10],1],null],[28,[37,1],[[30,11],false],null],[28,[37,1],[[30,12],\"#e0e0e0\"],null],[28,[37,1],[[30,13],1],null],[28,[37,1],[[30,14],2],null],[28,[37,1],[[30,15],400],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,16]],[[[1,\"    \"],[18,16,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"rx\",\"5\"],[14,\"ry\",\"5\"],[15,\"width\",[28,[37,1],[[30,15],400],null]],[15,\"height\",[28,[37,1],[[30,6],130],null]],[12],[13],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@animate\",\"@ariaLabel\",\"@baseUrl\",\"@gradientRatio\",\"@height\",\"@interval\",\"@preserveAspectRatio\",\"@primaryColor\",\"@primaryOpacity\",\"@rtl\",\"@secondaryColor\",\"@secondaryOpacity\",\"@speed\",\"@width\",\"&default\"],false,[\"content-loader-svg\",\"defaults-to\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-content-loader/templates/components/content-loader.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});