define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/BYyCGaO",
    "block": "[[[43,[28,[37,1],null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"Trigger\",\"Content\"],[[30,0,[\"publicAPI\",\"uniqueId\"]],[30,0,[\"publicAPI\",\"isOpen\"]],[30,0,[\"publicAPI\",\"disabled\"]],[30,0,[\"publicAPI\",\"actions\"]],[50,[28,[37,3],[[30,1],\"basic-dropdown-trigger\"],null],0,null,[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"vPosition\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null],[28,[37,4],[[30,0,[\"hPosition\"]]],null],[28,[37,4],[[30,0,[\"renderInPlace\"]]],null],[28,[37,4],[[30,0,[\"vPosition\"]]],null]]]],[50,[28,[37,3],[[30,2],\"basic-dropdown-content\"],null],0,null,[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null],[28,[37,4],[[30,0,[\"hPosition\"]]],null],[28,[37,4],[[30,0,[\"renderInPlace\"]]],null],[28,[37,4],[[30,3]],null],[28,[37,3],[[30,4],\"click\"],null],[28,[37,4],[[30,0,[\"vPosition\"]]],null],[28,[37,4],[[30,0,[\"destination\"]]],null],[28,[37,4],[[30,0,[\"top\"]]],null],[28,[37,4],[[30,0,[\"left\"]]],null],[28,[37,4],[[30,0,[\"right\"]]],null],[28,[37,4],[[30,0,[\"width\"]]],null],[28,[37,4],[[30,0,[\"height\"]]],null],[28,[37,4],[[30,0,[\"otherStyles\"]]],null]]]]]]],[[[41,[30,0,[\"renderInPlace\"]],[[[1,\"    \"],[11,0],[24,0,\"ember-basic-dropdown\"],[17,6],[12],[18,7,[[30,5]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,7,[[30,5]]],[1,\"\\n\"]],[]]]],[5]],null]],[\"@triggerComponent\",\"@contentComponent\",\"@preventScroll\",\"@rootEventType\",\"api\",\"&attrs\",\"&default\"],false,[\"with\",\"hash\",\"component\",\"or\",\"readonly\",\"if\",\"yield\"]]",
    "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});