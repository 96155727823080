define("ember-svg-jar/inlined/area-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.422.133l4.48 4.12 4.875-2.988a.491.491 0 01.383-.058l3.457.883a.514.514 0 01.383.5v8.893a.511.511 0 01-.505.516H.506a.515.515 0 01-.48-.68L3.604.352a.5.5 0 01.818-.219zm4.36 5.4l5.336-3.272 2.871.734V8.9l-2.766-1.884a.5.5 0 00-.541-.013L9.07 9.831a.5.5 0 01-.359.063l-4.638-.948a.5.5 0 00-.407.1l-2.452 1.923h-.007L4.319 1.43z\" fill=\"#9d9fa3\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "12",
      "viewBox": "0 0 18 12"
    }
  };
  _exports.default = _default;
});