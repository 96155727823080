define("ember-svg-jar/inlined/track", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5354\"><g data-name=\"Groupe 5353\"><path d=\"M12.083 1H3.917A2.92 2.92 0 001 3.917v8.167A2.92 2.92 0 003.917 15h8.167A2.92 2.92 0 0015 12.083V3.917A2.92 2.92 0 0012.083 1zm1.75 11.083a1.75 1.75 0 01-1.75 1.75H3.917a1.75 1.75 0 01-1.75-1.75V3.917a1.75 1.75 0 011.75-1.75h8.167a1.75 1.75 0 011.75 1.75z\" fill=\"#fff\" data-name=\"Groupe 5345\"/><path d=\"M11.099 5.265a.861.861 0 01-.908.8h-.908v3.622a2.153 2.153 0 01-2.27 2.012 2.153 2.153 0 01-2.269-2.002 2.153 2.153 0 012.27-2.012 2.629 2.629 0 01.454.04v-1.65a1.722 1.722 0 011.815-1.61h.908a.861.861 0 01.908.8z\" fill=\"#fff\"/></g><path data-name=\"Trac&#xE9; 3150\" d=\"M0 0h16v16H0z\" fill=\"none\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});