define("ember-svg-jar/inlined/audio-file-unvailable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5303\"><path data-name=\"Trac&#xE9; 2984\" d=\"M31.194 0H1.5C.67 0 0 .7 0 2.064v56.865A1.551 1.551 0 001.5 60h41a1.551 1.551 0 001.5-1.071V13.9c0-.746-.1-.986-.263-1.163L31.83.275A.884.884 0 0031.194 0z\" fill=\"#efefef\"/><path data-name=\"Trac&#xE9; 2991\" d=\"M31.721.162v12.7h12.125z\" fill=\"#cecece\"/><path data-name=\"Trac&#xE9; 3140\" d=\"M19.73 20.648v6.216l-4.116-6.216h-1.368V29h1.368v-6.228L19.73 29h1.37v-8.352zm9.47 5.04a3.261 3.261 0 00-3.374-3.408 3.261 3.261 0 00-3.372 3.408 3.224 3.224 0 003.312 3.42 3.312 3.312 0 003.434-3.42zm-5.352 0a1.97 1.97 0 011.952-2.22 2 2 0 011.992 2.22 2.037 2.037 0 01-2.028 2.232 1.956 1.956 0 01-1.918-2.232zM12.44 44h1.368v-3.648h2.76v-1.116h-2.76v-2.46h3.54V35.66H12.44zm6.18 0h1.368v-6.612H18.62zm.7-7.488a.876.876 0 10-.876-.876.863.863 0 00.872.876zM21.788 44h1.368v-8.88h-1.368zm5.952-5.568a1.717 1.717 0 011.848 1.668h-3.66a1.8 1.8 0 011.812-1.668zm3.1 3.6h-1.48a1.6 1.6 0 01-1.56.924 1.819 1.819 0 01-1.884-1.756h5.052a4.22 4.22 0 00.048-.672A3.084 3.084 0 0027.8 37.28a3.166 3.166 0 00-3.3 3.408 3.208 3.208 0 003.3 3.42 3.021 3.021 0 003.036-2.076z\" fill=\"#9b9999\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "44",
      "height": "60",
      "viewBox": "0 0 44 60"
    }
  };
  _exports.default = _default;
});