define("ember-custom-actions/actions/model", ["exports", "ember-custom-actions/actions/action", "lodash.merge"], function (_exports, _action, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(path) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return function () {
      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var actionOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      actionOptions.data = payload;
      return _action.default.create({
        id: path,
        model: this,
        instance: true,
        options: (0, _lodash.default)({}, options, actionOptions)
      }).callAction();
    };
  }
});