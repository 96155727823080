define("ember-cli-new-version/components/new-version-notifier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ua/KXRvx",
    "block": "[[[41,[33,1],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[33,4],[33,5],[28,[37,6],[[30,0],\"reload\"],null],[28,[37,6],[[30,0],\"close\"],null]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"update-notification\"],[12],[1,\"\\n      \"],[1,[34,1]],[1,\" \"],[41,[33,7],[[[11,3],[24,6,\"\"],[24,0,\"update-notification__btn\"],[4,[38,6],[[30,0],\"reload\"],null],[12],[1,[34,8]],[13]],[]],null],[1,\"\\n      \"],[11,3],[24,6,\"\"],[24,0,\"update-notification__close\"],[4,[38,6],[[30,0],\"close\"],null],[12],[1,\"×\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"&default\"],false,[\"if\",\"message\",\"has-block\",\"yield\",\"version\",\"lastVersion\",\"action\",\"showReload\",\"reloadButtonText\"]]",
    "moduleName": "ember-cli-new-version/components/new-version-notifier/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});