define("ember-svg-jar/inlined/cross-big-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.707.293A1 1 0 00.293 1.707l3.536 3.536L.293 8.778a1 1 0 001.414 1.414l3.536-3.535 3.535 3.535a1 1 0 001.414-1.414L6.657 5.243l3.536-3.536A1 1 0 008.777.293L5.243 3.828 1.707.293z\" fill=\"#9EA1A3\"/>",
    "attrs": {
      "width": "11",
      "height": "11",
      "viewBox": "0 0 11 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});