define("ember-svg-jar/inlined/contracts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.126 2.478l-1.315-1.316A3.944 3.944 0 009 0H3.969A3.974 3.974 0 000 3.969v11.113a3.974 3.974 0 003.969 3.969h6.35a3.974 3.974 0 003.969-3.969v-9.8a3.944 3.944 0 00-1.163-2.806zM12 3.6a2.327 2.327 0 01.3.368h-1.98V1.985a2.461 2.461 0 01.369.3zm.7 11.482a2.381 2.381 0 01-2.381 2.381h-6.35a2.381 2.381 0 01-2.381-2.381V3.969a2.381 2.381 0 012.381-2.381h4.763v2.381a1.588 1.588 0 001.588 1.588h2.38zm-2.38-7.938a.794.794 0 010 1.588H3.969a.794.794 0 010-1.588zm.794 3.969a.794.794 0 01-.794.794H3.969a.794.794 0 010-1.588h6.35a.794.794 0 01.794.794zm-.152 2.709a.794.794 0 01-.172 1.107 5.131 5.131 0 01-2.743.947 2.581 2.581 0 01-1.588-.556c-.26-.179-.36-.238-.556-.238a3.136 3.136 0 00-1.454.63.794.794 0 01-.964-1.261 4.653 4.653 0 012.421-.957 2.4 2.4 0 011.454.52 1.069 1.069 0 00.686.274 3.64 3.64 0 001.808-.642.794.794 0 011.107.177z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14.289",
      "height": "19.051",
      "viewBox": "0 0 14.289 19.051"
    }
  };
  _exports.default = _default;
});