define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2740\" d=\"M16.15 3.333h-2.635A4.238 4.238 0 009.35 0h-1.7a4.238 4.238 0 00-4.165 3.333H.85A.833.833 0 10.85 5h.85v10.833A4.214 4.214 0 005.95 20h5.1a4.214 4.214 0 004.25-4.167V5h.85a.833.833 0 100-1.667zm-8.5-1.666h1.7a2.553 2.553 0 012.4 1.667H5.245a2.553 2.553 0 012.4-1.667zm5.95 14.166a2.525 2.525 0 01-2.55 2.5h-5.1a2.525 2.525 0 01-2.55-2.5V5h10.2z\"/><path data-name=\"Trac&#xE9; 2741\" d=\"M6.8 15a.842.842 0 00.85-.833v-5a.85.85 0 00-1.7 0v5A.842.842 0 006.8 15z\"/><path data-name=\"Trac&#xE9; 2742\" d=\"M10.2 15a.842.842 0 00.85-.833v-5a.85.85 0 00-1.7 0v5a.842.842 0 00.85.833z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 20"
    }
  };
  _exports.default = _default;
});