define("ember-hifi/hifi-connections/hls", ["exports", "ember-hifi/hifi-connections/base", "hls"], function (_exports, _base, _hls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClassMethods = Ember.Mixin.create({
    acceptMimeTypes: ['application/vnd.apple.mpegurl'],
    canUseConnection: function canUseConnection()
    /* audioUrl */
    {
      // We basically never want to use this on a mobile device
      return _hls.default.isSupported();
    },
    toString: function toString() {
      return 'HLS';
    }
  });
  /**
  * This class connects with HLS.js to create sounds.
  *
  * @class HLS
  * @extends BaseSound
  * @constructor
  */

  var Sound = _base.default.extend({
    loaded: false,
    mediaRecoveryAttempts: 0,
    id3TagMetadata: null,
    setup: function setup() {
      var hls = new _hls.default({
        debug: false,
        startFragPrefetch: true
      });
      var video = document.createElement('video');
      this.set('video', video);
      this.set('hls', hls);
      hls.attachMedia(video);

      this._setupHLSEvents(hls);

      this._setupPlayerEvents(video);
    },
    _setupHLSEvents: function _setupHLSEvents(hls) {
      var _this = this;

      hls.on(_hls.default.Events.MEDIA_ATTACHED, function () {
        _this.debug('media attached');

        hls.loadSource(_this.get('url'));
        hls.on(_hls.default.Events.MANIFEST_PARSED, function (e, data) {
          _this.debug("manifest parsed and loaded, found ".concat(data.levels.length, " quality level(s)"));

          _this.set('manifest', data);
        });
        hls.on(_hls.default.Events.LEVEL_LOADED, function (e, data) {
          _this.debug("level ".concat(data.level, " loaded"));

          _this.set('live', data.details.live);

          _this._checkIfAudioIsReady();
        });
        hls.on(_hls.default.Events.AUDIO_TRACK_LOADED, function () {
          _this.debug('audio track loaded');

          _this._checkIfAudioIsReady();
        });
        hls.on(_hls.default.Events.ERROR, function (e, data) {
          return _this._onHLSError(e, data);
        });
        var self = _this;
        hls.on(_hls.default.Events.FRAG_CHANGED, function (e, f) {
          var newId3TagMetadata = {
            title: f.frag.title
          };

          if (JSON.stringify(self.get('id3TagMetadata')) !== JSON.stringify(newId3TagMetadata)) {
            _this.debug('hls metadata changed');

            _this.trigger('audio-metadata-changed', _this, {
              old: self.get('id3TagMetadata'),
              new: newId3TagMetadata
            });

            self.set('id3TagMetadata', newId3TagMetadata);
          }
        });
      });
    },
    _setupPlayerEvents: function _setupPlayerEvents(video) {
      var _this2 = this;

      video.addEventListener('playing', function () {
        if (_this2.get('loaded')) {
          _this2.trigger('audio-played', _this2);
        } else {
          _this2._signalAudioIsReady();
        }
      });
      video.addEventListener('pause', function () {
        return _this2.trigger('audio-paused', _this2);
      });
      video.addEventListener('durationchange', function () {
        return _this2.trigger('audio-duration-changed', _this2);
      });
      video.addEventListener('seeked', function () {
        return _this2.trigger('audio-position-changed', _this2);
      });
      video.addEventListener('progress', function () {
        return _this2.trigger('audio-loading');
      });
      video.addEventListener('error', function (e) {
        return _this2._onVideoError(e);
      });
    },
    _checkIfAudioIsReady: function _checkIfAudioIsReady() {
      if (!this.get('loaded')) {
        // The only reliable way to check if this thing is actually ready
        // is to play it. If we get a play signal we're golden, but if we
        // get an error, we're outta here
        this.debug('Testing if audio is ready');
        this.get('video').volume = 0;
        this.get('video').play();
      }
    },
    _signalAudioIsReady: function _signalAudioIsReady() {
      this.debug('Test succeeded, signaling audio-ready');
      this.set('loaded', true);
      this.get('video').pause();
      this.trigger('audio-ready');
    },
    _onVideoError: function _onVideoError(e) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
          this.debug("video element error: playback aborted");

          this._giveUpAndDie("unknown error");

          break;

        case e.target.error.MEDIA_ERR_NETWORK:
          this.debug("video element error: network error");

          this._giveUpAndDie("Network error caused download to fail");

          break;

        case e.target.error.MEDIA_ERR_DECODE:
          this.debug("video element error: decoding error");

          this._tryToRecoverFromMediaError(e.target.error.MEDIA_ERR_DECODE);

          break;

        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          this.debug("video element error: source format not supported");

          this._giveUpAndDie("audio source format is not supported");

          break;

        default:
          this._giveUpAndDie("unknown error");

          break;
      }
    },
    _onHLSError: function _onHLSError(error, data) {
      if (data.fatal) {
        switch (data.type) {
          case _hls.default.ErrorTypes.NETWORK_ERROR:
            this.debug(data);

            this._giveUpAndDie("".concat(data.details));

            break;

          case _hls.default.ErrorTypes.MEDIA_ERROR:
            this._tryToRecoverFromMediaError("".concat(data.details));

            break;

          default:
            this._giveUpAndDie("".concat(data.details));

            break;
        }
      }
    },
    _tryToRecoverFromMediaError: function _tryToRecoverFromMediaError(error) {
      var mediaRecoveryAttempts = this.get('mediaRecoveryAttempts');
      var hls = this.get('hls');

      switch (mediaRecoveryAttempts) {
        case 0:
          this.debug("First attempt at media error recovery for error: ".concat(error));
          hls.recoverMediaError();
          break;

        case 1:
          this.debug("Second attempt at media error recovery: switching codecs for error: ".concat(error));
          hls.swapAudioCodec();
          hls.recoverMediaError();
          break;

        case 2:
          this.debug("We tried our best and we failed: ".concat(error));

          this._giveUpAndDie(error);

          break;
      }

      this.incrementProperty('mediaRecoveryAttempts');
    },
    _giveUpAndDie: function _giveUpAndDie(error) {
      this.get('hls').destroy();
      this.trigger('audio-load-error', error);
    },

    /* Public interface to sound */
    _audioDuration: function _audioDuration() {
      if (this.get('live')) {
        return Infinity;
      } else {
        return this.get('video').duration * 1000;
      }
    },
    _currentPosition: function _currentPosition() {
      return this.get('video').currentTime * 1000;
    },
    _setPosition: function _setPosition(position) {
      this.get('video').currentTime = position / 1000;
      return position;
    },
    _setVolume: function _setVolume(volume) {
      this.get('video').volume = volume / 100;
    },
    play: function play() {
      if (!this.get('video').src) {
        this.setup(); // the stream was stopped before
      }

      this.get('video').play();

      if (this.get('loadStopped')) {
        this.get('hls').startLoad();
        this.set('loadStopped', false);
      }
    },
    pause: function pause() {
      this.get('video').pause();
      this.get('hls').stopLoad();
      this.set('loadStopped', true);
    },
    stop: function stop() {
      this.pause();
      this.get('video').removeAttribute('src');
    },
    teardown: function teardown() {
      this.get('hls').destroy();
    }
  });

  Sound.reopenClass(ClassMethods);
  var _default = Sound;
  _exports.default = _default;
});