define("ember-changeset/utils/is-relay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isRelay;
  _exports.RELAY = void 0;
  var RELAY = '__RELAY__';
  _exports.RELAY = RELAY;

  function isRelay(relay
  /*: mixed */
  )
  /*: boolean */
  {
    if (!relay) return false;
    return Ember.get(relay, '__relay__') === RELAY;
  }
});