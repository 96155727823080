define("ember-svg-jar/inlined/audio-file-uploaded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Composant 152 &#x2013; 7\"><path data-name=\"Trac&#xE9; 2984\" d=\"M31.194 0H1.5C.67 0 0 .7 0 2.064v56.865A1.551 1.551 0 001.5 60h41a1.551 1.551 0 001.5-1.071V13.9c0-.746-.1-.986-.263-1.163L31.83.275A.884.884 0 0031.194 0z\" fill=\"#efefef\"/><path data-name=\"Trac&#xE9; 2991\" d=\"M31.721.162v12.7h12.125z\" fill=\"#cecece\"/><path d=\"M28.873 22.599a3.344 3.344 0 00-2.767-.717l-7.721 1.448a4.2 4.2 0 00-3.429 4.131v8.281a3.328 3.328 0 00-1.681-.467 3.363 3.363 0 103.363 3.363v-7.672a1.681 1.681 0 011.374-1.652l9.4-1.765a.841.841 0 011 .832v4.842a3.328 3.328 0 00-1.681-.471 3.363 3.363 0 103.363 3.363V25.186a3.352 3.352 0 00-1.221-2.587zM13.275 40.323a1.681 1.681 0 111.681-1.681 1.681 1.681 0 01-1.681 1.681zm13.827-14.424l-9.4 1.762a3.363 3.363 0 00-1.062.4v-.6a2.522 2.522 0 012.055-2.48l7.717-1.447a1.681 1.681 0 012 1.652v.827a2.484 2.484 0 00-1.31-.114zm-.376 11.9a1.681 1.681 0 111.686-1.684 1.681 1.681 0 01-1.686 1.681z\" fill=\"#575757\"/></g>",
    "attrs": {
      "data-name": "Composant 153 – 12",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "44",
      "height": "60",
      "viewBox": "0 0 44 60"
    }
  };
  _exports.default = _default;
});