define("ember-svg-jar/inlined/check-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.291.294L8.472 14.112a1 1 0 01-1.417 0L1.711 8.763a1 1 0 00-1.417 0 1 1 0 000 1.417l5.346 5.345a3.008 3.008 0 004.25 0L23.708 1.71a1 1 0 000-1.416 1 1 0 00-1.417 0z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24.002",
      "height": "16.404",
      "viewBox": "0 0 24.002 16.404"
    }
  };
  _exports.default = _default;
});