define("ember-svg-jar/inlined/reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E23D51\" d=\"M10 0L0 10v26h28V0H10zM9 2.4V9H2.4L9 2.4zM27 35H1V10h9V1h17v34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 36"
    }
  };
  _exports.default = _default;
});