define("liquid-wormhole/templates/components/liquid-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IAgb28Qc",
    "block": "[[[41,[33,1],[[[41,[48,[30,2]],[[[46,[33,4],null,[[\"id\",\"class\"],[[33,5],[28,[37,6],[[33,7],\" liquid-wormhole-element\"],null]]],[[\"default\"],[[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[]],[[[1,\"    \"],[46,[33,4],null,[[\"id\",\"class\"],[[33,5],[28,[37,6],[[33,7],\" liquid-wormhole-element\"],null]]],null],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[10,0],[15,1,[36,5]],[15,0,[29,[[36,7],\" liquid-wormhole-element\"]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"details\",\"&default\"],false,[\"if\",\"hasSend\",\"has-block\",\"component\",\"send\",\"wormholeId\",\"concat\",\"wormholeClass\",\"yield\"]]",
    "moduleName": "liquid-wormhole/templates/components/liquid-wormhole.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});