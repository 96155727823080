define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.22 6.639L6.92.805A4.51 4.51 0 002.383.45 4.151 4.151 0 000 4.166v11.668a4.151 4.151 0 002.383 3.716 4.51 4.51 0 004.54-.352l8.3-5.834a4.065 4.065 0 000-6.721zm-1.03 5.376l-8.3 5.834a2.706 2.706 0 01-2.72.211 2.491 2.491 0 01-1.43-2.227V4.166a2.448 2.448 0 011.428-2.227 2.72 2.72 0 011.188-.273 2.659 2.659 0 011.535.491l8.3 5.834a2.439 2.439 0 010 4.031z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "20",
      "viewBox": "0 0 17 20"
    }
  };
  _exports.default = _default;
});