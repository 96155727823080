define("ember-custom-actions/index", ["exports", "ember-custom-actions/actions/resource", "ember-custom-actions/actions/model", "ember-custom-actions/actions/custom", "ember-custom-actions/serializers/json-api", "ember-custom-actions/serializers/rest", "ember-custom-actions/mixins/adapter"], function (_exports, _resource, _model, _custom, _jsonApi, _rest, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "resourceAction", {
    enumerable: true,
    get: function get() {
      return _resource.default;
    }
  });
  Object.defineProperty(_exports, "modelAction", {
    enumerable: true,
    get: function get() {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "customAction", {
    enumerable: true,
    get: function get() {
      return _custom.default;
    }
  });
  Object.defineProperty(_exports, "JSONAPISerializer", {
    enumerable: true,
    get: function get() {
      return _jsonApi.default;
    }
  });
  Object.defineProperty(_exports, "RESTSerializer", {
    enumerable: true,
    get: function get() {
      return _rest.default;
    }
  });
  Object.defineProperty(_exports, "AdapterMixin", {
    enumerable: true,
    get: function get() {
      return _adapter.default;
    }
  });
});