define("ember-svg-jar/inlined/star-plain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"nonzero\"><path fill=\"#FFF\" d=\"M9 14.229l-4.387 2.326a.5.5 0 01-.727-.528l.857-4.891-3.568-3.454a.5.5 0 01.277-.854L6.37 6.13 8.55 1.67a.5.5 0 01.898 0l2.182 4.46 4.917.697a.5.5 0 01.277.854l-3.568 3.454.857 4.89a.5.5 0 01-.727.529L9 14.229z\"/><path fill=\"#9D9FA3\" d=\"M12.18 10.786l3.215-3.112-4.43-.628L9 3.028 7.034 7.046l-4.43.628 3.215 3.112-.771 4.406L9 13.097l3.952 2.095-.771-4.406zM9 14.229l-4.387 2.326a.5.5 0 01-.727-.528l.857-4.891-3.568-3.454a.5.5 0 01.277-.854L6.37 6.13 8.55 1.67a.5.5 0 01.898 0l2.182 4.46 4.917.697a.5.5 0 01.277.854l-3.568 3.454.857 4.89a.5.5 0 01-.727.529L9 14.229z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});