define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9D9FA3\" d=\"M14.293 3H11.5a.5.5 0 110-1h4a.5.5 0 01.5.5v4a.5.5 0 11-1 0V3.707l-7.146 7.147a.5.5 0 01-.708-.708L14.293 3zM14 9.1a.5.5 0 111 0v4.8a2.1 2.1 0 01-2.1 2.1H4.1A2.1 2.1 0 012 13.9V5.1C2 3.94 2.94 3 4.1 3h4.8a.5.5 0 010 1H4.1A1.1 1.1 0 003 5.1v8.8A1.1 1.1 0 004.1 15h8.8a1.1 1.1 0 001.1-1.1V9.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});