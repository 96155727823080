define("ember-truncate/components/ember-truncate/truncation-target", ["exports", "ember-truncate/templates/just-yield"], function (_exports, _justYield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _justYield.default,
    classNames: ['truncate-multiline--truncation-target']
  });

  _exports.default = _default;
});