define("ember-svg-jar/inlined/headphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.386 8.86V7.65A7.77 7.77 0 0010.5 0a7.77 7.77 0 00-7.884 7.65v1.21a4.221 4.221 0 00-2.523 4.759A4.35 4.35 0 004.368 17a1.727 1.727 0 001.752-1.7v-5.1a1.727 1.727 0 00-1.752-1.7v-.85A6.043 6.043 0 0110.5 1.7a6.043 6.043 0 016.132 5.95v.85a1.727 1.727 0 00-1.752 1.7v5.1a1.727 1.727 0 001.753 1.7 4.35 4.35 0 004.275-3.381 4.221 4.221 0 00-2.522-4.759zM4.368 15.3a2.551 2.551 0 110-5.1zm12.265 0v-5.1a2.551 2.551 0 110 5.1z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "17",
      "viewBox": "0 0 21 17"
    }
  };
  _exports.default = _default;
});