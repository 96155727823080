define("ember-svg-jar/inlined/check-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle data-name=\"Ellipse 62\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\" fill=\"#C7C7C7\"/><g data-name=\"Groupe 4745\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-width=\"2\"><path data-name=\"Ligne 19\" d=\"M3.727 7.278l3.552 3.108\"/><path data-name=\"Ligne 20\" d=\"M11.275 4.614l-3.996 5.772\"/></g>",
    "attrs": {
      "data-name": "Composant 200 – 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});