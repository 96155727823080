define("ember-changeset/utils/computed/transform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  /*::
  import type Change from 'ember-changeset/-private/change';
  import type Err from 'ember-changeset/-private/err';
  */
  var keys = Object.keys;
  /**
   * Transform an Object's values with a `transform` function.
   */

  function transform
  /*:: <T> */
  (dependentKey
  /*: string       */
  , transform
  /*: (T) => mixed */
  )
  /*: Object */
  {
    return Ember.computed(dependentKey, function () {
      var obj
      /*: Object */
      = Ember.get(this, dependentKey);
      return keys(obj).reduce(function (newObj, key) {
        newObj[key] = transform(obj[key]);
        return newObj;
      }, {});
    });
  }
});