define("ember-sticky-element/templates/components/sticky-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RCgtC0w/",
    "block": "[[[1,[28,[35,0],null,[[\"offset\",\"enter\",\"exit\",\"registerElement\"],[[33,1],[28,[37,2],[[30,0],\"parentTopEntered\"],null],[28,[37,2],[[30,0],\"parentTopExited\"],null],[28,[37,2],[[30,0],\"registerTopTrigger\"],null]]]]],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"\\n  \",[36,3],\"\\n  \",[52,[33,5],[33,6]],\"\\n  \",[52,[33,7],[33,8]],\"\\n  \",[52,[33,9],[33,10]]]]],[15,5,[36,11]],[12],[1,\"\\n\\n  \"],[18,1,[[28,[37,13],null,[[\"isSticky\",\"isStickyTop\",\"isStickyBottom\"],[[33,5],[33,7],[33,9]]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[41,[33,14],[[[1,\"  \"],[1,[28,[35,0],null,[[\"type\",\"offset\",\"enter\",\"exit\",\"registerElement\"],[\"bottom\",[33,15],[28,[37,2],[[30,0],\"parentBottomEntered\"],null],[28,[37,2],[[30,0],\"parentBottomExited\"],null],[28,[37,2],[[30,0],\"registerBottomTrigger\"],null]]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"sticky-element/trigger\",\"top\",\"action\",\"containerClassName\",\"if\",\"isSticky\",\"containerStickyClassName\",\"isStickyTop\",\"containerStickyTopClassName\",\"isStickyBottom\",\"containerStickyBottomClassName\",\"containerStyle\",\"yield\",\"hash\",\"stickToBottom\",\"offsetBottom\"]]",
    "moduleName": "ember-sticky-element/templates/components/sticky-element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});