define("ember-svg-jar/inlined/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 3079\" d=\"M5.833 0h-2.5A3.333 3.333 0 000 3.333v2.5a3.333 3.333 0 003.333 3.334h2.5a3.333 3.333 0 003.334-3.334v-2.5A3.333 3.333 0 005.833 0zM7.5 5.833A1.667 1.667 0 015.833 7.5h-2.5a1.667 1.667 0 01-1.666-1.667v-2.5a1.667 1.667 0 011.666-1.666h2.5A1.667 1.667 0 017.5 3.333z\" fill=\"#8e8e8e\"/><path data-name=\"Trac&#xE9; 3080\" d=\"M16.666 0h-2.5a3.333 3.333 0 00-3.333 3.333v2.5a3.333 3.333 0 003.333 3.333h2.5a3.333 3.333 0 003.333-3.333v-2.5A3.333 3.333 0 0016.666 0zm1.667 5.833A1.667 1.667 0 0116.666 7.5h-2.5a1.667 1.667 0 01-1.667-1.667v-2.5a1.667 1.667 0 011.667-1.667h2.5a1.667 1.667 0 011.667 1.667z\" fill=\"#8e8e8e\"/><path data-name=\"Trac&#xE9; 3081\" d=\"M5.833 10.833h-2.5A3.333 3.333 0 000 14.166v2.5a3.333 3.333 0 003.333 3.333h2.5a3.333 3.333 0 003.333-3.333v-2.5a3.333 3.333 0 00-3.333-3.333zM7.5 16.666a1.667 1.667 0 01-1.667 1.667h-2.5a1.667 1.667 0 01-1.667-1.667v-2.5a1.667 1.667 0 011.667-1.667h2.5A1.667 1.667 0 017.5 14.166z\" fill=\"#8e8e8e\"/><path data-name=\"Trac&#xE9; 3082\" d=\"M16.666 10.833h-2.5a3.333 3.333 0 00-3.333 3.333v2.5a3.333 3.333 0 003.333 3.333h2.5a3.333 3.333 0 003.333-3.333v-2.5a3.333 3.333 0 00-3.333-3.333zm1.667 5.833a1.667 1.667 0 01-1.667 1.667h-2.5a1.667 1.667 0 01-1.667-1.667v-2.5a1.667 1.667 0 011.667-1.667h2.5a1.667 1.667 0 011.667 1.667z\" fill=\"#8e8e8e\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});