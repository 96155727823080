define("liquid-wormhole/index", ["exports", "liquid-fire/constraint"], function (_exports, _constraint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.target = target;
  _exports.onOpenWormhole = onOpenWormhole;
  _exports.onCloseWormhole = onCloseWormhole;

  function target(name) {
    return new _constraint.default('parentElementClass', "".concat(name));
  }

  function onOpenWormhole() {
    return new _constraint.default('newValue', function (value) {
      return value !== null;
    });
  }

  function onCloseWormhole() {
    return new _constraint.default('newValue', function (value) {
      return value === null;
    });
  }
});