define("ember-svg-jar/inlined/male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#8CB1E8\" fill-rule=\"evenodd\" d=\"M13.22 20.445c.733 0 1.326-.398 1.326-.89 0-.493-.593-.891-1.326-.891-.733 0-1.327.398-1.327.89 0 .493.594.891 1.327.891zm4.863-2.672c.733 0 1.327-.598 1.327-1.336 0-.739-.594-1.337-1.327-1.337-.732 0-1.326.598-1.326 1.337 0 .738.594 1.336 1.326 1.336zm0 4.9H8.356c0 2.706 2.178 4.9 4.864 4.9s4.863-2.194 4.863-4.9zm-6.632 2.895c0-.369.792-.668 1.769-.668.977 0 1.768.3 1.768.668 0 .369-.791.668-1.768.668s-1.769-.3-1.769-.668zm-1.768-9.131c0-.739-.594-1.337-1.327-1.337-.732 0-1.326.598-1.326 1.337 0 .738.594 1.336 1.326 1.336.733 0 1.327-.598 1.327-1.336zm7.03 15.088v-.796c4.075-1.15 7.074-4.925 7.074-9.393v-1.852a3.07 3.07 0 002.653-3.047 3.07 3.07 0 00-2.653-3.048V9.755c0-5.38-4.344-9.755-9.683-9.755h-1.768c-.267 0-.529.014-.79.035l-.079.006c-4.329.39-7.845 3.661-8.642 7.892l-.084.385.017.009a9.878 9.878 0 00-.105 1.428v3.634A3.07 3.07 0 000 16.437a3.07 3.07 0 002.653 3.047v1.852a9.759 9.759 0 007.074 9.393v.796c-4.754.948-8.436 3.785-9.264 7.44L.229 40H26.21l-.234-1.035c-.829-3.654-4.51-6.493-9.264-7.44zM4.333 21.336v-3.529l-1.282.01c-.756 0-1.37-.62-1.37-1.38 0-.762.614-1.381 1.37-1.381l1.282.01V9.755c0-.212.015-.42.031-.628 3.977 1.816 9.105 2.81 14.604 2.81 1.043 0 2.093-.05 3.139-.125v3.255l.85-.01.432-.001c.756 0 1.37.62 1.37 1.38 0 .762-.614 1.381-1.37 1.381-.15 0-.293 0-.433-.002l-.85-.01v3.531c0 4.446-3.589 8.063-8.002 8.063h-1.768c-4.413 0-8.003-3.617-8.003-8.063zm10.7 12.027c0 .761-.615 1.38-1.371 1.38h-.884c-.756 0-1.37-.619-1.37-1.38v-2.317c.305.029.615.046.928.046h1.768a9.7 9.7 0 00.929-.046v2.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "40",
      "viewBox": "0 0 27 40"
    }
  };
  _exports.default = _default;
});