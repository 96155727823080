define("ember-svg-jar/inlined/upload-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2685\" d=\"M10.5 21A10.5 10.5 0 1121 10.5 10.5 10.5 0 0110.5 21zm0-19.25a8.75 8.75 0 108.75 8.75 8.75 8.75 0 00-8.75-8.75z\" fill=\"#9b9999\"/><path d=\"M15.074 9.722a.892.892 0 01-1.133 0l-2.639-2.284-.01 7.224a.81.81 0 01-1.6 0l.006-7.225L7.06 9.722a.891.891 0 01-1.133 0 .63.63 0 010-.981L8.8 6.253a2.675 2.675 0 013.4 0l2.874 2.49a.63.63 0 010 .979z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21"
    }
  };
  _exports.default = _default;
});