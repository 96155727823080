define("ember-svg-jar/inlined/hamburger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EA2A3\" d=\"M18 15.5a.5.5 0 01-.494.5H.494a.5.5 0 010-1h17.012c.273 0 .494.232.494.5zM18 9a.5.5 0 01-.494.5H.494a.5.5 0 010-1h17.012c.273 0 .494.232.494.5zm0-6.5a.5.5 0 01-.494.5H.494a.5.5 0 010-1h17.012c.273 0 .494.232.494.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});