define("ember-paper/components/paper-autocomplete/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gm/Yuqdw",
    "block": "[[[11,1],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tokens\"]]],null]],null],null,[[[41,[30,2,[\"isMatch\"]],[[[10,1],[14,0,\"highlight\"],[12],[1,[30,2,[\"text\"]]],[13]],[]],[[[1,[30,2,[\"text\"]]]],[]]]],[2]],null],[13]],[\"&attrs\",\"token\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "ember-paper/components/paper-autocomplete/highlight/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});