define("ember-svg-jar/inlined/user-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 7a4 4 0 118 0v2a3.98 3.98 0 01-.675 2.225A4.002 4.002 0 0115 15v1.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5V15a4.002 4.002 0 012.675-3.775A3.981 3.981 0 015 9V7zm7 0v2a3 3 0 11-6 0V7a3 3 0 016 0zm-5.58 5.056A3 3 0 004 15v1h10v-1a3 3 0 00-2.42-2.944A3.984 3.984 0 019 13a3.984 3.984 0 01-2.58-.944z\" fill=\"#9D9FA3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 2a5 5 0 015 5v2.5a.5.5 0 00.5.5h.5a2 2 0 100-4h-.083A6.002 6.002 0 003.083 6H3a2 2 0 100 4h.5a.5.5 0 00.5-.5V7a5 5 0 015-5zM3 7a1 1 0 100 2V7zm13 1a1 1 0 01-1 1V7a1 1 0 011 1z\" fill=\"#9D9FA3\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});