define("ember-hifi/hifi-connections/native-audio", ["exports", "ember-hifi/hifi-connections/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // These are the events we're watching for
  var AUDIO_EVENTS = ['loadstart', 'durationchange', 'loadedmetadata', 'loadeddata', 'progress', 'canplay', 'canplaythrough', 'error', 'playing', 'pause', 'ended', 'emptied']; // Ready state values
  // const HAVE_NOTHING = 0;
  // const HAVE_METADATA = 1;

  var HAVE_CURRENT_DATA = 2; // const HAVE_FUTURE_DATA = 3;
  // const HAVE_ENOUGH_DATA = 4;

  var ClassMethods = Ember.Mixin.create({
    canPlayMimeType: function canPlayMimeType(mimeType) {
      var audio = new Audio(); // it returns "probably" and "maybe". Both are worth trying. Empty is bad.

      return audio.canPlayType(mimeType) !== "";
    },
    toString: function toString() {
      return 'Native Audio';
    }
  });
  /**
  * This class connects with the native audio element to create sounds.
  *
  * @class NativeAudio
  * @extends BaseSound
  * @constructor
  */

  var Sound = _base.default.extend({
    setup: function setup() {
      var audio = this.requestControl();
      audio.src = this.get('url');

      this._registerEvents(audio);

      if (Ember.testing) {
        console.warn('setting audio element volume to zero for testing, to get around autoplay restrictions'); // eslint-disable-line

        audio.muted = true;
      }

      audio.load();
    },
    _registerEvents: function _registerEvents(audio) {
      var _this = this;

      AUDIO_EVENTS.forEach(function (eventName) {
        audio.addEventListener(eventName, function (e) {
          return Ember.run(function () {
            return _this._handleAudioEvent(eventName, e);
          });
        });
      });
    },
    _unregisterEvents: function _unregisterEvents(audio) {
      AUDIO_EVENTS.forEach(function (eventName) {
        return audio.removeEventListener(eventName);
      });
    },
    _handleAudioEvent: function _handleAudioEvent(eventName, e) {
      if (!this.urlsAreEqual(e.target.src, this.get('url')) && e.target.src !== '') {
        // This event is not for us if our srcs aren't equal
        // but if the target src is empty it means we've been stopped and in
        // that case should allow the event through.
        return;
      }

      this.debug("Handling '".concat(eventName, "' event from audio element"));

      switch (eventName) {
        case 'loadeddata':
          var audio = this.audioElement(); // Firefox doesn't fire a 'canplay' event until after you call *play* on
          // the audio, but it does fire 'loadeddata' when it's ready

          if (audio.readyState >= HAVE_CURRENT_DATA) {
            this._onAudioReady();
          }

          break;

        case 'canplay':
        case 'canplaythrough':
          this._onAudioReady();

          break;

        case 'error':
          this._onAudioError(e.target.error);

          break;

        case 'playing':
          this._onAudioPlayed();

          break;
        // the emptied event is triggered by our more reliable stream pause method

        case 'emptied':
          this._onAudioEmptied();

          break;

        case 'pause':
          this._onAudioPaused();

          break;

        case 'durationchange':
          this._onAudioDurationChanged();

          break;

        case 'ended':
          this._onAudioEnded();

          break;

        case 'progress':
          this._onAudioProgress(e);

          break;
      }
    },
    audioElement: function audioElement() {
      // If we have control, return the shared element
      // if we don't have control, return the internal cloned element
      var sharedAudioAccess = this.get('sharedAudioAccess');

      if (sharedAudioAccess && sharedAudioAccess.hasControl(this)) {
        return sharedAudioAccess.get('audioElement');
      } else {
        var audioElement = this.get('_audioElement') || document.createElement('audio');
        this.set('_audioElement', audioElement);
        return audioElement;
      }
    },
    releaseControl: function releaseControl() {
      if (!this.get('sharedAudioAccess')) {
        return;
      }

      if (this.get('isPlaying')) {
        // send a pause event so anyone subscribed to hifi's relayed events gets the message
        this._onAudioPaused(this);
      }

      this.get('sharedAudioAccess').releaseControl(this); // save current state of audio element to the internal element that won't be played

      this._saveState(this.get('sharedAudioAccess.audioElement'));
    },
    _saveState: function _saveState(audio) {
      this.debug('Saving audio state');
      var shadowAudio = document.createElement('audio');
      this.set('_audioElement', shadowAudio);
      shadowAudio.preload = 'none';
      shadowAudio.src = audio.src;

      try {
        shadowAudio.currentTime = audio.currentTime;
      } catch (e) {
        this.debug('Errored while trying to save audio current time');
        this.debug(e);
      }

      shadowAudio.volume = audio.volume;
      this.debug('Saved audio state');
    },
    requestControl: function requestControl() {
      if (this.get('sharedAudioAccess')) {
        return this.get('sharedAudioAccess').requestControl(this);
      } else {
        return this.audioElement();
      }
    },
    restoreState: function restoreState() {
      var sharedElement = this.audioElement();
      var internalElement = this.get('_audioElement');

      if (this.get('sharedAudioAccess') && internalElement) {
        this.debug('Restoring audio state…');

        try {
          // restore the state of the shared element from the dummy element
          if (internalElement.currentTime) {
            sharedElement.currentTime = internalElement.currentTime;
          }

          if (internalElement.volume) {
            sharedElement.volume = internalElement.volume;
          }

          this.debug('Restored audio state');
        } catch (e) {
          this.debug('Errored while trying to restore audio state');
          this.debug(e);
        }
      }
    },
    _onAudioProgress: function _onAudioProgress() {
      this.trigger('audio-loading', this._calculatePercentLoaded());
    },
    _onAudioDurationChanged: function _onAudioDurationChanged() {
      this.trigger('audio-duration-changed', this);
    },
    _onAudioPlayed: function _onAudioPlayed() {
      if (!this.get('isPlaying')) {
        this.trigger('audio-played', this);
      }
    },
    _onAudioEnded: function _onAudioEnded() {
      this.trigger('audio-ended', this);
    },
    _onAudioError: function _onAudioError(error) {
      var message = "";

      switch (error.code) {
        case error.MEDIA_ERR_ABORTED:
          message = 'You aborted the audio playback.';
          break;

        case error.MEDIA_ERR_NETWORK:
          message = 'A network error caused the audio download to fail.';
          break;

        case error.MEDIA_ERR_DECODE:
          message = 'Decoder error.';
          break;

        case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          message = 'Audio source format is not supported.';
          break;

        default:
          message = error.message;
          break;
      }

      this.debug("audio element threw error ".concat(message));
      this.trigger('audio-load-error', message);
    },
    _onAudioEmptied: function _onAudioEmptied() {
      this.trigger('audio-paused', this);
    },
    _onAudioPaused: function _onAudioPaused() {
      this.trigger('audio-paused', this);
    },
    _onAudioReady: function _onAudioReady() {
      this.trigger('audio-ready', this);
      this.trigger('audio-loaded', this);
    },
    _calculatePercentLoaded: function _calculatePercentLoaded() {
      var audio = this.audioElement();

      if (audio && audio.buffered && audio.buffered.length) {
        var ranges = audio.buffered;
        var totals = [];

        for (var index = 0; index < ranges.length; index++) {
          totals.push(ranges.end(index) - ranges.start(index));
        }

        var total = Ember.A(totals).reduce(function (a, b) {
          return a + b;
        }, 0);
        this.debug("ms loaded: ".concat(total * 1000));
        this.debug("duration: ".concat(this._audioDuration()));
        this.debug("percent loaded = ".concat(total / audio.duration * 100));
        return {
          percentLoaded: total / audio.duration
        };
      } else {
        return 0;
      }
    },

    /* Public interface */
    _audioDuration: function _audioDuration() {
      var audio = this.audioElement();

      if (audio.duration > 172800000) {
        // if audio is longer than 3 days in milliseconds,
        // assume it's a stream, and set duration to infinity as it should be
        // this is a bug in Opera and was reported on 5/25/2017
        return Infinity;
      }

      return audio.duration * 1000;
    },
    _currentPosition: function _currentPosition() {
      var audio = this.audioElement();
      return audio.currentTime * 1000;
    },
    _setPosition: function _setPosition(position) {
      var audio = this.audioElement();
      audio.currentTime = position / 1000;
      return this._currentPosition();
    },
    _setVolume: function _setVolume(volume) {
      var audio = this.audioElement();
      audio.volume = volume / 100;
    },
    play: function play() {
      var _this2 = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          position = _ref.position;

      var audio = this.requestControl(); // since we clear the `src` attr on pause, restore it here

      this.loadAudio(audio);
      this.restoreState();

      if (typeof position !== 'undefined') {
        this._setPosition(position);
      }

      this.debug('telling audio to play');
      return audio.play().catch(function (e) {
        return _this2._onAudioError(e);
      });
    },
    pause: function pause() {
      var audio = this.audioElement();

      if (this.get('isStream')) {
        this.stop(); // we don't want to the stream to continue loading while paused
      } else {
        audio.pause();
      }
    },
    stop: function stop() {
      var audio = this.audioElement();
      audio.pause(); // calling pause halts playback but does not stop downloading streaming
      // media. this is the method recommended by MDN: https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Using_HTML5_audio_and_video#Stopping_the_download_of_media
      // NOTE: this fires an `'emptied'` event, which we treat the same way as `'pause'`

      audio.removeAttribute('src');
      audio.load();
    },
    loadAudio: function loadAudio(audio) {
      if (!this.urlsAreEqual(audio.src, this.get('url'))) {
        audio.setAttribute('src', this.get('url'));
      }
    },
    urlsAreEqual: function urlsAreEqual(url1, url2) {
      // GOTCHA: audio.src is a fully qualified URL, and this.get('url') may be a relative url
      // So when comparing, make sure we're dealing in absolutes
      var parser1 = document.createElement('a');
      var parser2 = document.createElement('a');
      parser1.href = url1;
      parser2.href = url2;
      return parser1.href === parser2.href;
    },
    teardown: function teardown() {
      var audio = this.requestControl();
      this.trigger('_will_destroy');

      this._unregisterEvents(audio);
    }
  });

  Sound.reopenClass(ClassMethods);
  var _default = Sound;
  _exports.default = _default;
});