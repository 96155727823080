define("ember-svg-jar/inlined/linked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9D9FA3\" d=\"M17 1H7v4H6V1a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H7a1 1 0 01-1-1V8h1v3h10V1zm-6 9V7H1v10h10v-4h1v4a1 1 0 01-1 1H1a1 1 0 01-1-1V7a1 1 0 011-1h10a1 1 0 011 1v3h-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});