define("ember-feature-controls/templates/features-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MblUTUtl",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"ember-feature-controls\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ember-feature-controls-title\"],[12],[1,\"Feature Flags Controls Room\"],[13],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13]],[],false,[\"feature-controls\"]]",
    "moduleName": "ember-feature-controls/templates/features-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});