define("ember-svg-jar/inlined/next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 6.419l-6.28 3.768c-.95.57-1.72.13-1.72-.977V1.227C0 .118.768-.32 1.72.25L8 4.02V.729a.51.51 0 01.5-.51.5.5 0 01.5.51v8.98a.5.5 0 11-1 0V6.42z\" fill=\"#9b9999\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "10.437",
      "viewBox": "0 0 9 10.437"
    }
  };
  _exports.default = _default;
});