define("ember-paper/templates/components/paper-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YZEhjGus",
    "block": "[[[10,0],[14,0,\"md-text\"],[12],[18,1,null],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});