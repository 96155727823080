define("ember-svg-jar/inlined/volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5361\"><g data-name=\"Groupe 5358\"><path data-name=\"Trac&#xE9; 3150\" d=\"M0 0h16v16H0z\" fill=\"none\"/></g><g data-name=\"Groupe 5359\" fill=\"#fff\"><path d=\"M13.232 2.711H5.48a2.772 2.772 0 00-2.768 2.768v7.752a2.772 2.772 0 002.768 2.768h7.752A2.772 2.772 0 0016 13.231V5.479a2.772 2.772 0 00-2.768-2.768zm1.661 10.52a1.661 1.661 0 01-1.661 1.661H5.48a1.661 1.661 0 01-1.661-1.661V5.479A1.661 1.661 0 015.48 3.818h7.752a1.661 1.661 0 011.661 1.661z\"/><path d=\"M12.107 6.604a.808.808 0 01-.808.808h-.808v3.637A2.021 2.021 0 118.47 9.028a2.083 2.083 0 01.4.04V7.413a1.617 1.617 0 011.62-1.617h.808a.808.808 0 01.809.808z\"/><path data-name=\"stop\" d=\"M10.558 0H2.807A2.772 2.772 0 00.036 2.768v7.752c0 .063-.358 0 1.107 0V2.768l1.664-1.661h7.751zM2.807 1.107L1.146 2.768v7.752-7.752a1.661 1.661 0 011.661-1.661z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});