define("ember-hifi/services/hifi", ["exports", "ember-hifi/helpers/one-at-a-time", "ember-hifi/utils/promise-race", "ember-hifi/utils/shared-audio-access", "ember-hifi/mixins/debug-logging"], function (_exports, _oneAtATime, _promiseRace, _sharedAudioAccess, _debugLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SERVICE_EVENT_MAP = _exports.EVENT_MAP = void 0;
  var EVENT_MAP = [{
    event: 'audio-played',
    handler: '_relayPlayedEvent'
  }, {
    event: 'audio-paused',
    handler: '_relayPausedEvent'
  }, {
    event: 'audio-ended',
    handler: '_relayEndedEvent'
  }, {
    event: 'audio-duration-changed',
    handler: '_relayDurationChangedEvent'
  }, {
    event: 'audio-position-changed',
    handler: '_relayPositionChangedEvent'
  }, {
    event: 'audio-loaded',
    handler: '_relayLoadedEvent'
  }, {
    event: 'audio-loading',
    handler: '_relayLoadingEvent'
  }, {
    event: 'audio-position-will-change',
    handler: '_relayPositionWillChangeEvent'
  }, {
    event: 'audio-will-rewind',
    handler: '_relayWillRewindEvent'
  }, {
    event: 'audio-will-fast-forward',
    handler: '_relayWillFastForwardEvent'
  }, {
    event: 'audio-metadata-changed',
    handler: '_relayMetadataChangedEvent'
  }];
  _exports.EVENT_MAP = EVENT_MAP;
  var SERVICE_EVENT_MAP = [{
    event: 'current-sound-changed'
  }, {
    event: 'current-sound-interrupted'
  }, {
    event: 'new-load-request'
  }, {
    event: 'pre-load'
  }];
  /**
  * This is the hifi service class.
  *
  * @class hifi
  * @constructor
  */

  _exports.SERVICE_EVENT_MAP = SERVICE_EVENT_MAP;

  var _default = Ember.Service.extend(Ember.Evented, _debugLogging.default, {
    debugName: 'ember-hifi',
    poll: Ember.inject.service(),
    soundCache: Ember.inject.service('hifi-cache'),
    isMobileDevice: Ember.computed({
      get: function get() {
        return 'ontouchstart' in window;
      },
      set: function set(k, v) {
        return v;
      }
    }),
    useSharedAudioAccess: Ember.computed.or('isMobileDevice', 'alwaysUseSingleAudioElement'),
    currentSound: null,
    currentMetadata: Ember.computed('currentSound.metadata', {
      get: function get() {
        return this.get('currentSound.metadata');
      },
      set: function set(k, v) {
        return v;
      }
    }),
    isPlaying: Ember.computed.readOnly('currentSound.isPlaying'),
    isLoading: Ember.computed('currentSound.isLoading', {
      get: function get() {
        return this.get('currentSound.isLoading');
      },
      set: function set(k, v) {
        return v;
      }
    }),
    isStream: Ember.computed.readOnly('currentSound.isStream'),
    isFastForwardable: Ember.computed.readOnly('currentSound.isFastForwardable'),
    isRewindable: Ember.computed.readOnly('currentSound.isRewindable'),
    isMuted: Ember.computed.equal('volume', 0),
    duration: Ember.computed.readOnly('currentSound.duration'),
    percentLoaded: Ember.computed.readOnly('currentSound.percentLoaded'),
    pollInterval: Ember.computed.reads('options.emberHifi.positionInterval'),
    id3TagMetadata: Ember.computed.reads('currentSound.id3TagMetadata'),
    defaultVolume: 50,
    position: Ember.computed.alias('currentSound.position'),
    volume: Ember.computed({
      get: function get() {
        return this.get('currentSound.volume') || this.get('defaultVolume');
      },
      set: function set(k, v) {
        if (this.get('currentSound')) {
          this.get('currentSound')._setVolume(v);
        }

        if (v > 0) {
          this.set('unmuteVolume', v);
        }

        return v;
      }
    }),

    /**
     * When the Service is created, activate connections that were specified in the
     * configuration. This config is injected into the Service as `options`.
     *
     * @method init
     */
    init: function init() {
      var connections = Ember.getWithDefault(this, 'options.emberHifi.connections', Ember.A());
      var owner = Ember.getOwner(this);
      owner.registerOptionsForType('ember-hifi@hifi-connection', {
        instantiate: false
      });
      owner.registerOptionsForType('hifi-connection', {
        instantiate: false
      });
      Ember.set(this, 'alwaysUseSingleAudioElement', Ember.getWithDefault(this, 'options.emberHifi.alwaysUseSingleAudioElement', false));
      Ember.set(this, 'appEnvironment', Ember.getWithDefault(this, 'options.environment', 'development'));
      Ember.set(this, '_connections', {});
      Ember.set(this, 'oneAtATime', _oneAtATime.default.create());
      Ember.set(this, 'volume', 50);

      this._activateConnections(connections);

      this.set('isReady', true); // Polls the current sound for position. We wanted to make it easy/flexible
      // for connection authors, and since we only play one sound at a time, we don't
      // need other non-active sounds telling us position info

      this.get('poll').addPoll({
        interval: Ember.get(this, 'pollInterval') || 500,
        callback: Ember.run.bind(this, this._setCurrentPosition)
      });

      this._super.apply(this, arguments);
    },

    /**
     * Returns the list of activated and available connections
     *
     * @method availableConnections
     */
    availableConnections: function availableConnections() {
      return Object.keys(this.get('_connections'));
    },

    /**
     * Given an array of URLS, return a sound ready for playing
     *
     * @method load
     * @async
     * @param urlsOrPromise [..{Promise|String}]
     * Provide an array of urls to try, or a promise that will resolve to an array of urls
     * @return {Sound} A sound that's ready to be played, or an error
     */
    load: function load(urlsOrPromise, options) {
      var _this = this;

      var sharedAudioAccess = this._createAndUnlockAudio();

      options = Ember.assign({
        debugName: "load-".concat(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 3)),
        metadata: {}
      }, options);
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        return _this._resolveUrls(urlsOrPromise).then(function (urlsToTry) {
          if (Ember.isEmpty(urlsToTry)) {
            return reject(new Error('[ember-hifi] URLs must be provided'));
          }

          _this.trigger('pre-load', urlsToTry);

          var sound = _this.get('soundCache').find(urlsToTry);

          if (sound) {
            _this.debug('ember-hifi', 'retreived sound from cache');

            return resolve({
              sound: sound
            });
          } else {
            var strategies = [];

            if (options.useConnections) {
              // If the consumer has specified a connection to prefer, use it
              var connectionNames = options.useConnections;
              strategies = _this._prepareStrategies(urlsToTry, connectionNames);
            } else if (_this.get('isMobileDevice')) {
              // If we're on a mobile device, we want to try NativeAudio first
              strategies = _this._prepareMobileStrategies(urlsToTry);
            } else {
              strategies = _this._prepareStandardStrategies(urlsToTry);
            }

            if (_this.get('useSharedAudioAccess')) {
              // If we're on a mobile device or have specified to always use a single audio element,
              // pass in sharedAudioAccess into each connection.
              // Using a single audio element combats autoplay blocking issues on touch devices, and resolves
              // some issues when using a cookied content provider (adswizz)
              strategies = strategies.map(function (s) {
                s.sharedAudioAccess = sharedAudioAccess;
                return s;
              });
            }

            var search = _this._findFirstPlayableSound(strategies, options);

            search.then(function (results) {
              return resolve({
                sound: results.success,
                failures: results.failures
              });
            });
            search.catch(function (e) {
              // reset the UI since trying to play that sound failed
              _this.set('isLoading', false);

              var err = new Error("[ember-hifi] URL Promise failed because: ".concat(e.message));
              err.failures = e.failures;
              reject(err);
            });
            return search;
          }
        });
      });
      this.trigger('new-load-request', {
        loadPromise: promise,
        urlsOrPromise: urlsOrPromise,
        options: options
      });
      promise.then(function (_ref) {
        var sound = _ref.sound;
        return sound.set('metadata', options.metadata);
      });
      promise.then(function (_ref2) {
        var sound = _ref2.sound;
        return _this.get('soundCache').cache(sound);
      }); // On audio-played this pauses all the other sounds. One at a time!

      promise.then(function (_ref3) {
        var sound = _ref3.sound;
        return _this.get('oneAtATime').register(sound);
      });
      promise.then(function (_ref4) {
        var sound = _ref4.sound;
        return sound.on('audio-played', function () {
          var previousSound = _this.get('currentSound');

          var currentSound = sound;

          if (previousSound !== currentSound) {
            if (previousSound && Ember.get(previousSound, 'isPlaying')) {
              _this.trigger('current-sound-interrupted', previousSound);
            }

            _this.trigger('current-sound-changed', currentSound, previousSound);

            _this.setCurrentSound(sound);
          }
        });
      });
      return promise;
    },

    /**
     * Given an array of URLs, return a sound and play it.
     *
     * @method play
     * @async
     * @param urlsOrPromise [..{Promise|String}]
     * Provide an array of urls to try, or a promise that will resolve to an array of urls
     * @return {Sound} A sound that's playing, or an error
     */
    play: function play(urlsOrPromise) {
      var _this2 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (this.get('isPlaying')) {
        this.trigger('current-sound-interrupted', Ember.get(this, 'currentSound'));
        this.pause();
      } // update the UI immediately while `.load` figures out which sound is playable


      this.set('isLoading', true);
      this.set('currentMetadata', options.metadata);
      var load = this.load(urlsOrPromise, options); // We want to keep this chainable elsewhere

      return new Ember.RSVP.Promise(function (resolve, reject) {
        load.then(function (_ref5) {
          var sound = _ref5.sound,
              failures = _ref5.failures;

          _this2.debug("ember-hifi", "Finished load, trying to play sound");

          sound.one('audio-played', function () {
            return resolve({
              sound: sound,
              failures: failures
            });
          });

          _this2._registerEvents(sound);

          _this2._attemptToPlaySound(sound, options);
        });
        load.catch(reject);
      });
    },

    /**
     * Pauses the current sound
     *
     * @method pause
     */
    pause: function pause() {
      (false && !(this.get('currentSound')) && Ember.assert('[ember-hifi] Nothing is playing.', this.get('currentSound')));
      this.get('currentSound').pause();
    },

    /**
     * Stops the current sound
     *
     * @method stop
     */
    stop: function stop() {
      (false && !(this.get('currentSound')) && Ember.assert('[ember-hifi] Nothing is playing.', this.get('currentSound')));
      this.get('currentSound').stop();
    },

    /**
     * Toggles play/pause state of the current sound
     *
     * @method togglePause
     */
    togglePause: function togglePause() {
      (false && !(this.get('currentSound')) && Ember.assert('[ember-hifi] Nothing is playing.', this.get('currentSound')));

      if (this.get('isPlaying')) {
        this.get('currentSound').pause();
      } else {
        this.set('isLoading', true);
        this.get('currentSound').play();
      }
    },

    /**
     * Toggles mute state. Sets volume to zero on mute, resets volume to the last level it was before mute, unless
     * unless the last level was zero, in which case it sets it to the default volume
     *
     * @method toggleMute
     */
    toggleMute: function toggleMute() {
      if (this.get('isMuted')) {
        this.set('volume', this.get('unmuteVolume'));
      } else {
        this.set('volume', 0);
      }
    },

    /**
     * Fast forwards current sound if able
     *
     * @method fastForward
     * @param {Integer} duration in ms
     */
    fastForward: function fastForward(duration) {
      (false && !(this.get('currentSound')) && Ember.assert('[ember-hifi] Nothing is playing.', this.get('currentSound')));
      this.get('currentSound').fastForward(duration);
    },

    /**
     * Rewinds current sound if able
     *
     * @method rewind
     * @param {Integer} duration in ms
     */
    rewind: function rewind(duration) {
      (false && !(this.get('currentSound')) && Ember.assert('[ember-hifi] Nothing is playing.', this.get('currentSound')));
      this.get('currentSound').rewind(duration);
    },

    /**
     * Set the current sound and wire up all the events the sound fires so they
     * trigger through the service, remove the ones on the previous current sound,
     * and set the new current sound to the system volume
     *
     * @method setCurrentSound
     * @param {Sound} sound
     */
    setCurrentSound: function setCurrentSound(sound) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return; // should use ember-concurrency to cancel any pending promises in willDestroy
      }

      this._unregisterEvents(this.get('currentSound'));

      this._registerEvents(sound);

      sound._setVolume(this.get('volume'));

      this.set('currentSound', sound);
      this.debug('ember-hifi', "setting current sound -> ".concat(sound.get('url')));
    },

    /* ------------------------ PRIVATE(ISH) METHODS ---------------------------- */

    /* -------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------- */

    /**
     * Sets the current sound with its current position, so the sound doesn't have
     * to deal with timers. The service runs the show.
     *
     * @method _setCurrentSoundForPosition
     * @private
     */
    _setCurrentPosition: function _setCurrentPosition() {
      var sound = this.get('currentSound');

      if (sound) {
        try {
          Ember.set(sound, '_position', sound._currentPosition());
        } catch (e) {// continue regardless of error
          // TODO: why is this wrapped in a try catch?
        }
      }
    },

    /**
     * Register events on a current sound. Audio events triggered on that sound
     * will be relayed and triggered on this service
     *
     * @method _registerEvents
     * @param {Object} sound
     * @private
     */
    _registerEvents: function _registerEvents(sound) {
      var _this3 = this;

      var service = this;
      EVENT_MAP.forEach(function (item) {
        sound.on(item.event, service, service[item.handler]);
      }); // Internal event for cleanup

      sound.on('_will_destroy', function () {
        _this3._unregisterEvents(sound);
      });
    },

    /**
     * Register events on a current sound. Audio events triggered on that sound
     * will be relayed and triggered on this service
     *
     * @method _unregisterEvents
     * @param {Object} sound
     * @private
     */
    _unregisterEvents: function _unregisterEvents(sound) {
      if (!sound) {
        return;
      }

      var service = this;
      EVENT_MAP.forEach(function (item) {
        if (sound.has(item.event)) {
          sound.off(item.event, service, service[item.handler]);
        }
      });
    },

    /**
     * Relays an audio event on the sound to an event on the service
     *
     * @method relayEvent
     * @param {String, Object} eventName, sound
     * @private
     */
    _relayEvent: function _relayEvent(eventName, sound) {
      var info = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.trigger(eventName, sound, info);
    },

    /**
      Named functions so Ember Evented can successfully register/unregister them
    */
    _relayPlayedEvent: function _relayPlayedEvent(sound) {
      this._relayEvent('audio-played', sound);
    },
    _relayPausedEvent: function _relayPausedEvent(sound) {
      this._relayEvent('audio-paused', sound);
    },
    _relayEndedEvent: function _relayEndedEvent(sound) {
      this._relayEvent('audio-ended', sound);
    },
    _relayDurationChangedEvent: function _relayDurationChangedEvent(sound) {
      this._relayEvent('audio-duration-changed', sound);
    },
    _relayPositionChangedEvent: function _relayPositionChangedEvent(sound) {
      this._relayEvent('audio-position-changed', sound);
    },
    _relayLoadedEvent: function _relayLoadedEvent(sound) {
      this._relayEvent('audio-loaded', sound);
    },
    _relayLoadingEvent: function _relayLoadingEvent(sound) {
      this._relayEvent('audio-loading', sound);
    },
    _relayPositionWillChangeEvent: function _relayPositionWillChangeEvent(sound) {
      var info = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._relayEvent('audio-position-will-change', sound, info);
    },
    _relayWillRewindEvent: function _relayWillRewindEvent(sound, info) {
      this._relayEvent('audio-will-rewind', sound, info);
    },
    _relayWillFastForwardEvent: function _relayWillFastForwardEvent(sound, info) {
      this._relayEvent('audio-will-fast-forward', sound, info);
    },
    _relayMetadataChangedEvent: function _relayMetadataChangedEvent(sound, info) {
      this._relayEvent('audio-metadata-changed', sound, info);
    },

    /**
     * Activates the connections as specified in the config options
     *
     * @method _activateConnections
     * @private
     * @param {Array} connectionOptions
     * @return {Object} instantiated connections
     */
    _activateConnections: function _activateConnections() {
      var _this4 = this;

      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var cachedConnections = Ember.get(this, '_connections');
      var activatedConnections = {};
      options.forEach(function (connectionOption) {
        var name = connectionOption.name;
        var connection = cachedConnections[name] ? cachedConnections[name] : _this4._activateConnection(connectionOption);
        Ember.set(activatedConnections, name, connection);
      });
      return Ember.set(this, '_connections', activatedConnections);
    },

    /**
     * Activates the a single connection
     *
     * @method _activateConnection
     * @private
     * @param {Object} {name, config}
     * @return {Connection} instantiated Connection
     */
    _activateConnection: function _activateConnection() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          name = _ref6.name,
          config = _ref6.config;

      var Connection = this._lookupConnection(name);

      (false && !(name) && Ember.assert('[ember-hifi] Could not find hifi connection ${name}.', name));
      Connection.setup(config);
      return Connection;
    },

    /**
     * Looks up the connection from the container. Prioritizes the consuming app's
     * connections over the addon's connections.
     *
     * @method _lookupConnection
     * @param {string} connectionName
     * @private
     * @return {Connection} a local connection or a connection from the addon
     */
    _lookupConnection: function _lookupConnection(connectionName) {
      (false && !(connectionName) && Ember.assert('[ember-hifi] Could not find a hifi connection without a name.', connectionName));
      var dasherizedConnectionName = Ember.String.dasherize(connectionName);
      var availableConnection = Ember.getOwner(this).lookup("ember-hifi@hifi-connection:".concat(dasherizedConnectionName));
      var localConnection = Ember.getOwner(this).lookup("hifi-connection:".concat(dasherizedConnectionName));
      (false && !(localConnection || availableConnection) && Ember.assert("[ember-hifi] Could not load hifi connection ".concat(dasherizedConnectionName), localConnection || availableConnection));
      return localConnection ? localConnection : availableConnection;
    },

    /**
     * URLs given to load or play may be a promise, resolve this promise and get the urls
     * or promisify an array/string and
     * @method _resolveUrls
     * @param {Array or String or Promise} urlOrPromise
     * @private
     * @return {Promise.<urls>} a promise resolving to a cleaned up array of URLS
     */
    _resolveUrls: function _resolveUrls(urlsOrPromise) {
      var _this5 = this;

      var prepare = function prepare(urls) {
        return Ember.A(Ember.makeArray(urls)).uniq().reject(function (i) {
          return Ember.isEmpty(i);
        });
      };

      if (urlsOrPromise && urlsOrPromise.then) {
        this.debug('ember-hifi', "#load passed URL promise");
      }

      return Ember.RSVP.Promise.resolve(urlsOrPromise).then(function (urls) {
        urls = prepare(urls);

        _this5.debug('ember-hifi', "given urls: ".concat(urls.join(', ')));

        return urls;
      });
    },

    /**
     * Given an array of strategies with {connection, url} try the connection and url
     * return the first thing that works
     *
     * @method _findFirstPlayableSound
     * @param {Array} urlsToTry
     * @private
     * @return {Promise.<Sound|error>} A sound that's ready to be played, or an error with a failures property
     */
    _findFirstPlayableSound: function _findFirstPlayableSound(strategies, options) {
      var _this6 = this;

      this.timeStart(options.debugName, "_findFirstPlayableSound");

      var promise = _promiseRace.default.start(strategies, function (strategy, returnSuccess, markAsFailure) {
        var Connection = strategy.connection;
        var connectionOptions = Ember.getProperties(strategy, 'url', 'connectionName', 'sharedAudioAccess', 'options');
        var sound = Connection.create(connectionOptions);

        _this6.debug('ember-hifi', "TRYING: [".concat(strategy.connectionName, "] -> ").concat(strategy.url));

        sound.one('audio-load-error', function (error) {
          strategy.error = error;
          markAsFailure(strategy);

          _this6.debug('ember-hifi', "FAILED: [".concat(strategy.connectionName, "] -> ").concat(error, " (").concat(strategy.url, ")"));
        });
        sound.one('audio-ready', function () {
          returnSuccess(sound);

          _this6.debug('ember-hifi', "SUCCESS: [".concat(strategy.connectionName, "] -> (").concat(strategy.url, ")"));
        });
      });

      promise.catch(function (_ref7) {
        var failures = _ref7.failures;

        _this6.debug('ember-hifi', "All promises failed:");

        failures.forEach(function (f) {
          _this6.debug('ember-hifi', "".concat(f.connectionName, ": ").concat(f.error));
        });
      });
      promise.finally(function () {
        return _this6.timeEnd(options.debugName, "_findFirstPlayableSound");
      });
      return promise;
    },

    /**
     * Given some urls, it prepares an array of connection and url pairs to try
     *
     * @method _prepareParamsForLoadWorkingAudio
     * @param {Array} urlsToTry
     * @private
     * @return {Array} {connection, url}
     */

    /**
     * Take our standard strategy and reorder it to prioritize native audio
     * first since it's most likely to succeed and play immediately with our
     * audio unlock logic
      * we try each url on each compatible connection in order
     * [{connection: NativeAudio, url: url1},
     *  {connection: NativeAudio, url: url2},
     *  {connection: HLS, url: url1},
     *  {connection: Other, url: url1},
     *  {connection: HLS, url: url2},
     *  {connection: Other, url: url2}]
      * @method _prepareMobileStrategies
     * @param {Array} urlsToTry
     * @private
     * @return {Array} {connection, url}
     */
    _prepareMobileStrategies: function _prepareMobileStrategies(urlsToTry) {
      var strategies = this._prepareStandardStrategies(urlsToTry);

      this.debug("modifying standard strategy for to work best on mobile");
      var nativeStrategies = Ember.A(strategies).filter(function (s) {
        return s.connectionKey === 'NativeAudio';
      });
      var otherStrategies = Ember.A(strategies).reject(function (s) {
        return s.connectionKey === 'NativeAudio';
      });
      var orderedStrategies = nativeStrategies.concat(otherStrategies);
      return orderedStrategies;
    },

    /**
     * Given a list of urls, prepare the strategy that we think will succeed best
     *
     * Breadth first: we try each url on each compatible connection in order
     * [{connection: NativeAudio, url: url1},
     *  {connection: HLS, url: url1},
     *  {connection: Other, url: url1},
     *  {connection: NativeAudio, url: url2},
     *  {connection: HLS, url: url2},
     *  {connection: Other, url: url2}]
      * @method _prepareStandardStrategies
     * @param {Array} urlsToTry
     * @private
     * @return {Array} {connection, url}
     */
    _prepareStandardStrategies: function _prepareStandardStrategies(urlsToTry, options) {
      return this._prepareStrategies(urlsToTry, this.availableConnections(), options);
    },

    /**
     * Given a list of urls and a list of connections, assemble array of
     * strategy objects to be tried in order. Each strategy object
     * should contain a connection, a connectionName, a url, and in some cases
     * a sharedAudioAccess
      * @method _prepareStrategies
     * @param {Array} urlsToTry
     * @private
     * @return {Array} {connection, url}
     */
    _prepareStrategies: function _prepareStrategies(urlsToTry, connectionNames) {
      var _this7 = this;

      connectionNames = Ember.makeArray(connectionNames);
      var strategies = [];
      var connectionOptions = this.get('options.emberHifi.connections') || [];
      connectionOptions = Ember.A(connectionOptions);
      urlsToTry.forEach(function (url) {
        var connectionSuccesses = [];
        connectionNames.forEach(function (name) {
          var connection = _this7.get("_connections.".concat(name));

          var config = connectionOptions.findBy('name', name);

          if (connection.canPlay(url)) {
            connectionSuccesses.push(name);
            strategies.push({
              connectionName: connection.toString(),
              connectionKey: name,
              connection: connection,
              url: url.url || url,
              options: config ? config.options : null
            });
          }
        });

        _this7.debug("Compatible connections for ".concat(url, ": ").concat(connectionSuccesses.join(", ")));
      });
      return strategies;
    },

    /**
     * Creates an empty audio element and plays it to unlock audio on a mobile (iOS)
     * device at the beggining of a play event.
     *
     * @method _createAndUnlockAudio
     * @private
     * @return {element} an audio element
     */
    _createAndUnlockAudio: function _createAndUnlockAudio() {
      // Audio will play automatically if is Mobile device to get around
      // autoplaying restrictions. If not, it won't autoplay because
      // IE desktop browsers can't deal with that and will suddenly
      // play the loading audio before it's ready
      return _sharedAudioAccess.default.unlock(this.get('isMobileDevice'));
    },

    /**
     * Attempts to play the sound after a load, which in certain cases can fail on mobile
     * @method _attemptToPlaySoundOnMobile
     * @param {Sound} sound
     * @private
     */
    _attemptToPlaySound: function _attemptToPlaySound(sound, options) {
      var _this8 = this;

      if (this.get('isMobileDevice')) {
        var touchPlay = function touchPlay() {
          _this8.debug("triggering sound play from document touch");

          sound.play();
        };

        document.addEventListener('touchstart', touchPlay, {
          passive: true
        });
        var blockCheck = Ember.run.later(function () {
          _this8.debug("Looks like the mobile browser blocked an autoplay trying to play sound with url: ".concat(sound.get('url')));
        }, 2000);
        sound.one('audio-played', function () {
          document.removeEventListener('touchstart', touchPlay);
          Ember.run.cancel(blockCheck);
        });
      }

      sound.play(options);
    }
  });

  _exports.default = _default;
});