define("ember-hifi/helpers/one-at-a-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.Evented, {
    init: function init() {
      this.set('sounds', Ember.A());
    },
    register: function register(sound) {
      var _this = this;

      var sounds = this.get("sounds");
      sound.on('audio-played', function () {
        return _this.pauseAll(sound);
      });

      if (!sounds.includes(sound)) {
        sounds.pushObject(sound);
      }
    },
    pauseAll: function pauseAll(sound) {
      this.get('sounds').without(sound).forEach(this._pauseSound);
    },
    _pauseSound: function _pauseSound(s) {
      s.pause();
    }
  });

  _exports.default = _default;
});