define("ember-svg-jar/inlined/comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2737\" d=\"M16.667 0H3.333A3.333 3.333 0 000 3.333v10a3.333 3.333 0 003.333 3.333H5.75L9.459 19.8a.833.833 0 001.077 0l3.714-3.136h2.417A3.333 3.333 0 0020 13.334v-10A3.333 3.333 0 0016.667 0zm1.667 13.334A1.667 1.667 0 0116.667 15H14.25a1.667 1.667 0 00-1.076.394L10 18.075l-3.173-2.681A1.667 1.667 0 005.75 15H3.333a1.667 1.667 0 01-1.667-1.667v-10a1.667 1.667 0 011.667-1.666h13.334a1.667 1.667 0 011.667 1.667z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});