define("ember-paper/components/paper-select/eps-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nKUajJuD",
    "block": "[[[10,\"md-select-value\"],[15,0,[29,[\"md-select-value \",[52,[30,0,[\"isPlaceholder\"]],\"md-select-placeholder\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"selected\"]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[18,5,[[30,1,[\"selected\"]],[30,1]]],[13],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,2],[28,[37,3],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"      \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[24,\"role\",\"button\"],[4,[38,4],[\"mousedown\",[30,0,[\"clear\"]]],null],[4,[38,4],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-placeholder\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],[[[41,[30,4,[\"label\"]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-placeholder\"],[12],[1,[30,4,[\"label\"]]],[13],[1,\"\\n  \"]],[]],null]],[]]]],[]]],[1,\"  \"],[10,1],[14,0,\"md-select-icon\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"],[13]],[\"@select\",\"@allowClear\",\"@placeholder\",\"@extra\",\"&default\"],false,[\"if\",\"yield\",\"and\",\"not\",\"on\"]]",
    "moduleName": "ember-paper/components/paper-select/eps-trigger/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});