define("ember-cli-swiper/templates/components/swiper-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2KbI/4e",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-cli-swiper/templates/components/swiper-slide.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});