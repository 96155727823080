define("ember-svg-jar/inlined/arrow-select-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.707.286a1.017 1.017 0 00-1.414 0L7.707 4.762a1.039 1.039 0 01-1.414 0L1.707.286a1.017 1.017 0 00-1.414 0 .96.96 0 000 1.38l4.585 4.476a3.052 3.052 0 004.243 0l4.586-4.476a.96.96 0 000-1.38z\" fill=\"#949494\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "7",
      "viewBox": "0 0 14 7"
    }
  };
  _exports.default = _default;
});