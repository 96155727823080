define("ember-tribute/components/ui-tribute", ["exports", "ember-tribute/templates/components/ui-tribute", "tributejs"], function (_exports, _uiTribute, _tributejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTribute.default,
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(Ember.isPresent(Ember.get(this, 'options'))) && Ember.assert('Pass Tribure collections object as options attribute to the component', Ember.isPresent(Ember.get(this, 'options'))));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run(function () {
        var tribute = new _tributejs.default(Ember.get(_this, 'options'));

        var targetDom = _this.getTargetDom();

        tribute.attach(targetDom);
        Ember.set(_this, 'tribute', tribute);

        _this.registerTributeInstance(tribute);

        targetDom.addEventListener('tribute-replaced', function (e) {
          if (_this.tributeReplaced) {
            _this.tributeReplaced(e);
          }
        });
        targetDom.addEventListener('tribute-no-match', function (e) {
          if (_this.tributeNoMatch) {
            _this.tributeNoMatch(e);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var targetDom = this.getTargetDom();
      Ember.run(function () {
        var tribute = Ember.get(_this2, 'tribute');
        tribute.hideMenu();
        tribute.detach(targetDom);
      });
    },
    getTargetDom: function getTargetDom() {
      if (this.target && typeof this.target === 'string') {
        return this.element.querySelector("".concat(this.target));
      } else {
        return this.element.querySelector(':first-child');
      }
    },
    registerTributeInstance: function registerTributeInstance(tribute) {
      var action = Ember.get(this, 'getTributeInstance');

      if (action) {
        action(tribute);
      }
    }
  });

  _exports.default = _default;
});