define("ember-content-loader/templates/components/content-loader-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Rh+syHb",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"title\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[15,\"width\",[30,2]],[15,\"height\",[30,3]],[15,\"clip-path\",[30,0,[\"rectClipPath\"]]],[15,5,[30,0,[\"rectStyle\"]]],[12],[13],[1,\"\\n\\n\"],[10,\"defs\"],[12],[1,\"\\n  \"],[10,\"clipPath\"],[15,1,[30,0,[\"idClip\"]]],[12],[18,9,null],[13],[1,\"\\n  \"],[10,\"linearGradient\"],[15,1,[30,0,[\"idGradient\"]]],[12],[1,\"\\n    \"],[10,\"stop\"],[14,\"offset\",\"0%\"],[15,\"stop-color\",[30,4]],[15,\"stop-opacity\",[30,5]],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,\"animate\"],[14,\"attributeName\",\"offset\"],[15,\"values\",[30,0,[\"animateValues1\"]]],[15,\"keyTimes\",[30,0,[\"keyTimes\"]]],[15,\"dur\",[30,0,[\"dur\"]]],[14,\"repeatCount\",\"indefinite\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"stop\"],[14,\"offset\",\"50%\"],[15,\"stop-color\",[30,7]],[15,\"stop-opacity\",[30,8]],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,\"animate\"],[14,\"attributeName\",\"offset\"],[15,\"values\",[30,0,[\"animateValues2\"]]],[15,\"keyTimes\",[30,0,[\"keyTimes\"]]],[15,\"dur\",[30,0,[\"dur\"]]],[14,\"repeatCount\",\"indefinite\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"stop\"],[14,\"offset\",\"100%\"],[15,\"stop-color\",[30,4]],[15,\"stop-opacity\",[30,5]],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,\"animate\"],[14,\"attributeName\",\"offset\"],[15,\"values\",[30,0,[\"animateValues3\"]]],[15,\"keyTimes\",[30,0,[\"keyTimes\"]]],[15,\"dur\",[30,0,[\"dur\"]]],[14,\"repeatCount\",\"indefinite\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@ariaLabel\",\"@width\",\"@height\",\"@primaryColor\",\"@primaryOpacity\",\"@animate\",\"@secondaryColor\",\"@secondaryOpacity\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-content-loader/templates/components/content-loader-svg.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});