define("ember-cli-notifications/templates/components/notification-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B7vhcCFA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2,[\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"notification\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"notification\"],false,[\"each\",\"-track-array\",\"notifications\",\"notification-message\"]]",
    "moduleName": "ember-cli-notifications/templates/components/notification-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});