define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 4.501a.5.5 0 00-.5-.5H1.707l1.268-1.267a.5.5 0 00-.707-.708L.147 4.148a.5.5 0 000 .707l2.12 2.121a.5.5 0 00.708-.707L1.707 5.001H8.5a.5.5 0 00.5-.5z\" fill=\"#9EA2A3\"/>",
    "attrs": {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});