define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OcU03IaN",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[30,2],null,[[\"extra\",\"option\",\"select\"],[[28,[37,2],[[30,3]],null],[28,[37,2],[[30,1,[\"selected\"]]],null],[28,[37,2],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[18,7,[[30,1,[\"selected\"]],[33,4]]],[13],[1,\"\\n\"]],[]]],[41,[28,[37,5],[[30,4],[28,[37,6],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"    \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[4,[38,7],[\"mousedown\",[30,0,[\"clear\"]]],null],[4,[38,7],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[46,[30,5],null,[[\"placeholder\"],[[30,6]]],null],[1,\"\\n\"]],[]]],[10,1],[14,0,\"ember-power-select-status-icon\"],[12],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@allowClear\",\"@placeholderComponent\",\"@placeholder\",\"&default\"],false,[\"if\",\"component\",\"readonly\",\"yield\",\"select\",\"and\",\"not\",\"on\"]]",
    "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});