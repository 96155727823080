define("ember-svg-jar/inlined/pause-rounded-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9b9999\" data-name=\"Groupe 4398\"><path data-name=\"Trac&#xE9; 2984\" d=\"M11.771 1.561A10.209 10.209 0 1021.98 11.77 10.209 10.209 0 0011.771 1.561m0-1.561A11.771 11.771 0 110 11.771 11.771 11.771 0 0111.771 0z\"/><path data-name=\"Trac&#xE9; 2760\" d=\"M9.327 6.771a1.509 1.509 0 00-1.556 1.458v7.084a1.509 1.509 0 001.556 1.458 1.509 1.509 0 001.555-1.458V8.229a1.509 1.509 0 00-1.555-1.458z\"/><path data-name=\"Trac&#xE9; 2761\" d=\"M14.216 6.771a1.509 1.509 0 00-1.556 1.458v7.084a1.559 1.559 0 003.111 0V8.229a1.509 1.509 0 00-1.555-1.458z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23.542",
      "height": "23.542",
      "viewBox": "0 0 23.542 23.542"
    }
  };
  _exports.default = _default;
});