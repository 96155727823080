define("ember-svg-jar/inlined/check-empty-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5512\" transform=\"translate(-421.832 -226.832)\"><circle data-name=\"Ellipse 64\" cx=\"17.5\" cy=\"17.5\" r=\"17.5\" transform=\"translate(421.832 226.832)\" fill=\"#c7c7c7\"/><g data-name=\"Groupe 5141\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-width=\"2\"><path data-name=\"Ligne 19\" d=\"M432.081 243.858l6.447 5.641\"/><path data-name=\"Ligne 20\" d=\"M445.781 239.023l-7.253 10.476\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35"
    }
  };
  _exports.default = _default;
});