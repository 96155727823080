define("ember-svg-jar/inlined/check-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.354 7.768L7.12 9.536l3.89-3.89a.5.5 0 01.707.708l-4.243 4.242a.498.498 0 01-.707 0L4.646 8.475a.5.5 0 01.708-.707z\" fill=\"#9EA2A3\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 8A8 8 0 110 8a8 8 0 0116 0zm-1 0A7 7 0 111 8a7 7 0 0114 0z\" fill=\"#9EA2A3\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});