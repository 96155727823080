define("ember-svg-jar/inlined/file-drop-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"Trac&#xE9; 2740\" d=\"M15.945 3.161h-2.6A4.15 4.15 0 009.231 0H7.553a4.15 4.15 0 00-4.112 3.161h-2.6a.816.816 0 00-.841.79.816.816 0 00.839.79h.839v10.273a4.084 4.084 0 004.2 3.951h5.032a4.084 4.084 0 004.2-3.951V4.741h.839a.816.816 0 00.839-.79.816.816 0 00-.843-.79zM7.553 1.58h1.678a2.518 2.518 0 012.374 1.58H5.179a2.518 2.518 0 012.374-1.58zm5.874 13.434a2.447 2.447 0 01-2.518 2.371H5.874a2.447 2.447 0 01-2.518-2.371V4.741h10.07z\" fill=\"#fff\"/><path data-name=\"Trac&#xE9; 2741\" d=\"M6.713 14.224a.816.816 0 00.839-.79V8.692a.841.841 0 00-1.678 0v4.741a.816.816 0 00.839.791z\" fill=\"#fff\"/><path data-name=\"Trac&#xE9; 2742\" d=\"M10.07 14.224a.816.816 0 00.839-.79V8.692a.841.841 0 00-1.678 0v4.741a.816.816 0 00.839.791z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.784",
      "height": "18.965",
      "viewBox": "0 0 16.784 18.965"
    }
  };
  _exports.default = _default;
});