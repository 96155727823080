define("ember-changeset/utils/take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = take;

  // @flow
  function take() {
    var originalObj
    /*: Object */
    = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var keysToTake
    /*: Array<string> */
    = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var newObj = {};

    for (var key in originalObj) {
      if (keysToTake.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    }

    return newObj;
  }
});