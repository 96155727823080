define("ember-svg-jar/inlined/youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"red\" d=\"M29.382 3.227c.643 2.305.618 7.11.618 7.11s0 4.78-.618 7.085a3.725 3.725 0 01-2.644 2.596c-2.348.607-11.738.607-11.738.607s-9.366 0-11.738-.63a3.725 3.725 0 01-2.644-2.597C0 15.117 0 10.313 0 10.313s0-4.78.618-7.086C.964 1.965 2.002.947 3.262.607 5.61 0 15 0 15 0s9.39 0 11.738.63a3.725 3.725 0 012.644 2.597zM12.187 14.062l7.5-4.218-7.5-4.219v8.438z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "21",
      "viewBox": "0 0 30 21"
    }
  };
  _exports.default = _default;
});