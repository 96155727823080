define("ember-svg-jar/inlined/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group</title><defs><circle id=\"a\" cx=\"12\" cy=\"12\" r=\"12\"/><mask id=\"b\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"24\" height=\"24\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use stroke=\"#FF7701\" mask=\"url(#b)\" stroke-width=\"2\" xlink:href=\"#a\"/><path d=\"M11.5 14V8h1v6h-1zm0 2v-1h1v1h-1z\" fill=\"#FF7701\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});