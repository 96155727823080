define("ember-svg-jar/inlined/welcome-round", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>round</title><path d=\"M6 11a5 5 0 005-5 5 5 0 00-5-5 5 5 0 00-5 5 5 5 0 005 5z\" stroke-width=\"2\" stroke=\"#8CB1E8\" fill=\"#FFF\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});