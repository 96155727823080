define("ember-svg-jar/inlined/previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 4.018L7.28.249C8.23-.32 9 .12 9 1.226V9.21c0 1.11-.768 1.548-1.72.977L1 6.418v3.29a.51.51 0 01-.5.51.505.505 0 01-.5-.51V.728a.51.51 0 01.5-.51.5.5 0 01.5.51z\" fill=\"#9b9999\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "10.438",
      "viewBox": "0 0 9 10.438"
    }
  };
  _exports.default = _default;
});