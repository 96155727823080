define("ember-svg-jar/inlined/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.a{fill:#9d9fa3;fill-rule:evenodd}</style></defs><path class=\"a\" d=\"M0 1a1 1 0 011-1h3a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1zm1 0h3v16H1zM13 6a1 1 0 011-1h3a1 1 0 011 1v11a1 1 0 01-1 1h-3a1 1 0 01-1-1zm1 0h3v11h-3zM6.5 10a1 1 0 011-1h3a1 1 0 011 1v7a1 1 0 01-1 1h-3a1 1 0 01-1-1zm1 0h3v7h-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});