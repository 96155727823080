define("ember-hifi/services/hifi-cache", ["exports", "ember-hifi/mixins/debug-logging"], function (_exports, _debugLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * This class caches sound objects based on urls. You shouldn't have to interact with this class.
  *
  * @class hifi-cache
  * @constructor
  */
  var _default = Ember.Service.extend(_debugLogging.default, {
    debugName: 'hifi-cache',
    cachedCount: 0,
    init: function init() {
      this.set('_cache', {});

      this._super.apply(this, arguments);
    },
    reset: function reset() {
      this.set('_cache', {});
    },

    /**
     * find - finds sounds in the cache by urls
     *
     * @param {string} urls
     * @return {Sound}
     */
    find: function find(urls) {
      urls = Ember.makeArray(urls);
      var cache = this.get('_cache');
      var keysToSearch = Ember.A(urls).map(function (url) {
        return url.url || url;
      });
      var sounds = Ember.A(keysToSearch).map(function (url) {
        return cache[url];
      });
      var foundSounds = Ember.A(sounds).compact();

      if (foundSounds.length > 0) {
        this.debug("cache hit for ".concat(foundSounds[0].get('url')));
      } else {
        this.debug("cache miss for ".concat(keysToSearch.join(',')));
      }

      return foundSounds[0];
    },

    /**
     * remove - removes a sound from the cache
     *
     * @param {Sound} sound
     */
    remove: function remove(sound) {
      if (this.isDestroyed) return;
      this.debug("removing sound from cache with url: ".concat(sound.get('url')));

      if (this._cache[sound.get('url')]) {
        delete this._cache[sound.get('url')];
        this.set('cachedCount', Object.keys(this._cache).length);
        this.notifyPropertyChange('_cache');
      }
    },

    /**
     * cache - caches the sound by the url
     *
     * @param  {Sound} sound
     */
    cache: function cache(sound) {
      if (this.isDestroyed) return;
      this.debug("caching sound with url: ".concat(sound.get('url')));

      if (!this._cache[sound.get('url')]) {
        this._cache[sound.get('url')] = sound;
        this.set('cachedCount', Object.keys(this._cache).length);
        this.notifyPropertyChange('_cache');
      }
    }
  });

  _exports.default = _default;
});