define("ember-svg-jar/inlined/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#575757\"><path data-name=\"Trac&#xE9; 3028\" d=\"M8.063.038a5.977 5.977 0 00-1.344 0A7.378 7.378 0 001.8 12.217a6.021 6.021 0 001.1 1.03 7.34 7.34 0 009 0 6.831 6.831 0 001.088-1.03A7.378 7.378 0 008.063.038zM1.344 7.395a6.047 6.047 0 116.047 6.047 6.047 6.047 0 01-6.047-6.047z\"/><path data-name=\"Trac&#xE9; 3031\" d=\"M8.063 6.445V3.364a.672.672 0 10-1.344 0v3.36a.672.672 0 00.2.475l2.016 2.016a.672.672 0 10.95-.95z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14.782",
      "height": "14.787",
      "viewBox": "0 0 14.782 14.787"
    }
  };
  _exports.default = _default;
});