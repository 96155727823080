define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yiqd1STn",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1,[\"option\"]],[30,1,[\"isSelected\"]],[30,2]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[30,1,[\"isSelected\"]],[33,7]]]]],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[1,2]],null]],[\"checkbox\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"checkboxes\",\"if\",\"has-block\",\"yield\",\"input\",\"disabled\"]]",
    "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});