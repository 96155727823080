define("ember-svg-jar/inlined/note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15 14a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zm-4 3H6a1 1 0 000 2h5a1 1 0 000-2zm9-6.515V19a5.006 5.006 0 01-5 5H5a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515a6.958 6.958 0 014.95 2.05l3.484 3.486A6.951 6.951 0 0120 10.485zm-6.949-7.021A5.01 5.01 0 0012 2.684V7a1 1 0 001 1h4.316a4.983 4.983 0 00-.781-1.05zM18 10.485c0-.165-.032-.323-.047-.485H13a3 3 0 01-3-3V2.047C9.838 2.032 9.679 2 9.515 2H5a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "20",
      "viewBox": "0 0 20 24"
    }
  };
  _exports.default = _default;
});