define("ember-validated-form/components/validated-input/types/-themes/uikit/checkbox-group", ["exports", "ember-validated-form/templates/components/validated-input/types/-themes/uikit/checkbox-group", "ember-validated-form/components/validated-input/types/checkbox-group"], function (_exports, _checkboxGroup, _checkboxGroup2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkboxGroup2.default.extend({
    layout: _checkboxGroup.default
  });

  _exports.default = _default;
});