define("ember-svg-jar/inlined/read-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5180\" fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 2715\" d=\"M15.434 5.484a.872.872 0 00-1.233 0l-8.1 8.1-3.742-3.741a.872.872 0 10-1.233 1.233l4.359 4.353a.872.872 0 001.233 0l8.716-8.716a.872.872 0 000-1.229z\"/><path data-name=\"Trac&#xE9; 2716\" d=\"M14.563.255a.872.872 0 00-1.233 0l-8.1 8.1-3.742-3.741A.872.872 0 10.255 5.847L4.614 10.2a.872.872 0 001.233 0l8.716-8.716a.872.872 0 000-1.229z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15.689",
      "height": "15.688",
      "viewBox": "0 0 15.689 15.688"
    }
  };
  _exports.default = _default;
});