define("ember-svg-jar/inlined/arrow-right-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.293 4.844L1.704.258A1 1 0 00.293 1.672l4.586 4.586a1 1 0 010 1.414L.293 12.258a1 1 0 101.415 1.414l4.585-4.586a3 3 0 000-4.242z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "7.171",
      "height": "13.965",
      "viewBox": "0 0 7.171 13.965"
    }
  };
  _exports.default = _default;
});