define("ember-svg-jar/inlined/pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>xml-inpro</title><path d=\"M0 63.002v-62a1 1 0 011-1h32.768a.993.993 0 01.126 0h.194a1 1 0 01.707.292l11.912 11.893a1 1 0 01.293.708v50.107a1 1 0 01-1 1H1a1 1 0 01-1-1zm45-1V14.159H33.823a1 1 0 01-1-1V2.002H2v60h42.998zM34.823 3.414l8.742 8.743h-8.742V3.414zM23 46c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 100-18 9 9 0 000 18zm0-6.955a2.045 2.045 0 100-4.09 2.045 2.045 0 000 4.09z\" fill=\"#8CB1E8\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "47",
      "height": "64",
      "viewBox": "0 0 47 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});