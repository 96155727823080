define("ember-image-drop/components/image-drop", ["exports", "ember-image-drop/templates/components/image-drop"], function (_exports, _imageDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _imageDrop.default,
    classNames: ['ember-image-drop'],
    attributeBindings: ['style'],
    placeholder: "",
    helpText: "Drop your image here, or click to select",
    hideTextOnImage: true,
    accept: '.jpg, .jpeg, .png, .gif, image/png, image/jpg, image/jpeg, image/gif',
    image: null,
    file: null,
    maxFilesize: 1024 * 1024 * 3,
    textStyle: Ember.computed('image', function () {
      var textStyle = "";

      if (this.get('hideTextOnImage') && this.get('image')) {
        textStyle = "display: none;";
      }

      return Ember.String.htmlSafe(textStyle);
    }),
    style: Ember.computed('image', function () {
      var backgroundStyle = "";

      if (this.get('image')) {
        backgroundStyle = "background-image: url(".concat(this.get('image'), ");");
      }

      return Ember.String.htmlSafe(backgroundStyle);
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $input = this.$('input');
      $input.on('change', function (event) {
        _this.handleFileDrop(event.target.files[0]);

        _this.resetInputElement($input);
      });
    },
    resetInputElement: function resetInputElement(inputElement) {
      inputElement.wrap('<form>').closest('form').get(0).reset();
      inputElement.unwrap();
    },
    handleFileDrop: function handleFileDrop(file) {
      var _this2 = this;

      if (file == null || !this.accept.split(', ').includes(file.type)) {
        return;
      }

      this.set('file', file);
      var reader = new FileReader();

      reader.onload = function (e) {
        var fileToUpload = e.target.result;
        Ember.run(function () {
          _this2.set('image', fileToUpload);
        });
      };

      reader.readAsDataURL(file);
    },
    drop: function drop(event) {
      var _this3 = this;

      event.preventDefault();

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        return this.handleFileDrop(event.dataTransfer.files[0]);
      }

      var imageUrl = event.dataTransfer.getData('URL');

      if (!imageUrl) {
        return;
      }

      this.convertImgToBase64URL(imageUrl, function (base64) {
        _this3.set('image', base64);
      });
    },
    convertImgToBase64URL: function convertImgToBase64URL(url, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = function () {
        var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
      };

      img.src = url;
    }
  });

  _exports.default = _default;
});