define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LVETKtao",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,1],[24,0,\"ember-power-select-placeholder\"],[17,2],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null]],[\"@placeholder\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});