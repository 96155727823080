define("ember-svg-jar/inlined/distribution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.831 1.192l-.076-.439a.6.6 0 00-.467-.493l-.421-.082a9.973 9.973 0 00-5.354.449 10.8 10.8 0 00-4.981 3.882l-.68-.247a3.072 3.072 0 00-3.19.7L.513 7.02a1.667 1.667 0 001 2.868l1.9.063a2.065 2.065 0 00.531 1.741l2.984 3.15a1.827 1.827 0 001.645.558l.059 2.006a1.551 1.551 0 002.717 1.058l1.95-2.272a3.517 3.517 0 00.659-3.367l-.234-.718a11.252 11.252 0 003.678-5.257 11.664 11.664 0 00.429-5.658zM1.294 7.927l2.149-2.062a1.957 1.957 0 012.031-.443l.314.114a26.548 26.548 0 00-1.889 3.205l-2.35-.077a.427.427 0 01-.257-.736zm11.57 5.295a2.24 2.24 0 01-.419 2.144l-1.954 2.269a.4.4 0 01-.7-.271l-.073-2.481a24.666 24.666 0 003.038-1.991zm.44-2.822a20.489 20.489 0 01-4.492 3.422c-.278.144-.2.283-.571 0 0 0-.139-.244-3.15-3.422-.22-.232-.475-.441-.338-.735.149-.32.177-.361.338-.674l3.15 3.325a.966.966 0 001.122 0 .829.829 0 000-1.006L6.081 7.927a21.2 21.2 0 012.16-2.969 8.429 8.429 0 018.3-3.345 9.385 9.385 0 01-3.242 8.787zm-3.038-5.972a2.666 2.666 0 001.77 4.509 2.665 2.665 0 001.77-4.509 2.413 2.413 0 00-3.54 0zm2.6 2.754a1.056 1.056 0 01-1.677 0 1.182 1.182 0 010-1.759 1.056 1.056 0 011.677 0 1.182 1.182 0 01-.003 1.759zM.892 14.335a.754.754 0 01-.068-.951l.728-.71a.671.671 0 01.914 0 .637.637 0 010 .867l-.752.794a.559.559 0 01-.821 0zm5.189 2.094a.726.726 0 010 .934l-.758.774a1.036 1.036 0 01-.569.23c-.513 0-.672-.757-.3-1.144l.752-.794a.629.629 0 01.88 0zm-1.327-1.58l-2.555 2.696a.76.76 0 01-.975.09.873.873 0 01.069-1.032l2.606-2.781a.729.729 0 01.958 0 .873.873 0 01-.106 1.028z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "19",
      "viewBox": "0 0 18 19"
    }
  };
  _exports.default = _default;
});