define("ember-svg-jar/inlined/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.544 8.112A9.311 9.311 0 000 9.325a9.216 9.216 0 002.917 6.76 3.755 3.755 0 002.6 1H13.1a3.875 3.875 0 002.723-1.109 9.267 9.267 0 002.724-7.863zm-3.809 6.75a2.328 2.328 0 01-1.638.67H5.519a2.221 2.221 0 01-1.537-.575 7.681 7.681 0 01-2.43-5.632 7.77 7.77 0 012.59-5.786A7.667 7.667 0 019.3 1.566a8.188 8.188 0 01.911.051 7.754 7.754 0 014.524 13.249zM6.057 12.65a.776.776 0 11-1.086 1.11A6.208 6.208 0 0110.2 3.181a.776.776 0 01.657.879.767.767 0 01-.879.658A4.655 4.655 0 006.06 12.65zm9.4-4.212a6.263 6.263 0 01.063.887 6.154 6.154 0 01-1.868 4.435.777.777 0 11-1.086-1.111 4.64 4.64 0 001.353-3.989.777.777 0 011.537-.222zm-4.65.49a1.577 1.577 0 11-1.1-1.1l2.934-2.934a.778.778 0 111.1 1.1z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18.625",
      "height": "17.084",
      "viewBox": "0 0 18.625 17.084"
    }
  };
  _exports.default = _default;
});