define("ember-svg-jar/inlined/logo-idol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined Shape</title><path d=\"M1.858 5.067a1.86 1.86 0 011.859 1.858V20.73a1.854 1.854 0 01-1.859 1.859A1.858 1.858 0 010 20.73V6.925C0 5.9.832 5.067 1.858 5.067zM0 1.88a1.858 1.858 0 113.717 0A1.858 1.858 0 010 1.88zM43.142.022A1.86 1.86 0 0145 1.882v18.85a1.853 1.853 0 01-1.858 1.857 1.861 1.861 0 01-1.859-1.856V1.88c0-1.026.832-1.859 1.859-1.859zM20.28 5.22v3.264c-.001.762-.323 1.34-1.014 1.674-.683.33-1.342.254-1.95-.204-3.04-2.285-7.233-.699-8.019 3.033-.652 3.092 1.992 6.148 5.143 5.923 1.548-.11 2.747-.9 3.746-2.038 1.028-1.174 1.771-2.535 2.55-3.872.443-.759.843-1.548 1.348-2.262.79-1.115 1.52-2.282 2.551-3.207 1.928-1.73 4.174-2.538 6.756-2.213 3.53.445 5.964 2.368 7.235 5.684 1.74 4.537-.953 9.87-5.624 11.205-2.825.807-5.43.335-7.792-1.418-.864-.641-1.022-1.764-.39-2.592.615-.808 1.734-.945 2.579-.315 1.914 1.428 4.307 1.415 6.199-.032 2.358-1.804 2.539-5.477.38-7.527-.933-.885-2.035-1.394-3.313-1.414-1.34-.02-2.509.483-3.485 1.403-.945.889-1.663 1.957-2.34 3.05-.815 1.316-1.498 2.713-2.37 3.992-.819 1.202-1.65 2.4-2.806 3.33-4.728 3.801-11.875 1.635-13.688-4.16C4.443 11.62 7.447 6.471 12.468 5.42a8.449 8.449 0 013.821.084c.254.064.344.025.34-.26-.015-1.11-.012-2.22-.006-3.33.005-.93.56-1.672 1.376-1.862 1.178-.273 2.261.56 2.277 1.772.015 1.132.003 2.264.003 3.396h.002z\" fill=\"#E53549\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "45",
      "height": "23",
      "viewBox": "0 0 45 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});