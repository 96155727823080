define("ember-svg-jar/inlined/retry-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.134 14.125c-.267-.173-.407-.536-.607-.29-1.805 2.315-4.74 3.464-7.397 2.19a6.3 6.3 0 01-3.464-7.072 5.844 5.844 0 016.106-4.444c2.946.048 4.853 1.483 5.445 4.363l-1.158-.655a.663.663 0 00-.888.22 1.174 1.174 0 00.307 1.22l2.003.972a1.531 1.531 0 001.929-.477l1.245-1.776a.925.925 0 00-.269-1.072c-.29-.188-.681-.235-.869.053l-.87 1.286a7.384 7.384 0 00-7.28-5.748 7.5 7.5 0 105.917 12.152.63.63 0 00-.15-.917z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20.75",
      "height": "20.75",
      "viewBox": "0 0 20.75 20.75"
    }
  };
  _exports.default = _default;
});