define("ember-svg-jar/inlined/add-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9D9FA3\" fill-rule=\"evenodd\" d=\"M.5 3h11a.5.5 0 110 1H.5a.5.5 0 010-1zm0 4h8a.5.5 0 010 1h-8a.5.5 0 010-1zm0 4h5a.5.5 0 110 1h-5a.5.5 0 110-1zM14 11V8.5a.5.5 0 111 0V11h2.5a.5.5 0 110 1H15v2.5a.5.5 0 11-1 0V12h-2.5a.5.5 0 110-1H14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});