define("ember-svg-jar/inlined/check-trusted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 2622\"><g data-name=\"Groupe 2621\"><circle data-name=\"Ellipse 37\" cx=\"9\" cy=\"9\" r=\"9\" fill=\"#4593ea\"/></g><path data-name=\"Trac&#xE9; 2700\" d=\"M4.329 9.666l3.162 3.261 3.834-3.849 2.346-2.356\" fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.8\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});