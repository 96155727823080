define("ember-svg-jar/inlined/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9d9fa3\"><path data-name=\"Trac&#xE9; 2710\" d=\"M1 4.75h2.736a3.728 3.728 0 007.2 0H23a1 1 0 000-2H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2zM7.333 2a1.75 1.75 0 11-1.75 1.75A1.75 1.75 0 017.333 2z\"/><path data-name=\"Trac&#xE9; 2711\" d=\"M23 11h-2.736a3.727 3.727 0 00-7.194 0H1a1 1 0 000 2h12.07a3.727 3.727 0 007.194 0H23a1 1 0 000-2zm-6.333 2.75a1.75 1.75 0 111.75-1.75 1.75 1.75 0 01-1.75 1.75z\"/><path data-name=\"Trac&#xE9; 2712\" d=\"M23 19.25H10.931a3.728 3.728 0 00-7.195 0H1a1 1 0 000 2h2.736a3.728 3.728 0 007.2 0H23a1 1 0 000-2zM7.333 22a1.75 1.75 0 111.75-1.75A1.75 1.75 0 017.333 22z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24.001",
      "viewBox": "0 0 24 24.001"
    }
  };
  _exports.default = _default;
});