define("ember-validated-form/-private/themed-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(component) {
    return Ember.computed({
      get: function get() {
        var parts = component.split("/");
        var componentNameParts = parts.slice(1, parts.length).join("/");

        if (Ember.get(this, "overrideComponents.".concat(componentNameParts))) {
          return Ember.get(this, "overrideComponents.".concat(componentNameParts));
        }

        var config = Ember.getOwner(this).resolveRegistration("config:environment")["ember-validated-form"] !== undefined ? Ember.getOwner(this).resolveRegistration("config:environment")["ember-validated-form"] : {};
        var theme = config.theme;
        var defaultComponent = Ember.get(config, "defaults.".concat(componentNameParts));
        var name = parts.pop();
        var basePath = parts.join("/");
        return defaultComponent || (theme ? "".concat(basePath, "/-themes/").concat(theme, "/").concat(name) : "".concat(basePath, "/").concat(name));
      },
      set: function set(key, value) {
        if (!Ember.get(this, "overrideComponents")) {
          Ember.set(this, "overrideComponents", {});
        }

        Ember.set(this, "overrideComponents.".concat(key), value);
        return value;
      }
    });
  };

  _exports.default = _default;
});