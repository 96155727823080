define("ember-cli-foundation-6-sass/templates/components/zf-off-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ry5DBfbs",
    "block": "[[[10,0],[14,0,\"off-canvas-wrapper-inner\"],[14,\"data-off-canvas-wrapper\",\"\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[10,0],[14,0,\"off-canvas position-left\"],[14,1,\"zf-off-canvas-left\"],[14,\"data-off-canvas\",\"\"],[12],[1,\"\\n      \"],[18,1,[[33,3]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"off-canvas position-right\"],[14,1,\"zf-off-canvas-right\"],[14,\"data-off-canvas\",\"\"],[14,\"data-position\",\"right\"],[12],[1,\"\\n      \"],[18,1,[[33,5]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"off-canvas-content\"],[14,\"data-off-canvas-content\",\"\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"showLeftOffCanvas\",\"yield\",\"offCanvasLeftContent\",\"showRightOffCanvas\",\"offCanvasRightContent\"]]",
    "moduleName": "ember-cli-foundation-6-sass/templates/components/zf-off-canvas.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});