define("ember-svg-jar/inlined/drag-and-drop-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M26 17.65l-1-.286V1H1v20h20.363l.286 1H0V0h26v17.65zM9 11a3 3 0 110-6 3 3 0 010 6zm0-1a2 2 0 100-4 2 2 0 000 4zm-9 5.435l4.105-4.194.684.699-4.105 4.194L0 15.435zm4.105-2.796l.684-.7 4.79 4.894-.684.7-4.79-4.894zm4.79 3.495l8.894-9.088.685.7-8.895 9.087-.684-.699zm8.894-7.69l.685-.699L26 15.435l-.684.7-7.527-7.69zM26.27 20.86l1.628-1.627-6.535-1.867 1.869 6.532 1.627-1.626 3.514 3.514a501.94 501.94 0 011.418-1.406l-3.52-3.52zm-6.087-3.983c-.153-.534.149-.845.69-.69l7.966 2.274c.535.153.655.59.261.983l-1.415 1.416 3.535 3.535c-.022-.022-2.848 2.804-2.848 2.804l-3.513-3.513-1.415 1.415c-.39.39-.828.28-.983-.261l-2.278-7.963z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "28",
      "viewBox": "0 0 32 28"
    }
  };
  _exports.default = _default;
});