define("ember-paper/components/paper-autocomplete/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8h4/AnQZ",
    "block": "[[[8,[39,0],[[24,0,\"md-autocomplete-suggestions\"],[24,\"role\",\"listbox\"],[16,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[30,1,[\"uniqueId\"]]]]],[17,2],[4,[38,1],[[30,0,[\"addHandlers\"]]],null]],[[\"@items\",\"@tagName\",\"@containerSelector\",\"@estimateHeight\",\"@staticHeight\",\"@bufferSize\"],[[30,3],\"ul\",\".md-autocomplete-suggestions-container\",48,true,3]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"li\"],[15,0,[29,[\"ember-power-select-option md-autocomplete-suggestion \",[52,[28,[37,3],[[30,4],[30,1,[\"highlighted\"]]],null],\"selected\"]]]],[15,\"aria-selected\",[29,[[28,[37,4],[[30,4],[30,1,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[52,[30,4,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,3],[[30,4],[30,1,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,6],[30,5]]]],[14,\"role\",\"option\"],[15,\"tabindex\",[52,[30,4,[\"disabled\"]],\"-1\",\"0\"]],[12],[1,\"\\n    \"],[18,7,[[30,4],[30,1]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[4,5]]]]]],[\"@select\",\"&attrs\",\"@options\",\"opt\",\"index\",\"@groupIndex\",\"&default\"],false,[\"vertical-collection\",\"did-insert\",\"if\",\"eq\",\"ember-power-select-is-selected\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-autocomplete/options/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});