define("ember-svg-jar/inlined/apply-to-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path data-name=\"copy (1)\" d=\"M11.25 15h-7.5A3.754 3.754 0 010 11.25v-7.5A3.754 3.754 0 013.75 0h7.5A3.754 3.754 0 0115 3.75v7.5A3.754 3.754 0 0111.25 15zM3.75 1.5A2.25 2.25 0 001.5 3.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25zM18 14.25V4.5a.75.75 0 10-1.5 0v9.75a2.25 2.25 0 01-2.25 2.25H4.5a.75.75 0 100 1.5h9.75A3.755 3.755 0 0018 14.25z\" fill=\"#9b9999\"/><path data-name=\"Ligne 136\" d=\"M7.609 10.807a.6.6 0 01-.6-.6V4.539a.6.6 0 01.6-.6.6.6 0 01.6.6v5.668a.6.6 0 01-.6.6z\" fill=\"#9b9999\"/><path data-name=\"Ligne 137\" d=\"M4.281 7.373a.6.6 0 01.6-.6h5.457a.6.6 0 01.6.6.6.6 0 01-.6.6H4.881a.6.6 0 01-.6-.6z\" fill=\"#9b9999\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});