define("ember-svg-jar/inlined/users-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#EC9493\" d=\"M22.462 32.46a10.939 10.939 0 01-5.238-7.868A3.485 3.485 0 0115 21.347v-.694c0-1.43.862-2.674 2.118-3.204v-1.89c0-4.175 3.38-7.559 7.551-7.559H38a1 1 0 011 1v8.504c1.192.56 2 1.774 2 3.15v.693c0 1.42-.85 2.66-2.1 3.196a10.933 10.933 0 01-5.683 8.167l1.99 4.063h5.63c3.4 0 6.163 2.739 6.163 6.113v2.862A25.909 25.909 0 0054 28a1 1 0 012 0c0 15.464-12.536 28-28 28S0 43.464 0 28 12.536 0 28 0a1 1 0 010 2C13.64 2 2 13.64 2 28a25.909 25.909 0 007 17.748V43c0-3.366 2.978-6.227 6.399-6.227h4.943l2.064-4.213a1.01 1.01 0 01.056-.1zM49 8h-4a1 1 0 010-2h4V2a1 1 0 012 0v4h4a1 1 0 010 2h-4v4a1 1 0 01-2 0V8zM24.252 33.318a1.01 1.01 0 01-.05.122l-2.338 4.773a1 1 0 01-.898.56h-5.567C13.065 38.773 11 40.757 11 43v4.673A25.899 25.899 0 0028 54c6.5 0 12.442-2.385 17-6.327v-4.787c0-2.266-1.863-4.113-4.162-4.113h-6.255a1 1 0 01-.898-.56l-2.314-4.724c-1.046.332-2.16.511-3.312.511-1.336 0-2.62-.24-3.807-.682zM24.67 10a5.555 5.555 0 00-5.551 5.558v2.643a1 1 0 01-.86.99c-.72.102-1.258.721-1.258 1.462v.694c0 .74.553 1.368 1.276 1.464a1 1 0 01.866.918C19.484 28.37 23.372 32 28.059 32c4.698 0 8.585-3.632 8.918-8.287a1 1 0 01.82-.913A1.469 1.469 0 0039 21.347v-.694c0-.707-.507-1.317-1.19-1.45a1 1 0 01-.81-.981V10H24.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56"
    }
  };
  _exports.default = _default;
});