define("ember-svg-jar/inlined/accounting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.819 17.756H3.476A3.6 3.6 0 010 14.057V3.7A3.6 3.6 0 013.476 0h8.343A3.6 3.6 0 0115.3 3.7v10.357a3.6 3.6 0 01-3.481 3.699zM3.476 1.48A2.156 2.156 0 001.391 3.7v10.357a2.156 2.156 0 002.086 2.22h8.343a2.156 2.156 0 002.086-2.22V3.7a2.156 2.156 0 00-2.086-2.22zm6.953 5.92H4.867a2.156 2.156 0 01-2.086-2.22 2.156 2.156 0 012.086-2.22h5.562a2.156 2.156 0 012.086 2.22 2.156 2.156 0 01-2.086 2.22zM4.867 4.439a.741.741 0 000 1.48h5.562a.741.741 0 000-1.48zM3.476 9.618a.741.741 0 000 1.48.741.741 0 000-1.48zm2.781 0a.741.741 0 10.7.74.719.719 0 00-.7-.74zm2.781 0a.741.741 0 10.7.74.719.719 0 00-.7-.74zm-5.562 2.959a.741.741 0 000 1.48.741.741 0 000-1.48zm2.781 0a.741.741 0 10.7.74.719.719 0 00-.7-.74zm5.562-2.959a.741.741 0 10.7.74.719.719 0 00-.7-.74zm.7 3.7a.719.719 0 00-.7-.74H9.038a.741.741 0 000 1.48h2.781a.719.719 0 00.696-.741z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15.296",
      "height": "17.756",
      "viewBox": "0 0 15.296 17.756"
    }
  };
  _exports.default = _default;
});