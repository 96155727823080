define("ember-svg-jar/inlined/cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.721.279a.953.953 0 00-1.348 0L6 4.652 1.628.279a.953.953 0 00-1.348 0 .953.953 0 000 1.348L4.653 6 .279 10.373a.953.953 0 000 1.348.953.953 0 001.348 0L6 7.348l4.373 4.373a.953.953 0 001.348 0 .953.953 0 000-1.348L7.348 6l4.373-4.373a.953.953 0 000-1.348z\" fill=\"#9d9fa3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
  _exports.default = _default;
});