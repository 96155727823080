define("ember-singularity-mixins/mixins/scroll-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SCROLL = 'scroll';
  var EVENTTARGET = 'eventTarget';
  var WINDOW = 'window';

  var _default = Ember.Mixin.create((_Ember$Mixin$create = {
    unifiedEventHandler: Ember.inject.service('unified-event-handler')
  }, _defineProperty(_Ember$Mixin$create, EVENTTARGET, WINDOW), _defineProperty(_Ember$Mixin$create, SCROLL, undefined), _defineProperty(_Ember$Mixin$create, "scrollEventInterval", undefined), _defineProperty(_Ember$Mixin$create, "triggerOnInsert", false), _defineProperty(_Ember$Mixin$create, "registerScrollHandlers", Ember.on('didInsertElement', function () {
    // TODO: limit this to the views object (this.$()) or the window
    var eventTarget = this.get(EVENTTARGET); // Bind 'this' context to the scroll handler for when passed as a callback

    var scroll = this.get(SCROLL).bind(this); // Save the newly bound function back as a reference for deregistration.

    this.set(SCROLL, scroll);
    this.get('unifiedEventHandler').register(eventTarget, SCROLL, scroll, this.get('scrollEventInterval'));
    this._scrollHandlerRegistered = true;

    if (this.get('triggerOnInsert')) {
      Ember.run.scheduleOnce('afterRender', scroll);
    }
  })), _defineProperty(_Ember$Mixin$create, "unregisterScrollHandlers", Ember.on('willDestroyElement', function () {
    if (this._scrollHandlerRegistered) {
      var scroll = this.get(SCROLL);
      var eventTarget = this.get(EVENTTARGET);
      this.get('unifiedEventHandler').unregister(eventTarget, SCROLL, scroll);
      this._scrollHandlerRegistered = false;
    }
  })), _Ember$Mixin$create));

  _exports.default = _default;
});