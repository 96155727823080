define("ember-svg-jar/inlined/placeholder-no-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5399\"><g data-name=\"Groupe 5370\"><g data-name=\"Groupe 5369\"><path data-name=\"Trac&#xE9; 3326\" d=\"M150.974 54.33c.089-1.035.137-2.086.137-3.148 0-16.633-11.2-30.118-25.019-30.118a21.69 21.69 0 00-12.136 3.783 59.224 59.224 0 00-36.518-12.259c-22.73 0-42.281 12.238-50.956 29.788a20.635 20.635 0 00-1.456-.052C11.2 42.323 0 55.806 0 72.44a34.508 34.508 0 004.117 16.554c0 14.964 11.9 27.1 26.582 27.1h18.508l.082-.142a4.219 4.219 0 015.507-1.214 5.828 5.828 0 001.461.734c2.144.459 3.583-3.015 5.753-2.678 1.089.17 1.939 1.3 3.034 1.188.727-.078 1.277-.691 1.96-.951a2.663 2.663 0 012.969 1.105c.445-1.288.441-2.815 1.161-4a4.219 4.219 0 015.507-1.214 5.827 5.827 0 001.461.734c2.144.459 3.583-3.015 5.753-2.678 1.089.17 1.939 1.3 3.034 1.188.727-.078 1.277-.691 1.96-.951 1.7-.653 3.445 1.119 3.864 2.9a12.471 12.471 0 01.256 2.39c1.468-.717 2.709-2.733 4.386-2.471 1.089.17 1.939 1.3 3.034 1.188.727-.078 1.277-.691 1.96-.951 1.7-.653 3.445 1.119 3.864 2.9a15.239 15.239 0 01.276 2.9h30.667a21.074 21.074 0 0020.871-21.275 34.678 34.678 0 003.691-15.751c-.002-10.228-4.25-19.274-10.744-24.715z\" fill=\"#f2f2f2\"/><path data-name=\"Trac&#xE9; 3324\" d=\"M127.926 65.512a44.286 44.286 0 00-82.8-11.828 35.444 35.444 0 003.846 70.683h76.885a29.66 29.66 0 0029.571-29.571 29.211 29.211 0 00-27.502-29.284zm-34.008 17.44v22.474H74.992V82.952H54.884l29.573-29.557 29.571 29.571z\" fill=\"#ccc\"/></g><path data-name=\"Rectangle 2339\" fill=\"none\" d=\"M14.538 0H16v10.91h-1.462z\"/><path data-name=\"Rectangle 2340\" fill=\"none\" d=\"M16 123h1.462v10.91H16z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "161.716",
      "height": "133.91",
      "viewBox": "0 0 161.716 133.91"
    }
  };
  _exports.default = _default;
});