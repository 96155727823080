define("ember-query-params-service/index", ["exports", "ember-query-params-service/utils/query-param", "ember-query-params-service/services/query-params"], function (_exports, _queryParam, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "queryParam", {
    enumerable: true,
    get: function get() {
      return _queryParam.queryParam;
    }
  });
  Object.defineProperty(_exports, "QueryParamsService", {
    enumerable: true,
    get: function get() {
      return _queryParams.default;
    }
  });
});