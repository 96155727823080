define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 2688\" d=\"M17.805.052c-7.631 0-7.293-.206-8.116.2S.628 9.413.628 9.413a2.15 2.15 0 000 3.037l6.925 6.923A2.134 2.134 0 009.071 20a2.133 2.133 0 001.519-.627c9.3-9.3 9.256-8.932 9.362-9.6s0-7.571 0-7.571a2.15 2.15 0 00-2.147-2.15zm.374 9.361c-.125.162-.826.858-.826.858l-8.07 7.727c-.184.184-.6 0-.787-.181l-6.547-6.221a.678.678 0 010-1.107s7.9-8.1 8.641-8.585 6.965 0 6.965 0c.54 0 .872.212.872.752 0 0-.127 6.595-.248 6.757zm-.479.12\"/><path data-name=\"Trac&#xE9; 2689\" d=\"M14.778 2.82a2.362 2.362 0 101.67.692 2.346 2.346 0 00-1.67-.692zm.607 2.968a.858.858 0 11.251-.607.853.853 0 01-.251.607zm0 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});