define("ember-paper/components/paper-autocomplete/ebd-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TbWAByh1",
    "block": "[[[8,[39,0],[[24,0,\"md-virtual-repeat-container md-autocomplete-suggestions-container\"],[17,1]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"@otherStyles\",\"@animationEnabled\",\"@htmlTag\",\"@shouldReposition\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,0,[\"customStyles\"]],false,\"md-virtual-repeat-container\",[30,0,[\"shouldReposition\"]]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[18,14,null],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@width\",\"@height\",\"&default\"],false,[\"basic-dropdown-content\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-autocomplete/ebd-content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});