define("ember-feature-controls/instance-initializers/load-feature-controls", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var featureControls = _emberGetConfig.default.featureControls;

  function initialize(appInstance) {
    var features = appInstance.lookup('service:features');

    if (featureControls && featureControls.useLocalStorage) {
      var controlStorageService = appInstance.lookup('service:feature-control-storage'); // result of controlStorageService.get('featuresLS') is an ObjectProxy we need to use "content"

      var _controlStorageServic = controlStorageService.get('featuresLS'),
          _featureControls = _controlStorageServic.content;

      if (_featureControls) {
        Object.keys(_featureControls).forEach(function (flag) {
          if (features.get('flags').includes(flag)) {
            _featureControls[flag] ? features.enable(flag) : features.disable(flag);
          }
        });
      }
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});