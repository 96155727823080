define("ember-content-loader/components/content-loader-svg", ["exports", "ember-content-loader/templates/components/content-loader-svg"], function (_exports, _contentLoaderSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _contentLoaderSvg.default,
    tagName: 'svg',
    attributeBindings: ['role', 'rtlStyle:style', 'aria-label', 'viewBox', 'preserveAspectRatio'],
    role: 'img',
    rtlStyle: Ember.computed('rtl', function () {
      return Ember.String.htmlSafe(this.rtl ? 'transform: scaleX(-1)' : '');
    }),
    'aria-label': Ember.computed('ariaLabel', function () {
      return this.ariaLabel || null;
    }),
    viewBox: Ember.computed('width', 'height', function () {
      return Ember.String.htmlSafe("0 0 ".concat(this.width, " ").concat(this.height));
    }),
    rectClipPath: Ember.computed('baseUrl', 'idClip', function () {
      return Ember.String.htmlSafe("url(".concat(this.baseUrl, "#").concat(this.idClip, ")"));
    }),
    rectStyle: Ember.computed('baseUrl', 'idGradient', function () {
      return Ember.String.htmlSafe("fill: url(".concat(this.baseUrl, "#").concat(this.idGradient, ")"));
    }),
    animateValues1: Ember.computed('gradientRatio', function () {
      return Ember.String.htmlSafe("".concat(-this.gradientRatio, "; ").concat(-this.gradientRatio, "; 1"));
    }),
    animateValues2: Ember.computed('gradientRatio', function () {
      var div2 = this.gradientRatio / 2;
      return Ember.String.htmlSafe("".concat(-div2, "; ").concat(-div2, "; ").concat(1 + div2));
    }),
    animateValues3: Ember.computed('gradientRatio', function () {
      return Ember.String.htmlSafe("0; 0; ".concat(1 + this.gradientRatio));
    }),
    idClip: Ember.computed('elementId', function () {
      return Ember.String.htmlSafe("".concat(this.elementId, "-idClip"));
    }),
    idGradient: Ember.computed('elementId', function () {
      return Ember.String.htmlSafe("".concat(this.elementId, "-idGradient"));
    }),
    keyTimes: Ember.computed('interval', function () {
      return "0; ".concat(this.interval, "; 1");
    }),
    dur: Ember.computed('speed', function () {
      return Ember.String.htmlSafe("".concat(this.speed, "s"));
    })
  });

  _exports.default = _default;
});