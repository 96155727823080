define("ember-truncate/components/ember-truncate/button-toggle", ["exports", "ember-truncate/templates/components/button-toggle"], function (_exports, _buttonToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _buttonToggle.default,
    tagName: '',
    DOMClick: function DOMClick() {
      var onClick = this.onClick;

      if (typeof onClick === 'function') {
        onClick();
      }
    }
  });

  _exports.default = _default;
});