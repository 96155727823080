define("ember-svg-jar/inlined/upload-multiple-audios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5650\"><g data-name=\"Groupe 5648\"><g data-name=\"Groupe 5649\" fill=\"#9b9999\"><path data-name=\"Trac&#xE9; 3493\" d=\"M13.768 12.651a.79.79 0 01-.8.78h-.8v3.51a2 2 0 11-2-1.947 2.109 2.109 0 01.4.039v-1.6a1.579 1.579 0 011.6-1.56h.8a.789.789 0 01.8.78zm5.595-1.182l-.042 5c0 .279 2.869.621 3.9 2.725.643 1.31-.448 4.394-1.192 4.956-.448.339-3.509 1.723-5.5.843-1.809-.8-2.565-3.92-2.76-3.8-.37.249 1.266.769.612.822l-7.003-.004a3.954 3.954 0 01-4-3.9V7.191a3.954 3.954 0 014-3.9h3.6a5.629 5.629 0 013.961 1.6l2.779 2.717a5.36 5.36 0 011.645 3.862zm-5.36-5.861c-.251-.238-.98-.255-1.287-.421v3.564c0 .431.61 1.147 1.051 1.147l3.957.162a11.478 11.478 0 00-.717-1.6zm3.99 5.861c0-.128.012-.252 0-.378h-4.225a2.37 2.37 0 01-2.4-2.34V4.514a38.589 38.589 0 00-4 0 2.953 2.953 0 00-2.69 2.677v10.918a2.956 2.956 0 002.7 2.682l6.945.017c.1.4.3 3.232 2.208 4.183 1.58.8 4.895-.349 5.5-1.466 0 0 1.869-2.568 1.192-4.332s-3.9-2.725-3.9-2.725c.062.211-1.314.225-1.314 0z\"/><path data-name=\"Trac&#xE9; 3077\" d=\"M18.621 16.654a4.106 4.106 0 104.214 4.105 4.161 4.161 0 00-4.214-4.105m0-1.135a5.242 5.242 0 11-5.38 5.241 5.311 5.311 0 015.38-5.241z\"/><g data-name=\"Groupe 4088\"><path data-name=\"Ligne 136\" d=\"M18.619 23.598a.664.664 0 01-.672-.655v-4.367a.673.673 0 011.345 0v4.367a.664.664 0 01-.673.655z\"/><path data-name=\"Ligne 137\" d=\"M15.706 20.76a.664.664 0 01.672-.655h4.483a.655.655 0 110 1.31h-4.483a.664.664 0 01-.672-.655z\"/></g></g></g><path d=\"M4.053 0A4.185 4.185 0 000 4.117v11.526c0 2.267 1.816 2.874 4.053 2.877 0 0-1.266-8.951.9-13.36.728-1.481 6.631-.967 6.631-.967 0-.058.516-1.2 0-1.8A4.1 4.1 0 009.519.704 20.22 20.22 0 004.053 0zm0 17.325c-1.343 0-2.739-.319-2.739-1.683V4.117A2.7 2.7 0 014.053 1.25a27.554 27.554 0 015.226.555c1.528.482.955 1.607.955 1.607-.053.356-.246.062-.3.585-.67.449-3.6-.108-5.294.838z\" fill=\"#9b9999\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "26",
      "viewBox": "0 0 24 26"
    }
  };
  _exports.default = _default;
});