define("ember-svg-jar/inlined/dolby-atmos-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Groupe 5237\" transform=\"translate(-1108 -417)\"><rect data-name=\"Rectangle 2313\" width=\"20\" height=\"20\" rx=\"1\" transform=\"translate(1108 417)\"/><g data-name=\"Groupe 5238\"><path data-name=\"Rectangle 2311\" fill=\"#fff\" d=\"M1110.751 422.965h14.089v7.685h-14.089z\"/><g data-name=\"3efe6e5eb37b43153b12f568d0e97175\"><path data-name=\"Trac&#xE9; 3058\" d=\"M1110.246 422.359v9.281h15.508v-9.281zm3.6 8.078h-1.771v-6.878h1.771a3.439 3.439 0 010 6.877zm10.085 0h-1.776a3.439 3.439 0 010-6.877h1.772z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});