define("ember-svg-jar/inlined/attention-big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle data-name=\"Ellipse 62\" cx=\"17.5\" cy=\"17.5\" r=\"17.5\" fill=\"#ffcb1d\"/><g transform=\"translate(16.001 8.31)\" fill=\"#fff\"><path data-name=\"Trac&#xE9; 3122\" d=\"M1.661-.003A1.8 1.8 0 000 1.676v10.759a1.813 1.813 0 001.661 1.691 1.813 1.813 0 001.661-1.691V1.676A1.8 1.8 0 001.661-.003z\"/><rect data-name=\"Rectangle 2322\" width=\"3.322\" height=\"3.559\" rx=\"1.661\" transform=\"translate(0 14.821)\"/></g>",
    "attrs": {
      "data-name": "Groupe 5464",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35"
    }
  };
  _exports.default = _default;
});