define("ember-paper/templates/components/paper-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TG0+BPcs",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]],[8,[39,4],null,null,null]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"name\",\"paper-ripple\"]]",
    "moduleName": "ember-paper/templates/components/paper-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});