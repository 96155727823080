define("liquid-tether/index", ["exports", "liquid-fire/constraint"], function (_exports, _constraint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.target = target;
  _exports.onOpenTether = onOpenTether;
  _exports.onCloseTether = onCloseTether;

  function target(name) {
    return new _constraint.default('parentElementClass', "".concat(name));
  }

  function onOpenTether() {
    return new _constraint.default('newValue', function (value) {
      return value !== null;
    });
  }

  function onCloseTether() {
    return new _constraint.default('newValue', function (value) {
      return value === null;
    });
  }
});