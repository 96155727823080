define("ember-svg-jar/inlined/line-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 1.5A1.5 1.5 0 0116.5 3h-.058l-.007.068a12.391 12.391 0 01-.457 2.33c-.513 1.667-1.623 3.6-3.978 3.6a5.227 5.227 0 01-3.277-1.084A4.253 4.253 0 006 7a3.239 3.239 0 00-2.7 1.765 7.284 7.284 0 00-.628 1.286c-.055.148-.1.275-.128.371A1.5 1.5 0 111.501 10c.043 0 .085 0 .127.005.03-.089.065-.192.108-.306a8.291 8.291 0 01.716-1.464A4.229 4.229 0 016 6a5.227 5.227 0 013.277 1.084A4.253 4.253 0 0012 8c1.645 0 2.535-1.314 3.022-2.9a11.363 11.363 0 00.418-2.138q.021-.2.033-.372A1.5 1.5 0 1118 1.5zm-1.99.1a.5.5 0 00.962.065A.507.507 0 0017 1.5a.5.5 0 00-1 0 .488.488 0 00.01.1zM1.5 12a.5.5 0 10-.5-.5.5.5 0 00.5.5z\" fill=\"#9d9fa3\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "13",
      "viewBox": "0 0 18 13"
    }
  };
  _exports.default = _default;
});